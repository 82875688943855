import React from 'react'
import { useRecoilValue } from 'recoil'

import AppIcon, { AppIconIdentifier } from 'components/icons/AppIcon'
import DashboardEditorBody from '../base/DashboardEditorBody'
import DashboardEditorHeader from '../base/DashboardEditorHeader'
import DataSourcesView from 'components/dashboardEditor/graph/DataSourcesView'
import Flex from 'components/layout/Flex'
import MediaCard from 'components/mediaCard/MediaCard'
import OperationsView from 'components/dashboardEditor/graph/OperationsView'
import ResourcesView from 'components/dashboardEditor/graph/ResourcesView'
import Text from 'components/typography/Text'
import useDashboard from 'hooks/useDashboard'
import { ViewParams, Views } from 'components/dashboardEditor/constants'
import type { ActiveViewProps } from '../DashboardEditor'
import type { App } from 'generated/schema'

type Params = ViewParams[Views.PROJECT_DETAILS]

const ProjectDetailsView = ({ onClose }: ActiveViewProps) => {
  const { dashboardEditorState, openDashboardEditorView } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { app, parentView } = params! as Params
  const { name, workspaceId, identifier, icon } = app as App

  return (
    <>
      <DashboardEditorHeader
        subtitle={name}
        onEdit={() => openDashboardEditorView({
          target: Views.EDIT_PROJECT,
          params: { initialValues: app }
        })}
        heading={parentView ? 'Dashboard Editor' : 'Projects'}
        onClose={onClose}
        icon={(
          <AppIcon
            isProject
            isCustomApp={!!workspaceId}
            identifier={identifier as AppIconIdentifier}
            icon={icon}
            size={24}
          />
        )}
      />
      <DashboardEditorBody>
        <ResourcesView />
        <OperationsView />
        <DataSourcesView />
        <Actions />
      </DashboardEditorBody>
    </>
  )
}

const actions = [
  {
    title: 'Create Resource',
    identifier: Views.CREATE_RESOURCE,
    media: 'graph'
  },
  {
    title: 'Create Operation',
    identifier: Views.CREATE_OPERATION,
    media: 'advance'
  },
  {
    title: 'Import from Database',
    identifier: Views.IMPORT_FROM_DATABASE,
    media: 'app-database-hub'
  },
  {
    title: 'Import from API',
    identifier: Views.IMPORT_FROM_API,
    media: 'app-api-hub'
  }
]

const Actions = () => {
  const { openDashboardEditorView, dashboardEditorState } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { app } = params! as Params

  return (
    <Flex gap={16} direction="column">
      <Text
        color="dark500"
        fontSize={10}
        fontWeight="bold"
        textTransform="uppercase"
      >
        Actions
      </Text>
      <Flex direction="column" gap={2}>
        {actions.map((action) => {
          const onClick = () => openDashboardEditorView({
            target: action.identifier,
            params: { app }
          })

          return (
            <MediaCard
              compact
              key={action.identifier}
              onClick={onClick}
              media={action.media}
              title={action.title}
              height={64}
              width="full"
              actions={[ {
                description: '',
                icon: 'arrow-right',
                onClick,
                isIconAlwaysVisible: true
              } ]}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}

export default ProjectDetailsView

export { Actions }
