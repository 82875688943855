/* TODO: Improve this
  function definitons and return are not proper
  improve the whole component for general purpose
*/

import arrayMutators from 'final-form-arrays'
import get from 'lodash/get'
import React, { useMemo, useRef, useState } from 'react'

import { Field, Form } from 'react-final-form'
import { Scrollbars } from 'react-custom-scrollbars'
import type { DropResult, ResponderProvided } from 'react-beautiful-dnd'

import * as mixins from 'styles/mixins'
import Button from 'components/buttons/Button'
import ChipRenderer from 'components/renderers/ChipRenderer'
import DataList from 'components/dataList/DataList'
import DateTimeField from 'components/contentEditors/generic/fields/DateTimeField'
import DropdownField from 'components/contentEditors/generic/fields/DropdownField'
import FieldArray, { FieldArrayChildrenProps } from 'components/form/FieldArray'
import FieldGroup from 'components/form/FieldGroup'
import Flex from 'components/layout/Flex'
import FormValuesField from 'components/form/FormValuesField'
import Icon from 'components/icons/Icon'
import Loader from 'components/loaders/Loader'
import NumberField from 'components/contentEditors/generic/fields/NumberField'
import SelectInput from 'components/inputs/SelectInput'
import SwitchField from 'components/contentEditors/generic/fields/SwitchField'
import Tab from 'components/tabs/Tab'
import Tabs from 'components/tabs/Tabs'
import Text from 'components/typography/Text'
import TextField from 'components/contentEditors/generic/fields/TextField'
import TextInput from 'components/inputs/TextInput'
import TextLink from 'components/links/TextLink'
import ToggleInput from 'components/inputs/ToggleInput'
import {
  Popover,
  PopoverBody,
  PopoverContainer,
  PopoverFooter
} from 'components/popover'
import { styled } from 'styles/stitches'
import type { Space } from 'styles/theme'
import type { DEFAULT_ROW_DATA } from 'components/providers/DataManagerProvider'
import type { Column } from 'components/dataTable/types'

type Operators = typeof OPERATION_OPTIONS[number]['value']

type OperatorTypeMap = {
  in?: string[],
  gt?: string | number,
  gte?: string | number,
  lt?: string | number,
  lte?: string | number,
  contains?: string,
  eq?: string | number | boolean,
  neq?: string | number | boolean
}

type CommonFilterType = Record<string, OperatorTypeMap | 'notNull' | 'null'>
type ArrayFilterType = Partial<Record<'and' | 'or', Array<CommonFilterType>>>
type FilterType = CommonFilterType | ArrayFilterType

type FiterValue =
  | { value: string, operation: 'contains', column: string }
  | { value: string | number | boolean, operation: 'eq' | 'neq', column: string }
  | { value: string[], operation: 'in', column: string }
  | { value: string | number, operation: 'gt' | 'lt' | 'gte' | 'lte', column: string }
  | { value: undefined, operation: 'null' | 'notNull', column: string };

type CustomizeDisplayProps<T extends DEFAULT_ROW_DATA> = {
  disabled?: boolean,
  labelKey?: string,
  metaKey?: string,
  columns: any[],
  filters: FilterType,
  onReorder?: (
    result: DropResult,
    provided?: ResponderProvided,
    currentLevelData?: T[]
  ) => void,
  onFilterChange: React.Dispatch<React.SetStateAction<FilterType>>,
  onEditColumn?: () => void,
  onVisibilityToggle: (index: number, value: boolean) => void
};

const TOP_MENU_HEADER_SPACING_X: Space = 24
const TOP_MENU_HEADER_SPACING_Y: Space = 20
const POPOVER_WIDTH = 640
const POPOVER_MAX_HEIGHT = 320
const FILTERABLE_FIELD_TYPE_MAP = Object.freeze({
  'text-field': TextField,
  'uid-field': TextField,
  'number-field': NumberField,
  'switch-field': SwitchField,
  'date-time-field': DateTimeField,
  'relationship-field': TextField,
  'dropdown-field': DropdownField
})

const FILTERABLE_FIELD_TYPES = Object.keys(
  FILTERABLE_FIELD_TYPE_MAP
) as unknown as keyof typeof FILTERABLE_FIELD_TYPE_MAP

const NUMERIC_OPERATIONS = [
  { label: 'Greater than', value: 'gt' },
  { label: 'Greater than or equals', value: 'gte' },
  { label: 'Less than', value: 'lt' },
  { label: 'Less than or equals', value: 'lte' }
] as const

const COMMON_OPERATIONS = [
  { label: 'Is', value: 'eq' },
  { label: 'Is not', value: 'neq' },
  { label: 'Is empty', value: 'null' },
  { label: 'Is not empty', value: 'notNull' }
] as const

const STRING_OPERATIONS = [
  { label: 'Contains', value: 'contains' },
  { label: 'Matches', value: 'matches' }
] as const

const LIST_OPERATIONS = [
  { label: 'Includes', value: 'in' }
]

const OPERATION_OPTIONS = [
  ...NUMERIC_OPERATIONS,
  ...STRING_OPERATIONS,
  ...LIST_OPERATIONS,
  ...COMMON_OPERATIONS
] as const

const FIELD_TYPE_OPERATION_MAP: Record<typeof FILTERABLE_FIELD_TYPES,
  | typeof STRING_OPERATIONS
  | typeof NUMERIC_OPERATIONS
  | typeof LIST_OPERATIONS
  | []> = {
    'text-field': [ ...STRING_OPERATIONS, ...LIST_OPERATIONS ],
    'uid-field': [],
    'number-field': NUMERIC_OPERATIONS,
    'switch-field': [],
    'date-time-field': NUMERIC_OPERATIONS,
    'relationship-field': [],
    'dropdown-field': LIST_OPERATIONS
  }

const StyledButton = styled('button', {
  ...mixins.transition('fluid'),

  width: 40,
  height: '100%',
  color: 'dark100',

  '&:hover': {
    color: 'dark700'
  },

  '&[disabled]': {
    cursor: 'not-allowed',
    [`& ${Icon}`]: {
      color: 'dark100'
    }
  },

  variants: {
    active: {
      true: {
        color: 'dark700'
      }
    }
  }
})

const StyledPopoverHeader = styled(Flex, {
  ...mixins.transition('simple'),

  paddingY: TOP_MENU_HEADER_SPACING_Y,
  paddingX: TOP_MENU_HEADER_SPACING_X
})

const StyledCustomizeDisplayPopover = styled(Popover, {
  width: POPOVER_WIDTH,
  maxHegiht: POPOVER_MAX_HEIGHT
})

const StyledScrollbar = styled(Scrollbars, {
  flexGrow: 1,
  overflow: 'auto'
})

const StyledFieldContainer = styled('div', {
  flex: '1 1 0',
  alignItems: 'baseline',
  variants: {
    size: {
      small: {
        maxWidth: 150
      },
      normal: {
        maxWidth: 250
      }
    }
  }
})

const toggleFieldProps = {
  name: 'toggle',
  onBlur: () => { },
  onFocus: () => { },
  value: ''
}

const parseValue = (filter: FiterValue): OperatorTypeMap | 'notNull' | 'null' => {
  switch (filter.operation) {
    case 'null':
      return filter.operation
    case 'notNull':
      return filter.operation

    default:
      return { [filter.operation]: filter.value } as OperatorTypeMap
  }
}

const constructFilters = (filter: FiterValue): CommonFilterType => ({
  [filter.column]: parseValue(filter)
})

const constructInitialValues = (filters: FilterType) => ({
  filters: (filters.and as ArrayFilterType['and'])?.map((filt: CommonFilterType) => {
    const [ key, filter ] = Object.entries(filt)[0]
    const operation = (
      typeof filter === 'string'
        ? filter
        : Object.keys(filter)[0] as Operators || 'contains'
    ) as keyof OperatorTypeMap | 'null' | 'notNull'

    return {
      column: key,
      operation,
      value: operation === 'null' || operation === 'notNull' || typeof filter === 'string' ? undefined : filter[operation]
    } as FiterValue
  }) || []
})

function CustomizeDisplay<T extends DEFAULT_ROW_DATA>({
  disabled = false,
  labelKey = 'title',
  metaKey = 'fieldType',
  columns,
  filters,
  onReorder,
  onEditColumn,
  onFilterChange,
  onVisibilityToggle
}: CustomizeDisplayProps<T>) {
  const fieldsRef = useRef<FieldArrayChildrenProps<any>>()

  const initialValues: { filters: FiterValue[] } = constructInitialValues(filters)

  const actions = onEditColumn ? [
    {
      icon: 'edit',
      title: 'Edit',
      onClick: onEditColumn
    }
  ] : []

  const filterOptions = useMemo(
    () => columns
      .filter((col) => FILTERABLE_FIELD_TYPES.includes(col.fieldType))
      .map((col) => ({
        label: col.title,
        value: col.dataKey
      })),
    [ columns ]
  )

  return (
    <PopoverContainer
      placement="bottom-end"
      modifiers={[ { name: 'offset', options: { offset: [ 10, 10 ] } } ]}
    >
      {({ isActive, closePopover, openPopover, ...otherToggleProps }) => (
        <StyledButton
          disabled={disabled}
          type="button"
          aria-label="Customize Display"
          // @ts-ignore
          active={isActive || !!filters.and?.length}
          {...otherToggleProps}
        >
          <Icon name="filter" data-icon size={24} />
        </StyledButton>
      )}
      {(popoverProps) => (
        <StyledCustomizeDisplayPopover
          withArrow
          {...popoverProps}
        >
          <StyledPopoverHeader>
            <Text fontSize={12} textTransform="uppercase" color="dark500">
              Customize Display
            </Text>
          </StyledPopoverHeader>
          <Tabs>
            <Tab label="Filters" index={0}>
              <Flex direction="column" style={{ maxHeight: '400px' }}>
                <Form
                  keepDirtyOnReinitialize
                  initialValues={initialValues}
                  mutators={{
                    ...arrayMutators
                  }}
                  subscription={{ submitting: true, pristine: true }}
                  onSubmit={(values) => {
                    onFilterChange((prev) => ({
                      ...prev, and: values.filters.map(constructFilters)
                    }))
                  }}
                  render={({ handleSubmit, submitting, pristine }) => (
                    <>
                      <PopoverBody>
                        <StyledScrollbar
                          autoHide
                          autoHeight
                          autoHeightMax={POPOVER_MAX_HEIGHT - 100}
                          renderTrackHorizontal={() => <div />}
                          renderThumbHorizontal={() => <div />}
                        >
                          <FilterBody
                            fieldName="filters"
                            fieldsRef={fieldsRef}
                            filterOptions={filterOptions}
                            columns={columns}
                          />
                        </StyledScrollbar>
                      </PopoverBody>
                      <PopoverFooter withDivider={false}>
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          grow={1}
                          style={{ padding: '16px' }}
                        >
                          <Flex alignItems="center">
                            <TextLink
                              fontSize={12}
                              as="button"
                              type="button"
                              variant="underlined"
                              mode="subtle"
                              alignSelf="flex-start"
                              fontWeight="bold"
                              onClick={() => fieldsRef.current?.fields.push({
                                column: '',
                                operation: 'eq',
                                value: ''
                              })}
                            >
                              Add Filter
                            </TextLink>
                          </Flex>
                          <Flex gap={8}>
                            <Button
                              size="small"
                              label="Clear Filters"
                              variant="simple"
                              mode="subtle"
                              onClick={() => {
                                fieldsRef.current?.fields.map(() => fieldsRef.current?.fields.pop())
                                handleSubmit()
                                popoverProps.closePopover()
                              }}
                            />
                            <Button
                              onClick={() => {
                                handleSubmit()
                                popoverProps.closePopover()
                              }}
                              size="small"
                              type="submit"
                              disabled={submitting || pristine}
                              label="Submit"
                            />
                          </Flex>
                        </Flex>
                      </PopoverFooter>
                    </>
                  )}
                />
              </Flex>
            </Tab>
            <Tab label="Columns" index={1}>
              <PopoverBody>
                <Flex
                  direction="column"
                  gap={20}
                  style={{ maxHeight: '400px' }}
                >
                  <StyledScrollbar
                    autoHide
                    autoHeight
                    autoHeightMax={POPOVER_MAX_HEIGHT}
                    renderTrackHorizontal={() => <div />}
                    renderThumbHorizontal={() => <div />}
                  >
                    <DataList
                      actions={actions}
                      contents={[
                        { dataKey: labelKey, slot: 'primary' },
                        {
                          dataKey: 'hidden',
                          slot: 'toggle',
                          renderer: ({ rowData, dataKey, index }) => {
                            const value = get(rowData, dataKey!)
                            return (
                              <ToggleInput
                                meta={{}}
                                input={{
                                  checked: !value,
                                  onChange: (e) => onVisibilityToggle?.(
                                    index || 0,
                                    e.target.checked
                                  ),
                                  ...toggleFieldProps
                                }}
                              />
                            )
                          }
                        },
                        {
                          dataKey: metaKey,
                          slot: 'meta',
                          renderer: ChipRenderer
                        }
                      ]}
                      data={columns}
                      onRowDragEnd={onReorder}
                      selectionMode="none"
                    />
                  </StyledScrollbar>
                </Flex>
              </PopoverBody>
            </Tab>
          </Tabs>
        </StyledCustomizeDisplayPopover>
      )}
    </PopoverContainer>
  )
}

type ValueFieldComponentType = {
  isInitiallyVariable?: boolean,
  fieldProps: any
}

const ValueFieldComponent: React.FC<ValueFieldComponentType> = ({
  children, isInitiallyVariable = false, fieldProps
}) => {
  const [ isVariable, setIsVariable ] = useState(isInitiallyVariable)
  return (
    <Flex direction="column" gap={8}>
      {isVariable ? (
        <Field
          component={TextInput}
          {...fieldProps}
        />
      ) : children}
      <TextLink
        fontSize={12}
        as="button"
        type="button"
        variant="underlined"
        mode="subtle"
        alignSelf="flex-start"
        fontWeight="bold"
        onClick={() => setIsVariable(!isVariable)}
      >{isVariable ? 'Use Default' : 'Use Variable'}
      </TextLink>
    </Flex>
  )
}

type FilterBodyProps = {
  fieldName: string,
  fieldsRef: React.MutableRefObject<FieldArrayChildrenProps<any> | undefined>,
  filterOptions: any,
  columns: any[],
  noFilterMessage?: string
}

const FilterBody = ({
  fieldName: filterFieldName, fieldsRef, filterOptions, columns, noFilterMessage = 'No filters applied'
}: FilterBodyProps) => (
  <Flex
    direction="column"
    gap={18}
    style={{ padding: '16px' }}
  >
    <FieldArray name={filterFieldName} fieldsRef={fieldsRef}>
      {({ fields }) => (
        <Loader
          data={fields.value}
          empty={{ title: noFilterMessage, subtitle: 'Click on "Add Filter" to get started.' }}
        >
          {fields.map((fieldName, index) => (
            <FieldGroup
              hasFieldLabel
              alignItems="baseline"
              key={fieldName}
              onClick={() => fields.remove(index)}
            >
              <StyledFieldContainer size="small">
                <Field
                  component={SelectInput}
                  name={`${filterFieldName}.${index}.column`}
                  label="Column"
                  size="small"
                  options={filterOptions}
                />
              </StyledFieldContainer>

              <FormValuesField
                fieldNames={[
                  `${filterFieldName}.${index}.column`,
                  `${filterFieldName}.${index}.operation`
                ]}
              >
                {(values) => {
                  const operation = values[
                    `${filterFieldName}.${index}.operation`
                  ]
                  const column: Column = columns.find((col) => col.dataKey === values[`${filterFieldName}.${index}.column`])

                  // @ts-ignore
                  const FieldComponent = FILTERABLE_FIELD_TYPE_MAP[
                    column?.fieldType!
                  ] || TextField

                  const props = {
                    name: `${filterFieldName}.${index}.value`,
                    size: 'small',
                    label: 'Value',
                    settings: column?.settings,
                    ...column?.fieldProps
                  }

                  const selectFieldProps = {
                    isCreatable: operation === 'in',
                    isArray: operation === 'in',
                    ...column?.fieldProps,
                    options: column?.fieldProps?.options?.map(
                      (o: any) => ({ value: o.key, ...o })
                    ) || []
                  }

                  const allowedOperations = column?.fieldType
                    ? [
                      ...FIELD_TYPE_OPERATION_MAP[
                        column.fieldType as typeof FILTERABLE_FIELD_TYPES
                      ],
                      ...COMMON_OPERATIONS
                    ]
                    : COMMON_OPERATIONS

                  return (
                    <>
                      <StyledFieldContainer size="small">
                        <Field
                          component={SelectInput}
                          defaultValue={allowedOperations[0].value}
                          name={`${filterFieldName}.${index}.operation`}
                          label="Operation"
                          size="small"
                          options={allowedOperations}
                        />
                      </StyledFieldContainer>
                      <FormValuesField fieldNames={[ `${filterFieldName}.${index}.value` ]}>
                        {(values) => {
                          const fieldValue = get(values, `${filterFieldName}.${index}.value`)
                          const isVariables = typeof fieldValue === 'string' && fieldValue.includes('{{')
                          return (
                            <StyledFieldContainer size="normal">
                              {operation === 'in' && (
                                <ValueFieldComponent
                                  isInitiallyVariable={isVariables}
                                  fieldProps={{ ...props, ...selectFieldProps }}
                                >
                                  <DropdownField
                                    {...props}
                                    {...selectFieldProps}
                                  />
                                </ValueFieldComponent>
                              )}
                              {![ 'null', 'notNull', 'in' ].includes(operation) && (
                                <ValueFieldComponent
                                  isInitiallyVariable={isVariables}
                                  fieldProps={{ ...props, ...selectFieldProps }}
                                >
                                  <FieldComponent
                                    {...props}
                                    {...selectFieldProps}
                                  />
                                </ValueFieldComponent>
                              )}
                            </StyledFieldContainer>
                          )
                        }}
                      </FormValuesField>

                    </>
                  )
                }}
              </FormValuesField>
            </FieldGroup>
          ))}
        </Loader>
      )}
    </FieldArray>
  </Flex>
)

export default CustomizeDisplay

export type {
  ArrayFilterType,
  CommonFilterType,
  CustomizeDisplayProps,
  FilterBodyProps,
  FilterType,
  FiterValue,
  OperatorTypeMap
}

export {
  constructFilters,
  constructInitialValues,
  FilterBody,
  FILTERABLE_FIELD_TYPES,
  OPERATION_OPTIONS,
  COMMON_OPERATIONS,
  NUMERIC_OPERATIONS,
  STRING_OPERATIONS
}
