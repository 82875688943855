import camelCase from 'lodash/camelCase'
import get from 'lodash/get'
import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'
import React, { Suspense, useState } from 'react'
import set from 'lodash/set'

import AddRecordView from 'components/views/graph/AddRecordView'
import Button from 'components/buttons/Button'
import Chip from 'components/chip/Chip'
import DataTableBlock from 'components/blocks/DataTableBlock'
import DataType from 'models/DataType'
import GenericResourceDetailsView from 'components/views/graph/GenericResourceDetailsView'
import IconButton from 'components/buttons/IconButton'
import SearchBar from 'components/searchbar/SearchBar'
import Tabs from 'components/tabs/Tabs'
import Tab from 'components/tabs/Tab'
import TextRenderer from 'components/renderers/TextRenderer'
import useConfirmation from 'hooks/useConfirmation'
import usePager from 'hooks/usePager'
import useRecordPublishing, { PublishingAction } from 'hooks/useRecordPublishing'
import useReorder from 'hooks/useReorder'
import useSearchGenericRecords from './useSearchGenericRecords'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { Attribute, CustomRecord, InternalSearchRecordsDocument, InternalSummarizeRecordsDocument, Parameter, Resource, useInternalDeleteRecordMutation, useInternalEditRecordMutation, useInternalImportRecordsMutation, useInternalSummarizeRecordsQuery, useOperationsListQuery, useParametersListQuery, useRelationshipsListQuery } from 'generated/schema'
import { css } from 'styles/stitches'
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'components/dataWidgets/Pager'
import { ExportRecordsButton, FIELD_TYPE_TO_RENDERER_MAP } from 'components/blocks/wrappers/DataTableBlockWrapper'
import { FieldIdentifier } from 'models/Field'
import { generateLinkRenderer } from 'components/renderers/LinkRenderer'
import { Kind } from 'models/Relationship'
import { OPERATIONS_LIST_LIMIT, PARAMETERS_LIST_LIMIT, RELATIONSHIPS_LIST_LIMIT } from 'models/Resource'
import { SIDE_PANE_PADDING_X } from 'components/sidePane/constants'
import { useViewDispatch } from 'hooks/useViewContext'
import type { Column } from 'components/dataTable/types'
import type { FilterType } from 'components/dataWidgets/CustomizeDisplay'
import type { RendererOptions } from 'components/dataList/DataList'

const ImportRecordsDialog = React.lazy(() => import('./ImportRecordsDialog'))

const tabsListStyle = css({
  flex: '1 0 auto'
})

const ImportButton = ({ resource, operationId, targetEnvironment }: any) => {
  const [ isOpen, setOpen ] = useState(false)

  const [ importRecords ] = useInternalImportRecordsMutation({
    refetchQueries: [
      InternalSearchRecordsDocument,
      InternalSummarizeRecordsDocument
    ]
  })

  const handleImportRecords = useSubmitHandler(importRecords)

  const { data } = useParametersListQuery({
    variables: {
      filter: {
        operationId: {
          eq: operationId
        }
      },
      limit: PARAMETERS_LIST_LIMIT
    }
  })

  return (
    <Suspense fallback={null}>
      <IconButton
        name="download"
        description="Import"
        variant="dark"
        size={24}
        onClick={() => setOpen(true)}
      />
      <ImportRecordsDialog
        title="Import"
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        onSubmit={({ headers, rows }) => handleImportRecords({
          headers,
          resourceId: resource.id,
          targetEnvironment,
          rows
        })}
        parameters={data?.parametersList as Parameter[]}
      />
    </Suspense>
  )
}

type Props = {
  resource: Resource,
  relationshipFilter?: FilterType,
  initialValues?: any,
  switcher?: any
}

function GenericResourceRecordsList({
  resource, relationshipFilter, initialValues, switcher
}: Props) {
  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = usePager({
    initialPageSize: 20
  })
  const [ filters, setFilters ] = useState<FilterType>({})
  const [ order, setOrder ] = useState<any[]>()
  const { openView } = useViewDispatch()
  const confirm = useConfirmation({ style: 'DIALOG' })
  const resourceId = resource.id
  const { polymorphicAttribute } = resource
  const tabs = polymorphicAttribute?.dataType?.settings?.options

  const { orderStyle } = resource
  const isLinearDragDrop = orderStyle === 'LINEAR_DRAG_AND_DROP'

  const {
    data: operationsData,
    loading: operationsLoading
  } = useOperationsListQuery({
    variables: {
      filter: {
        resourceId: { eq: resourceId }
      },
      order: [ {
        position: 'asc'
      } ],
      limit: OPERATIONS_LIST_LIMIT
    },
    skip: !resourceId
  })

  const {
    data: relationshipsData,
    loading: relationshipsLoading
  } = useRelationshipsListQuery({
    variables: {
      filter: {
        sourceId: { eq: resourceId }
      },
      order: [ {
        position: 'asc'
      } ],
      limit: RELATIONSHIPS_LIST_LIMIT
    },
    skip: !resourceId
  })

  const operations = operationsData?.operationsList || []
  const relationships = relationshipsData?.relationshipsList || []
  const isIsolated = resource.environmentBehavior === 'ISOLATED'
  const environmentId = isIsolated ? switcher.data?.environment?.id : undefined
  const [ polymorphicValue, setPolymorphicValue ] = useState(() => tabs?.[0]?.key)

  const {
    attributesResult: {
      data: attributesData,
      loading: attributesLoading,
      error: attributesError
    },
    searchResult: [ {
      data: { internalSearchRecords: searchRecords = [] } = {},
      loading: listLoading,
      error: listError,
      variables: listQueryVariables
    }, onSearch ]
  } = useSearchGenericRecords({
    resourceId: resource.id!,
    relationships,
    environmentId,
    page,
    limit: pageSize,
    order,
    filters: {
      ...relationshipFilter,
      ...filters,
      ...(polymorphicAttribute && polymorphicValue ? {
        [camelCase(polymorphicAttribute.identifier)]: {
          eq: polymorphicValue
        }
      } : {})
    },
    skip: (isIsolated && !environmentId) || !relationshipsData
  })

  const [ updateInternalRecord ] = useInternalEditRecordMutation({
    refetchQueries: [ InternalSearchRecordsDocument ]
  })

  const handleUpdateInternalRecord = useSubmitHandler(updateInternalRecord, {
    successAlert: { message: 'Record reordered.' }
  })

  const reorder = useReorder({
    query: InternalSearchRecordsDocument,
    variables: listQueryVariables,
    dataKey: 'internalSearchRecords',
    callback: (record: any) => handleUpdateInternalRecord({
      resourceId,
      arguments: attributes
        .filter((a) => ![
          resource.creationTimestampAttributeId,
          resource.updationTimestampAttributeId,
          resource.deletionTimestampAttributeId
        ].includes(a.id))
        .map((a) => ({ [camelCase(a.identifier)]: get(record.data, camelCase(a.identifier)) }))
        .reduce((a, e) => ({ ...a, ...e }), {}),
      targetEnvironment: environmentId
    }),
    positionKey: 'data.position.en_US',
    returnKey: 'data'
  })

  const attributes = attributesData?.attributesList || []
  const titleAttribute = attributes.find(
    (attr) => attr.id === resource.titleAttributeId
  )

  const importOperation = operations.find((op) => op.graphqlKind === 'MUTATION' && op.method === 'IMPORT')
  const exportOperation = operations.find((op) => op.graphqlKind === 'MUTATION' && op.method === 'EXPORT')
  const createOperation = operations.find((op) => op.graphqlKind === 'MUTATION' && op.method === 'CREATE')
  const updateOperation = operations.find((op) => op.graphqlKind === 'MUTATION' && op.method === 'UPDATE')
  const destroyOperation = operations.find((op) => op.graphqlKind === 'MUTATION' && op.method === 'DESTROY')

  const createOperationParams = createOperation?.parameters || []
  const updateOperationParams = updateOperation?.parameters || []

  const canImport = !!importOperation
  const canExport = !!exportOperation
  const canCreate = !!createOperation && createOperationParams.length > 0
  const canUpdate = !!updateOperation && updateOperationParams.length > 0
  const canDestroy = !!destroyOperation
  const canSearch = (attributes.some(
    (attr) => !attr.isArray && attr.fieldType === FieldIdentifier.TEXT
  ) || [])
  const canFilter = attributes.some(
    (attr) => attr.isFilterable
  ) || []

  const {
    data: { internalSummarizeRecords: summarizeRecords } = {},
    loading: summarizeLoading,
    error: summarizeError
  } = useInternalSummarizeRecordsQuery({
    variables: {
      input: {
        resourceId,
        filter: listQueryVariables?.input.filter,
        targetEnvironment: environmentId
      }
    }
  })

  const [ deleteRecord ] = useInternalDeleteRecordMutation({
    refetchQueries: [
      InternalSearchRecordsDocument
    ]
  })

  const handleDelete = useSubmitHandler(deleteRecord, {
    optimisticResponse: {
      response: 'DESTROY',
      mutation: 'internalDeleteRecord',
      // need to figure how to get this
      typename: 'CustomRecord'
    },
    update: {
      strategy: 'REMOVE',
      dataKey: 'internalSearchRecords',
      mutation: 'internalDeleteRecord',
      query: InternalSearchRecordsDocument,
      queryVariables: listQueryVariables
    }
  })

  const openAddView = () => openView({
    title: resource.name,
    component: AddRecordView,
    params: {
      resource,
      record: {
        data: initialValues
      },
      titleAttributeIdentifier: titleAttribute?.identifier,
      switcher,
      operationId: createOperation!.id
    },
    style: 'PANEL'
  })

  const openEditView = (record: CustomRecord) => {
    openView({
      title: resource.name,
      component: AddRecordView,
      params: {
        resource,
        record,
        titleAttributeIdentifier: titleAttribute?.identifier,
        switcher,
        operationId: updateOperation!.id,
        onDelete: () => openDeleteConfirmDialog(record)
      },
      style: 'PANEL'
    })
  }

  const openDeleteConfirmDialog = (record: any) => {
    const prefix = 'data'
    const currentLocale = 'en_US'

    confirm({
      action: 'delete',
      onConfirmClick: () => {
        const formParams = {}

        const requiredParams = destroyOperation?.parameters?.map(
          (param) => {
            const paramName = `${prefix}.${camelCase(param.identifier)}.${currentLocale}`
            const paramValue = get(record, paramName)
            if (paramValue) {
              return set(formParams, camelCase(param.identifier), paramValue)
            }
            return null
          }
        ).reduce((acc, curr) => ({ ...acc, ...curr }), {})

        return handleDelete({
          resourceId,
          targetEnvironment: environmentId,
          arguments: requiredParams
        })
      },
      recordDescription: titleAttribute?.identifier
        ? `${get(record, `${prefix}.${titleAttribute?.identifier}.${currentLocale}`)}`
        : `${get(record, `${prefix}.id.${currentLocale}`)}`
    })
  }

  const columns: Column[] = orderBy(
    attributes, 'position'
  )
    .map((attr) => {
      const renderer = FIELD_TYPE_TO_RENDERER_MAP[attr.fieldType as FieldIdentifier]

      const relationship = relationships.find((rel) => (
        rel.sourceId === resourceId && rel.sourceAttributeId === attr.id
      ))

      const oneToOneRelationship = relationships.find(
        (rel) => rel.sourceAttributeId === attr.id
        && [ Kind.BELONGS_TO, Kind.HAS_ONE ].includes(rel.kind)
      )

      const OneToOneReferenceRenderer = ({
        rowData,
        dataKey,
        ...props
      }: RendererOptions) => {
        const titleAttribute = oneToOneRelationship?.target.attributes.find(
          (attr) => oneToOneRelationship?.target.titleAttributeId === attr.id
        )

        const titleKey = DataType.resolveTitleKey(titleAttribute as any)

        const onClick = () => {
          const title = titleAttribute
            ? get(rowData, `data.${camelCase(oneToOneRelationship?.identifier)}.${titleKey}`)
            : oneToOneRelationship!.target.name
          const key = camelCase(oneToOneRelationship?.sourceAttribute.identifier || '')

          openView({
            title,
            component: GenericResourceDetailsView,
            style: GenericResourceDetailsView.defaultStyle,
            params: {
              title,
              recordId: rowData?.data[key]?.en_US || rowData?.data[key]?.data?.id.en_US,
              resourceId: oneToOneRelationship?.targetId
            }
          })
        }

        const LinkRenderer = generateLinkRenderer({
          as: TextRenderer,
          onClick
        })

        return (
          <LinkRenderer
            {...props}
            // eslint-disable-next-line no-nested-ternary
            dataKey={titleAttribute ? camelCase(titleAttribute.identifier)
              : relationship ? oneToOneRelationship?.targetAttribute.identifier || dataKey
                : dataKey}
            rowData={rowData}
            prefix={`data.${camelCase(oneToOneRelationship?.identifier)}.data`}
            suffix="en_US"
            fieldType={titleAttribute?.fieldType}
          />
        )
      }

      const isTitleAttribute = attr.id === resource.titleAttributeId
      const onClick = (record: any) => {
        openView({
          title: resource.name,
          component: AddRecordView,
          params: {
            resource,
            record,
            titleAttributeIdentifier: titleAttribute?.identifier,
            switcher,
            operationId: updateOperation!.id,
            onDelete: () => openDeleteConfirmDialog(record)
          },
          style: 'PANEL'
        })
      }

      const TitleRenderer = ({
        rowData,
        ...props
      }: RendererOptions) => {
        const LinkRenderer = generateLinkRenderer({
          as: TextRenderer,
          onClick
        })

        return <LinkRenderer rowData={rowData} {...props} />
      }

      const rendererProp = {
      // eslint-disable-next-line no-nested-ternary
        renderer: isTitleAttribute
          ? TitleRenderer
        // eslint-disable-next-line no-nested-ternary
          : relationship?.kind === (Kind.BELONGS_TO || Kind.HAS_ONE)
            ? OneToOneReferenceRenderer
            : (props: RendererOptions) => (
              renderer?.renderer
                ? renderer.renderer(props)
                : <TextRenderer {...props} prefix="data" suffix="en_US" />
            )
      }

      return ({
        dataKey: camelCase(attr.identifier),
        prefix: 'data',
        suffix: 'en_US',
        title: attr.fieldType === FieldIdentifier.REFERENCE
          ? (relationship?.name || attr.name) : attr.name,
        sortable: attr.isOrderable,
        hidden: false,
        fieldType: attr.fieldType,
        attributeProps: {
          resource: (relationship ? relationship?.target : resource) as Resource,
          attributes: (relationship ? relationship?.target.attributes : attributes) as Attribute[]
        },
        fieldProps: attr.dataType?.settings || {},
        displayTypeSettings: attr.displayTypeSettings || {},
        style: { width: '200px' },
        isArray: attr.isArray,
        ...rendererProp
      })
    }) || []

  const { isPublishingEnabled } = resource

  if (isPublishingEnabled) {
    columns.push({
      dataKey: 'publishedId',
      title: 'Status',
      style: { flexGrow: 1, justifyContent: 'flex-end', width: 200 },
      renderer: ({ rowData, isHovered }) => {
        const isPublished = rowData.publishedId === rowData.latestId
        return (
          <Chip
            label={isPublished ? 'Published' : 'Draft'}
            variant={isPublished ? 'positive' : 'light'}
            isCollapsed={!isHovered}
          />
        )
      }
    })
  }

  const actions = []

  if (canUpdate) {
    actions.push({
      icon: 'edit',
      title: 'Edit',
      onClick: openEditView
    })

    actions.push({
      icon: 'duplicate',
      title: 'Duplicate',
      onClick: (record: CustomRecord) => openEditView({ data: omit(record.data, 'id', 'createdAt', 'updatedAt') } as CustomRecord),
      isSecondary: true
    })
  }

  if (canDestroy) {
    actions.push({
      icon: 'trash',
      title: 'Delete',
      onClick: openDeleteConfirmDialog
    })
  }

  const [ handlePublishing ] = useRecordPublishing()

  const onPublishingClick = (record: CustomRecord) => {
    const isPublished = record?.publishedId
          === record?.latestId
    return handlePublishing({
      action: isPublished ? PublishingAction.UNPUBLISH : PublishingAction.PUBLISH,
      id: record.latestId
    })
  }

  if (isPublishingEnabled) {
    actions.push({
      icon: (record: CustomRecord) => {
        const isPublished = record?.publishedId
          === record?.latestId
        return isPublished ? 'cross-circle' : 'publish'
      },
      title: (record: CustomRecord) => {
        const isPublished = record?.publishedId
          === record?.latestId
        return isPublished ? 'Unpublish' : 'Publish'
      },
      onClick: onPublishingClick,
      isSecondary: true
    })
  }

  const filterProps = {
    filters,
    setFilters
  }

  const primaryElements = (
    <>
      {tabs?.length && (
        <Tabs
          activeIndex={tabs.findIndex((t: any) => t.key === polymorphicValue)}
          onChange={(index) => setPolymorphicValue(
            tabs[index].key
          )}
          tabListClassName={tabsListStyle}
        >
          {tabs.map((t: any, i: number) => (
            <Tab
              index={i}
              key={t.key}
              label={t.label}
            />
          ))}
        </Tabs>
      ) }
      {canSearch && <SearchBar placeholder="Search..." loading={listLoading} onChange={onSearch} />}
    </>
  )

  const secondaryElements = (
    <>
      <ExportRecordsButton
        resourceId={resourceId}
        fileName={`${resource.name}-${(new Date()).toISOString()}.csv`}
      />
      {canImport && (
        <ImportButton
          resource={resource}
          operationId={createOperation!.id}
          targetEnvironment={environmentId}
        />
      )}
      {canCreate && <Button icon="add-thin" onClick={openAddView} size="small" />}
    </>
  )

  const shouldEnableDragging = isLinearDragDrop && !order?.length

  return (
    <DataTableBlock
      fullWidth
      actions={actions}
      columns={columns}
      loading={
        listLoading
        || summarizeLoading
        || attributesLoading
        || operationsLoading
        || relationshipsLoading
      }
      error={listError || summarizeError || attributesError}
      data={attributesData && relationshipsData && summarizeRecords ? searchRecords as any[] : []}
      primaryElements={primaryElements}
      secondaryElements={secondaryElements}
      page={page}
      pageSize={pageSize}
      pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
      paginationMode="infinite"
      onChangePage={handlePageChange}
      onChangePageSize={handlePageSizeChange}
      totalRows={summarizeRecords?.count || 0}
      onRowDragEnd={shouldEnableDragging ? reorder : undefined}
      defaultOrder={order}
      setOrder={setOrder}
      key={`${environmentId}-${polymorphicValue}`}
      {...(canFilter && filterProps)}
      {...(relationshipFilter && { asFragment: true, containerPadding: SIDE_PANE_PADDING_X })}
    />
  )
}

export default GenericResourceRecordsList

export { ImportButton }
