import mapValues from 'lodash/mapValues'
import React, { useRef } from 'react'
import uuid from 'uuid-random'
import { BeforeCapture, DragDropContext, Draggable, DraggableChildrenFn, Droppable } from 'react-beautiful-dnd'

import AddRecordView from 'components/views/graph/AddRecordView'
import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'
import Divider from 'components/divider/Divider'
import FieldArray, { FieldArrayChildrenProps, useFieldArray } from 'components/form/FieldArray'
import Flex from 'components/layout/Flex'
import GenericObjectRender from 'components/displayTypes/GenericObjectRenderer'
import Icon from 'components/icons/Icon'
import IconButton from 'components/buttons/IconButton'
import InputHelpText from 'components/inputHelpText/InputHelpText'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import useReorderFieldArray from 'hooks/useReorderFieldArray'
import { DRAWER_HEIGHT_CLOSED_SMALL } from 'components/blocks/DrawerBlock'
import { Resource, useResourceQuery } from 'generated/schema'
import { useViewDispatch } from 'hooks/useViewContext'
import type { fieldProps } from './fieldProps'
import type { TextInputProps } from 'components/inputs/TextInput'

type NestedRelationsFieldProps = Omit<TextInputProps, 'input' | 'meta'> & fieldProps<'text'> & {
  resourceId?: string
}

type FieldData = any

const NestedRelationsField = ({
  label,
  name,
  helpText,
  isTranslatable,
  settings,
  shouldValidate
}: NestedRelationsFieldProps) => {
  const { resource_id: relatedResourceId } = settings as any
  const { data, loading, error } = useResourceQuery({
    variables: {
      id: relatedResourceId
    },
    skip: !relatedResourceId
  })

  const { openView } = useViewDispatch()

  const onAdd = () => {
    openView({
      component: AddRecordView,
      params: {
        resource: data?.resource as Resource,
        resourceId: data?.resource.id,
        operationMethod: 'CREATE',

        onSubmit: (values) => {
          fieldsRef.current?.fields.push(
            values.arguments || values
          )
        }
      },
      style: 'PANEL'
    })
  }

  const onEdit = (index: number) => {
    openView({
      component: AddRecordView,
      params: {
        resource: data?.resource as Resource,
        resourceId: data?.resource.id,
        record: {
          data: mapValues(
            fieldsRef.current?.fields.value[index],
            (value) => ({ en_US: value })
          )
        },
        operationMethod: 'CREATE',
        onSubmit: (values) => {
          fieldsRef.current?.fields.update(
            index,
            values.arguments || values
          )
        }
      },
      style: 'PANEL'
    })
  }

  const fieldsRef = useRef<FieldArrayChildrenProps<FieldData>>()
  useFieldArray({ name, fieldsRef, settings, shouldValidate })
  const droppableId = useRef(uuid())

  // To ensure that drawer placeholder height is DRAWER_HEIGHT_CLOSED_SMALL
  // even if the drawer being dragged is open
  const onBeforeCapture = ({ draggableId }: BeforeCapture) => {
    const element = document.querySelector(`[data-rbd-draggable-id="${draggableId}"]`) as HTMLElement

    element.style.setProperty('height', `${DRAWER_HEIGHT_CLOSED_SMALL}px`, 'important') //
  }
  const onDragEnd = useReorderFieldArray(fieldsRef)

  const renderDraggableChildren: DraggableChildrenFn = (provided, snapshot, rubric) => (
    <Flex
      gap={8}
      {...provided.dragHandleProps}
      {...provided.draggableProps}
      ref={provided.innerRef}
    >
      <Flex alignItems="center" css={{ height: 45 }}>
        <Icon name="drag-alt" color="dark500" />
      </Flex>
      <Flex direction="column" grow={1}>
        <GenericObjectRender
          displaySettings={{ fontWeight: 'semibold' }}
          data={fieldsRef.current?.fields.value?.[rubric.source.index]}
        />
      </Flex>
      <Flex alignItems="center" css={{ height: 45 }} gap={4}>
        <IconButton name="edit" description="Edit" onClick={() => onEdit(rubric.source.index)} />
        <IconButton name="trash" description="Delete" onClick={() => fieldsRef.current?.fields.remove(rubric.source.index)} />
      </Flex>
    </Flex>
  )

  return (
    <Flex direction="column" gap={10}>
      <Flex justifyContent="space-between" gap={16}>
        <Text
          color="dark500"
          fontSize={10}
          fontWeight="bold"
          textTransform="uppercase"
        >
          {label}{settings?.checkRequired && <span> *</span>}
        </Text>
        <Flex gap={8} alignItems="center">
          {isTranslatable && (
          <Flex alignItems="center" alignSelf="stretch" gap={12}>
            <Divider variant="ruler" orientation="vertical" color="light700" />
            <Icon size={12} name="translate" color="dark200" />
          </Flex>
          )}
          {relatedResourceId && (
            <TextLink
              as="button"
              type="button"
              fontSize={10}
              onClick={onAdd}
              mode="distinct"
              disabled={!relatedResourceId}
            >
              Add new
            </TextLink>
          )}
        </Flex>
      </Flex>
      <DashboardEditorLoader
        data={data}
        loading={loading}
        error={error}
        empty={{
          variant: 'simple',
          element: (
            <Flex alignItems="center" direction="column">
              <Text fontSize={14} color="dark500">No resource mapped as nested relationship.</Text>
            </Flex>
          )
        }}
      >
        {helpText && <InputHelpText helpText={helpText} />}
        <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
          <Droppable
            renderClone={renderDraggableChildren}
            droppableId={droppableId.current}
          >
            {(droppableProvided) => (
              <Flex
                direction="column"
                gap={2}
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                <FieldArray
                  name={name}
                  fieldsRef={fieldsRef}
                  settings={settings}
                >
                  {({ keys }) => (keys.length
                    ? keys.map((key, index) => (
                      <Draggable
                        disableInteractiveElementBlocking
                        draggableId={key}
                        index={index}
                        key={key}
                      >
                        {renderDraggableChildren}
                      </Draggable>
                    )) : (
                      <DashboardEditorLoader empty={{
                        variant: 'simple',
                        element: (
                          <Flex alignItems="center" direction="column" gap={8}>
                            <Text fontSize={14} color="dark500">Nothing to show here.</Text>
                            <TextLink
                              as="button"
                              type="button"
                              fontSize={10}
                              onClick={onAdd}
                              mode="distinct"
                              disabled={!relatedResourceId}
                            >
                              Add new
                            </TextLink>
                          </Flex>
                        )
                      }}
                      />
                    ))}
                </FieldArray>
                {droppableProvided.placeholder}
                <div />
              </Flex>
            )}
          </Droppable>
        </DragDropContext>
      </DashboardEditorLoader>
    </Flex>
  )
}

export default NestedRelationsField
