import { object, string } from 'yup'

import BaseModel from 'models/BaseModel'

enum Kind {
  WORKSPACE = 'WORKSPACE',
  APP = 'APP',
  RESOURCE = 'RESOURCE',
}

const KIND_ICON_MAP = {
  [Kind.WORKSPACE]: 'workspace',
  [Kind.APP]: 'app',
  [Kind.RESOURCE]: 'graph'
}

const WORKSPACE_KIND_OPTIONS = [
  { label: 'Workspace', icon: 'workspace', value: Kind.WORKSPACE },
  { label: 'App', icon: 'app', value: Kind.APP }
  // { label: 'Resource', icon: 'graph', value: Kind.RESOURCE }
]

class Role extends BaseModel {
  static schema = object({
    name: string().required()
  })
}

const ROLE_IDS = {
  DASHBOARD_MANAGER: '344a69ad-286e-4591-a3fa-757330e46b75',
  DASHBOARD_MEMBER: '5fb41813-b2f5-4b9f-9d07-c3c50c6dbba9',
  WORKSPACE_DASHBOARDS_MANAGER: '3b952d50-9c61-4564-bb01-c6e3937e31d8',
  WORKSPACE_OWNER: 'd0c8f28c-406e-43fe-b52a-72fee62a9812'
}

export {
  Kind,
  KIND_ICON_MAP,
  ROLE_IDS,
  WORKSPACE_KIND_OPTIONS
}

export default Role
