import compact from 'lodash/compact'
import differenceWith from 'lodash/differenceWith'
import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import mapKeys from 'lodash/mapKeys'
import React, { useEffect, useMemo, useState } from 'react'
import set from 'lodash/set'

import convertToArray from 'lib/convertToArray'
import FieldError from 'components/form/FieldError'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import InputHelpText from 'components/inputHelpText/InputHelpText'
import Select from 'components/select/Select'
import useComponentDidMount from 'hooks/useComponentDidMount'
import { styled } from 'styles/stitches'
import type { SelectOptionType, SelectProps } from 'components/select/Select'

type SelectInputProps<T extends SelectOptionType> = SelectProps<T> & {
  label?: string,
  virtualize?: boolean,
  isTranslatable?: boolean,
  labelKey?: string,
  descriptionKey?: string,
  valueKey?: string,
  metaKey?: string,
  iconKey?: string,
  onAfterSelect?: SelectProps<T>['onChange'],
  /**
   * Use this prop, if `value` is passed as `object | object[]` instead of `string[] | string`
   * and form value needs to maintain that
  */
  setValueAsObject?: boolean
}

type CreatableOptionType = SelectProps['options'] & { isNew?: boolean }

const StyledContainer = styled('div', {
  position: 'relative'
})

const StyledFlex = styled(Flex, {
  width: '100%'
})

const optionIsNotNew = (option: CreatableOptionType) => !option.isNew
const optionIsNew = (option: CreatableOptionType) => option.isNew

function SelectInput<T>({
  input,
  label,
  meta,
  helpText,
  isTranslatable,
  checkRequired = false,
  labelKey = 'label',
  descriptionKey = 'description',
  valueKey = 'value',
  metaKey = 'meta',
  iconKey = 'icon',
  setValueAsObject = false,
  onCreateOption,
  options,
  css,
  onAfterSelect,
  ...others
}: SelectInputProps<T>) {
  const [ menuIsOpen, setMenuIsOpen ] = useState(false)
  const error = FieldError.getError(meta)
  const hasError = !!FieldError.getError(meta)

  const { defaultValue, isMulti } = others
  const { value = defaultValue, onChange, ...otherInputs } = input || {}

  const [ currentOptions, setCurrentOptions ] = useState(options as CreatableOptionType | undefined)

  const visibleOptions = filter(currentOptions, optionIsNotNew)
  const isAsync = !!others.loadOptions

  // set currentOptions when options changes
  useEffect(() => {
    setCurrentOptions(options)
  }, [ options ])

  // When 'options' prop changes, keep created options
  useEffect(() => {
    if (!others.loadOptions) {
      setCurrentOptions((currentOptionsPrev) => [
        ...(options || []) as any[], ...filter(currentOptionsPrev, optionIsNew)
      ])
    }
  }, [ options, others.loadOptions ])

  // When 'isCreatable' prop changes, remove created options
  useEffect(() => {
    if (!others.isCreatable && !others.loadOptions) {
      setCurrentOptions(
        (currentOptionsPrev) => filter(currentOptionsPrev, optionIsNotNew) as SelectOptionType[]
      )
    }
  }, [ others.isCreatable, others.loadOptions ])

  // When 'isMulti' prop changes, preserve selected options
  useEffect(() => {
    if (value) {
      if (isMulti) {
        if (!Array.isArray(value)) {
          onChange?.([ value ])
        }
      } else if (Array.isArray(value)) {
        onChange?.(value[0])
      }
    }

    // This effect must run only when isMulti prop changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isMulti ])

  const normalizedOptions = React.useMemo(
    () => mapKeys(currentOptions, valueKey), [ currentOptions, valueKey ]
  )

  const handleSingleValueChange = (selectedOption?: SelectOptionType) => {
    const selectValue = setValueAsObject ? selectedOption : get(selectedOption, valueKey)
    onChange?.(selectValue || null)
    onAfterSelect?.(selectedOption || null)
  }

  const handleMultiValueChange = (selectedOptions?: any[]) => {
    const selectValue = setValueAsObject
      ? selectedOptions
      : selectedOptions?.map(
        (selectedOption) => get(selectedOption, valueKey)
      )
    onChange?.(selectValue || [])
    onAfterSelect?.(selectedOptions || [])
  }

  const handleChange = isMulti ? handleMultiValueChange : handleSingleValueChange

  const selectedOptions = useMemo(() => {
    if (!value) return null

    if (isMulti && Array.isArray(value) && value.length > 0) {
      if (isAsync && others.defaultOptions.length > 0) {
        Promise.all(value.map(async (inputValue: any) => {
          const asyncOption = others.defaultOptions.find(
            (option: any) => get(option, valueKey) === inputValue
          )
          if (typeof inputValue === 'string') return inputValue
          return get(asyncOption, get(inputValue, valueKey))
        })).then((resolvedSelectedOptions) => compact(resolvedSelectedOptions))
      }

      return value.map((inputValue: any) => {
        if (typeof inputValue === 'string') return normalizedOptions[inputValue]
        return get(normalizedOptions, get(inputValue, valueKey))
      })
    }

    if (typeof value === 'string') {
      return get(normalizedOptions, value) || others.defaultOptions?.find(
        (option: any) => get(option, valueKey) === value
      )
    }

    return get(normalizedOptions, get(value, valueKey)) || others.defaultOptions?.find(
      (option: any) => option === value
    )
  }, [
    isAsync,
    others.defaultOptions,
    value,
    isMulti,
    valueKey,
    normalizedOptions
  ])

  // Invoke handleChange on mount to sync values with selectedOptions
  // & add created options which exist in values but not in options array
  useComponentDidMount(() => {
    if (value && others.isCreatable) {
      const inputValue = value

      if (isMulti) {
        const createdOptions = differenceWith(
          (typeof inputValue?.[0] === 'string' ? inputValue : get(inputValue, valueKey)),
          (options || []) as SelectOptionType[],
          (a, b) => a === b.value
        ).map((createdOption) => (set(set({}, labelKey, createdOption), valueKey, createdOption)))

        handleMultiValueChange([
          // ensures selectedOptions is always an array
          ...convertToArray(selectedOptions),
          ...createdOptions
        ] as SelectOptionType[])

        setCurrentOptions((currentOptionsPrev) => [
          ...(currentOptionsPrev || []),
          ...(createdOptions || [])
        ])
      } else {
        const createdOption = !(options as SelectOptionType[])?.find((
          option
        ) => (typeof inputValue === 'string'
          ? inputValue === option.value
          : get(inputValue, valueKey) === option.value))

        handleSingleValueChange((
          createdOption
            ? set(set({}, labelKey, createdOption), valueKey, createdOption)
            : selectedOptions) as SelectOptionType)

        setCurrentOptions((currentOptionsPrev) => [
          ...(currentOptionsPrev || []) as any[],
          ...(createdOption
            ? [ set(set({}, labelKey, createdOption), valueKey, createdOption) ]
            : [])
        ])
      }
    } else if (defaultValue) {
      if (isMulti) handleMultiValueChange(defaultValue as SelectOptionType[])
      else handleSingleValueChange(defaultValue as SelectOptionType)
    }
  })

  const handleCreate = (inputValue: string) => {
    const addedOption = find(currentOptions,
      (option: SelectOptionType) => option?.[valueKey] === inputValue)

    if (!addedOption) {
      const newOption = { [valueKey]: inputValue, [labelKey]: inputValue, isNew: true }
      setCurrentOptions([ ...currentOptions as SelectOptionType[], newOption ])

      onCreateOption?.(inputValue)
    }

    if (isMulti) onChange([ ...(value || []), inputValue ])
    else onChange(inputValue)
  }

  return (
    <StyledFlex css={css} as="label" grow={1} direction="column" gap={10}>
      {label && (
        <FieldLabel checkRequired={checkRequired} isTranslatable={isTranslatable}>
          {label}
        </FieldLabel>
      )}
      <StyledContainer>
        <Select
          hasError={hasError}
          onChange={handleChange}
          onMenuClose={() => setMenuIsOpen(false)}
          onMenuOpen={() => setMenuIsOpen(true)}
          value={selectedOptions}
          options={visibleOptions}
          onCreateOption={handleCreate}
          getOptionLabel={(option: any) => (typeof option === 'string' ? option : get(option, labelKey))}
          getOptionValue={(option: any) => (typeof option === 'string' ? option : get(option, valueKey))}
          getOptionDescription={(option: any) => get(option, descriptionKey)}
          getOptionMeta={(option: any) => get(option, metaKey)}
          getOptionIcon={(option: any) => get(option, iconKey)}
          {...otherInputs}
          {...others}
        />
        {!menuIsOpen && !!error && <FieldError error={error} />}
      </StyledContainer>
      {helpText && <InputHelpText helpText={helpText} />}
    </StyledFlex>
  )
}

export default SelectInput

export type { SelectInputProps }
