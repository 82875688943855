import React, { Suspense, useEffect, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'

import componentLoader from 'lib/componentLoader'
import DashboardViewContext from 'components/contexts/DashboardViewContext'
import Masonry from 'components/layout/Masonry'
import NotFoundPage from 'components/pages/NotFoundPage'
import PageLoader from 'components/loaders/PageLoader'
import useCheckIncomingBlocksIntoRecoilState from 'hooks/useCheckBlocksIntoRecoilState'
import useDashboard from 'hooks/useDashboard'
import useSwitcherState from 'hooks/useSwitcherState'
import useViewUrn from 'hooks/useViewUrn'
import { DashboardView } from 'components/pages/DashboardPage'
import { safeParseLiquid } from 'lib/templater'
import { useDashboardEditorContextProvider } from 'components/dashboardEditor/DashboardEditorProvider'
import type { ViewProps } from '.'
import { useCurrentAccountContext } from 'components/contexts/CurrentAccountContext'

type Params = {
  viewUrn: string,
  params?: Record<string, any>
}

const useGenericView = (viewUrn: string, params?: Record<any, any>) => {
  const [ title, setTitle ] = useState('')
  const [ footerEl, setFooterEl ] = useState<HTMLDivElement | null>(null)
  const { data: activeView, loading, error } = useViewUrn({ urn: viewUrn })

  const activeApp = activeView?.app
  const activeAppId = activeApp?.id || activeView?.appId
  const activeResourceId = activeView?.resourceId
  const activeBlocks = activeView?.blocks || []

  const componentPath = activeView?.componentPath
  const isCustomResourceView = activeResourceId && activeBlocks.length

  const ViewComponent = useMemo(() => (
    componentPath && !isCustomResourceView
      ? React.lazy(() => componentLoader(`views/${componentPath}`))
      : () => null
  ), [ componentPath, isCustomResourceView ])

  const {
    blockPropertiesState,
    updateBlock,
    updateBlockProperties,
    updateOperation,
    updateView
  } = useDashboard()
  const blockProperties = useRecoilValue(blockPropertiesState)
  const parsedTitle = safeParseLiquid(title, blockProperties)

  const {
    resetDashboardEditorStack
  } = useDashboard()

  const { onUrnChange } = useDashboardEditorContextProvider()

  useEffect(() => {
    let current: string
    onUrnChange((previousUrn) => {
      current = previousUrn
      return viewUrn!
    })
    resetDashboardEditorStack()

    return () => {
      onUrnChange(current)
      resetDashboardEditorStack()
    }
  }, [ viewUrn, onUrnChange, resetDashboardEditorStack ])

  useEffect(() => {
    activeView && updateView(viewUrn!, activeView)

    activeView?.operations?.map((operation) => (
      updateOperation(operation)
    ))
  }, [ viewUrn, activeView, updateBlock, updateOperation, updateView ])

  useCheckIncomingBlocksIntoRecoilState(viewUrn, activeBlocks)

  const { switcher } = useSwitcherState(activeAppId)
  const currentAccount = useCurrentAccountContext()

  useEffect(() => {
    updateBlockProperties({
      // old: to be removed
      currentEnvironment: switcher.data.environment!,
      current: {
        environment: switcher.data.environment!,
        account: currentAccount!
      }
    })
  }, [ currentAccount, switcher.data, updateBlockProperties ])

  useEffect(() => {
    let oldData: any
    updateBlockProperties((p) => {
      oldData = p.data
      return {
        ...p,
        data: params
      }
    })

    return () => {
      updateBlockProperties({
        data: oldData
      })
    }
  }, [ params, updateBlockProperties ])

  return {
    activeView,
    componentPath,
    error,
    footerEl,
    loading,
    parsedTitle,
    setFooterEl,
    setTitle,
    switcher,
    ViewComponent
  }
}

function GenericView({
  onRequestClose,
  params,
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const activeUrn = params.viewUrn
  const {
    activeView,
    componentPath,
    footerEl,
    loading,
    parsedTitle,
    setFooterEl,
    setTitle,
    switcher,
    ViewComponent
  } = useGenericView(activeUrn, params.params)

  if (!activeView) {
    if (loading) return <PageLoader loading />

    return (
      <View contentLabel={parsedTitle} onRequestClose={onRequestClose} {...other}>
        {() => (
          <>
            <NotFoundPage fullscreen={false} />
          </>
        )}
      </View>
    )
  }

  if (componentPath && !activeView.blocks?.length) {
    return (
      <DashboardViewContext.Provider
        value={{ activeView, activeUrn, switcher }}
      >
        <Suspense fallback={<PageLoader loading />}>
          <ViewComponent
            key={activeView?.id}
            params={params.params}
            onRequestClose={onRequestClose}
            viewStyleComponent={View}
            {...other}
          />
        </Suspense>
      </DashboardViewContext.Provider>
    )
  }

  return (
    <View contentLabel={parsedTitle} onRequestClose={onRequestClose} {...other}>
      {({ Body, Header, Footer }) => (
        <DashboardViewContext.Provider
          value={{ activeView, activeUrn, switcher }}
        >
          <Header
            title={parsedTitle}
            onCloseClick={onRequestClose}
          />
          <Body>
            <Masonry css={{ margin: -32 }}>
              <DashboardView
                activeUrn={activeUrn}
                activeView={activeView!}
                setTitle={setTitle}
                footerEl={footerEl}
              />
            </Masonry>
          </Body>
          <Footer>
            <Footer.Right ref={setFooterEl} />
          </Footer>
        </DashboardViewContext.Provider>
      )}
    </View>
  )
}

export default GenericView

export { useGenericView }
