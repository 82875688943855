import createDecorator from 'final-form-calculate'
import isEmpty from 'lodash/isEmpty'
import kebabCase from 'lodash/kebabCase'
import type { Decorator } from 'final-form'

const createSetIdentifier = <T = object>(
  source: string,
  target: string
): Decorator<T> => createDecorator({
  field: source,
  updates: {
    [target]: (value, allValues, prevValues) => {
      const newValues = allValues as Record<string, string>
      const oldValues = prevValues as Record<string, string>

      // Preserve identifier when...
      if (
        // ... record is being edited
        newValues.id
          || ( // ... identifier was modified
            newValues[target]
            && oldValues[source]
            && newValues[target] !== serialize(oldValues[source])
          )
          || ( // ... form is submitted
            newValues[target]
            && isEmpty(oldValues)
          )
      ) {
        return newValues[target]
      }

      return serialize(value)
    }
  }
}) as unknown as Decorator<T> // https://github.com/final-form/final-form-calculate/issues/38

const serialize = (value?: string) => kebabCase(value?.toLowerCase())

const setIdentifier = createSetIdentifier('name', 'identifier')

export { createSetIdentifier }

export default setIdentifier
