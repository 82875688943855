import arrayMutators from 'final-form-arrays'
import camelCase from 'lodash/camelCase'
// @ts-ignore
import currencyToSymbolMap from 'currency-symbol-map/map'
import kebabCase from 'lodash/kebabCase'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import startCase from 'lodash/startCase'
import uuid from 'uuid-random'
import { Form, FormRenderProps, RenderableProps, useField, useForm } from 'react-final-form'
import { useRecoilValue } from 'recoil'

import * as mixins from 'styles/mixins'
import ActionsList from './ActionsList'
import AddFormBlockView from './AddFormBlockView'
import AddFormFieldBlockView from './AddFormFieldBlockView'
import BlocksList from './BlocksList'
import Button from 'components/buttons/Button'
import ButtonGroupInput from 'components/inputs/ButtonGroupInput'
import Card from 'components/card/Card'
import CodeEditorInput from 'components/inputs/CodeEditorInput'
import ConditionalField from 'components/form/ConditionalField'
import DashboardEditorBody from './base/DashboardEditorBody'
import DashboardEditorHeader from './base/DashboardEditorHeader'
import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'
import DataList from 'components/dataList/DataList'
import Divider from 'components/divider/Divider'
import DrawerBlock from 'components/blocks/DrawerBlock'
import DropdownField from 'components/contentEditors/generic/fields/DropdownField'
import DropdownFieldView from 'components/fieldViews/DropdownFieldView'
import EnvironmentSwitcher from 'components/switcher/EnvironmentSwitcher'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import FormValuesField from 'components/form/FormValuesField'
import getPropertyToElementMap, { TMap } from 'lib/getPropertyToElementMap'
import Icon from 'components/icons/Icon'
import JsonField from 'components/contentEditors/generic/fields/JsonField'
import LiveBlockEditorOrchestrator from './LiveBlockEditorOrchestrator'
import MediaCard from 'components/mediaCard/MediaCard'
import NumberField from 'components/contentEditors/generic/fields/NumberField'
import OperationField, { getOptionIcon } from './OperationField'
import SearchSelectField from 'components/contentEditors/generic/fields/SearchSelectField'
import Text from 'components/typography/Text'
import TextField from 'components/contentEditors/generic/fields/TextField'
import TextFieldView from 'components/fieldViews/TextFieldView'
import TextInput from 'components/inputs/TextInput'
import ToggleInput from 'components/inputs/ToggleInput'
import useDashboard, { DashboardEditorView } from 'hooks/useDashboard'
import useReorderFieldArray from 'hooks/useReorderFieldArray'
import useSearchGenericRecords from 'components/views/graph/useSearchGenericRecords'
import useSubmitHandler from 'hooks/useSubmitHandler'
import useSwitcherState from 'hooks/useSwitcherState'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { BehaviorMethod } from 'models/Operation'
import { AttributesListQuery, Operation, Parameter, RelationshipsListQuery, Resource, ResourcesListDocument, ResourcesListQuery, ResourcesListQueryVariables, useAttributesListQuery, useOperationQuery, useParametersListQuery, useRelationshipsListQuery, useResourceQuery, useUpsertViewMutation } from 'generated/schema'
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'components/dataWidgets/Pager'
import { DisplayType } from 'models/Attribute'
import { FieldArrayChildrenProps, useFieldArray } from 'components/form/FieldArray'
import { HeaderBlockProps, sizeMap } from 'components/blocks/HeaderBlock'
import { RepeatedField } from 'components/contentEditors/generic/fields'
import { SidePaneFooter } from 'components/sidePane'
import { StatItemValueKind, statItemValueKindOptions } from 'components/blocks/StatBlock'
import { styled } from 'styles/stitches'
import { useDashboardEditorContextProvider } from './DashboardEditorProvider'
import { ActionType, BLOCK_TYPE_TO_NAME_MAP, ELEMENT_TYPE_TO_ICON_MAP, Views } from './constants'
import type { ActiveViewProps } from './DashboardEditor'
import type { BlockType } from 'components/blocks'
import type { ElementType } from 'components/views/AddElementView'
import WhenFieldChanges from 'components/form/WhenFieldChanges'
import { ATTRIBUTES_LIST_LIMIT, RELATIONSHIPS_LIST_LIMIT } from 'models/Resource'
import IconInput from 'components/inputs/IconInput'

enum DataSource {
  RESOURCE = 'RESOURCE',
  OPERATION = 'OPERATION'
}

const DataSourceOptions = [
  { label: 'Resource', value: DataSource.RESOURCE },
  { label: 'Operation', value: DataSource.OPERATION }
]

const SelectionModeOptions = [
  { label: 'None', value: 'NONE' },
  { label: 'Single', value: 'SINGLE' },
  { label: 'Multiple', value: 'MULTIPLE' }
]

const defaultOperationToolbarActions = [
  {
    id: uuid(),
    name: 'Refresh',
    identifier: 'REFRESH',
    icon: 'refresh',
    kind: 'TOOLBAR',
    placement: 'RIGHT',
    behavior: 'SET_VARIABLE',
    trigger: 'CLICK',
    display_style: 'SECONDARY',
    is_system: true,
    is_hidden: false
  },
  {
    id: uuid(),
    name: 'Export',
    identifier: 'EXPORT',
    icon: 'upload',
    kind: 'TOOLBAR',
    placement: 'RIGHT',
    behavior: 'SET_VARIABLE',
    trigger: 'CLICK',
    display_style: 'SECONDARY',
    is_system: true,
    is_hidden: false
  }
]

const defaultToolbarActions = [
  {
    id: uuid(),
    name: 'Import',
    identifier: 'IMPORT',
    icon: 'download',
    kind: 'TOOLBAR',
    placement: 'RIGHT',
    behavior: 'SET_VARIABLE',
    trigger: 'CLICK',
    display_style: 'SECONDARY',
    is_system: true,
    is_hidden: false
  },
  {
    id: uuid(),
    name: 'Create',
    identifier: 'CREATE',
    icon: 'add-thin',
    kind: 'TOOLBAR',
    placement: 'RIGHT',
    behavior: 'OPEN_VIEW',
    view_urn: '',
    view_style: 'PANEL',
    input: {},
    trigger: 'CLICK',
    display_style: 'PRIMARY',
    is_system: true,
    is_hidden: false
  }
]

const defaultRowActions = [
  {
    id: uuid(),
    name: 'Edit',
    identifier: 'EDIT',
    icon: 'edit',
    kind: 'ROW',
    is_bulk: false,
    behavior: 'OPEN_VIEW',
    view_urn: '',
    view_style: 'PANEL',
    input: {},
    trigger: 'CLICK',
    display_style: 'PRIMARY',
    is_system: true,
    is_hidden: false
  },
  {
    id: uuid(),
    name: 'Delete',
    identifier: 'DELETE',
    icon: 'trash',
    kind: 'ROW',
    is_bulk: false,
    behavior: 'SET_VARIABLE',
    trigger: 'CLICK',
    display_style: 'PRIMARY',
    is_system: true,
    is_hidden: false
  }
]

const BLOCKS_TO_FIELDS_MAP = {
  TitleBlock: [
    { name: 'heading', label: 'Heading', type: 'string' }
  ],
  HeaderBlock: [
    { name: 'heading', label: 'Title', type: 'string' },
    {
      name: 'style',
      label: 'Style',
      type: 'dropdown',
      getOptionLabel: (option: {label: string, value: HeaderBlockProps['style']}) => (
        <Text
          fontWeight="bold"
          fontSize={sizeMap[option.value]}
          letterSpacing="compact"
          truncate
        >
          {option.label}
        </Text>
      ),
      options: [
        { label: 'Heading 1', value: 'H1' },
        { label: 'Heading 2', value: 'H2' },
        { label: 'Heading 3', value: 'H3' }
      ]
    }
  ],
  NotesBlock: [
    { name: 'text', label: 'Text', type: 'string' }
  ],
  StatBlock: [
    { name: 'Heading', label: 'heading', type: 'string' },
    { name: 'description', label: 'Description', type: 'string' },
    { name: 'value', label: 'Value', type: 'string' },
    { name: 'previousValue', label: 'Previous Value', type: 'string' },
    { name: 'showChangePercentage', label: 'Show Change Percentage', type: 'boolean' },
    { name: 'showChangeValue', label: 'Show Change Value', type: 'boolean' },
    { name: 'showPreviousValue', label: 'Show Previous Value', type: 'boolean' }
  ],
  QuickLinksBlock: [
    { name: 'title', label: 'Title', type: 'string' },
    { name: 'links', label: 'Links', type: 'object' }
  ],
  DataTableBlock: [
    { name: 'title', label: 'Title', type: 'string' },
    { name: 'columns', label: 'Columns', type: 'object' },
    { name: 'data', label: 'Data', type: 'object' }
  ],
  DataListBlock: [
    { name: 'title', label: 'Title', type: 'string' },
    { name: 'contents', label: 'Contents', type: 'object' },
    { name: 'data', label: 'Data', type: 'object' }
  ],
  LineChartBlock: [
    { name: 'title', label: 'Title', type: 'string' },
    { name: 'data', label: 'Data', type: 'object' }
  ],
  PieChartBlock: [
    { name: 'title', label: 'Title', type: 'string' },
    { name: 'data', label: 'Data', type: 'object' }
  ],
  BarChartBlock: [
    { name: 'title', label: 'Title', type: 'string' },
    { name: 'data', label: 'Data', type: 'object' }
  ],
  GaugeBlock: [
    { name: 'title', label: 'Title', type: 'string' },
    { name: 'data', label: 'Data', type: 'object' }
  ],
  BehaviorBlock: [
    { name: 'title', label: 'Title', type: 'string' },
    { name: 'data', label: 'Data', type: 'object' }
  ],
  FlowBlock: [
    { name: 'title', label: 'Title', type: 'string' },
    { name: 'data', label: 'Data', type: 'object' }
  ],
  FunnelBlock: [
    { name: 'title', label: 'Title', type: 'string' },
    { name: 'data', label: 'Data', type: 'object' }
  ],
  ImpactBlock: [
    { name: 'title', label: 'Title', type: 'string' },
    { name: 'data', label: 'Data', type: 'string' }
  ],
  RetentionBlock: [
    { name: 'title', label: 'Title', type: 'string' },
    { name: 'data', label: 'Data', type: 'string' }
  ],
  TimeSeriesBlock: [
    { name: 'title', label: 'Title', type: 'string' },
    { name: 'data', label: 'Data', type: 'string' }
  ],
  DrawerBlock: [
    { name: 'title', label: 'Title', type: 'string' },
    { name: 'data', label: 'Data', type: 'object' }
  ],
  ColumnsBlock: [],
  EmailPreviewBlock: [
    { name: 'heading', type: 'string', label: 'Heading' },
    { name: 'from', type: 'string', label: 'From' },
    { name: 'subject', type: 'string', label: 'Subject' },
    { name: 'preview', type: 'string', label: 'Preview' },
    { name: 'html_body', type: 'string', label: 'HTML Body' },
    { name: 'plain_body', type: 'string', label: 'Plain Body' },
    { name: 'reply_to', type: 'string', label: 'Reply To' },
    { name: 'actions', type: 'actions', label: 'Actions' }
  ],
  DropdownBlock: [],
  TextInputBlock: [],
  ButtonBlock: [],
  CSVInputBlock: [],
  FormFieldBlock: [],
  CardBlock: []
} as const

const TitleBlockFields = () => {
  const primaryElementsField = useField('primary_elements')
  const primaryElements = primaryElementsField.input.value || []
  const secondaryElementsField = useField('secondary_elements')
  const secondaryElements = secondaryElementsField.input.value || []

  return (
    <>
      <FormField
        name="heading"
        label="Heading"
        size="small"
      />
      <Text
        color="dark500"
        fontSize={10}
        fontWeight="bold"
        textTransform="uppercase"
      >
        Primary Elements
      </Text>
      <Flex direction="column" gap={2}>
        {primaryElements.length === 0 && <Text textAlign="center" color="dark400" fontSize="14">No elements added</Text>}
        {primaryElements.map((element: any, index: number) => (
          <MediaCard
            compact
            key={element.id}
            media={ELEMENT_TYPE_TO_ICON_MAP[element.type as ElementType]}
            title={startCase(element.type)}
            height={64}
            width="full"
            actions={[ {
              description: '',
              icon: 'trash',
              onClick: () => {
                primaryElementsField.input.onChange([
                  ...primaryElements.slice(0, index),
                  ...primaryElements.slice(index + 1)
                ])
              }
            } ]}
          />
        ))}
      </Flex>
      <Text
        color="dark500"
        fontSize={10}
        fontWeight="bold"
        textTransform="uppercase"
      >
        Secondary Elements
      </Text>
      <Flex direction="column" gap={2}>
        {secondaryElements?.map((element: any, index: number) => (
          <MediaCard
            compact
            key={element.id}
            media={ELEMENT_TYPE_TO_ICON_MAP[element.type as ElementType]}
            title={startCase(element.type)}
            height={64}
            width="full"
            actions={[ {
              description: '',
              icon: 'trash',
              onClick: () => {
                secondaryElementsField.input.onChange([
                  ...secondaryElements.slice(0, index),
                  ...secondaryElements.slice(index + 1)
                ])
              }
            } ]}
          />
        ))}
      </Flex>
    </>
  )
}

const AddNewCard = styled(Card, {
  ...mixins.transition('fluid'),
  border: '1px dashed dark100',
  cursor: 'pointer',

  '[data-icon]': {
    ...mixins.transition('fluid'),
    color: 'dark100'
  },

  [`${Text}`]: {
    ...mixins.transition('fluid'),
    color: 'dark500'
  },

  '&:hover': {
    borderColor: 'dark500',
    '[data-icon]': { color: 'dark500' },
    [`${Text}`]: { color: 'dark800' }
  }
})

const StatBlockFields = () => {
  const resourceField = useField('data_source_settings.resource')
  const operationField = useField('data_source_settings.operation')
  const dataSourceField = useField('data_source')

  const { data } = useResourceQuery({
    variables: { id: resourceField.input.value },
    skip: !resourceField.input.value
  })

  const {
    data: { parametersList = [] } = {},
    loading: parametersListLoading
  } = useParametersListQuery({
    variables: {
      filter: {
        operationId: {
          eq: operationField.input.value
        }
      }
    },
    skip: !operationField.input.value
  })

  const { data: operationData } = useOperationQuery({
    variables: {
      id: operationField.input.value
    },
    skip: !operationField.input.value
  })

  const showParameterDrawer = operationField.input.value

  return (
    <>
      <DropdownField
        name="data_source"
        label="Data Source"
        size="small"
        defaultValue={DataSource.RESOURCE}
        options={DataSourceOptions}
        onChange={({ value }: any) => {
          dataSourceField.input.onChange(value)
          operationField.input.onChange(null)
          resourceField.input.onChange(null)
        }}
      />
      <ConditionalField when="data_source" is={DataSource.RESOURCE}>
        <SearchSelectField<ResourcesListQuery, ResourcesListQueryVariables>
          isSearchable
          preload
          name="data_source_settings.resource"
          label="Linked Resource"
          prependIcon="search"
          placeholder="Start typing to search"
          size="small"
          variant="light"
          labelKey="name"
          iconKey="app.identifier"
          valueKey="id"
          options={data?.resource ? [ data.resource ] : []}
          getOptionLabel={(option: Resource) => [ option.app?.name, option.name ].filter(Boolean).join(' > ')}
          getOptionIcon={getOptionIcon}
          query={ResourcesListDocument}
          dataKey="resourcesList"
          keys={[ 'name', 'identifier' ]}
          input={{
            value: resourceField.input.value,
            onChange: (value: Resource['id']) => {
              resourceField.input.onChange(value)
            }
          }}
        />
      </ConditionalField>
      <ConditionalField when="data_source" is={DataSource.OPERATION}>
        <OperationField
          operation={operationData?.operation}
          name="data_source_settings.operation"
          label="Linked Operation"
          input={{
            value: operationField.input.value,
            onChange: (value: Operation['id']) => {
              operationField.input.onChange(value)
            }
          }}
        />
      </ConditionalField>
      <ConditionalField when="data_source" is={DataSource.OPERATION}>
        {showParameterDrawer && (
          <ParametersDrawer
            parameters={parametersList as Parameter[]}
            loading={parametersListLoading}
          />
        )}
      </ConditionalField>
      <FormField
        name="heading"
        label="Heading"
        size="small"
      />
      <DrawerBlock
        defaultOpened
        title="Current Stat"
        as={Flex}
      >
        {() => (
          <Flex direction="column" gap={24}>
            <DropdownField
              name="current.kind"
              label="Kind"
              size="small"
              options={statItemValueKindOptions}
              defaultValue={StatItemValueKind.FIXED}
              required
            />
            <FormField
              name="current.value"
              label="Value"
              size="small"
              required
            />
            <FormField
              name="current.caption"
              label="Description"
              size="small"
            />
          </Flex>
        )}
      </DrawerBlock>
      <DrawerBlock
        title="Previous Stat"
        as={Flex}
      >
        {() => (
          <Flex direction="column" gap={24}>
            <FormField
              name="previous.is_visible"
              label="Visible?"
              size="small"
              type="checkbox"
              component={ToggleInput}
            />
            <DropdownField
              name="previous.kind"
              label="Kind"
              size="small"
              options={statItemValueKindOptions}
              defaultValue={StatItemValueKind.FIXED}
              required
            />
            <FormField
              name="previous.value"
              label="Value"
              size="small"
            />
            <FormField
              name="previous.caption"
              label="Description"
              size="small"
            />
          </Flex>
        )}
      </DrawerBlock>
      <DrawerBlock
        title="Change in Stat"
        as={Flex}
      >
        {() => (
          <Flex direction="column" gap={24}>
            <FormField
              name="delta.is_visible"
              label="Visible?"
              size="small"
              type="checkbox"
              component={ToggleInput}
            />
            <DropdownField
              name="delta.kind"
              label="Kind"
              size="small"
              options={statItemValueKindOptions}
              defaultValue={StatItemValueKind.FIXED}
              required
            />
            <FormField
              name="delta.value"
              label="Value"
              size="small"
            />
          </Flex>
        )}
      </DrawerBlock>
      <DrawerBlock
        title="Format"
        as={Flex}
      >
        {() => (
          <Flex direction="column" gap={24}>
            <DropdownField
              name="format.style"
              label="Style"
              size="small"
              options={[
                { label: 'Number', value: 'NUMBER', icon: 'number-field' },
                { label: 'Percentage', value: 'PERCENTAGE', icon: 'percentage-field' },
                { label: 'Currency', value: 'CURRENCY', icon: 'dollar' }
              ]}
            />
            <FormField
              name="format.precision"
              label="Precision"
              size="small"
            />
            <ConditionalField when="format.style" is="currency">
              <DropdownField
                name="format.currency_code"
                label="Currency Code"
                size="small"
                virtualize
                options={Object.entries(currencyToSymbolMap).map(([ code, symbol ]) => ({
                  label: `${code} (${symbol})`,
                  value: code
                }))}
              />
            </ConditionalField>
          </Flex>
        )}
      </DrawerBlock>
    </>
  )
}

const ColumnsList = ({ resourceId }: { resourceId?: string }) => {
  const { openDashboardEditorView, selectedBlockState } = useDashboard()
  const block = useRecoilValue(selectedBlockState)
  const fieldsRef = useRef<FieldArrayChildrenProps<any>>()
  useFieldArray({ name: 'columns', fieldsRef, subscription: {} })
  const field = useField('columns')
  const dataSourceField = useField('data_source')
  const onDragEnd = useReorderFieldArray(fieldsRef)
  let idToAttributesMap = {} as TMap<AttributesListQuery['attributesList'][number]>

  const { data, loading, error } = useAttributesListQuery({
    variables: {
      filter: {
        resourceId: {
          eq: resourceId
        }
      }
    },
    skip: !resourceId,
    onCompleted: ({ attributesList }) => {
      idToAttributesMap = getPropertyToElementMap(attributesList || [], 'id')
      const currentResourceId = idToAttributesMap[field.input.value?.[0]?.attribute]?.resourceId

      if (currentResourceId !== resourceId) {
        field.input.onChange(attributesList.map((attribute) => ({
          attribute: attribute.id,
          kind: 'RESOURCE',
          width: '200px',
          is_visible: true,
          is_orderable: false
        })))
      }
    }
  })

  idToAttributesMap = data ? getPropertyToElementMap(data.attributesList, 'id') : idToAttributesMap

  return (
    <DataList
      loading={loading}
      error={error}
      contents={[
        {
          dataKey: 'attribute',
          slot: 'primary',
          renderer: ({ index }) => (
            <Text>
              {field.input.value?.[index!].label
                || idToAttributesMap[field.input.value?.[index!].attribute]?.name || ''}
            </Text>
          )
        },
        {
          dataKey: 'attribute.identfier',
          slot: 'secondary',
          renderer: ({ index }) => (
            <Text>
              {idToAttributesMap[field.input.value?.[index!].attribute]?.identifier}
            </Text>
          )
        },
        {
          dataKey: 'is_visible',
          slot: 'toggle',
          renderer: ({ index }) => (
            <FormField
              defaultValue
              type="checkbox"
              component={ToggleInput}
              name={`columns[${index}].is_visible`}
            />
          )
        }
      ]}
      actions={[
        {
          icon: 'edit',
          title: 'Edit',
          onClick: (rowData, index) => {
            openDashboardEditorView({
              target: Views.ADD_COLUMN,
              params: {
                initialValues: rowData as any,
                currentIndex: index,
                type: dataSourceField.input.value,
                block: block!,
                isUpdating: true
              }
            })
          }
        },
        {
          icon: 'trash',
          title: 'Delete',
          onClick: (_, index) => {
            fieldsRef.current?.fields.remove(index!)
          }
        }
      ]}
      data={field.input.value || []}
      onRowDragEnd={onDragEnd}
      selectionMode="none"
    />
  )
}

const ParametersList = ({
  name = 'data_source_settings.parameters',
  parameters
}: {
  name?: string,
  parameters: Parameter[]
}) => {
  const field = useField(name, { subscription: {} })
  const form = useForm()

  const onChangeRef = useRef(field.input.onChange)

  useEffect(() => {
    onChangeRef.current = field.input.onChange
  })

  useEffect(() => {
    // clear outldated values saved in blocks data_source_settings.parameters
    onChangeRef.current(
      parameters
        .map((param) => ({ [param.identifier]: form.getFieldState(`${name}.${param.identifier}`)?.value }))
        .reduce((a, e) => ({ ...a, ...e }), {})
    )
  }, [ form, name, parameters ])

  return (
    <Flex direction="column" gap={16}>
      {parameters.map((param) => (
        <FormField
          key={param.id}
          name={`${name}.${param.identifier}`}
          label={param.name}
          component={param.fieldType === 'json-field' ? CodeEditorInput : TextInput}
          language={param.fieldType === 'json-field' ? 'json' : undefined}
          size="small"
          type="text"
        />
      ))}
    </Flex>
  )
}

const ParametersDrawer = ({
  name = 'data_source_settings.parameters',
  parameters,
  loading
}: {
  name?: string,
  parameters: Parameter[],
  loading: boolean
}) => {
  const field = useField(name, { subscription: {} })
  const form = useForm()

  const onChangeRef = useRef(field.input.onChange)

  useEffect(() => {
    onChangeRef.current = field.input.onChange
  })

  useEffect(() => {
    // clear the form of any old values saved in blocks data_source_settings.parameters
    if (!parameters.length && !loading) onChangeRef.current({})
  }, [ loading, form, name, parameters ])

  if (!parameters.length) return null

  return (
    <DrawerBlock
      defaultOpened
      title="Parameters"
      as={Flex}
    >
      {() => (
        <Flex direction="column">
          <DashboardEditorLoader
            empty={{
              variant: 'simple',
              element: (
                <Flex alignItems="center" direction="column">
                  <Text fontSize={14} color="dark500">No parameters available.</Text>
                </Flex>
              )
            }}
            data={parameters}
            loading={loading}
          >
            <ParametersList name={name} parameters={parameters} />
          </DashboardEditorLoader>
        </Flex>
      )}
    </DrawerBlock>
  )
}

const getActionName = (operation: Operation) => {
  switch (operation.method) {
    case (BehaviorMethod.GENERIC):
      return operation.name
    case (BehaviorMethod.LIST):
      return 'Refresh'
    default:
      return startCase(operation.method.toLowerCase())
  }
}

// TBD
const currentLocale = 'en_US'

const DataTableBlockFields = () => {
  const { openDashboardEditorView, selectedBlockState } = useDashboard()
  const block = useRecoilValue(selectedBlockState)

  const columnsField = useField('columns')
  const dataSourceField = useField('data_source')
  const operationField = useField('data_source_settings.operation')
  const resourceField = useField('data_source_settings.resource')

  const isOperationMode = dataSourceField.input.value === DataSource.OPERATION
  const hideColumnsList = isOperationMode && !columnsField.input.value?.length
  const showDrawer = resourceField.input.value || operationField.input.value
  const resourceId = resourceField.input.value
  const operationId = operationField.input.value
  const fieldsRef = useRef<FieldArrayChildrenProps<any>>()
  useFieldArray({ name: 'actions', fieldsRef, subscription: {} })

  useEffect(() => {
    if (fieldsRef.current?.fields.length) return

    if (resourceId) {
      fieldsRef.current?.fields.change([
        ...defaultOperationToolbarActions,
        ...defaultToolbarActions,
        ...defaultRowActions
      ])
    }

    if (operationId) {
      fieldsRef.current?.fields.change([
        ...defaultOperationToolbarActions
      ])
    }
  }, [ operationId, resourceId ])

  return (
    <>
      <FormField
        name="heading"
        label="Heading"
        size="small"
      />
      <DataSourceFields />
      {showDrawer && (
        <DrawerBlock
          contentPadding="small"
          defaultOpened={!!resourceField.input.value || !!operationField.input.value}
          title="Columns"
          as={Flex}
        >
          {() => (
            <Flex direction="column">
              {!hideColumnsList && (
                <FormValuesField fieldNames={[ 'data_source_settings' ]}>
                  {({ data_source_settings: dataSourceSettings }) => (
                    dataSourceSettings && <ColumnsList resourceId={dataSourceSettings.resource} />
                  )}
                </FormValuesField>
              )}
              <AddNewCard
                as={Flex}
                alignItems="center"
                direction="row"
                gap={16}
                onClick={() => {
                  openDashboardEditorView({
                    target: Views.ADD_COLUMN,
                    params: {
                      initialValues: {
                        kind: isOperationMode ? 'DEFAULT' : 'RESOURCE',
                        display_type_settings: {},
                        width: '200px',
                        is_visible: true,
                        is_orderable: false
                      },
                      type: dataSourceField.input.value,
                      block: block!
                    }
                  })
                }}
              >
                <Icon data-icon name="add-thin" size={12} />
                <Text fontSize={12}>Add new</Text>
              </AddNewCard>
            </Flex>
          )}
        </DrawerBlock>
      )}
      {showDrawer && (
        <DrawerBlock
          contentPadding="small"
          // defaultOpened
          title="Toolbar"
          as={Flex}
        >
          {() => (
            <Flex direction="column" gap={16}>
              <FormField
                name="search.is_enabled"
                label="Quick Search?"
                size="small"
                type="checkbox"
                defaultValue
                initialValue
                component={ToggleInput}
              />
              <ActionsList
                    // loading={operationsLoading}
                type={ActionType.TOOLBAR}
              />
            </Flex>
          )}
        </DrawerBlock>
      )}
      {showDrawer && (
        <DrawerBlock
          contentPadding="small"
          // defaultOpened
          title="Rows"
          as={Flex}
        >
          {() => (
            <Flex direction="column" gap={16}>
              <DropdownField
                name="selection_mode"
                label="Selection Mode"
                size="small"
                defaultValue="MULTIPLE"
                options={SelectionModeOptions}
              />
              <ActionsList
                        // loading={operationsLoading}
                type={ActionType.ROW}
              />
            </Flex>
          )}
        </DrawerBlock>
      )}
      <DrawerBlock
        title="Pagination"
        as={Flex}
      >
        {() => (
          <Flex direction="column" gap={16}>
            <FormField
              name="pagination.is_enabled"
              label="Show Pagination?"
              size="small"
              type="checkbox"
              component={ToggleInput}
            />
            <DropdownField
              name="pagination.page_size"
              label="Page Size"
              size="small"
              options={DEFAULT_PAGE_SIZE_OPTIONS.map((size) => ({ label: size, value: size }))}
              defaultValue={DEFAULT_PAGE_SIZE_OPTIONS[0]}
            />
          </Flex>
        )}
      </DrawerBlock>
    </>
  )
}

const SlotsList = ({ resourceId, operationId }: { resourceId: string, operationId: string }) => {
  const { data } = useAttributesListQuery({
    variables: {
      filter: {
        resourceId: {
          eq: resourceId
        }
      }
    },
    skip: !resourceId
  })

  return (
    <Flex direction="column" gap={16}>
      {resourceId && (
        <>
          <DropdownField
            name="primary_value.attribute"
            label="Primary Value"
            size="small"
            defaultValue="NONE"
            options={data?.attributesList.map((attribute) => ({
              label: attribute.name,
              value: attribute.id
            })) || []}
          />
          <DropdownField
            name="secondary_value.attribute"
            label="Secondary Value"
            size="small"
            defaultValue="NONE"
            options={data?.attributesList.map((attribute) => ({
              label: attribute.name,
              value: attribute.id
            })) || []}
          />
        </>
      )}
      {operationId && (
        <>
          <FormField
            name="primary_value.value"
            label="Primary Value"
            component={TextInput}
            size="small"
            type="text"
          />
          <FormField
            name="secondary_value.value"
            label="Secondary Value"
            component={TextInput}
            size="small"
            type="text"
          />
        </>
      )}
      {(resourceId || operationId) && (
        <>
          <FormField
            name="primary_value.display_type"
            type="hidden"
            size="small"
            component="input"
            initialValue={DisplayType.PLAIN_TEXT}
          />
          <FormField
            name="primary_value.display_type_settings.truncate"
            type="hidden"
            size="small"
            component="input"
            initialValue={false}
          />
          <FormField
            name="primary_value.width"
            type="hidden"
            size="small"
            component="input"
            initialValue="200"
          />
          <FormField
            name="secondary_value.display_type"
            type="hidden"
            component="input"
            size="small"
            initialValue={DisplayType.PLAIN_TEXT}
          />
          <FormField
            name="secondary_value.display_type_settings.truncate"
            type="hidden"
            component="input"
            size="small"
            initialValue={false}
          />
          <FormField
            name="secondary_value.width"
            type="hidden"
            size="small"
            component="input"
            initialValue="100%"
          />
        </>
      )}
    </Flex>
  )
}

const DataListBlockFields = () => {
  const resourceField = useField('data_source_settings.resource')
  const operationField = useField('data_source_settings.operation')
  const dataSourceField = useField('data_source')
  const primaryValueField = useField('primary_value')
  const secondaryValueField = useField('secondary_value')

  const showSlots = operationField.input.value || resourceField.input.value

  const { data } = useResourceQuery({
    variables: { id: resourceField.input.value },
    skip: !resourceField.input.value
  })

  const {
    data: { parametersList = [] } = {},
    loading: parametersListLoading
  } = useParametersListQuery({
    variables: {
      filter: {
        operationId: {
          eq: operationField.input.value
        }
      }
    },
    skip: !operationField.input.value
  })

  const { data: operationData } = useOperationQuery({
    variables: {
      id: operationField.input.value
    },
    skip: !operationField.input.value
  })

  const showDrawer = resourceField.input.value || operationField.input.value

  return (
    <>
      <DropdownField
        name="data_source"
        label="Data Source"
        size="small"
        defaultValue={DataSource.RESOURCE}
        options={DataSourceOptions}
        onChange={({ value }: any) => {
          dataSourceField.input.onChange(value)
          operationField.input.onChange(null)
          resourceField.input.onChange(null)
        }}
      />
      <ConditionalField when="data_source" is={DataSource.RESOURCE}>
        <SearchSelectField<ResourcesListQuery, ResourcesListQueryVariables>
          isSearchable
          isClearable
          preload
          name="data_source_settings.resource"
          label="Linked Resource"
          prependIcon="search"
          placeholder="Start typing to search"
          size="small"
          variant="light"
          labelKey="name"
          iconKey="app.identifier"
          valueKey="id"
          options={data?.resource ? [ data.resource ] : []}
          getOptionLabel={(option: Resource) => [ option.app?.name, option.name ].filter(Boolean).join(' > ')}
          getOptionIcon={getOptionIcon}
          query={ResourcesListDocument}
          dataKey="resourcesList"
          keys={[ 'name', 'identifier' ]}
          input={{
            value: resourceField.input.value,
            onChange: (value: Resource['id']) => {
              if (resourceField.input.value !== value) {
                primaryValueField.input.onChange(null)
                secondaryValueField.input.onChange(null)
                resourceField.input.onChange(value)
              }
            }
          }}
        />
      </ConditionalField>
      <ConditionalField when="data_source" is={DataSource.OPERATION}>
        <OperationField
          operation={operationData?.operation}
          name="data_source_settings.operation"
          label="Linked Operation"
          input={{
            value: operationField.input.value,
            onChange: (value: Resource['id']) => {
              if (operationField.input.value !== value) {
                primaryValueField.input.onChange(null)
                secondaryValueField.input.onChange(null)
                operationField.input.onChange(value)
              }
            }
          }}
        />
      </ConditionalField>
      <ConditionalField when="data_source" is={DataSource.OPERATION}>
        {showDrawer && (
          <ParametersDrawer
            parameters={parametersList as Parameter[]}
            loading={parametersListLoading}
          />
        )}
      </ConditionalField>
      <FormField
        name="heading"
        label="Heading"
        size="small"
      />
      {showSlots && (
        <Flex direction="column" gap={16}>
          <FormValuesField fieldNames={[ 'data_source_settings' ]}>
            {({ data_source_settings: dataSourceSettings }) => (
              dataSourceSettings && (
                <SlotsList
                  resourceId={dataSourceSettings.resource}
                  operationId={dataSourceSettings.operation}
                />
              )
            )}
          </FormValuesField>
        </Flex>
      )}
      <DrawerBlock
        title="Rows"
        as={Flex}
      >
        {() => (
          <Flex direction="column" gap={16}>
            <DropdownField
              name="selection_mode"
              label="Selection Mode"
              size="small"
              defaultValue="MULTIPLE"
              options={SelectionModeOptions}
            />
            <FormValuesField fieldNames={[ 'resource' ]}>
              {({ resource }) => (
                <ActionsList
                        // loading={operationsLoading}
                  resourceId={resource}
                  type={ActionType.ROW}
                />
              )}
            </FormValuesField>
          </Flex>
        )}
      </DrawerBlock>
      <DrawerBlock
        title="Toolbar"
        as={Flex}
      >
        {() => (
          <Flex direction="column" gap={16}>
            <FormField
              name="search.is_enabled"
              label="Quick Search?"
              size="small"
              type="checkbox"
              component={ToggleInput}
            />
            <FormValuesField fieldNames={[ 'resource' ]}>
              {({ resource }) => (
                <ActionsList
                    // loading={operationsLoading}
                  resourceId={resource}
                  type={ActionType.TOOLBAR}
                />
              )}
            </FormValuesField>
          </Flex>
        )}
      </DrawerBlock>
      <DrawerBlock
        title="Pagination"
        as={Flex}
      >
        {() => (
          <Flex direction="column" gap={16}>
            <FormField
              name="pagination.is_enabled"
              label="Show Pagination?"
              size="small"
              type="checkbox"
              component={ToggleInput}
            />
            <DropdownField
              name="pagination.page_size"
              label="Page Size"
              size="small"
              options={DEFAULT_PAGE_SIZE_OPTIONS.map((size) => ({ label: size, value: size }))}
              defaultValue={DEFAULT_PAGE_SIZE_OPTIONS[0]}
            />
          </Flex>
        )}
      </DrawerBlock>
    </>
  )
}

const DataSourceFields = () => {
  const actionsField = useField('actions')
  const resourceField = useField('data_source_settings.resource')
  const operationField = useField('data_source_settings.operation')
  const dataSourceField = useField('data_source')

  const { data } = useResourceQuery({
    variables: { id: resourceField.input.value },
    skip: !resourceField.input.value
  })

  const {
    data: { parametersList = [] } = {},
    loading: parametersListLoading
  } = useParametersListQuery({
    variables: {
      filter: {
        operationId: {
          eq: operationField.input.value
        }
      }
    },
    skip: !operationField.input.value
  })

  const { data: operationData } = useOperationQuery({
    variables: {
      id: operationField.input.value
    },
    skip: !operationField.input.value
  })

  const showDrawer = resourceField.input.value || operationField.input.value

  return (
    <>
      <DropdownField
        name="data_source"
        label="Data Source"
        size="small"
        isClearable
        defaultValue={DataSource.RESOURCE}
        options={DataSourceOptions}
        onChange={(change: { value: any } | null) => {
          dataSourceField.input.onChange(change?.value)
          actionsField.input.onChange(null)
          operationField.input.onChange(null)
          resourceField.input.onChange(null)
        }}
      />
      <ConditionalField when="data_source" is={DataSource.RESOURCE}>
        <SearchSelectField<ResourcesListQuery, ResourcesListQueryVariables>
          isSearchable
          isClearable
          preload
          name="data_source_settings.resource"
          label="Linked Resource"
          prependIcon="search"
          placeholder="Start typing to search"
          size="small"
          variant="light"
          labelKey="name"
          iconKey="app.identifier"
          valueKey="id"
          options={data?.resource ? [ data.resource ] : []}
          getOptionLabel={(option: Resource) => [ option.app?.name, option.name ].filter(Boolean).join(' > ')}
          getOptionIcon={getOptionIcon}
          query={ResourcesListDocument}
          dataKey="resourcesList"
          keys={[ 'name', 'identifier' ]}
          input={{
            value: resourceField.input.value,
            onChange: (value: Resource['id']) => {
              actionsField.input.onChange(null)
              if (resourceField.input.value !== value) {
                resourceField.input.onChange(value)
              }
            }
          }}
        />
        <FormField
          name="data_source_settings.filter"
          label="Filter"
          component={CodeEditorInput}
          language="json"
          size="small"
          type="text"
        />
        <FormField
          name="data_source_settings.order"
          label="Order"
          component={CodeEditorInput}
          language="json"
          size="small"
          type="text"
        />
        <FormField
          name="data_source_settings.fields"
          label="Fields"
          component={CodeEditorInput}
          language="json"
          size="small"
          type="text"
        />
      </ConditionalField>
      <ConditionalField when="data_source" is={DataSource.OPERATION}>
        <OperationField
          operation={operationData?.operation}
          name="data_source_settings.operation"
          label="Linked Operation"
          input={{
            value: operationField.input.value,
            onChange: (value: Resource['id']) => {
              if (operationField.input.value !== value) {
                operationField.input.onChange(value)
              }
            }
          }}
        />
      </ConditionalField>
      <ConditionalField when="data_source" is={DataSource.OPERATION}>
        {showDrawer && (
          <ParametersDrawer
            parameters={parametersList as Parameter[]}
            loading={parametersListLoading}
          />
        )}
      </ConditionalField>
      <WhenFieldChanges field="data_source" includes={[ null, undefined ]} set="data_source_settings" to={null} />
    </>
  )
}

const DropdownBlockFields = () => (
  <>
    <DataSourceFields />
    <ConditionalField when="data_source" is={DataSource.RESOURCE}>
      <FormField
        name="data_source_settings.is_async"
        label="Async?"
        size="small"
        type="checkbox"
        component={ToggleInput}
      />
    </ConditionalField>
    <FormField
      name="label_key"
      label="Label Key"
      size="small"
    />
    <FormField
      name="value_key"
      label="Value Key"
      size="small"
    />
    <FormField
      name="meta_key"
      label="Meta Key"
      size="small"
    />
    <DropdownFieldView.Settings
      fieldSettingsPrefix="settings."
    />
  </>
)

const TextInputBlockFields = () => (
  <>
    <TextFieldView.Settings
      fieldSettingsPrefix="settings."
    />
    <FormField
      component={TextInput}
      name="settings.default_value"
      label="Default Value"
      helpText="Liquid parseable"
      size="small"
    />
  </>
)

const RowsList = ({ resourceId, kind }: { resourceId?: string, kind: 'attribute' | 'relationship' }) => {
  const name = kind === 'attribute' ? 'rows' : 'related_rows'
  const { openDashboardEditorView, selectedBlockState } = useDashboard()
  const block = useRecoilValue(selectedBlockState)
  const fieldsRef = useRef<FieldArrayChildrenProps<any>>()
  useFieldArray({ name, fieldsRef, subscription: {} })
  const field = useField(name)

  const onDragEnd = useReorderFieldArray(fieldsRef)
  let idToAttributesMap: TMap<AttributesListQuery['attributesList'][number]>
  let idToRelationshipsMap: TMap<RelationshipsListQuery['relationshipsList'][number]>

  const { data, loading, error } = useAttributesListQuery({
    variables: {
      filter: {
        resourceId: { eq: resourceId }
      },
      order: [ {
        position: 'asc'
      } ],
      limit: ATTRIBUTES_LIST_LIMIT
    },
    skip: !resourceId,
    onCompleted: ({ attributesList }) => {
      if (kind === 'relationship') return

      idToAttributesMap = getPropertyToElementMap(attributesList || [], 'id')
      const currentResourceId = idToAttributesMap[field.input.value?.[0]?.attribute]?.resourceId

      if (currentResourceId !== resourceId) {
        field.input.onChange(attributesList.map((attribute) => ({
          attribute: attribute.id,
          is_hidden: false
        })))
      }
    }
  })

  const {
    data: { relationshipsList } = {}, loading: relationshipsLoading, error: relationshipsError
  } = useRelationshipsListQuery({
    variables: {
      filter: {
        sourceId: { eq: resourceId }
      },
      order: [ {
        position: 'asc'
      } ],
      limit: RELATIONSHIPS_LIST_LIMIT
    },
    skip: !resourceId,
    onCompleted: ({ relationshipsList }) => {
      if (kind === 'attribute') return
      idToRelationshipsMap = getPropertyToElementMap(relationshipsList || [], 'id')
      const currentResourceId = idToRelationshipsMap[field.input.value?.[0]?.relationship]?.sourceId

      if (currentResourceId !== resourceId) {
        field.input.onChange(relationshipsList.map((relationship) => ({
          relationship: relationship.id,
          is_hidden: false
        })))
      }
    }
  })

  if (data) idToAttributesMap = getPropertyToElementMap(data.attributesList, 'id')
  if (relationshipsList) idToRelationshipsMap = getPropertyToElementMap(relationshipsList, 'id')

  return (
    <>
      {field.input.value && (
        <DataList
          loading={loading || relationshipsLoading}
          error={error || relationshipsError}
          contents={[
            {
              dataKey: kind,
              slot: 'primary',
              renderer: ({ index }) => (
                <Text>
                  {field.input.value?.[index!].label
                  || (kind === 'attribute' ? idToAttributesMap : idToRelationshipsMap)
                    ?.[field.input.value?.[index!][kind]]
                    ?.name}
                </Text>
              )
            },
            {
              dataKey: `${kind}.identfier`,
              slot: 'secondary',
              renderer: ({ index }) => (
                <Text>
                  {(kind === 'attribute' ? idToAttributesMap : idToRelationshipsMap)
                    ?.[field.input.value?.[index!][kind]]
                    ?.identifier}
                </Text>
              )
            },
            {
              dataKey: 'is_hidden',
              slot: 'toggle',
              renderer: ({ index }) => (
                <FormField
                  invert
                  type="checkbox"
                  component={ToggleInput}
                  name={`${name}[${index}].is_hidden`}
                />
              )
            }
          ]}
          data={field.input.value || []}
          onRowDragEnd={onDragEnd}
          selectionMode="none"
          actions={[
            {
              icon: 'edit',
              title: 'Edit',
              onClick: (rowData, index) => {
                openDashboardEditorView({
                  target: Views.ADD_ROW,
                  params: {
                    initialValues: rowData as any,
                    kind,
                    currentIndex: index,
                    block: block!,
                    isUpdating: true,
                    type: resourceId ? DataSource.RESOURCE : DataSource.OPERATION
                  }
                })
              }
            },
            {
              icon: 'trash',
              title: 'Delete',
              onClick: (_, index) => {
                fieldsRef.current?.fields.remove(index!)
              }
            }
          ]}
        />
      )}
      <AddNewCard
        as={Flex}
        alignItems="center"
        direction="row"
        gap={16}
        onClick={() => {
          openDashboardEditorView({
            target: Views.ADD_ROW,
            params: {
              initialValues: {
                is_hidden: false,
                display_type_settings: {}
              },
              kind,
              type: resourceId ? DataSource.RESOURCE : DataSource.OPERATION,
              block: block!
            }
          })
        }}
      >
        <Icon data-icon name="add-thin" size={12} />
        <Text fontSize={12}>Add new</Text>
      </AddNewCard>
    </>
  )
}

const DetailsBlockFields = () => {
  const form = useForm()
  const { currentWorkspace } = useContext(WorkspaceContext)!
  const resourceField = useField('data_source_settings.resource')
  const operationField = useField('data_source_settings.operation')
  const dataSourceField = useField('data_source')
  const { data: resourceData } = useResourceQuery({
    variables: { id: resourceField.input.value },
    skip: !resourceField.input.value
  })

  const {
    data: { parametersList = [] } = {},
    loading: parametersListLoading
  } = useParametersListQuery({
    variables: {
      filter: {
        operationId: {
          eq: operationField.input.value
        }
      }
    },
    skip: !operationField.input.value
  })

  const { data: operationData } = useOperationQuery({
    variables: {
      id: operationField.input.value
    },
    skip: !operationField.input.value
  })

  const showParameterDrawer = operationField.input.value

  const resource = resourceData?.resource

  const recordField = useField('record')

  const switcherState = useSwitcherState(resource?.appId)
  const {
    attributesResult: {
      data: { attributesList: attributes = [] } = {}
    },
    searchResult: [ {
      data: { internalSearchRecords: searchRecords = [] } = {},
      loading: searchLoading,
      error: searchError
    }, onSearch ]
  } = useSearchGenericRecords({
    resourceId: resourceData?.resource.id,
    environmentId: switcherState.switcher.data.environment?.id,
    filters: {
      ...(recordField.input.value && {
        or: [
          {
            id: {
              eq: recordField.input.value
            }
          }
        ]
      })
    },
    skip: !resourceData?.resource.id
  })

  const titleAttribute = attributes.find((attr) => attr.id === resource?.titleAttributeId)
  const subTitleAttribute = attributes.find(
    (attr) => attr.id === resource?.subtitleAttributeId
  )
  const labelKey = `data.${camelCase(titleAttribute?.identifier || attributes[0]?.identifier || 'id')}.${currentLocale}`
  const metaKey = subTitleAttribute ? `data.${camelCase(subTitleAttribute.identifier)}.${currentLocale}` : undefined
  const valueKey = `data.id.${currentLocale}`

  const [ mode, setMode ] = useState<'RECORD' | 'EXPRESSION'>('RECORD')

  return (
    <>
      <DropdownField
        name="data_source"
        label="Data Source"
        size="small"
        defaultValue={DataSource.RESOURCE}
        options={DataSourceOptions}
        onChange={({ value }: any) => {
          dataSourceField.input.onChange(value)
          operationField.input.onChange(null)
          resourceField.input.onChange(null)
        }}
      />
      <ConditionalField when="data_source" is={DataSource.RESOURCE}>
        <SearchSelectField<ResourcesListQuery, ResourcesListQueryVariables>
          isSearchable
          isClearable
          preload
          name="data_source_settings.resource"
          label="Resource"
          prependIcon="search"
          placeholder="Start typing to search"
          size="small"
          variant="light"
          labelKey="name"
          iconKey="app.identifier"
          valueKey="id"
          options={resourceData?.resource ? [ resourceData.resource ] : []}
          getOptionLabel={(option: Resource) => [ option.app?.name, option.name ].filter(Boolean).join(' > ')}
          getOptionIcon={getOptionIcon}
          query={ResourcesListDocument}
          queryOptions={{
            variables: {
              filter: {
                workspaceId: {
                  eq: currentWorkspace.id
                }
              },
              order: [ {
                name: 'asc'
              } ]
            }
          }}
          input={{
            value: resourceField.input.value,
            onChange: (value: Resource['id']) => {
              form.change('data_source_settings', { resource: value })
            }
          }}
          dataKey="resourcesList"
          keys={[ 'name', 'identifier' ]}
        />
        <ButtonGroupInput
          fullWidth
          options={[
            { label: 'Select a Record', value: 'RECORD' },
            { label: 'Pass an Expression', value: 'EXPRESSION' }
          ]}
          input={{
            name: 'mode',
            onBlur: () => {},
            onFocus: () => {},
            value: mode,
            onChange: (value: 'RECORD' | 'EXPRESSION') => setMode(value)
          }}
          meta={{}}
        />
        {mode === 'RECORD' && (
          <>
            <EnvironmentSwitcher appId={null} {...switcherState} />
            <DropdownField
              name="data_source_settings.record"
              label="Record"
              size="small"
              variant="light"
              isLoading={searchLoading}
              hasError={Boolean(searchError)}
              labelKey={labelKey}
              metaKey={metaKey}
              valueKey={valueKey}
              defaultOptions={searchRecords}
              options={searchRecords}
              loadOptions={((inputValue: string, callback: any) => {
                onSearch(inputValue, (data) => callback(data.internalSearchRecords))
              })}
              isClearable
            />
          </>
        )}
        {mode === 'EXPRESSION' && (
          <FormField
            component={CodeEditorInput}
            name="data_source_settings.record"
            label="Record"
            size="small"
          />
        )}
      </ConditionalField>
      <ConditionalField when="data_source" is={DataSource.OPERATION}>
        <OperationField
          operation={operationData?.operation}
          name="data_source_settings.operation"
          label="Linked Operation"
          input={{
            value: operationField.input.value,
            onChange: (value: Operation['id']) => {
              form.change('rows', [])
              form.change('related_rows', [])
              form.change('data_source_settings', { operation: value })
            }
          }}
        />
      </ConditionalField>
      <ConditionalField when="data_source" is={DataSource.OPERATION}>
        {showParameterDrawer && (
          <ParametersDrawer
            parameters={parametersList as Parameter[]}
            loading={parametersListLoading}
          />
        )}
      </ConditionalField>
      <FormField
        name="heading"
        label="Heading"
        size="small"
      />
      <NumberField
        name="columns"
        label="Columns"
        size="small"
        defaultValue={1}
      />
      <Flex direction="column" gap={10}>
        <FieldLabel>Rows</FieldLabel>
        <RowsList kind="attribute" resourceId={resourceField.input.value || operationData?.operation.resourceId} />
      </Flex>
      <FormField
        name="show_related"
        label="Show Related?"
        size="small"
        type="checkbox"
        component={ToggleInput}
      />
      <ConditionalField when="show_related" is>
        <Flex direction="column" gap={10}>
          <FieldLabel>Relationships</FieldLabel>
          <RowsList kind="relationship" resourceId={resourceField.input.value || operationData?.operation.resourceId} />
        </Flex>
      </ConditionalField>
      <FormField
        component={CodeEditorInput}
        name="preprocess"
        label="Transform"
        language="javascript"
        size="small"
        helpText="Use this to transform the data before it is displayed."
      />
    </>
  )
}

const TABS_STYLE_OPTIONS = [
  { label: 'Fixed', value: 'fixed' },
  { label: 'Fluid', value: 'fluid' }
]

const TabsBlockFields = () => (
  <>
    <DropdownField
      name="variant"
      label="Style"
      size="small"
      variant="light"
      options={TABS_STYLE_OPTIONS}
    />
    <RepeatedField
      name="tabs"
      field={{ name: 'Tabs' }}
      onAdd={() => ({})}
      renderField={(_, { fieldName, index }) => (
        <TextField
          name={`${fieldName}.title`}
          initialValue={`Tab ${index + 1}`}
          size="small"
        />
      )}
    />
  </>
)

const CSVInputBlockFields = () => (
  <>
    <RepeatedField
      name="headers"
      field={{ name: 'headers' }}
      renderField={(_, { fieldName }) => (
        <TextField
          name={fieldName}
          size="small"
        />
      )}
    />
  </>
)

const ButtonBlockFields = () => (
  <>
    <FormField
      name="type"
      component={ButtonGroupInput}
      fullWidth
      options={[
        { label: 'Default', value: 'BUTTON' },
        { label: 'Submit', value: 'SUBMIT' }
      ]}
    />
    <FormField
      checkRequired
      name="label"
      label="Label"
      component={TextInput}
      size="small"
    />
    <FormField
      component={IconInput}
      name="icon"
      label="Icon"
      placeholder="Choose Icon"
      size="small"
      type="text"
    />
    <ConditionalField when="type" is="BUTTON">
      <ActionsList
        type={ActionType.GENERIC}
      />
    </ConditionalField>
    <ConditionalField when="type" is="SUBMIT">
      <Flex direction="column" gap={10}>
        <FieldLabel>Actions</FieldLabel>
        <Text color="dark500" fontSize={14}>This button is bound to a form via submit behavior.
          You can modify the actions in the parent Form
        </Text>
      </Flex>
    </ConditionalField>
  </>
)

const CardBlockFields = () => (
  <>
    <FormField name="heading" label="Heading" size="small" />
    <BlocksList key="blocks" />
  </>
)

const renderBlockForm = (type: BlockType) => {
  if (!type) return null

  if (type === 'TitleBlock') {
    return <TitleBlockFields />
  }

  if (type === 'StatBlock') {
    return <StatBlockFields />
  }

  if (type === 'DataTableBlock') {
    return <DataTableBlockFields />
  }

  if (type === 'DataListBlock') {
    return <DataListBlockFields />
  }

  if (type === 'DetailsBlock') {
    return <DetailsBlockFields />
  }

  if (type === 'FormBlock') {
    return 'AddFormBlockView'
  }

  if (type === 'TabsBlock') {
    return <TabsBlockFields />
  }

  if (type === 'DropdownBlock') {
    return <DropdownBlockFields />
  }

  if (type === 'TextInputBlock') {
    return <TextInputBlockFields />
  }

  if (type === 'CSVInputBlock') {
    return <CSVInputBlockFields />
  }

  if (type === 'ButtonBlock') {
    return <ButtonBlockFields />
  }

  if (type === 'FormFieldBlock') {
    return 'AddFormFieldBlockView'
  }

  if (type === 'CardBlock') {
    return <CardBlockFields />
  }

  return BLOCKS_TO_FIELDS_MAP[type]?.map((field) => (
    {
      object: <JsonField
        key={field.name}
        name={field.name}
        label={field.label}
        size="small"
      />,
      boolean: <FormField
        component={ToggleInput}
        type="checkbox"
        key={field.name}
        name={field.name}
        label={field.label}
        size="small"
      />,
      number: <NumberField
        key={field.name}
        name={field.name}
        label={field.label}
        size="small"
      />,
      dropdown: <DropdownField
        key={field.name}
        name={field.name}
        label={field.label}
        size="small"
        // @ts-ignore
        options={field.options}
        // @ts-ignore
        getOptionLabel={field.getOptionLabel}
      />,
      string: <FormField
        key={field.name}
        name={field.name}
        label={field.label}
        size="small"
      />,
      actions: <ActionsList
        type={ActionType.TOOLBAR}
      />
    }[field.type] || (
      <FormField
        key={field.name}
        name={field.name}
        label={field.label}
        size="small"
      />
    )
  ))
}

const EditBlockForm = ({ type, onSubmit }: { type: BlockType, onSubmit: () => void }) => (
  <Flex as="form" onSubmit={onSubmit} justifyContent="space-between" direction="column" gap={24}>
    <Flex direction="column" gap={24}>
      <Text fontSize={18} fontWeight="bold">
        Block Properties
      </Text>
      {renderBlockForm(type)}
      <Divider />
      <Text fontSize={18} fontWeight="bold">
        Advanced
      </Text>
      <FormField
        name="_identifier"
        label="Identifier"
        size="small"
      />
      <FormField
        name="visibility_criteria.expression"
        label="Visibility Criteria"
        size="small"
        component={CodeEditorInput}
      />
      <LiveBlockEditorOrchestrator />
    </Flex>
    <input type="submit" style={{ display: 'none' }} />
  </Flex>
)

const DEFAULT_VISIBILITY_CRITERIA = Object.freeze({
  kind: 'LIQUID',
  expression: ''
})

const EditBlockFormWrapper = ({ type }: any) => {
  const {
    selectedBlockState,
    getBlocks,
    getOperations,
    dashboardEditorState,
    blockState,
    resetDashboardEditorStack
  } = useDashboard()

  const { params: { block } = {} } = useRecoilValue<DashboardEditorView<Views.EDIT_BLOCK>>(
    dashboardEditorState
  )

  // eslint-disable-next-line no-underscore-dangle
  const _selectedBlock = useRecoilValue(selectedBlockState)! || block
  const selectedBlock = useRecoilValue(blockState(_selectedBlock.id))

  const [ upsertView ] = useUpsertViewMutation()

  const handleUpsertBlock = useSubmitHandler(upsertView, { successAlert: { message: 'Your changes were saved.' } })
  const { urn } = useDashboardEditorContextProvider()

  const handleSubmit = async () => handleUpsertBlock({
    urn,
    blocks: await getBlocks(urn),
    operations: await getOperations()
  })

  const render: RenderableProps<FormRenderProps<any>>['render'] = useMemo(() => ({ handleSubmit, submitting }) => (
    <>
      <DashboardEditorBody>
        <EditBlockForm onSubmit={handleSubmit} type={type} />
      </DashboardEditorBody>
      <SidePaneFooter variant="small" isSticky>
        <Button disabled={submitting} type="submit" label="Save Changes" size="small" onClick={handleSubmit} />
      </SidePaneFooter>
    </>
  ), [ type ])

  useEffect(() => {
    if (!selectedBlock) {
      resetDashboardEditorStack()
    }
  }, [ selectedBlock, resetDashboardEditorStack ])

  if (!selectedBlock) return null

  return (
    <Form
      key={selectedBlock.id}
      mutators={{ ...arrayMutators }}
      keepDirtyOnReinitialize
      initialValues={{
        _identifier: selectedBlock.identifier || `${kebabCase(selectedBlock.type).toLowerCase()}-${selectedBlock.id.slice(0, 8)}`,
        visibility_criteria: selectedBlock.visibility_criteria || DEFAULT_VISIBILITY_CRITERIA,
        ...selectedBlock.properties,
        actions: selectedBlock.actions
      }}
      subscription={{ submitting: true }}
      onSubmit={handleSubmit}
      render={render}
    />
  )
}

function EditBlockView({ onClose }: ActiveViewProps) {
  const {
    selectBlock, selectedBlockTypeState, dashboardEditorState, viewState
  } = useDashboard()
  const { params: { block } = {} } = useRecoilValue<DashboardEditorView<Views.EDIT_BLOCK>>(
    dashboardEditorState
  )

  const { urn } = useDashboardEditorContextProvider()

  const view = useRecoilValue(viewState(urn))

  const viewName = [ view.app?.name, view.resource?.name, view.name ].filter(Boolean).join(' > ')

  const selectedBlockType = useRecoilValue(selectedBlockTypeState) || block?.type

  useEffect(() => () => selectBlock(null), [ selectBlock ])

  const memoizedView = useMemo(() => {
    if (selectedBlockType === 'FormBlock') {
      return <AddFormBlockView onClose={onClose} />
    }

    if (selectedBlockType === 'FormFieldBlock') {
      return <AddFormFieldBlockView onClose={onClose} />
    }

    const subtitle = selectedBlockType
      ? `Edit ${BLOCK_TYPE_TO_NAME_MAP[selectedBlockType]} Block`
      : 'Edit Block'

    return (
      <>
        <DashboardEditorHeader
          subtitle={subtitle}
          heading={viewName ? `View: ${viewName}` : 'Dashboard Editor'}
          onClose={onClose}
        />
        <>
          {selectedBlockType ? (
            <EditBlockFormWrapper type={selectedBlockType} />
          ) : (
            <DashboardEditorBody>
              <Flex direction="column" gap={24}>
                <Text>
                  No block selected
                </Text>
              </Flex>
            </DashboardEditorBody>
          )}
        </>
      </>
    )
  }, [ selectedBlockType, viewName, onClose ])

  return memoizedView
}

export default EditBlockView

export {
  DataSource,
  DataSourceOptions,
  getActionName,
  AddNewCard,
  ParametersDrawer,
  defaultOperationToolbarActions,
  defaultToolbarActions,
  defaultRowActions
}
