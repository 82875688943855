import React, { useRef } from 'react'
import type { FieldRenderProps } from 'react-final-form'

import * as mixins from 'styles/mixins'
import Divider from 'components/divider/Divider'
import FieldError from 'components/form/FieldError'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import InputHelpText from 'components/inputHelpText/InputHelpText'
import Text from 'components/typography/Text'
import { colorVars } from 'styles/theme'
import { styled } from 'styles/stitches'
import type { DividerProps } from 'components/divider/Divider'
import type { FlexProps } from 'components/layout/Flex'

const TOGGLE_INACTIVE_BACKGROUND_COLOR = 'dark100'
const TOGGLE_INACTIVE_COLOR = 'dark500'
const TOGGLE_ACTIVE_BACKGROUND_COLOR = 'accent400'
const TOGGLE_ACTIVE_COLOR = 'dark900'
const TOGGLE_HEIGHT = 21
const TOGGLE_WIDTH = 36
const TOGGLE_INACTIVE_POSITION = -1
const TOGGLE_ACTIVE_POSITION = TOGGLE_WIDTH - TOGGLE_HEIGHT - 1

const StyledContainer = styled(Flex, {
  paddingY: 8,
  position: 'relative',
  variants: {
    orientation: {
      horizontal: {
        width: '100%'
      },
      vertical: {
        height: '100%'
      }
    }
  }
})

const StyledToggleContainer = styled(Flex, {
  position: 'relative',
  cursor: 'pointer',
  width: 'inherit',
  height: 'inherit',

  variants: {
    disabled: {
      true: {
        cursor: 'auto'
      }
    }
  }
})

const TranslationContainer = styled(Flex, {
  [`${Icon}`]: {
    color: 'dark100'
  }
})

const StyledDivider = styled(Divider, {
  flex: '1 1 0'
})

const StyledToggle = styled(Flex, {
  ...mixins.transition('fastIn'),

  background: TOGGLE_INACTIVE_BACKGROUND_COLOR,
  borderColor: TOGGLE_INACTIVE_BACKGROUND_COLOR,
  borderRadius: TOGGLE_HEIGHT / 2,
  borderStyle: 'solid',
  borderWidth: 1,
  size: [ TOGGLE_WIDTH, TOGGLE_HEIGHT ],
  willChange: 'transform',

  '& [data-circle]': {
    ...mixins.shadow('xxxSmall', colorVars.dark600rgb, 0.2),
    ...mixins.transition('fastIn'),

    background: 'light100',
    borderRadius: '50%',
    transform: `translateX(${TOGGLE_INACTIVE_POSITION}px)`,
    size: [ TOGGLE_HEIGHT ]
  },

  [`& ~ ${Text}`]: {
    ...mixins.transition('fastIn'),

    color: TOGGLE_INACTIVE_COLOR,
    userSelect: 'none'
  },

  [`& ~ ${Icon}`]: {
    ...mixins.transition('fastIn'),

    color: TOGGLE_INACTIVE_BACKGROUND_COLOR
  },

  variants: {
    checked: {
      true: {
        ...mixins.transition('fastIn'),

        background: TOGGLE_ACTIVE_BACKGROUND_COLOR,
        color: 'dark900',

        '& [data-circle]': {
          ...mixins.transition('fastIn'),

          transform: `translateX(${TOGGLE_ACTIVE_POSITION}px)`
        },

        [`& ~ ${Text}`]: {
          ...mixins.transition('fastIn'),

          color: TOGGLE_ACTIVE_COLOR
        }
      }
    },
    disabled: {
      true: {
        opacity: 0.3,

        [`& ~ ${Text}`]: {
          opacity: 0.3
        }
      }
    }
  }
})

const StyledInput = styled('input', {
  hidden: true
})

const StyledLabel = styled(Text, {
  fontSize: 12,
  fontWeight: 'bold',
  wordBreak: 'break-word'
})

type labelPosition = 'left' | 'right' | 'top' | 'bottom'

type ToggleInputProps = FieldRenderProps<string, HTMLInputElement> & {
  disabled?: boolean,
  labelPosition?: labelPosition,
  helpText?: string,
  isTranslatable?: boolean,
  invert?: boolean
}

const flexDirectionMap: { [key in labelPosition]: FlexProps['direction'] } = {
  left: 'row-reverse',
  right: 'row',
  top: 'column-reverse',
  bottom: 'column'
}

const dividerOrientation: { [key in labelPosition]: DividerProps['orientation'] } = {
  left: 'horizontal',
  right: 'horizontal',
  top: 'vertical',
  bottom: 'vertical'
}

function ToggleInput({
  disabled = false,
  helpText,
  input,
  label,
  labelPosition = 'left',
  isTranslatable,
  checkRequired = false,
  meta,
  invert = false,
  ...other
}: ToggleInputProps) {
  const toggleRef = useRef<HTMLDivElement>(null)

  const error = FieldError.getError(meta)

  return (
    <StyledContainer orientation={label && dividerOrientation[labelPosition]} direction="column" gap={14}>
      <StyledToggleContainer
        disabled={disabled}
        alignItems="center"
        alignSelf="flex-start"
        justifyContent="space-between"
        as="label"
        direction={flexDirectionMap[labelPosition]}
        display="inline-flex"
        gap={10}
      >
        <StyledInput type="checkbox" {...input} {...other} onChange={input.onChange} disabled={disabled} />
        <StyledToggle
          alignItems="center"
          checked={invert ? !input.checked : !!input.checked}
          disabled={disabled}
          shrink={0}
        >
          <div data-circle ref={toggleRef} />
        </StyledToggle>
        {label && (
          <>
            <StyledDivider color="dark100" orientation={dividerOrientation[labelPosition]} />
            {isTranslatable && (
              <TranslationContainer alignItems="center" alignSelf="stretch" gap={12}>
                <Divider variant="ruler" orientation="vertical" color="light700" />
                <Icon data-icon size={12} name="translate" />
              </TranslationContainer>
            )}
            <StyledLabel data-label title={label}>
              {label}{checkRequired && <span> *</span>}
            </StyledLabel>
          </>
        )}
      </StyledToggleContainer>
      {!!error && <FieldError error={error} css={{ transform: 'translateY(-50%)' }} />}
      {helpText && <InputHelpText helpText={helpText} disabled={disabled} />}
    </StyledContainer>
  )
}

export default ToggleInput

export type { ToggleInputProps }
