import isEmpty from 'lodash/isEmpty'
import React, { useContext, useLayoutEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import * as mixins from 'styles/mixins'
import AddElementButton from '../AddElementButton'
import Block from 'components/blocks/Block'
import Breadcrumbs from 'components/blocks/TitleBlock/Breadcrumbs'
import DashboardContext from 'components/contexts/DashboardContext'
import Flex from 'components/layout/Flex'
import generateBreadcrumbs from 'lib/generateBreadcrumbs'
import getScrollParent from 'lib/getScrollParent'
import Icon from 'components/icons/Icon'
import InternalContext from 'components/contexts/InternalContext'
import Portal from 'components/portal/Portal'
import Text from 'components/typography/Text'
import useContainerScroll from 'hooks/useContainerScroll'
import useDocumentTitle from 'hooks/useDocumentTitle'
import { colorVars } from 'styles/theme'
import { css, styled } from 'styles/stitches'
import { TOPBAR_HEIGHT } from 'components/topbar/constants'
import { useMasonryContext } from 'components/layout/Masonry'
import type { BlockProps } from 'components/blocks/Block'
import type { BreadcrumbOwnProps } from 'components/blocks/TitleBlock/Breadcrumbs'
import type { Space } from 'styles/theme'

type TitleBlockProps = BlockProps & Pick<BreadcrumbOwnProps, 'hideLastCrumb' | 'appendStickyCrumb'> & {
  blockRef?: React.RefCallback<HTMLElement>,
  heading: string,
  primaryElements?: React.ReactNode,
  secondaryElements?: React.ReactNode
}

const STICKY_HEIGHT = 40
const TITLE_BLOCK_VERTICAL_PADDING = 20
const TITLE_BLOCK_SECTION_ELEMENTS_GAP = 16
const TITLE_BLOCK_PRIMARY_SECTION_ELEMENTS_GAP = 8
const TITLE_BLOCK_SECONDARY_SECTION_ELEMENTS_GAP = 32

const wrapperClass = (containerPadding: Space) => css({
  borderBottomColor: 'light700',
  borderBottomStyle: 'solid',
  borderBottomWidth: 1,
  paddingX: containerPadding,
  paddingY: TITLE_BLOCK_VERTICAL_PADDING
})

const titleClass = css({
  marginLeft: -2,
  paddingRight: 2,
  width: '-webkit-fill-available'
})

const TitleBlockSticky = styled('div', {
  ...mixins.shadow('xxSmall', colorVars.dark600rgb, 0.1),
  ...mixins.transition('fluid', 'all', 0.25),

  alignItems: 'center',
  backgroundColor: 'light100',
  borderBottomColor: 'light700',
  borderBottomStyle: 'solid',
  borderBottomWidth: 1,
  cursor: 'pointer',
  display: 'flex',
  height: STICKY_HEIGHT,
  left: 0,
  opacity: 1,
  position: 'fixed',
  top: TOPBAR_HEIGHT,
  width: '100%',
  zIndex: 'topbar',

  variants: {
    hidden: {
      true: {
        opacity: 0,
        pointerEvents: 'none'
      }
    }
  }
})

function TitleBlock({
  blockRef,
  primaryElements,
  secondaryElements,
  heading,
  hideLastCrumb = 1,
  appendStickyCrumb,
  id,

  ...other
}: TitleBlockProps) {
  const location = useLocation()
  const { currentSidebarWidth } = useContext(DashboardContext)!
  const { idToMenuElementMap = {} } = useContext(InternalContext) || {}
  const [ { scrollY }, updateScroll ] = useContainerScroll()

  const [ containerEl, setContainerEl ] = useState<HTMLDivElement | null>(null)
  const scrollParent = useMemo(() => getScrollParent(containerEl), [ containerEl ])

  useLayoutEffect(() => {
    const handleScroll = (event: Event) => {
      updateScroll({
        scrollX: (event.target as HTMLElement).scrollLeft,
        scrollY: (event.target as HTMLElement).scrollTop
      })
    }

    const config = { passive: true, capture: false }
    scrollParent.addEventListener('scroll', handleScroll, config)

    return () => scrollParent.removeEventListener('scroll', handleScroll, config)
  }, [ scrollParent, updateScroll ])

  const scrollToTop = () => {
    scrollParent.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const { containerPadding } = useMasonryContext()

  const activeMenuElement = useMemo(() => (
    Object
      .values(idToMenuElementMap)
      .find((element) => location.pathname === element.fullPath)
  ), [ idToMenuElementMap, location.pathname ])

  const crumbs = useMemo(() => {
    if (!activeMenuElement || isEmpty(idToMenuElementMap)) {
      return []
    }

    return generateBreadcrumbs(idToMenuElementMap, activeMenuElement)
  }, [ activeMenuElement, idToMenuElementMap ])

  useDocumentTitle(...crumbs.map((crumb) => crumb.renderedName as string))

  const hasCrumbs = crumbs.length > 0

  const isOutOfView = scrollY + STICKY_HEIGHT >= TOPBAR_HEIGHT + TITLE_BLOCK_VERTICAL_PADDING

  const stickyElement = (
    <TitleBlockSticky
      hidden={!isOutOfView}
      onClick={scrollToTop}
      onKeyPress={scrollToTop}
      role="button"
      style={{
        paddingLeft: currentSidebarWidth + containerPadding
      }}
      tabIndex={-1}
    >
      {hasCrumbs ? (
        <Breadcrumbs
          crumbs={crumbs}
          separator={<Icon name="arrow-right" size={10} />}
          isSticky
          appendStickyCrumb={appendStickyCrumb}
        />
      ) : (
        <Text fontSize={14} truncate>{heading}</Text>
      )}
    </TitleBlockSticky>
  )

  return (
    <Block name="Title" fullWidth masonryItemRef={blockRef} id={id} {...other}>
      <Flex direction="column" className={wrapperClass(containerPadding)} gap={4} grow={1} ref={setContainerEl}>
        <Portal>
          {stickyElement}
        </Portal>

        {crumbs.length > 1 && (
          <Flex alignItems="center" grow={1}>
            <Breadcrumbs
              crumbs={crumbs}
              hideLastCrumb={hideLastCrumb}
            />
          </Flex>
        )}

        <Flex alignItems="center" gap={TITLE_BLOCK_SECTION_ELEMENTS_GAP} justifyContent="space-between">
          <Flex alignItems="center" gap={TITLE_BLOCK_PRIMARY_SECTION_ELEMENTS_GAP} css={{ overflow: 'hidden' }}>
            <Text fontWeight="bold" fontSize={36} letterSpacing="compact" className={titleClass} truncate>
              {heading}
            </Text>

            {primaryElements}
            <AddElementButton id={id} variant="primary" />
          </Flex>
          <Flex grow={1} />
          <Flex alignItems="stretch" gap={TITLE_BLOCK_SECONDARY_SECTION_ELEMENTS_GAP}>
            <AddElementButton id={id} variant="secondary" />
            {secondaryElements}
          </Flex>
        </Flex>
      </Flex>
    </Block>
  )
}

export type { TitleBlockProps }

export default TitleBlock
