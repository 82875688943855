import arrayMutators from 'final-form-arrays'
import kebabCase from 'lodash/kebabCase'
import React, { useMemo } from 'react'
import { Field, Form, useForm } from 'react-final-form'
import type { Decorator } from 'final-form'

import App from 'models/App'
import Button from 'components/buttons/Button'
import ColorInput from 'components/inputs/ColorInput'
import Flex from 'components/layout/Flex'
import generatePosition, { getMaxPosition } from 'lib/generatePosition'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import useSubmitHandler from 'hooks/useSubmitHandler'
import WhenFieldChanges from 'components/form/WhenFieldChanges'
import { CreateIssueTypeInput, IssueStatusInputInput, IssueStatusKind, IssueTypesListDocument, IssueTypesListQueryVariables, UpdateIssueTypeInput, useCreateIssueTypeMutation, useUpdateIssueTypeMutation } from 'generated/schema'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { RepeatedField } from 'components/contentEditors/generic/fields'
import type { ViewProps } from 'components/views'

const STATUS_KINDS: { kind: IssueStatusKind, label: string, startPosition: number }[] = [
  { kind: 'BACKLOG', label: 'Backlog', startPosition: 0 },
  { kind: 'NOT_STARTED', label: 'Not Started', startPosition: 1000 },
  { kind: 'STARTED', label: 'Started', startPosition: 2000 },
  { kind: 'COMPLETED', label: 'Completed', startPosition: 3000 },
  { kind: 'CANCELED', label: 'Canceled', startPosition: 4000 }
]
const DEFAULT_STATUSES: IssueStatusInputInput[] = [
  { kind: 'BACKLOG', name: 'Backlog', identifier: 'backlog', color: '#bec2c8', position: 0 },
  { kind: 'NOT_STARTED', name: 'To Do', identifier: 'to-do', color: '#e2e2e2', position: 1000 },
  { kind: 'STARTED', name: 'In Progress', identifier: 'in-progress', color: '#f2c94c', position: 2000 },
  { kind: 'STARTED', name: 'In Review', identifier: 'in-review', color: '#0f783c', position: 2001 },
  { kind: 'COMPLETED', name: 'Done', identifier: 'done', color: '#5e6ad2', position: 3000 },
  { kind: 'CANCELED', name: 'Canceled', identifier: 'canceled', color: '#95a2b3', position: 4000 }
]

type InputValues = Partial<CreateIssueTypeInput> | UpdateIssueTypeInput

type StatusGroups = Record<IssueStatusKind, IssueStatusInputInput[]>

type FormValues = InputValues & {
  statusGroups: StatusGroups
}

type ViewParamsType = {
  initialValues: InputValues,
  queryVariables: IssueTypesListQueryVariables
}

const setIdentifier = createSetIdentifier(
  'name', 'identifier'
) as Decorator<FormValues>

function CreateIssueTypeView({
  closeView,
  onRequestClose,
  params,
  viewStyleComponent: View,
  ...other
}: ViewProps<ViewParamsType>) {
  const { initialValues, queryVariables, ...rest } = params

  const isUpdating = 'id' in initialValues

  const title = `${isUpdating ? 'Edit' : 'New'} Issue Type`

  const statusGroups = useMemo(() => {
    if (!isUpdating) {
      return groupStatusesByKind(DEFAULT_STATUSES)
    }
    return groupStatusesByKind(initialValues.statuses || [])
  }, [ isUpdating, initialValues.statuses ])

  const formInitialValues = useMemo(() => ({
    ...initialValues,
    statusGroups
  }) as FormValues, [ initialValues, statusGroups ])

  const [ createIssueType ] = useCreateIssueTypeMutation({
    onCompleted: onRequestClose,
    refetchQueries: [
      { query: IssueTypesListDocument, variables: queryVariables }
    ]
  })

  const handleCreateIssueType = useSubmitHandler(createIssueType, {
    update: {
      strategy: 'APPEND',
      query: IssueTypesListDocument,
      dataKey: 'issueTypesList',
      mutation: 'createIssueType',
      queryVariables
    },
    successAlert: {
      title: 'Success',
      message: 'Issue Type created successfully.'
    }
  })

  const [ updateIssueType ] = useUpdateIssueTypeMutation({
    onCompleted: onRequestClose,
    refetchQueries: [
      { query: IssueTypesListDocument, variables: queryVariables }
    ]
  })

  const handleUpdateIssueType = useSubmitHandler(updateIssueType, {
    successAlert: {
      title: 'Success',
      message: 'Issue Type updated successfully.'
    }
  })

  const handleSubmit = (values: FormValues) => {
    const statuses = flattenStatusGroups(values.statusGroups)
    if (isUpdating) {
      const updateValues = {
        id: initialValues.id,
        name: values.name,
        identifier: values.identifier,
        statuses
      }
      return handleUpdateIssueType(updateValues as UpdateIssueTypeInput)
    }
    const createValues = {
      spaceId: initialValues.spaceId,
      name: values.name,
      identifier: values.identifier,
      fields: [],
      statuses
    }
    return handleCreateIssueType(createValues as CreateIssueTypeInput)
  }

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body, Footer }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          <Form
            decorators={[ setIdentifier ]}
            initialValues={formInitialValues}
            keepDirtyOnReinitialize
            mutators={{ ...arrayMutators }}
            onSubmit={handleSubmit}
            validate={(values) => App.validate(values, [ 'name', 'identifier' ])}
            subscription={{ submitting: true, pristine: true }}
            render={({ handleSubmit, submitting, pristine }) => (
              <>
                <Body>
                  <Flex direction="column" as="form" onSubmit={handleSubmit}>
                    <Flex direction="column" gap={18}>
                      <Field
                        autoFocus
                        checkRequired
                        name="name"
                        label="Name"
                        component={TextInput}
                        size="small"
                      />
                      {/* TODO: Add custom fields
                      <ParameterFieldsList name="fields" title="Custom Fields" />
                      */}
                      <Flex direction="column" gap={16}>
                        <Text fontWeight="bold" fontSize={18}>Issue Statuses</Text>
                        {STATUS_KINDS.map(({ kind, label }) => (
                          <StatusGroup
                            key={kind}
                            kind={kind}
                            label={label}
                            name="statusGroups"
                          />
                        ))}
                      </Flex>
                    </Flex>
                  </Flex>
                </Body>
                <Footer>
                  <Flex alignItems="center" grow={1} justifyContent="flex-end">
                    <Button disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />
                  </Flex>
                </Footer>
              </>
            )}
            {...rest}
          />
        </>
      )}
    </View>
  )
}

type StatusGroupProps = {
  kind: IssueStatusKind,
  label: string,
  name: string
}

function StatusGroup({ kind, label, name }: StatusGroupProps) {
  const form = useForm()

  return (
    <Flex direction="column" gap={8}>
      <RepeatedField
        name={`${name}.${kind}`}
        field={{ name: label }}
        hideReorder
        onAdd={() => {
          const currentStatuses = form.getFieldState(`${name}.${kind}`)?.value || []
          const { startPosition } = STATUS_KINDS.find((config) => config.kind === kind)!

          const position = currentStatuses.length === 0
            ? startPosition + 1
            : generatePosition(getMaxPosition(currentStatuses), startPosition + 1000)

          return {
            kind,
            name: '',
            identifier: '',
            color: '#000',
            position
          }
        }}
        renderField={(_, { fieldName }) => (
          <Flex gap={8} alignItems="center">
            <Field
              component={ColorInput}
              name={`${fieldName}.color`}
              size="small"
            />
            <Field
              component={TextInput}
              name={`${fieldName}.name`}
              placeholder="Enter status name"
              size="small"
            />
            <Field
              component="input"
              name={`${fieldName}.identifier`}
              style={{ width: 0 }}
              type="hidden"
            />
            <WhenFieldChanges
              field={`${fieldName}.name`}
              set={`${fieldName}.identifier`}
              to={(value: string) => kebabCase(value?.toLowerCase())}
            />
          </Flex>
        )}
      />
    </Flex>
  )
}

function groupStatusesByKind(statuses: readonly IssueStatusInputInput[]) {
  return statuses.reduce((acc, status) => {
    const { kind } = status
    acc[kind] ||= []
    acc[kind].push(status)
    return acc
  }, {} as StatusGroups)
}

function flattenStatusGroups(groups: StatusGroups) {
  return Object.values(groups)
    .flatMap((statuses) => statuses || [])
}

CreateIssueTypeView.defaultStyle = 'PANEL' as const

export default CreateIssueTypeView
