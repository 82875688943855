import get from 'lodash/get'
import React, { Suspense, useMemo } from 'react'
import { useField, useForm, useFormState } from 'react-final-form'

import AttributeModel from 'models/Attribute'
import componentLoader from 'lib/componentLoader'
import DropdownField from 'components/contentEditors/generic/fields/DropdownField'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import Loader from 'components/loaders/Loader'
import pascalCase from 'lib/pascalCase'
import SelectInput from 'components/inputs/SelectInput'
import Text from 'components/typography/Text'
import useGetAllowedFieldTypes from 'hooks/useGetAllowedFieldTypes'
import { FieldIdentifier } from 'models/Field'
import { FieldType, Parameter, Resource, ResourcesListDocument, ResourcesListQuery, ResourcesListQueryVariables, useOperationQuery, useParametersListQuery, useResourceQuery } from 'generated/schema'
import { DataSource, DataSourceOptions, ParametersDrawer } from './EditBlockView'
import ConditionalField from 'components/form/ConditionalField'
import SearchSelectField from 'components/contentEditors/generic/fields/SearchSelectField'
import OperationField, { getOptionIcon } from './OperationField'
import CodeEditorInput from 'components/inputs/CodeEditorInput'
import ToggleInput from 'components/inputs/ToggleInput'
import WhenFieldChanges from 'components/form/WhenFieldChanges'

const getFieldViewName = (fieldType: string) => {
  if (!fieldType) return ''

  const identifier = fieldType.replace('input', 'field')

  // text-field -> TextFieldView
  return `${pascalCase(identifier)}View`
}

const DataSourceFields = () => {
  const resourceField = useField('field_type_settings.data_source_settings.resource')
  const operationField = useField('field_type_settings.data_source_settings.operation')
  const dataSourceField = useField('field_type_settings.data_source')

  const { data } = useResourceQuery({
    variables: { id: resourceField.input.value },
    skip: !resourceField.input.value
  })

  const {
    data: { parametersList = [] } = {},
    loading: parametersListLoading
  } = useParametersListQuery({
    variables: {
      filter: {
        operationId: {
          eq: operationField.input.value
        }
      }
    },
    skip: !operationField.input.value
  })

  const { data: operationData } = useOperationQuery({
    variables: {
      id: operationField.input.value
    },
    skip: !operationField.input.value
  })

  const showDrawer = resourceField.input.value || operationField.input.value

  return (
    <>
      <DropdownField
        name="field_type_settings.data_source"
        label="Data Source"
        size="small"
        isClearable
        // defaultValue={DataSource.RESOURCE}
        options={DataSourceOptions}
        onChange={(change: { value: any } | null) => {
          dataSourceField.input.onChange(change?.value)
          operationField.input.onChange(null)
          resourceField.input.onChange(null)
        }}
      />
      <ConditionalField when="field_type_settings.data_source" is={DataSource.RESOURCE}>
        <SearchSelectField<ResourcesListQuery, ResourcesListQueryVariables>
          isSearchable
          isClearable
          preload
          name="field_type_settings.data_source_settings.resource"
          label="Linked Resource"
          prependIcon="search"
          placeholder="Start typing to search"
          size="small"
          variant="light"
          labelKey="name"
          iconKey="app.identifier"
          valueKey="id"
          options={data?.resource ? [ data.resource ] : []}
          getOptionLabel={(option: Resource) => [ option.app?.name, option.name ].filter(Boolean).join(' > ')}
          getOptionIcon={getOptionIcon}
          query={ResourcesListDocument}
          dataKey="resourcesList"
          keys={[ 'name', 'identifier' ]}
          input={{
            value: resourceField.input.value,
            onChange: (value: Resource['id']) => {
              if (resourceField.input.value !== value) {
                resourceField.input.onChange(value)
              }
            }
          }}
        />
        <FormField
          name="field_type_settings.data_source_settings.filter"
          label="Filter"
          component={CodeEditorInput}
          language="json"
          size="small"
          type="text"
        />
        <FormField
          name="field_type_settings.data_source_settings.is_async"
          label="Async?"
          size="small"
          type="checkbox"
          component={ToggleInput}
        />
      </ConditionalField>
      <ConditionalField when="field_type_settings.data_source" is={DataSource.OPERATION}>
        <OperationField
          operation={operationData?.operation}
          name="field_type_settings.data_source_settings.operation"
          label="Linked Operation"
          input={{
            value: operationField.input.value,
            onChange: (value: Resource['id']) => {
              if (operationField.input.value !== value) {
                operationField.input.onChange(value)
              }
            }
          }}
        />
      </ConditionalField>
      <ConditionalField when="field_type_settings.data_source" is={DataSource.OPERATION}>
        {showDrawer && (
        <ParametersDrawer
          name="field_type_settings.data_source_settings.parameters"
          parameters={parametersList as Parameter[]}
          loading={parametersListLoading}
        />
        )}
      </ConditionalField>
      <WhenFieldChanges field="field_type_settings.data_source" includes={[ null, undefined ]} set="field_type_settings.data_source_settings" to={null} />
    </>
  )
}

const FieldTypeConfig = () => {
  const { values } = useFormState()
  const { change } = useForm()

  const fieldType = get(values, 'field_type')
  const fieldViewName = getFieldViewName(fieldType)
  const fieldViewFileName = pascalCase(fieldViewName)

  const getAllowedFieldTypes = useGetAllowedFieldTypes()

  const {
    fieldTypes: allowedFieldTypes, loading: loadingFieldTypes
  } = getAllowedFieldTypes()

  const defaultFieldType = FieldIdentifier.TEXT

  const FieldTypeSettings = useMemo(() => React.lazy(
    () => componentLoader(`fieldViews/${fieldViewFileName}`, { suppressAlert: true })
      .catch(() => componentLoader('fieldViews/GenericFieldView'))
      .then((module) => ({ default: module.default?.Settings }))
  ), [ fieldViewFileName ])

  return (
    <Flex direction="column" gap={16}>
      <Flex direction="column" gap={6}>
        <Text fontSize={14} fontWeight="bold" textTransform="uppercase">Field Type</Text>
        <Text color="dark600" fontSize={12}>Choose how this field gets rendered in <b>Forms</b>.</Text>
      </Flex>
      <FormField
        alwaysDirty
        component={SelectInput}
        name="field_type"
        size="small"
        options={allowedFieldTypes}
        loading={loadingFieldTypes}
        labelKey="name"
        valueKey="identifier"
        defaultValue={defaultFieldType}
        onChange={(option: FieldType) => {
          change('field_type', option.identifier)
          change('field_type_settings', {})
        }}
      />
      {fieldType === FieldIdentifier.DROPDOWN && (
        <>
          <DataSourceFields />
          <FormField
            name="field_type_settings.label_key"
            label="Label Key"
            size="small"
          />
          <FormField
            name="field_type_settings.value_key"
            label="Value Key"
            size="small"
          />
          <FormField
            name="field_type_settings.meta_key"
            label="Meta Key"
            size="small"
          />
        </>
      )}
      {AttributeModel.hasFieldTypeSettings(fieldType) && (
        <Suspense fallback={<Loader loading />}>
          <FormField component="input" type="hidden" name="field_type_settings" alwaysDirty />
          <FieldTypeSettings
            fieldSettingsPrefix="field_type_settings."
          />
        </Suspense>
      )}
      <FormField name="field_type_settings.is_readonly" label="Read Only?" component={ToggleInput} type="checkbox" />
      <ConditionalField when="field_type_settings.is_readonly" is>
        <FormField name="field_type_settings.value.expression" label="Value" size="small" initialValue="" />
        <FormField
          name="field_type_settings.value.kind"
          component="input"
          type="hidden"
          size="small"
          value="LIQUID"
          defaultValue="LIQUID"
          initialValue="LIQUID"
          style={{ display: 'none' }}
        />
      </ConditionalField>
    </Flex>
  )
}

export default FieldTypeConfig
