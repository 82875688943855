/* eslint-disable camelcase */
enum NUMBER_RANGE {
  BETWEEN = 'BETWEEN',
  NOT_BETWEEN = 'NOT_BETWEEN',
  EQUAL = 'EQUAL_TO',
  NOT_EQUAL = 'NOT_EQUAL_TO',
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL_TO',
  LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL_TO'
}

enum NUMBER_MODE {
  DECIMAL = 'DECIMAL',
  INTEGER = 'INTEGER'
}

enum DATE_FORMATS {
  MM_YYYY = 'MM/YYYY',
  MM_DD_YYYY = 'MM/DD/YYYY',
  DD_MM_YYYY = 'DD/MM/YYYY',
  MM_DD_YYYY_hh_mm_A = 'MM/DD/YYYY hh:mm A',
  MM_DD_YYYY_HH_mm = 'MM/DD/YYYY HH:mm',
  DD_MM_YYYY_hh_mm_A = 'DD/MM/YYYY hh:mm A',
  DD_MM_YYYY_HH_mm = 'DD/MM/YYYY HH:mm',
  YYYY_MM_DD = 'YYYY/MM/DD',
  YYYY_MM_DD_hh_mm_A = 'YYYY/MM/DD hh:mm A',
  YYYY_MM_DD_HH_mm = 'YYYY/MM/DD HH:mm',
}

enum SAVE_FORMATS {
  DATE = 'DATE',
  TIMESTAMP = 'TIMESTAMP_WITHOUT_TZ',
  TIME = 'TIME'
}

enum MEDIA_SIZE_OPTIONS {
  BYTES = 'bytes',
  KILOBYTES = 'kilobytes',
  MEGABYTES = 'megabytes'
}

enum MEDIA_TYPE_OPTIONS {
  AUDIO = 'audio',
  IMAGE = 'image',
  VIDEO = 'video',
  DOCUMENT = 'document',
  SPREADSHEET = 'spreadsheet',
  PRESENTATION = 'presentation',
  CUSTOM = 'custom'
}

const MEDIA_TYPE_TO_MIME_MAPPING = {
  [MEDIA_TYPE_OPTIONS.IMAGE]: 'image/*',
  [MEDIA_TYPE_OPTIONS.AUDIO]: 'audio/*',
  [MEDIA_TYPE_OPTIONS.VIDEO]: 'video/*',
  [MEDIA_TYPE_OPTIONS.DOCUMENT]: 'text/plain, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.oasis.opendocument.text',
  [MEDIA_TYPE_OPTIONS.PRESENTATION]: 'application/vnd.oasis.opendocument.presentation, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/x-iwork-keynote-sffkey',
  [MEDIA_TYPE_OPTIONS.SPREADSHEET]: 'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv, text/tsv, application/vnd.apple.numbers, application/x-iwork-numbers-sffnumbers, application/vnd.oasis.opendocument.spreadsheet',
  [MEDIA_TYPE_OPTIONS.CUSTOM]: null
}

type textFieldSettings = {
  checkLength: boolean,
  lengthEqualTo: number,
  lengthMaximum: number,
  lengthMinimum: number,
  is_multiline: boolean,
  min_height?: number,
  max_height?: number,
  is_autogrow?: boolean,
  existingValues?: string[],
  disableEmojis: boolean
}

type colorFieldSettings = {
  presetColors: string[],
  showAlpha: boolean
}

type dateFieldSettings = {
  checkValue: boolean,
  valueComparator: NUMBER_RANGE,
  valueMaximum: string,
  valueMinimum: string,
  format: DATE_FORMATS,
  saveFormat: SAVE_FORMATS
}

type durationFieldSettings = {
  checkValue: boolean,
  valueComparator: NUMBER_RANGE,
  valueMaximum: string,
  valueMinimum: string
}

type NumberFieldSettings = {
  mode: NUMBER_MODE,
  saveFormat: 'string' | 'number',
  checkValue: boolean,
  valueComparator: NUMBER_RANGE,
  decimalPlaces: number,
  valueEqualTo: number,
  valueNotEqualTo: number,
  valueLessThan: number,
  valueLessThanOrEqualTo: number,
  valueGreaterThan: number,
  valueGreaterThanOrEqualTo: number,
  step: number,
  min: number,
  max: number
}

type mediaFieldSettings = {
  fileSizeValueComparator: NUMBER_RANGE,
  fileCountValueComparator: NUMBER_RANGE,
  fileTypeCategory: MEDIA_TYPE_OPTIONS,
  checkSize: boolean,
  checkType: boolean,
  fileSizeUnit: string,
  fileSizeMinimum: number,
  fileSizeMaximum: number,
  includeExtensions: string,
  excludeExtensions: string,
  includeMime: string,
  excludeMime: string,
  dimensionWidthMinimum: number,
  dimensionWidthMaximum: number,
  dimensionHeightMinimum: number,
  dimensionHeightMaximum: number,
  fileDurationMinimum: number,
  fileDurationMaximum: number,
  fileCountEqualTo: number,
  fileSizeEqualTo: number
}

enum REFERENCE_FIELD_LIMIT {
  BETWEEN = 'between',
  EQUAL = 'equal',
  MINIMUM = 'minimum',
  MAXIMUM = 'maximum'
}

type referenceFieldSettings = {
  limit: REFERENCE_FIELD_LIMIT
}

type ratingFieldSettings = {}

type csvFieldSettings = {
  headers?: string[]
}

type dropDownFieldSettings = {
  options: { label: string, value?: string, key?: string }[],
  optionsCreatable: boolean,
  optionsKeyable: boolean
}

type settings = {
  color: colorFieldSettings,
  date: dateFieldSettings,
  media: mediaFieldSettings,
  number: NumberFieldSettings,
  text: textFieldSettings,
  reference: referenceFieldSettings,
  rating: ratingFieldSettings,
  dropDown: dropDownFieldSettings,
  duration: durationFieldSettings,
  csv: csvFieldSettings
}

type commonSettings = {
  placeholder?: string,
  helpText?: string,
  help_text?: string,
  checkRequired: boolean,
  checkUniqueness: boolean,
  repeatedMinimum: number,
  repeatedMaximum: number,
  repeatedAllowDuplicates: boolean,
  isArray?: boolean,
  isTranslatable?: boolean,
  appendIcon?: string,
  appendText?: string,
  prependIcon?: string,
  prependText?: string,

  // augmented settings
  hasFallbackLocale?: boolean
}

type fieldProps<T> = {
  name: string,
  label?: string,
  shouldValidate?: boolean,
  isNullable?: boolean,
  isTranslatable?: boolean,
  isArray?: boolean,
  helpText?: string,
  settings?: Partial<commonSettings & (T extends keyof settings ? settings[T] : {})>
}

export type { fieldProps, settings }
export {
  NUMBER_RANGE,
  NUMBER_MODE,
  MEDIA_SIZE_OPTIONS,
  MEDIA_TYPE_OPTIONS,
  MEDIA_TYPE_TO_MIME_MAPPING,
  REFERENCE_FIELD_LIMIT,
  DATE_FORMATS,
  SAVE_FORMATS
}
