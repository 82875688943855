import get from 'lodash/get'
import pick from 'lodash/pick'
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Form, Field } from 'react-final-form'
import { useParams } from 'react-router-dom'

import AutoSave from 'components/form/AutoSave'
import Block from 'components/blocks/Block'
import Button from 'components/buttons/Button'
import CreateIssueTypeView from 'components/views/CreateIssueTypeView'
import DataListBlock from 'components/blocks/DataListBlock'
import DrawerBlock from 'components/blocks/DrawerBlock'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import MediaCard from 'components/mediaCard/MediaCard'
import PageLoader from 'components/loaders/PageLoader'
import SectionLoader from 'components/loaders/SectionLoader'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import TitleBlock from 'components/blocks/TitleBlock'
import ToggleInput from 'components/inputs/ToggleInput'
import useQueryParams from 'hooks/useQueryParams'
import useSubmitHandler from 'hooks/useSubmitHandler'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import type { App, IssueLabel, IssueStatus, IssueType, Space, SpaceConfigurationFragmentFragment as SpaceConfigurationFragment } from 'generated/schema'
import { useAppQuery, useUpdateSpaceConfigurationMutation, useIssueLabelsListQuery, useIssueTypesListQuery, useSpaceConfigurationsListQuery, useSpacesListQuery, useDestroyIssueLabelMutation, IssueLabelsListDocument, IssueTypesListDocument, useDestroyIssueTypeMutation, useCreateSpaceConfigurationMutation, SpaceConfigurationsListDocument } from 'generated/schema'
import { useViewDispatch } from 'hooks/useViewContext'
import CreateIssueLabelView from 'components/views/CreateIssueLabelView'

const DEFAULT_CUSTOM_SPACE_ICON = 'app-bridge'
const DEFAULT_SYSTEM_SPACE_ICON = 'globe'
const DEFAULT_SYSTEM_SPACE_NAME = 'Workspace'
const SETTINGS_FORM_DEBOUNCE = 300 // ms
const SPACES_LIST_LIMIT = 100
const SPACE_PREFIX_MIN_LENGTH = 1
const SPACE_PREFIX_MAX_LENGTH = 7

function AppSettingsPage() {
  const queryParams = useQueryParams()
  const { appId } = useParams<{ appId: string }>()
  const id = queryParams.get('id') || appId

  const {
    data: { app } = {},
    error,
    loading
  } = useAppQuery({
    variables: {
      id
    }
  })

  const {
    data: { spacesList } = {},
    error: spacesListError,
    loading: spacesListLoading
  } = useSpacesListQuery({
    variables: {
      limit: SPACES_LIST_LIMIT,
      order: [ { name: 'asc' } ]
    }
  })

  const {
    data: { spaceConfigurationsList } = {},
    error: spaceConfigurationsListError,
    loading: spaceConfigurationsListLoading
  } = useSpaceConfigurationsListQuery({
    variables: {
      filter: {
        appId: { eq: id }
      }
    }
  })

  const getSpaceConfiguration = useCallback(
    (spaceId: string | null) => spaceConfigurationsList
      ?.find((config) => config.spaceId === spaceId),
    [ spaceConfigurationsList ]
  )

  const isAppEnabled = (spaceId: string | null) => getSpaceConfiguration(spaceId)
    ?.settings?.is_disabled === false

  const [ selectedSpace, setSelectedSpace ] = useState<Space | null>(null)

  const selectedSpaceConfiguration = useMemo(
    () => getSpaceConfiguration(selectedSpace?.id || null),
    [ getSpaceConfiguration, selectedSpace ]
  )

  const appEnabledIcon = <Icon color="positive400" name="accept" size={16} />

  const renderSpaceCard = (space: Space) => {
    const { id, icon, name } = space

    return (
      <MediaCard
        key={id}
        media={icon || DEFAULT_CUSTOM_SPACE_ICON}
        title={name}
        titlePosition="top"
        width="full"
        active={selectedSpace?.id === id}
        onClick={() => setSelectedSpace(space)}
        secondaryMedia={isAppEnabled(id) && appEnabledIcon}
      />
    )
  }

  return (
    <PageLoader
      data={app}
      error={error}
      loading={loading}
    >
      <TitleBlock heading="Settings" />
      <Block direction="column" gap={36} width={{ md: '25%' }}>
        <SectionLoader
          empty={{
            variant: 'neutral',
            element: (
              <Flex alignItems="center" direction="column" gap={16}>
                <Flex alignItems="center" direction="column" gap={8}>
                  <Text fontWeight="bold">There are no spaces.</Text>
                  <Text fontSize={14}>Nothing to show here.</Text>
                </Flex>
              </Flex>
            )
          }}
          data={spaceConfigurationsList}
          error={spacesListError || spaceConfigurationsListError}
          loading={spacesListLoading || spaceConfigurationsListLoading}
        >
          <Flex direction="column" gap={24}>
            <Text fontWeight="bold">System Spaces</Text>
            <Flex direction="column" gap={16}>
              <MediaCard
                media={DEFAULT_SYSTEM_SPACE_ICON}
                title={DEFAULT_SYSTEM_SPACE_NAME}
                titlePosition="top"
                width="full"
                active={selectedSpace === null}
                onClick={() => setSelectedSpace(null)}
                secondaryMedia={isAppEnabled(null) && appEnabledIcon}
              />
            </Flex>
          </Flex>
          <Flex direction="column" gap={24}>
            <Text fontWeight="bold">Custom Spaces</Text>
            <Flex direction="column" gap={16}>
              {spacesList?.map(renderSpaceCard)}
            </Flex>
            <Text fontSize={14} textWrap="pretty">You can create spaces from the Workspace Admin area.</Text>
          </Flex>
        </SectionLoader>
      </Block>
      <Block direction="column" gap={36} width={{ md: '75%' }}>
        <SpaceConfigurationSettings
          key={selectedSpace?.id}
          app={app}
          space={selectedSpace}
          spaceConfiguration={selectedSpaceConfiguration}
        />
      </Block>
    </PageLoader>
  )
}

type SpaceConfigurationSettingsProps = {
  app?: App,
  space: Space | null,
  spaceConfiguration?: SpaceConfigurationFragment
}

type SpaceConfigurationSettingsFormValues = {
  isEnabled: boolean,
  prefix: string
}

function SpaceConfigurationSettings({
  app,
  space,
  spaceConfiguration
}: SpaceConfigurationSettingsProps) {
  const { currentWorkspace } = useContext(WorkspaceContext) || {}

  const isFirstMount = useRef(true)
  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false
    }
  }, [ spaceConfiguration ])

  const initialValuesRef = useRef<SpaceConfigurationSettingsFormValues>({
    isEnabled: spaceConfiguration?.settings?.is_disabled === false,
    prefix: spaceConfiguration?.settings?.prefix
      || getDefaultPrefix(space?.name)
      || getDefaultPrefix(currentWorkspace?.name)
  })

  const validate = (values: SpaceConfigurationSettingsFormValues) => {
    const errors: Partial<SpaceConfigurationSettingsFormValues> = {}
    if (values.prefix.length < SPACE_PREFIX_MIN_LENGTH
      || values.prefix.length > SPACE_PREFIX_MAX_LENGTH) {
      errors.prefix = `must be between ${SPACE_PREFIX_MIN_LENGTH} and ${SPACE_PREFIX_MAX_LENGTH} characters`
    }
    return errors
  }

  const isUpdating = spaceConfiguration?.id !== undefined
  const [ createSpaceConfiguration ] = useCreateSpaceConfigurationMutation()
  const [ updateSpaceConfiguration ] = useUpdateSpaceConfigurationMutation()

  const handleCreateSpaceConfiguration = useSubmitHandler(createSpaceConfiguration, {
    update: {
      strategy: 'APPEND',
      query: SpaceConfigurationsListDocument,
      dataKey: 'spaceConfigurationsList',
      mutation: 'createSpaceConfiguration',
      queryVariables: {
        filter: {
          appId: { eq: app?.id }
        }
      }
    }
  })
  const handleUpdateSpaceConfiguration = useSubmitHandler(updateSpaceConfiguration)

  const handleFormSubmit = (values: SpaceConfigurationSettingsFormValues) => {
    if (isFirstMount.current) {
      isFirstMount.current = false

      if (!isUpdating) {
        return handleCreateSpaceConfiguration({
          appId: app?.id,
          spaceId: space?.id,
          settings: {
            is_disabled: !values.isEnabled,
            prefix: values.prefix
          }
        })
      }

      return undefined
    }

    if (isUpdating) {
      return handleUpdateSpaceConfiguration({
        id: spaceConfiguration?.id,
        settings: {
          ...spaceConfiguration?.settings,
          is_disabled: !values.isEnabled,
          prefix: values.prefix || spaceConfiguration?.settings?.prefix
        }
      })
    }

    return undefined
  }

  return (
    <Flex direction="column" gap={24}>
      <Flex gap={16} alignItems="center">
        <Icon color="dark900" size={24} name={space ? (space.icon || DEFAULT_CUSTOM_SPACE_ICON) : DEFAULT_SYSTEM_SPACE_ICON} />
        <Text key={space?.id} fontSize={24} fontWeight="bold">{space?.name || DEFAULT_SYSTEM_SPACE_NAME}</Text>
      </Flex>
      <Form
        initialValues={initialValuesRef.current}
        onSubmit={handleFormSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <>
            <AutoSave
              debounce={SETTINGS_FORM_DEBOUNCE}
              debounced={[ 'prefix' ]}
              save={handleSubmit}
              triggerOnMount
            />
            <Field
              component={ToggleInput}
              label={`IS ${app?.name.toLocaleUpperCase()} ENABLED FOR THIS SPACE?`}
              labelPosition="left"
              name="isEnabled"
              type="checkbox"
            />
            <Field
              component={TextInput}
              label="Prefix"
              name="prefix"
              parse={normalisePrefix}
              size="small"
            />
          </>
        )}
      />
      <Flex direction="column" gap={8}>
        <DrawerBlock as="div" icon="finalize" title="Workflow" summary="Issue Types, Statuses, Labels & Templates">
          {() => <WorkflowConfiguration space={space} />}
        </DrawerBlock>
      </Flex>
    </Flex>
  )
}

type WorkflowConfigurationProps = {
  space: Space | null
}

function WorkflowConfiguration({ space }: WorkflowConfigurationProps) {
  const queryVariables = {
    filter: {
      spaceId: space ? { eq: space.id } : 'null'
    }
  }

  const {
    data: { issueTypesList } = {},
    loading: issueTypesLoading,
    error: issueTypesError
  } = useIssueTypesListQuery({
    variables: queryVariables
  })

  const {
    data: { issueLabelsList } = {},
    loading: issueLabelsLoading,
    error: issueLabelsError
  } = useIssueLabelsListQuery({
    variables: queryVariables
  })

  const [ destroyIssueType ] = useDestroyIssueTypeMutation({
    refetchQueries: [
      { query: IssueTypesListDocument, variables: queryVariables }
    ]
  })

  const handleDestroyIssueType = useSubmitHandler(destroyIssueType, {
    optimisticResponse: {
      response: 'DESTROY',
      mutation: 'destroyIssueType',
      typename: 'IssueType'
    },
    update: {
      strategy: 'REMOVE',
      dataKey: 'issueTypesList',
      mutation: 'destroyIssueType',
      query: IssueTypesListDocument,
      queryVariables
    }
  })

  const [ destroyIssueLabel ] = useDestroyIssueLabelMutation({
    refetchQueries: [
      { query: IssueLabelsListDocument, variables: queryVariables }
    ]
  })

  const handleDestroyIssueLabel = useSubmitHandler(destroyIssueLabel, {
    optimisticResponse: {
      response: 'DESTROY',
      mutation: 'destroyIssueLabel',
      typename: 'IssueLabel'
    },
    update: {
      strategy: 'REMOVE',
      dataKey: 'issueLabelsList',
      mutation: 'destroyIssueLabel',
      query: IssueLabelsListDocument,
      queryVariables
    }
  })

  const { openView } = useViewDispatch()

  const handleCreateIssueType = () => openView({
    title: 'New Issue Type',
    component: CreateIssueTypeView,
    params: {
      initialValues: {
        spaceId: space?.id
      },
      queryVariables
    },
    style: CreateIssueTypeView.defaultStyle
  })

  const handleEditIssueType = (issueType: IssueType) => openView({
    title: 'Edit Issue Type',
    component: CreateIssueTypeView,
    params: {
      initialValues: {
        ...issueType,
        statuses: normalizeStatuses([ ...issueType.issueStatuses ])
          .map((status) => pick(status, [ 'kind', 'name', 'identifier', 'color', 'position' ]))
      },
      queryVariables
    },
    style: CreateIssueTypeView.defaultStyle
  })

  const handleCreateIssueLabel = () => openView({
    title: 'New Issue Label',
    component: CreateIssueLabelView,
    params: {
      initialValues: {
        spaceId: space?.id,
        position: issueLabelsList?.length
      },
      queryVariables
    },
    style: CreateIssueLabelView.defaultStyle
  })

  const handleEditIssueLabel = (issueLabel: IssueLabel) => openView({
    title: 'Edit Issue Label',
    component: CreateIssueLabelView,
    params: {
      initialValues: issueLabel,
      queryVariables
    },
    style: CreateIssueLabelView.defaultStyle
  })

  return (
    <Flex direction="column" gap={24}>
      <DataListBlock
        asFragment
        data={issueTypesList || []}
        error={issueTypesError}
        loading={issueTypesLoading}
        secondaryElements={(<Button icon="add-thin" onClick={handleCreateIssueType} size="small" />)}
        selectionMode="none"
        title="Issue Types & Statuses"
        contents={[
          { dataKey: 'name', slot: 'primary' },
          {
            dataKey: 'issueStatuses.length',
            slot: 'secondary',
            renderer: ({ dataKey, rowData }) => `${get(rowData, dataKey)} statuses`
          }
        ]}
        actions={[
          { icon: 'edit', title: 'Edit', onClick: handleEditIssueType },
          { icon: 'trash', title: 'Delete', onClick: (issueType) => handleDestroyIssueType({ id: issueType.id }) }
        ]}
        empty={{
          variant: 'neutral',
          element: (
            <Flex alignItems="center" direction="column" gap={16}>
              <Text fontWeight="bold">There are no Issue Types.</Text>
              <Button label="Create an Issue Type" size="small" mode="distinct" onClick={handleCreateIssueType} />
            </Flex>
          )
        }}
      />
      <DataListBlock
        asFragment
        data={issueLabelsList || []}
        error={issueLabelsError}
        loading={issueLabelsLoading}
        secondaryElements={(<Button icon="add-thin" onClick={handleCreateIssueLabel} size="small" />)}
        selectionMode="none"
        title="Issue Labels"
        contents={[
          { dataKey: 'name', slot: 'primary' },
          {
            dataKey: 'color',
            slot: 'icon',
            renderer: ({ dataKey, rowData }) => (
              <div
                style={{
                  backgroundColor: get(rowData, dataKey),
                  width: 16,
                  height: 16,
                  borderRadius: '100%'
                }}
              />
            )
          }
        ]}
        actions={[
          { icon: 'edit', title: 'Edit', onClick: handleEditIssueLabel },
          { icon: 'trash', title: 'Delete', onClick: (issueLabel) => handleDestroyIssueLabel({ id: issueLabel.id }) }
        ]}
        empty={{
          variant: 'neutral',
          element: (
            <Flex alignItems="center" direction="column" gap={16}>
              <Text fontWeight="bold">There are no Issue Labels.</Text>
              <Button label="Create an Issue Label" size="small" mode="distinct" onClick={handleCreateIssueLabel} />
            </Flex>
          )
        }}
      />
    </Flex>
  )
}

function getDefaultPrefix(name?: string): string {
  if (!name) return ''
  return normalisePrefix(name).slice(0, 3)
}

function normalisePrefix(value: string): string {
  return value
    .replace(/[^a-zA-Z0-9]/g, '')
    .toUpperCase()
}

function normalizeStatuses(statuses: IssueStatus[]) {
  return statuses.map((status) => ({
    ...status,
    color: status.color?.charAt(0) === '#' ? status.color : `#${status.color}`
  }))
}

export default AppSettingsPage
