import React, { useRef } from 'react'
import { useField } from 'react-final-form'
import { useRecoilValue } from 'recoil'

import DataList from 'components/dataList/DataList'
import Icon from 'components/icons/Icon'
import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import useDashboard, { DashboardEditorView } from 'hooks/useDashboard'
import useReorderFieldArray from 'hooks/useReorderFieldArray'
import { AddNewCard } from './EditBlockView'
import { BLOCK_TYPE_TO_NAME_MAP, Views } from './constants'
import { FieldArrayChildrenProps, useFieldArray } from 'components/form/FieldArray'
import type { BlockType } from 'components/blocks'

const BlocksList = () => {
  const {
    blockState,
    dashboardEditorState,
    openDashboardEditorView,
    selectBlock,
    selectedBlockState
  } = useDashboard()
  const { params: { block } = {} } = useRecoilValue<DashboardEditorView<any>>(
    dashboardEditorState
  )

  // eslint-disable-next-line no-underscore-dangle
  const _selectedBlock = useRecoilValue(selectedBlockState)! || block
  const selectedBlock = useRecoilValue(blockState(_selectedBlock.id))
  const fieldsRef = useRef<FieldArrayChildrenProps<any>>()
  useFieldArray({ name: 'children', fieldsRef, subscription: {} })
  const fields = useField('children')

  const onDragEnd = useReorderFieldArray(fieldsRef)

  return (
    <Flex gap={16} direction="column">
      <Flex direction="column" gap={4}>
        <Flex justifyContent="space-between" gap={16}>
          <Text
            color="dark700"
            fontSize={14}
            fontWeight="bold"
            textTransform="uppercase"
          >
            Components
          </Text>
        </Flex>
      </Flex>
      <DataList
        actions={[
          {
            icon: 'edit',
            title: 'Edit',
            onClick: (rowData: any) => {
              selectBlock(rowData.id)
              openDashboardEditorView({
                target: Views.EDIT_BLOCK,
                params: {
                  block: rowData!
                }
              })
            }
          },
          {
            icon: 'trash',
            title: 'Delete',
            onClick: (_, index) => {
              fieldsRef.current?.fields.remove(index!)
            }
          }
        ]}
        empty={{
          variant: 'simple',
          element: (
            <Flex alignItems="center" direction="column">
              <Text fontSize={14} color="dark500">No components added.</Text>
            </Flex>
          )
        }}
        contents={[
          {
            dataKey: 'identifier',
            slot: 'primary',
            renderer: ({ rowData }) => BLOCK_TYPE_TO_NAME_MAP[rowData.type as BlockType]
          },
          {
            dataKey: 'properties.name',
            slot: 'secondary',
            renderer: ({ rowData }) => (
              rowData.properties.name
              || rowData.properties.heading
              || rowData.properties.title
              || rowData.properties.identifier
              || rowData.identifier
            )
          }
        ]}
        data={fields.input.value || []}
        onRowDragEnd={onDragEnd}
        selectionMode="none"
      />
      <AddNewCard
        as={Flex}
        alignItems="center"
        direction="row"
        gap={16}
        onClick={() => {
          openDashboardEditorView({
            target: Views.ADD_BLOCK,
            params: {
              parentId: selectedBlock?.id
            }
          })
        }}
      >
        <Icon data-icon name="add-thin" size={12} />
        <Text fontSize={12}>Add new</Text>
      </AddNewCard>
    </Flex>
  )
}

export default BlocksList
