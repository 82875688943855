/* eslint-disable no-case-declarations */
import arrayMutators from 'final-form-arrays'
import camelCase from 'lodash/camelCase'
import get from 'lodash/get'
import mapValues from 'lodash/mapValues'
import merge from 'lodash/merge'
import omitBy from 'lodash/omitBy'
import React, { useContext, useMemo } from 'react'
import set from 'lodash/set'
import size from 'lodash/size'
import sum from 'lodash/sum'
import { Form, FormRenderProps, FormSpy } from 'react-final-form'
import { useRecoilValue } from 'recoil'
import type { FormApi } from 'final-form'

import * as mixins from 'styles/mixins'
import AddBlockButton from './AddBlockButton'
import Block from './Block'
import Button, { ButtonProps } from 'components/buttons/Button'
import Column from './Column'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import JSONParseOr from 'lib/JSONParseOr'
import Loader from 'components/loaders/Loader'
import ParameterFields from 'components/resource/ParameterFields'
import Portal from 'components/portal/Portal'
import Tab from 'components/tabs/Tab'
import Tabs, { useTabs } from 'components/tabs/Tabs'
import Text from 'components/typography/Text'
import useActiveLocales, { Locale } from 'hooks/useActiveLocales'
import useDashboard from 'hooks/useDashboard'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { GetMediaFieldStateContext } from 'components/contexts/MediaFieldContext'
import { useDashboardViewContext } from 'components/contexts/DashboardViewContext'
import {
  InternalAddRecordInput,
  InternalEditRecordInput,
  InternalSearchRecordsDocument,
  InternalSummarizeRecordsDocument,
  Operation,
  Parameter,
  useExecuteMutationOperationMutation,
  useInternalAddRecordMutation,
  useInternalEditRecordMutation,
  useOperationQuery
} from 'generated/schema'
import { createFocusOnErrors } from 'lib/formDecorators/focusOnErrors'
import { safeParseLiquid } from 'lib/templater'
import { styled } from 'styles/stitches'
import { useMasonryContext } from 'components/layout/Masonry'
import { useViewDispatch } from 'hooks/useViewContext'
import type { BlockProps } from './Block'
import { Behavior } from 'components/dashboardEditor/AddActionView'
import GenericView from 'components/views/GenericView'

type FormValues = InternalAddRecordInput | InternalEditRecordInput | Record<any, any>;

type FormBlockProps = BlockProps & {
  asFragment?: boolean,
  initialValues?: Record<any, any>,
  operationId: string,
  resourceId?: string,
  targetEnvironment?: string,
  prefix?: string,
  footerEl?: HTMLDivElement | null,
  isTranslatable?: boolean,
  publishedId?: string,
  latestId?: string,
  isPublishingEnabled?: boolean
}

const TAB_PADDING = 35
const TAB_NOTIFICATION_SIZE = 8

const StyledTabsWrapper = styled(Flex, {
  border: '1 solid light700',
  borderWidth: 0.5
})

const StyledTab = styled(Tab, {
  padding: TAB_PADDING,

  variants: {
    background: {
      light: {
        backgroundColor: 'light100'
      },
      none: {}
    }
  }
})

const StyledTabNotification = styled(Flex, {
  ...mixins.size(TAB_NOTIFICATION_SIZE),

  background: 'negative700',
  borderRadius: '50%',
  color: 'light700',
  position: 'absolute',
  right: 0,
  top: 8
})

const focusOnErrors = createFocusOnErrors<FormValues>()

type LocaleTabNotificationBadgeProps = {
  index: number,
  parameters: Parameter[],
  locale: Locale['identifier']
}

const LocaleTabNotificationBadge = ({
  index, parameters, locale
}: LocaleTabNotificationBadgeProps) => {
  const { activeIndex } = useTabs()

  if (index === activeIndex) {
    return null
  }

  return (
    <FormSpy subscription={{ errors: true, submitFailed: true }}>
      {({ errors, submitFailed }) => {
        const notificationCount = sum(parameters.map(
          (parameter) => size(errors?.arguments?.[camelCase(parameter.identifier)]?.[locale]) || 0
        ))

        return submitFailed && Boolean(notificationCount) && (
          <StyledTabNotification justifyContent="center" alignItems="center" />
        )
      }}
    </FormSpy>
  )
}

const SubmitButton = ({ submitting, isPublishingEnabled, ...rest }: Pick<FormRenderProps, 'submitting'> & ButtonProps) => {
  const uploadingMediaFields = useContext(GetMediaFieldStateContext)

  return (
    <Button
      key="submit-button"
      disabled={submitting || uploadingMediaFields.length}
      label={isPublishingEnabled ? 'Save Draft' : 'Save'}
      type="submit"
      {...rest}
    />
  )
}

const isEmpty = (v: any) => (v === '' || v === null)

function FormBlock({
  heading,
  initialValues,
  operationId,
  fields = [],
  children = [],
  containerId,
  resourceId: _resourceId,
  asFragment,
  footerEl,
  blockRef,
  prefix,
  identifier,
  isTranslatable,
  publishedId,
  latestId,
  isPublishingEnabled,
  switcher: _switcher,
  onSubmit,
  actions = [],
  ...others
}: FormBlockProps) {
  const { switcher = _switcher } = useDashboardViewContext()
  const { blockPropertiesState } = useDashboard()
  const blockProperties = useRecoilValue(blockPropertiesState)
  const targetEnvironment = switcher?.data.environment?.id

  const {
    data: { operation } = {},
    loading: operationLoading,
    error: operationError
  } = useOperationQuery({
    variables: { id: operationId },
    skip: !operationId
  })

  const resourceId = _resourceId || operation?.resourceId
  const isCustomForm = children?.length

  const formInitialValues = useMemo(() => {
    let values = {}
    const valueMapper = (value: any) => {
      if (isCustomForm && 'en_US' in value) return value.en_US
      return value
    }

    if (typeof initialValues === 'string' && initialValues) {
      values = mapValues(
        JSONParseOr(safeParseLiquid(initialValues, blockProperties), {}),
        valueMapper
      )
    } else if (initialValues && Object.keys(initialValues).length) {
      values = initialValues
    } else if (blockProperties.currentRecord) {
      // TODO: replace currentRecord with view parameters
      values = mapValues(blockProperties.currentRecord, valueMapper)
    }

    return values
  }, [ blockProperties, initialValues, isCustomForm ])

  const isUpdating = typeof formInitialValues === 'object' && 'id' in formInitialValues

  const { closeView } = useViewDispatch()

  const createMutationOptions = {
    onCompleted: () => closeView(),
    refetchQueries: [ InternalSearchRecordsDocument, InternalSummarizeRecordsDocument ]
  }

  const [ executeOperation ] = useExecuteMutationOperationMutation({
    onCompleted: () => closeView()
  })

  const [ createRecord ] = useInternalAddRecordMutation(createMutationOptions)
  const [ updateRecord ] = useInternalEditRecordMutation({ onCompleted: () => closeView() })

  const handleExecuteOperation = useSubmitHandler(executeOperation, {
    successAlert: { message: `${operation?.name || 'Operation'} successful.` }
  })

  const handleCreateRecord = useSubmitHandler(createRecord, {
    successAlert: operation
      ? { message: `${operation.resource?.name} added.` }
      : undefined
  })

  const handleUpdateRecord = useSubmitHandler(updateRecord, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'internalEditRecord',
      typename: 'Record',
      override: (values: InternalEditRecordInput) => ({
        operationId,
        resourceId,
        targetEnvironment,
        data: values
      })
    },
    successAlert: operation?.resource
      ? { message: `${operation.resource.name} updated.` }
      : undefined
  })

  const computedInitialValues = isCustomForm
    ? formInitialValues
    : {
      operationId,
      resourceId,
      targetEnvironment,
      arguments: formInitialValues
    }

  const { openView } = useViewDispatch()
  const parsedActions = actions?.map((action: any) => {
    const shouldRun = JSONParseOr(safeParseLiquid(action.run_if || 'true', blockProperties).trim())
    switch (action.behavior) {
      case Behavior.RUN_OPERATION:
        const handleRunOperationClick = (formValues: any) => {
          const input = typeof action.input === 'string'
            ? JSONParseOr(safeParseLiquid(action.input, blockProperties)) || {}
            : Object.fromEntries(
              Object.entries(action.input || {}).map(([ key, value ]: any[]) => [
                key,
                JSONParseOr(safeParseLiquid(value, blockProperties)) || null
              ])
            )

          return handleExecuteOperation({
            arguments: {
              ...input,
              ...formValues
            },
            operationId: action.operation,
            targetEnvironment: switcher?.data.environment?.id
          })
        }

        return ({
          icon: action.icon,
          run: handleRunOperationClick,
          // disabled: Object.values(input).every((v) => !v),
          name: action.identifier,
          shouldRun
        })

      case Behavior.REDIRECT:
        const url = safeParseLiquid(action.url, {
          ...blockProperties,
          environment: switcher?.data.environment?.id || ''
        })

        return ({
          icon: action.icon,
          href: url,
          target: action.target,
          name: action.identifier,
          shouldRun,
          run: () => window.open(url, action.target, 'noopener noreferrer')
        })

      case Behavior.OPEN_VIEW:
        const handleOpenViewClick = () => {
          const params = Object.fromEntries(
            Object.entries(action.input || {}).map(([ key, value ]: any[]) => [
              key,
              JSONParseOr(safeParseLiquid(value, blockProperties)) || null
            ])
          )

          openView({
            component: GenericView,
            style: action.view_style,
            params: {
              viewUrn: action.view_urn,
              params
            }
          })
        }

        return ({
          icon: action.icon,
          run: handleOpenViewClick,
          name: action.identifier,
          shouldRun
        })

      default:
        return null
    }
  }) || []

  const handleSubmit = isCustomForm
  // Custom forms use case
    ? (values: FormValues, form: FormApi<FormValues>) => {
      const args = omitBy(values, isEmpty)

      if (parsedActions.length) {
        return Promise.all(parsedActions.map((action: any) => {
          if (action.shouldRun) {
            return action.run(args)
          }

          return null
        })).then((result) => {
          setTimeout(() => {
            form.setConfig('keepDirtyOnReinitialize', false)

            form.reset(computedInitialValues)

            form.setConfig('keepDirtyOnReinitialize', true)
          })

          return result
        })
      }

      if (resourceId) {
        const formValues: InternalEditRecordInput = {
          resourceId,
          arguments: args,
          targetEnvironment,
          versionId: latestId
        }

        if (isUpdating) {
          return handleUpdateRecord(
          formValues as InternalEditRecordInput, form as FormApi<InternalEditRecordInput>
          ).then((result) => {
            if (result.data) {
              setTimeout(() => {
                form.setConfig('keepDirtyOnReinitialize', false)

                form.reset(computedInitialValues)

                form.setConfig('keepDirtyOnReinitialize', true)
              })
            }
            return result
          })
        }

        return handleCreateRecord(formValues as InternalAddRecordInput).then((result) => {
          if (result.data) {
            setTimeout(() => {
              form.setConfig('keepDirtyOnReinitialize', false)

              form.reset(computedInitialValues)

              form.setConfig('keepDirtyOnReinitialize', true)
            })
          }
          return result
        })
      }

      const formValues = {
        operationId,
        arguments: args,
        targetEnvironment
      }

      return handleExecuteOperation(formValues)
    }
    : (values: FormValues, form: FormApi<FormValues>) => {
      const args = (operation?.parameters || [])
        .map((param) => {
          const key = resourceId ? camelCase(param.identifier) : param.identifier

          const localisedValues = activeLocales.map((locale) => {
            const paramKey = `arguments.${key}.${locale.identifier}`
            const paramValue = get(values, paramKey)

            if (paramValue === undefined) {
              return undefined
            }

            return {
              [locale.identifier]: paramValue
            }
          }).filter((v) => Boolean(v))

          if (!localisedValues.length) {
            return undefined
          }

          return set(
            {},
            key,
            localisedValues.reduce((acc: any, curr: any) => ({
              ...acc,
              ...curr
            }), {})
          )
        })

      if (resourceId) {
        const formValues: InternalEditRecordInput = {
          resourceId,
          arguments: args.reduce((acc, curr) => ({ ...acc, ...curr })),
          targetEnvironment,
          versionId: latestId
        }

        if (isUpdating) {
          return handleUpdateRecord(
          formValues as InternalEditRecordInput, form as FormApi<InternalEditRecordInput>
          )
        }

        return handleCreateRecord(formValues as InternalAddRecordInput)
      }

      const formValues = {
        operationId,
        arguments: args.reduce((acc, curr) => merge({}, acc, curr), { ...values.arguments }),
        targetEnvironment
      }

      return handleExecuteOperation(formValues)
    }

  // eslint-disable-next-line no-nested-ternary
  const parameters = operation
    ? fields.length
      ? fields.map((field: any) => {
        if (field.is_hidden) return null
        return operation?.parameters.find((p) => p.id === field.parameter)
      }).filter(Boolean)
      : operation?.parameters
    : fields.map((field: any) => ({
      ...field,
      fieldType: field.fieldType || field.field_type,
      fieldTypeSettings: field.fieldTypeSettings || field.field_type_settings
    }))

  const {
    activeLocales,
    defaultLocale,
    loading: localesLoading,
    error: localesError
  } = useActiveLocales()

  const [ _, setLocaleIndex ] = React.useState(0)
  const { containerPadding, gap } = useMasonryContext()

  const formBlock = (
    <Form
      decorators={[ focusOnErrors ]}
      keepDirtyOnReinitialize// ={!!resourceId}
      initialValues={computedInitialValues}
      onSubmit={onSubmit || blockProperties.currentOnSubmit || handleSubmit}
      mutators={{
        ...arrayMutators
      }}
      subscription={{
        submitting: true
      }}
      render={({ handleSubmit, submitting }) => {
        if (!resourceId && !operationId && !fields?.length && !children?.length) {
          return (
            <Flex direction="column" gap={18} css={{ padding: 24 }}>
              <AddBlockButton label="Add Component" id={`blockId:${others.id}`} />
              <Divider />
              <Flex justifyContent="space-between">
                <Flex gap="16">
                  {/* <AddElementButton alwaysVisible id="TODO" variant="primary" /> */}
                </Flex>
                <Flex gap="16">
                  {/* <AddElementButton alwaysVisible id="TODO" variant="secondary" /> */}
                  <Button
                    label="Submit"
                    size="small"
                    disabled
                  />
                </Flex>
              </Flex>
            </Flex>
          )
        }

        if (children?.length) {
          return (
            <>
              {heading && (
              <Block width={{ md: '100%' }} gap={24}>
                <Text fontWeight="bold">{heading}</Text>
              </Block>
              )}
              <Column
                id={others.id}
                items={children}
                css={{
                  width: '100%'
                }}
              />
              <input style={{ display: 'none' }} type="submit" />
              {operationId && (footerEl ? (
                <Portal target={footerEl}>
                  <SubmitButton
                    submitting={submitting}
                    onClick={handleSubmit}
                    isPublishingEnabled={isPublishingEnabled}
                  />
                </Portal>
              ) : (
                <Block width={{ md: '100%' }} gap={24} direction="row-reverse">
                  <SubmitButton
                    submitting={submitting}
                    onClick={handleSubmit}
                    isPublishingEnabled={isPublishingEnabled}
                  />
                </Block>
              ))}
            </>
          )
        }

        const renderContentForm = (currentLocale?: Locale) => (
          <Flex as="form" gap={16} direction="column" onSubmit={handleSubmit}>
            {heading && <Text fontWeight="bold">{heading}</Text>}
            <Loader
              loading={localesLoading || operationLoading}
              data={activeLocales && parameters}
              error={localesError || operationError}
            >
              <ParameterFields
                currentLocale={(resourceId || operation?.resourceId) ? currentLocale : undefined}
                defaultLocale={(resourceId || operation?.resourceId) ? defaultLocale : undefined}
                isUpdating={isUpdating}
                operation={operation as Operation}
                parameters={parameters as Parameter[]}
                prefix="arguments"
                resourceId={operation?.resource?.id!}
                targetEnvironmentId={targetEnvironment}
              />
            </Loader>
            <input style={{ display: 'none' }} type="submit" />
          </Flex>
        )

        return (
          <Block width={{ md: '100%' }} direction="column">
            {resourceId && isTranslatable && activeLocales?.length > 1 && !!parameters?.length
              ? (
                <StyledTabsWrapper direction="column">
                  <Tabs
                    onChange={setLocaleIndex}
                  >
                    {activeLocales.map((locale, index) => (
                      <StyledTab
                        index={index}
                        key={locale.identifier}
                        label={locale.name}
                        appendHeaderNode={(
                          <LocaleTabNotificationBadge
                            index={index}
                            parameters={parameters}
                            locale={locale.identifier}
                          />
                          )}
                        background="light"
                        alwaysMounted
                      >
                        {renderContentForm(locale)}
                      </StyledTab>
                    ))}
                  </Tabs>
                </StyledTabsWrapper>
              ) : renderContentForm(defaultLocale)}

            {footerEl ? (
              <Portal target={footerEl}>
                <SubmitButton
                  submitting={submitting}
                  onClick={handleSubmit}
                  isPublishingEnabled={isPublishingEnabled}
                />
              </Portal>
            ) : (
              <Flex gap={24} direction="row-reverse">
                <SubmitButton
                  submitting={submitting}
                  onClick={handleSubmit}
                  isPublishingEnabled={isPublishingEnabled}
                />
              </Flex>
            )}
          </Block>
        )
      }}
    />
  )

  if (asFragment) {
    return formBlock
  }

  return (
    <Block
      fullWidth={!containerId}
      css={{
        paddingX: !containerId ? containerPadding - (gap / 2) : undefined,
        margin: containerId ? 0 : undefined,
        width: containerId ? '100%' : undefined
      }}
      direction="column"
      masonryItemRef={blockRef}
      {...others}
    >
      {formBlock}
    </Block>
  )
}

export type { FormBlockProps }

export default React.memo(FormBlock)
