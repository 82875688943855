import camelCase from 'lodash/camelCase'
import compact from 'lodash/compact'
import get from 'lodash/get'
import mapValues from 'lodash/mapValues'
import uniqBy from 'lodash/uniqBy'
import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

import * as schema from 'generated/schema'
import Block from './Block'
import JSONParseOr from 'lib/JSONParseOr'
import SelectInput from 'components/inputs/SelectInput'
import useDashboard from 'hooks/useDashboard'
import useExecuteOperationQuery from './wrappers/useExecuteOperationQuery'
import useSearch from 'hooks/useSearch'
import { FieldIdentifier } from 'models/Field'
import { safeParseLiquid } from 'lib/templater'
import { useDashboardViewContext } from 'components/contexts/DashboardViewContext'
import type { BlockProps } from './Block'
import type { DataSource } from 'components/dashboardEditor/EditBlockView'
import type { SelectInputProps } from 'components/inputs/SelectInput'

type DropdownBlockProps = BlockProps & SelectInputProps<any> & {
  dataSource?: DataSource,
  dataSourceSettings?: Record<any, any>
}

function DropdownBlock({
  blockRef,
  identifier,

  settings,
  options,
  dataSource,
  dataSourceSettings = {},
  valueKey = 'value',
  labelKey,
  metaKey,

  ...others
}: DropdownBlockProps) {
  const { switcher } = useDashboardViewContext()
  const { options: settingsOptions, ...restSettings } = settings || {}
  const { blockPropertiesState, updateBlockProperties } = useDashboard()

  const blockProperties = useRecoilValue(blockPropertiesState)
  const isMulti = settings?.is_multiselect

  const setSelectedOption = (value: Record<any, any>) => {
    updateBlockProperties(
      (currentBlockProperties) => ({
        [identifier]: {
          ...currentBlockProperties[identifier],
          selectedOption: value,
          value: isMulti
            ? value?.map((option: any) => option[valueKey])
            : value?.[valueKey]
        }
      })
    )
  }

  const {
    resource: resourceId,
    operation: operationId,
    parameters: paramProperties,
    is_async: isAsync,
    filter
  } = dataSourceSettings

  const { data: { attributesList = [] } = {} } = schema.useAttributesListQuery({
    variables: {
      filter: {
        resourceId: {
          eq: resourceId
        }
      }
    },
    skip: !resourceId
  })

  const searchRecordsVariables: schema.InternalSearchRecordsQueryVariables = {
    input: {
      preview: true,
      resourceId,
      limit: isAsync ? 60 : 999,
      targetEnvironment: switcher?.data.environment?.id,
      filter: filter
        ? JSONParseOr(safeParseLiquid(filter, blockProperties), {})
        : undefined
    }
  }

  const searchableAttributes: string[] = compact((attributesList.map(
    (attr: any) => {
      if (!attr?.isArray
        && attr?.fieldType === FieldIdentifier.TEXT
      ) return camelCase(attr?.identifier)
      return ''
    }
  ) || []))

  const [ {
    data: { internalSearchRecords: searchRecords = [] } = {},
    loading,
    error
  }, onSearch ] = useSearch<
    schema.InternalSearchRecordsQuery,
    schema.InternalSearchRecordsQueryVariables
  >({
    query: schema.InternalSearchRecordsDocument,
    queryOptions: {
      variables: searchRecordsVariables,
      skip: !resourceId
    },
    keys: searchableAttributes
  })
  const {
    data: executeOperationData,
    loading: executeOperationLoading,
    error: executeOperationError
  } = useExecuteOperationQuery({
    context: blockProperties,
    operationId,
    arguments: paramProperties,
    targetEnvironment: switcher?.data.environment?.id
  })

  const [ searchText, setSearchText ] = useState('')
  const filteredOperationRecords = executeOperationData?.executeQueryOperation?.filter(
    (record: any) => get(record, labelKey || '')
      ?.toLowerCase()
      .includes(searchText.toLowerCase())
        || get(record, valueKey || '')
          ?.toLowerCase()
          .includes(searchText.toLowerCase())
  )

  const recordsMapper = (record: schema.CustomRecord & any) => mapValues(record.data, 'en_US')

  const parsedDefaultValue = JSONParseOr(
    safeParseLiquid(settings?.default_value, blockProperties)
  )

  const isDefaultValuePresent = parsedDefaultValue !== undefined
    && parsedDefaultValue !== null
    && parsedDefaultValue.trim?.() !== ''

  const [ { data: { internalSearchRecords: defaultRecords = [] } = {} } ] = useSearch<
    schema.InternalSearchRecordsQuery,
    schema.InternalSearchRecordsQueryVariables
  >({
    query: schema.InternalSearchRecordsDocument,
    queryOptions: {
      variables: {
        input: {
          resourceId,
          targetEnvironment: switcher?.data.environment?.id,
          filter: {
            [valueKey]: { eq: parsedDefaultValue }
          }
        }
      },
      skip: !resourceId || !isDefaultValuePresent || !isAsync
    },
    keys: searchableAttributes
  })

  const getOptions = () => {
    if (resourceId) {
      return uniqBy(
        searchRecords
          .map(recordsMapper)
          .concat(defaultRecords.map(recordsMapper)),
        valueKey
      )
    }

    if (operationId) {
      return filteredOperationRecords
    }

    if (settingsOptions) {
      return settingsOptions
        .filter(Boolean)
        .map(({ label, value, key }: any) => {
          // when "use different values for key and label" is enabled
          // user can leave `key/value field` empty
          const finalValue = value ?? key ?? label
          return { label, value: finalValue }
        })
    }

    return options
  }

  const defaultSelection = getOptions()?.find(
    (record: any) => record[valueKey] === parsedDefaultValue
  )

  useEffect(() => {
    const currentBlockProperties = blockProperties[identifier] || {}
    if (
      defaultSelection
      && currentBlockProperties.selectedOption === undefined
    ) {
      updateBlockProperties({
        [identifier]: {
          ...currentBlockProperties[identifier],
          selectedOption: defaultSelection,
          value: isMulti
            ? [ defaultSelection[valueKey] ]
            : defaultSelection[valueKey]
        }
      })
    }
  }, [ identifier, blockProperties, defaultSelection, isMulti, updateBlockProperties, valueKey ])

  const currentBlockProperties = blockProperties[identifier] || {}

  return (
    <Block masonryItemRef={blockRef} {...others}>
      <SelectInput
        closeMenuOnSelect={!isMulti}
        isMulti={isMulti}
        options={getOptions()}
        defaultOptions={getOptions()}
        isClearable
        loadOptions={
          isAsync
            ? (inputValue: string, callback: any) => {
              onSearch(inputValue, (data) => (
                callback(data.internalSearchRecords.map(recordsMapper))))
            }
            : undefined
        }
        onInputChange={setSearchText}
        valueKey={valueKey}
        labelKey={labelKey}
        metaKey={metaKey}
        descriptionKey=""
        value={currentBlockProperties.selectedOption}
        onChange={setSelectedOption}
        isLoading={loading || executeOperationLoading}
        hasError={error || executeOperationError}
        size="small"
        {...restSettings}
        {...{
          checkRequired: settings?.checkRequired,
          placeholder: settings?.placeholder,
          helpText: settings?.helpText || settings?.help_text
        }}
      />
    </Block>
  )
}

export type { DropdownBlockProps }

export default DropdownBlock
