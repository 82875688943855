import React from 'react'

import ButtonBlock from 'components/blocks/ButtonBlock'
import type { BlockProps } from '../Block'

const ButtonBlockWrapper: React.FC<BlockProps> = ({ block, ...props }) => (
  <ButtonBlock
    {...props}
    {...block.properties}
    actions={block.actions}
    identifier={block.identifier}
  />
)

export default ButtonBlockWrapper
