import React, { useContext } from 'react'
import type { ReactNode } from 'react'

import * as mixins from 'styles/mixins'
import DashboardContext from 'components/contexts/DashboardContext'
import Flex from 'components/layout/Flex'
import Portal from 'components/portal/Portal'
import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'
import { DASHBOARD_EDITOR_WIDTH } from 'components/dashboardEditor/constants'
import Icon from 'components/icons/Icon'

type ProgressIndicatorProps = {
  visible?: boolean,
  loading?: ReactNode
}

const SPACING = 24
const WRAPPER_BORDER_RADIUS = 9
const WRAPPER_HEIGHT = 60
const PADDING_X = SPACING
const PADDING_Y = 15
const ACTION_Z_INDEX = 1

const baseBoxStyles = {
  background: 'dark100',
  borderRadius: WRAPPER_BORDER_RADIUS,
  height: WRAPPER_HEIGHT,
  paddingY: PADDING_Y,
  paddingX: PADDING_X
}

const StyledInfo = styled(Flex, {
  ...baseBoxStyles,

  zIndex: ACTION_Z_INDEX + 1
})

// TODO: prepend 2 '-' after upgrading stitches
const TRANSLATE_X_VAR = '-summary-bar-translate-x'
const TRANSLATE_Y_VAR = '-summary-bar-translate-y'
const DASHBOARD_EDITOR_WIDTH_VAR = '-dashboard-editor-width'

const StyledWrapper = styled(Flex, {
  ...mixins.transition('fastIn'),

  [TRANSLATE_X_VAR]: 0,
  [TRANSLATE_Y_VAR]: 0,
  whiteSpace: 'nowrap',
  width: 'fit-content',
  transform: `translate(var(-${TRANSLATE_X_VAR}), var(-${TRANSLATE_Y_VAR})) translateX(calc(var(-${DASHBOARD_EDITOR_WIDTH_VAR}) + var(--current-sidebar-width)))`,
  bottom: 20,
  left: '50%',
  overflow: 'hidden',
  position: 'fixed',
  [TRANSLATE_X_VAR]: '-50%',
  ':has(~[data-summary-fixed="true"])': {
    bottom: 80
  },
  '[data-summary-fixed="true"]~&': {
    bottom: 80
  },

  variants: {
    visible: {
      true: {
        opacity: 1
      },
      false: {
        [TRANSLATE_Y_VAR]: 'calc(100% + 80px)',
        opacity: 0
      }
    },
    editMode: {
      false: {
        [DASHBOARD_EDITOR_WIDTH_VAR]: 0
      },
      true: {
        [DASHBOARD_EDITOR_WIDTH_VAR]: `-${DASHBOARD_EDITOR_WIDTH / 2}px`
      }
    }
  }
})

function ProgressIndicator({
  visible, loading
}: ProgressIndicatorProps) {
  const { currentSidebarWidth, editMode } = useContext(DashboardContext)!

  const progress = (
    <StyledWrapper
      visible={visible}
      style={{
        // @ts-ignore
        '--current-sidebar-width': `${currentSidebarWidth / 2}px`
      }}
      editMode={editMode}
    >
      <StyledInfo
        alignItems="center"
        gap={16}
      >
        <Icon color="dark900" name="clock" />
        <Text color="dark900" fontSize={14}>{loading}</Text>
      </StyledInfo>
    </StyledWrapper>
  )

  return <Portal>{progress}</Portal>
}

export default ProgressIndicator
