import Box from 'components/layout/Box'
import { generateThemeVariants, generateVariants, styled } from 'styles/stitches'
import type BaseLink from 'components/links/BaseLink'

type TextElement = typeof BaseLink | 'p' | 'span' | 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'label'

const textTransformOptions = [ 'none', 'capitalize', 'uppercase', 'lowercase' ] as const
const textAlignOptions = [ 'center', 'end', 'justify', 'left', 'right', 'start' ] as const
const textWrapOptions = [ 'wrap', 'nowrap', 'balance', 'pretty' ] as const

const DEFAULT_ELEMENT: TextElement = 'p'

const colorVariants = generateThemeVariants('colors', 'color')

const fontFamilyVariants = generateThemeVariants('fonts', 'fontFamily')

const fontSizeVariants = generateThemeVariants('fontSizes', 'fontSize')

const fontWeightVariants = generateThemeVariants('fontWeights', 'fontWeight')

const letterSpacingVariants = generateThemeVariants('letterSpacings', 'letterSpacing')

const lineHeightVariants = generateThemeVariants('lineHeights', 'lineHeight')

const textTransformVariants = generateVariants(textTransformOptions, 'textTransform')
const textAlignVariants = generateVariants(textAlignOptions, 'textAlign')
const textWrapVariants = generateVariants(textWrapOptions, 'textWrap')

const Text = styled(Box, {

  '& > [data-highlight]': {
    fontWeight: 'bold'
  },

  '& > [data-highlight-bold]': {
    fontWeight: 'bold'
  },

  '& > [data-highlight-semibold]': {
    fontWeight: 'semibold'
  },

  variants: {
    color: colorVariants,
    fontFamily: fontFamilyVariants,
    fontSize: fontSizeVariants,
    fontWeight: fontWeightVariants,
    letterSpacing: letterSpacingVariants,
    lineHeight: lineHeightVariants,
    textTransform: textTransformVariants,
    textAlign: textAlignVariants,
    textWrap: textWrapVariants,
    truncate: {
      false: {
        wordBreak: 'break-word'
      },
      true: {
        truncate: true
      }
    }
  }
})

Text.defaultProps = {
  as: DEFAULT_ELEMENT,
  color: 'dark900',
  fontFamily: 'normal',
  letterSpacing: 'normal',
  lineHeight: 'normal',
  textAlign: 'left',
  textTransform: 'none'
}

type TextProps = StyledProps<typeof Text>

export type { TextProps }

export default Text
