import React, { useState } from 'react'

import AppIcon, { AppIconIdentifier } from 'components/icons/AppIcon'
import DashboardEditorBody from '../base/DashboardEditorBody'
import DashboardEditorHeader from '../base/DashboardEditorHeader'
import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'
import Flex from 'components/layout/Flex'
import MediaCard from 'components/mediaCard/MediaCard'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import useDashboard from 'hooks/useDashboard'
import { APP_LIST_LIMIT } from 'models/App'
import { useAppListQuery } from 'generated/schema'
import { Views } from 'components/dashboardEditor/constants'
import type { ActiveViewProps } from 'components/dashboardEditor/DashboardEditor'

const ProjectsView = ({ onClose }: ActiveViewProps) => {
  const [ search, setSearch ] = useState('')

  return (
    <>
      <DashboardEditorHeader
        subtitle="Projects"
        heading="Dashboard Editor"
        onClose={onClose}
        onSearch={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
      />
      <DashboardEditorBody>
        <Text color="dark900" fontSize={12}>Projects are applications — website, mobile app, or backend — that integrate with DashX.
          {' '}<TextLink variant="underlined" href="https://docs.dashx.com/platform/projects">Learn more</TextLink>
        </Text>
        <ProjectsGrid search={search} />
        <Actions />
      </DashboardEditorBody>
    </>
  )
}

const ProjectsGrid = ({ search = '' }: { search: string }) => {
  const { openDashboardEditorView } = useDashboard()

  const {
    data: { appsList } = {},
    error: appsListError,
    loading: appsListLoading
  } = useAppListQuery({
    variables: {
      filter: {
        or: [
          { kind: { eq: 'PROJECT' } }
        ]
      },
      limit: APP_LIST_LIMIT,
      order: [ { name: 'asc' } ]
    }
  })

  const filteredProjects = appsList?.filter(
    (app) => app.name.toLowerCase().includes(search.toLowerCase())
  )

  return (
    <Flex gap={16} direction="column">
      <DashboardEditorLoader
        empty={{
          variant: 'simple',
          element: (
            <Flex alignItems="center" direction="column">
              <Text fontSize={14} color="dark500">Nothing to show here.</Text>
            </Flex>
          )
        }}
        data={filteredProjects}
        loading={appsListLoading && !filteredProjects}
        error={appsListError}
      >
        <Flex direction="column" gap={2}>
          {filteredProjects?.map((project) => {
            const onClick = () => openDashboardEditorView({
              target: Views.PROJECT_DETAILS,
              params: { app: project }
            })

            const { id, icon, identifier, name, workspaceId } = project
            const isCustomApp = !!workspaceId

            return (
              <MediaCard
                compact
                onClick={onClick}
                key={id}
                media={(
                  <AppIcon
                    isProject
                    isCustomApp={isCustomApp}
                    identifier={identifier as AppIconIdentifier}
                    icon={icon}
                    size={32}
                  />
                )}
                title={name}
                height={64}
                width="full"
                actions={[ {
                  description: '',
                  icon: 'arrow-right',
                  onClick,
                  isIconAlwaysVisible: true
                } ]}
              />
            )
          })}
        </Flex>
      </DashboardEditorLoader>
    </Flex>
  )
}

const Actions = () => {
  const { openDashboardEditorView } = useDashboard()
  const onClick = () => openDashboardEditorView({ target: Views.CREATE_PROJECT })

  return (
    <Flex gap={16} direction="column">
      <Text
        color="dark500"
        fontSize={10}
        fontWeight="bold"
        textTransform="uppercase"
      >
        Actions
      </Text>
      <MediaCard
        compact
        onClick={onClick}
        media="project-information"
        title="Create Project"
        height={64}
        width="full"
        actions={[ {
          description: '',
          icon: 'arrow-right',
          onClick,
          isIconAlwaysVisible: true
        } ]}
      />
    </Flex>
  )
}

export default ProjectsView
