import React from 'react'
import type { RouteProps } from 'react-router-dom'

import InternalLayout from 'components/layouts/InternalLayout'
import ProtectedRoute, { SessionState } from 'components/routes/ProtectedRoute'
import { ROLE_IDS } from 'models/Role'

function WorkspaceRoute(props: RouteProps) {
  return (
    <ProtectedRoute
      layout={InternalLayout}
      requiredSessionState={SessionState.LOGGED_IN}
      requiredAccountState="ENABLED"
      requiredRoleIds={[ ROLE_IDS.WORKSPACE_OWNER ]}
      {...props}
    />
  )
}

export default WorkspaceRoute
