import React from 'react'

import ActionIcon from 'components/icons/ActionIcon'
import BackLink from 'components/links/BackLink'
import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import { SIDE_PANE_HEADER_TITLE_HEIGHT, SIDE_PANE_PADDING_X } from './constants'
import { styled } from 'styles/stitches'

const StyledSidePaneHeader = styled(Flex, {
  backgroundColor: 'light100',
  borderBottomStyle: 'solid',
  borderBottomWidth: 1,
  borderColor: 'light700',
  minHeight: SIDE_PANE_HEADER_TITLE_HEIGHT,
  paddingX: SIDE_PANE_PADDING_X,
  width: '100%',

  variants: {
    size: {
      small: {
        minHeight: 84,
        paddingX: 30
      },
      normal: {}
    }
  }
})

type SidePaneHeaderProps = React.PropsWithChildren<{
  onBackArrowClick?: (() => void),
  onCloseClick: () => void,
  title?: string,
  size?: 'normal' | 'small'
}>

function SidePaneHeader({ title = '', onBackArrowClick, onCloseClick, children, size }: SidePaneHeaderProps) {
  return (
    <StyledSidePaneHeader alignItems="center" justifyContent="space-between" shrink={0} size={size} gap={16}>
      <Flex alignItems="center" gap={16} grow={1} css={{ overflow: 'hidden' }}>
        {onBackArrowClick && (
          <BackLink
            onClick={() => onBackArrowClick()}
            label={title}
            labelSize={14}
          />
        )}
        {!onBackArrowClick && title && (
          <Text
            as="div"
            fontSize={size === 'small' ? 24 : 32}
            fontWeight="bold"
            letterSpacing="compact"
            truncate
          >
            {title}
          </Text>
        )}
        {children}
      </Flex>
      <ActionIcon data-icon name="close" onClick={onCloseClick} size={22} />
    </StyledSidePaneHeader>
  )
}

export default SidePaneHeader
