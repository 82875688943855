import compact from 'lodash/compact'
import uniq from 'lodash/uniq'
import { atom, atomFamily, RecoilState, selector, selectorFamily, useRecoilCallback } from 'recoil'
import type { ApolloError } from '@apollo/client'

import { ViewParams, Views } from 'components/dashboardEditor/constants'
import type { BlockType } from 'components/blocks'
import type { ComputedMenuElement } from 'lib/generateDashboard'
import type { ElementType } from 'components/views/AddElementView'
import type { OperationMethod, OperationGraphqlKind, Operation as OperationType, View, AppFragmentFragment, ResourceFragmentFragment } from 'generated/schema'

type ID = string
type URN = string

type LayoutProps<T> = {
  xs?: T,
  sm?: T,
  md?: T,
  lg?: T
}

/* type SelectedItem = {
  identifier: string,
  value: string
} */

type Block = {
  id: string,
  name: string,
  position: number,
  identifier: string,
  type: BlockType,
  operations: Operation[],
  actions?: Record<string, any>[] | null,
  // eslint-disable-next-line camelcase
  visibility_criteria?: {
    kind: string,
    expression: string
  },
  layout: {
    position: LayoutProps<number>,
    width: LayoutProps<number>,
    height: LayoutProps<number>,
    visibility?: LayoutProps<boolean>
  },
  properties: Record<any, any>
}

type BlockProperties = {
  [identifier: string]: Record<any, any> | Function | undefined
}

type Element = {
  id: string,
  name: string,
  identifier: string,
  blockId: string,
  type: ElementType,
  properties: Record<any, any>
}

type DashboardEditorView<P extends Views> = {
  target: P,
  params?: ViewParams[P]
}

type Operation = {
  id: string,
  name: string,
  identifier: string,
  operationId: string,
  type: OperationGraphqlKind,
  method: OperationMethod,
  documentName: string,
  nodeName: string,
  selections: string[],
  data: any,
  loading: boolean,
  error: ApolloError | undefined,
  variables: any,
  successMessage?: string,
  errorMessage?: string,
  autoTrigger?: boolean,
  requestMissingArguments?: boolean,
  enablePagination?: boolean,
  enableFilter?: boolean,
  enableSort?: boolean,
  operation?: OperationType
}

// @ts-ignore
const defaultBlock: Block = {
  id: '-1',
  name: '',
  identifier: '',
  operations: [],
  position: 0,
  properties: {},
  type: 'TitleBlock'
}

const defaultElement: Element = {
  id: '-1',
  name: '',
  identifier: '',
  blockId: '-1',
  properties: {},
  type: 'TextElement'
}

const defaultDashboardEditorView: DashboardEditorView<Views> = {
  target: Views.ACTIONS,
  params: {}
}

const defaultOperation: Operation = {
  id: '-1',
  name: '',
  identifier: '',
  operationId: '-1',
  type: 'QUERY',
  method: 'GENERIC',
  documentName: '',
  nodeName: '',
  selections: [],
  data: undefined,
  loading: false,
  error: undefined,
  variables: {}
}

type Params = {
  id: ID,
  atom: keyof AtomMapType
}

const selectedBlockId = atom<Block['id'] | null>({
  key: 'selectedBlockId',
  default: null
})

const selectedBlock = selector<Block | null>({
  key: 'selectedBlock',
  get: ({ get }) => {
    const blockId = get(selectedBlockId)
    if (!blockId) return null
    return get(blockState(blockId))
  }
})

const selectedBlockType = selector<BlockType | null>({
  key: 'selectedBlockType',
  get: ({ get }) => {
    const blockId = get(selectedBlockId)
    if (!blockId) return null
    return get(blockState(blockId))?.type
  }
})

const selectedMenu = atom<ComputedMenuElement | null>({
  key: 'selectedMenu',
  default: null
})

const draggingOverBlockId = atom<Block['id'] | null>({
  key: 'draggingOverBlockId',
  default: null
})

/* const draggingOverBlock = selector<Block | null>({
  key: 'draggingOverBlock',
  get: ({ get }) => {
    const blockId = get(draggingOverBlockId)
    if (!blockId) return null
    return get(blockState(blockId))
  }
}) */

const draggedBlock = atom<Block | null>({
  key: 'draggedBlock',
  default: null
})

const draggedMenu = atom<ComputedMenuElement | null>({
  key: 'draggedMenu',
  default: null
})

const draggingOverMenuId = atom<ComputedMenuElement['id'] | null>({
  key: 'draggingOverMenuId',
  default: null
})

const draggedApp = atom<AppFragmentFragment | null>({
  key: 'draggedApp',
  default: null
})

const draggedResource = atom<ResourceFragmentFragment | null>({
  key: 'draggedResource',
  default: null
})

const operationIds = atom<ID[]>({
  key: 'operationIds',
  default: []
})

const blockIds = atomFamily<ID[], URN>({
  key: 'blockIds',
  default: []
})

const elementIds = atom<ID[]>({
  key: 'elementIds',
  default: []
})

const blockState = atomFamily<Block, ID>({
  key: 'block',
  default: defaultBlock
})

const viewState = atomFamily<Omit<View, 'blocks' | 'operations'>, URN>({
  key: 'view',
  default: {} as View
})

const blockPropertiesState = atom<BlockProperties>({
  key: 'blockProperties',
  default: {} as BlockProperties
})

const dashboardEditorStack = atom<DashboardEditorView<Views>[]>({
  key: 'dashboardEditor',
  default: [ defaultDashboardEditorView ]
})

const dashboardEditorState = selector<DashboardEditorView<any>>({
  key: 'dashboardEditorStack',
  get: ({ get }) => {
    const stack = get(dashboardEditorStack)
    return stack[stack.length - 1]
  }
})

const elementState = atomFamily<Element, ID>({
  key: 'element',
  default: defaultElement
})

const operationState = atomFamily<Operation, ID>({
  key: 'operation',
  default: defaultOperation
})

const getBlocksSelector = selectorFamily<Block[], ID[]>({
  key: 'getBlocksSelector',
  get: (blockIds) => ({ get }) => blockIds.map((id) => get(blockState(id)))
})

const getElementsSelector = selectorFamily<Element[], ID[]>({
  key: 'getElementsSelector',
  get: (elementIds) => ({ get }) => elementIds.map((id) => get(elementState(id)))
})

const getOperationsSelector = selectorFamily<Operation[], ID[]>({
  key: 'getOperationsSelector',
  get: (operationIds) => ({ get }) => operationIds.map((id) => get(operationState(id)))
})

type AtomMapType = {
  blocks: (param: string) => RecoilState<Block>,
  elements: (param: string) => RecoilState<Element>,
  operations: (param: string) => RecoilState<Operation>
}

const atomMap: AtomMapType = {
  blocks: blockState,
  elements: elementState,
  operations: operationState
}

const getAtomData = selectorFamily<Operation | Block | Element, Params>({
  key: 'atomState',
  get: ({ id, atom }) => ({ get }) => {
    if (!id || !atom) return get(operationState('-1'))
    const atomFamily = atomMap[atom]
    return get<Operation | Block | Element>((atomFamily)(id))
  }
})

const updateBlockIds = (blockId: string) => (currentBlockIds: string[]) => (
  currentBlockIds.find((id) => id === blockId)
    ? currentBlockIds
    : [ ...currentBlockIds, blockId ]
).filter(Boolean)

const nullState = atom({
  key: 'nullState',
  default: null
})

function useDashboard() {
  const createOperation = useRecoilCallback(
    ({ set, snapshot }) => async (operationId: string, operation: Operation) => {
      const stateOperation = await snapshot.getPromise(operationState(operationId))
      if (stateOperation.identifier !== operationId) {
        set(operationIds, (currVal) => (compact(uniq([ ...currVal, operationId ]))))
        set(operationState(operationId), operation)
      }
    }, []
  )

  const createBlock = useRecoilCallback(
    ({ set, snapshot }) => async (urn: string, blockId: string, block: Block) => {
      const stateBlock = await snapshot.getPromise(blockState(blockId))
      if (stateBlock.identifier !== blockId) {
        set(blockIds(urn!), (currVal) => (compact(uniq([ ...currVal, blockId ]))))
        set(blockState(blockId), block)
      }
    }, []
  )

  const createElement = useRecoilCallback(
    ({ set, snapshot }) => async (elementId: string, element: Element) => {
      const stateElement = await snapshot.getPromise(elementState(elementId))
      if (stateElement.identifier !== elementId) {
        set(elementIds, (currVal) => (compact(uniq([ ...currVal, elementId ]))))
        set(elementState(elementId), element)
      }
    }, []
  )

  const removeOperation = useRecoilCallback(
    ({ set, reset, snapshot }) => async (operationId: string) => {
      const stateOperation = await snapshot.getPromise(operationState(operationId))
      if (stateOperation.identifier === operationId) {
        set(operationIds, (currVal) => (currVal.filter((val) => val === operationId)))
        reset(operationState(operationId))
      }
    }, []
  )

  const removeBlock = useRecoilCallback(
    ({ set, snapshot }) => async (urn: string, blockId: string) => {
      const existingBlockIds = await snapshot.getPromise(blockIds(urn!))
      const updatedBlockIds = existingBlockIds.filter((val) => val !== blockId)
      /* // @ts-ignore
      set(blockState(blockId), null) */

      if (updatedBlockIds.length < existingBlockIds.length) {
        set(blockIds(urn!), updatedBlockIds)

        return Promise.all(
          updatedBlockIds.map(
            (value) => snapshot.getPromise(blockState(value))
          )
        )
      }

      const recursiveDelete = (parentBlock: Block) => {
        if (parentBlock.id === blockId) {
          return {
            block: undefined,
            shouldUpdateParent: true
          }
        }

        if (parentBlock.type === 'FormBlock' || parentBlock.type === 'CardBlock') {
          let shouldUpdate = false

          const updatedChildren = (parentBlock.properties.children || [])
            .map((child: any) => {
              const { block: updatedChild, shouldUpdateParent } = recursiveDelete(child)
              shouldUpdate = shouldUpdate || shouldUpdateParent
              return updatedChild
            }).filter(Boolean)

          const updatedParentBlock = {
            ...parentBlock,
            properties: {
              ...parentBlock.properties,
              children: updatedChildren
            }
          }

          if (shouldUpdate) {
            set(blockState(parentBlock.id), updatedParentBlock)
          }

          return {
            block: updatedParentBlock,
            shouldUpdateParent: shouldUpdate
          }
        }

        if (parentBlock.type === 'ColumnsBlock') {
          let shouldUpdate = false

          const updatedColumns = (parentBlock.properties.columns || [])
            .map((column: any) => ({
              ...column,
              children: (column.children || []).map((child: any) => {
                const { block: updatedChild, shouldUpdateParent } = recursiveDelete(child)
                shouldUpdate = shouldUpdate || shouldUpdateParent
                return updatedChild
              })
                .filter(Boolean)
            })).filter((column: any) => !!column.children?.length)

          if (parentBlock.properties.columns?.length > updatedColumns.length) {
            const availableSpace = 100 - updatedColumns.reduce((sum: number, column: any) => sum + (+column.width.split('%')[0] || 0), 0)
            const lastColumn = updatedColumns[updatedColumns.length - 1]
            lastColumn.width = `${availableSpace + (+lastColumn.width.split('%')[0] || 0)}%`
          }

          const updatedParentBlock = {
            ...parentBlock,
            properties: {
              ...parentBlock.properties,
              columns: updatedColumns
            }
          }

          if (shouldUpdate) {
            set(blockState(parentBlock.id), updatedParentBlock)
          }

          return { block: updatedParentBlock, shouldUpdateParent: shouldUpdate }
        }

        if (parentBlock.type === 'TabsBlock') {
          let shouldUpdate = false

          const updatedTabs = (parentBlock.properties.tabs || [])
            .map((tab: any) => ({
              ...tab,
              children: (tab.children || []).map((child: any) => {
                const { block: updatedChild, shouldUpdateParent } = recursiveDelete(child)
                shouldUpdate = shouldUpdate || shouldUpdateParent
                return updatedChild
              })
                .filter(Boolean)
            }))

          const updatedParentBlock = {
            ...parentBlock,
            properties: {
              ...parentBlock.properties,
              tabs: updatedTabs
            }
          }

          if (shouldUpdate) {
            set(blockState(parentBlock.id), updatedParentBlock)
          }

          return { block: updatedParentBlock, shouldUpdateParent: shouldUpdate }
        }

        return {
          block: parentBlock,
          shouldUpdateParent: false
        }
      }

      return Promise.all(
        updatedBlockIds.map(
          async (blockId) => {
            const block = await snapshot.getPromise(blockState(blockId))
            return recursiveDelete(block).block
          }
        )
      )
    }, []
  )

  const getBlocks = useRecoilCallback(
    ({ snapshot }) => async (urn: string, paramIds?: string[]) => {
      const ids = paramIds || await snapshot.getPromise(blockIds(urn!))
      return Promise.all(ids.map((value) => snapshot.getPromise(blockState(value))))
    }, []
  )

  const getOperations = useRecoilCallback(
    ({ snapshot }) => async () => {
      const ids = await snapshot.getPromise(operationIds)
      return Promise.all(ids.map((value) => snapshot.getPromise(operationState(value))))
    }, []
  )

  const removeElement = useRecoilCallback(
    ({ set, reset, snapshot }) => async (elementId: string) => {
      const stateElement = await snapshot.getPromise(elementState(elementId))
      if (stateElement.identifier === elementId) {
        set(elementIds, (currVal) => (currVal.filter((val) => val === elementId)))
        reset(elementState(elementId))
      }
    }, []
  )

  const selectBlock = useRecoilCallback(
    ({ set }) => (blockId: Block['id'] | null) => {
      set(selectedBlockId, blockId)
    }, []
  )

  const updateBlock = useRecoilCallback(
    ({ set, snapshot }) => async (urn: string, block: Block, append = false) => {
      set(blockState(block.id), block)

      if (append) {
        set(blockIds(urn!), updateBlockIds(block.id))
      }

      // Do not change the order of set and getPromise calls
      let updatedBlockIds = await snapshot.getPromise(blockIds(urn!))
      if (append) {
        updatedBlockIds = updateBlockIds(block.id)(updatedBlockIds)
      }

      if (updatedBlockIds.find((blockId) => blockId === block.id)) {
        return Promise.all(updatedBlockIds.map((id) => (
          id === block.id
            ? block
            : snapshot.getPromise(blockState(id))
        )))
      }

      const recursiveUpdateBlocks = (parentBlock: Block) => {
        if (parentBlock.id === block.id) {
          return { block, shouldUpdateParent: true }
        }

        if (parentBlock.type === 'FormBlock' || parentBlock.type === 'CardBlock') {
          let shouldUpdate = false

          const updatedChildren = (parentBlock.properties.children || []).map((child: any) => {
            const { block: updatedChild, shouldUpdateParent } = recursiveUpdateBlocks(child)
            shouldUpdate = shouldUpdate || shouldUpdateParent
            return updatedChild
          })

          const updatedParentBlock = {
            ...parentBlock,
            properties: {
              ...parentBlock.properties,
              children: updatedChildren
            }
          }

          if (shouldUpdate) {
            set(blockState(parentBlock.id), updatedParentBlock)
          }

          return { block: updatedParentBlock, shouldUpdateParent: shouldUpdate }
        }

        if (parentBlock.type === 'ColumnsBlock') {
          let shouldUpdate = false

          const updatedColumns = (parentBlock.properties.columns || [])
            .map((column: any) => ({
              ...column,
              children: (column.children || []).map((child: any) => {
                const { block: updatedChild, shouldUpdateParent } = recursiveUpdateBlocks(child)
                shouldUpdate = shouldUpdate || shouldUpdateParent
                return updatedChild
              })
            }))

          const updatedParentBlock = {
            ...parentBlock,
            properties: {
              ...parentBlock.properties,
              columns: updatedColumns
            }
          }

          if (shouldUpdate) {
            set(blockState(parentBlock.id), updatedParentBlock)
          }

          return { block: updatedParentBlock, shouldUpdateParent: shouldUpdate }
        }

        if (parentBlock.type === 'TabsBlock') {
          let shouldUpdate = false

          const updatedTabs = (parentBlock.properties.tabs || [])
            .map((tab: any) => ({
              ...tab,
              children: (tab.children || []).map((child: any) => {
                const { block: updatedChild, shouldUpdateParent } = recursiveUpdateBlocks(child)
                shouldUpdate = shouldUpdate || shouldUpdateParent
                return updatedChild
              })
            }))

          const updatedParentBlock = {
            ...parentBlock,
            properties: {
              ...parentBlock.properties,
              tabs: updatedTabs
            }
          }

          if (shouldUpdate) {
            set(blockState(parentBlock.id), updatedParentBlock)
          }

          return { block: updatedParentBlock, shouldUpdateParent: shouldUpdate }
        }

        return { block: parentBlock, shouldUpdateParent: false }
      }

      return (await Promise.all(updatedBlockIds.map((id) => (
        snapshot.getPromise(blockState(id))
      )))).map((block) => recursiveUpdateBlocks(block).block)
    }, []
  )

  const moveBlockToIndex = useRecoilCallback(
    ({ set, snapshot }) => async (urn: string, block: Block, index: number) => {
      const currentBlockIds = (await snapshot.getPromise(blockIds(urn!))).slice()
      const sourceIndex = currentBlockIds.findIndex((id) => id === block.id)

      if (sourceIndex > index) {
        currentBlockIds.splice(index, 0, block.id)
        currentBlockIds.splice(sourceIndex + 1, 1)
      } else if (sourceIndex === -1) {
        await removeBlock(urn, block.id)
        currentBlockIds.splice(index + 1, 0, block.id)
      } else {
        currentBlockIds.splice(index + 1, 0, block.id)
        currentBlockIds.splice(sourceIndex, 1)
      }
      set(blockIds(urn!), currentBlockIds)
      set(blockState(block.id), block)
    }, []
  )

  const replaceBlockAtIndex = useRecoilCallback(
    ({ set, snapshot }) => async (urn: string, block: Block, index: number) => {
      const currentBlockIds = (await snapshot.getPromise(blockIds(urn!))).slice()
      currentBlockIds[index] = block?.id
      set(blockIds(urn!), currentBlockIds.filter(Boolean))
      if (block) set(blockState(block.id), block)
    }, []
  )

  const updateOperation = useRecoilCallback(
    ({ set, snapshot }) => async (operation: Operation) => {
      const currentOperationIds = (await snapshot.getPromise(operationIds))
      const updatedOperationIds = currentOperationIds.find((id) => id === operation.id)
        ? currentOperationIds
        : [ ...currentOperationIds, operation.id ]
      set(operationState(operation.id), operation)
      set(operationIds, updatedOperationIds)

      return Promise.all(currentOperationIds.map((id) => (id === operation.id
        ? operation
        : snapshot.getPromise(operationState(id)))))
    }, []
  )

  const resetBlockIds = useRecoilCallback(
    ({ reset }) => (urn: string) => {
      reset(blockIds(urn!))
    }, []
  )

  const resetElementIds = useRecoilCallback(
    ({ reset }) => () => {
      reset(elementIds)
    }, []
  )

  const resetOperationIds = useRecoilCallback(
    ({ reset }) => () => {
      reset(operationIds)
    }, []
  )

  const updateView = useRecoilCallback(
    ({ set }) => (urn: string, view: Omit<View, 'blocks' | 'operation'>) => {
      set(viewState(urn), view)
    }, []
  )

  const updateBlockProperties = useRecoilCallback(
    ({ set }) => async (newbP: BlockProperties | ((newbP: BlockProperties) => BlockProperties)) => {
      set(blockPropertiesState, (oldbp) => {
        if (typeof newbP === 'function') return ({ ...oldbp, ...newbP(oldbp) })

        return ({ ...oldbp, ...newbP })
      })
    }, []
  )

  const selectMenu = useRecoilCallback(
    ({ set }) => (menu: ComputedMenuElement | null) => {
      set(selectedMenu, menu)
    }, []
  )

  const openDashboardEditorView = useRecoilCallback(
    ({ set }) => <T extends Views>(view: DashboardEditorView<T>) => {
      set(dashboardEditorStack, (state) => state.concat(view))
    }, []
  )

  const stepBackDashboardEditor = useRecoilCallback(
    ({ set }) => (
      count: number = 1,
      newStateGetter: Record<any, any> | ((state: Record<any, any>) => Record<any, any>) = {}
    ) => {
      set(dashboardEditorStack, (state) => {
        const newStack = state.slice(0, -count)

        let lastItem = newStack[newStack.length - count]
        const newState = typeof newStateGetter === 'function' ? newStateGetter(lastItem?.params || {}) : newStateGetter

        if (!lastItem) return [ defaultDashboardEditorView ]

        // Do not use merge here as it may create undesirable side
        // effects in forms when deleting items of repeated field
        lastItem = { ...lastItem, params: { ...lastItem.params, ...newState } }
        return [ ...newStack.slice(0, -1), lastItem ]
      })
    }, []
  )

  const saveDashboardEditorViewState = useRecoilCallback(
    ({ set }) => (
      newStateGetter: Record<any, any> | ((state: Record<any, any>) => Record<any, any>) = {}
    ) => {
      set(dashboardEditorStack, (stack) => {
        let lastItem = stack[stack.length - 1]
        const newState = typeof newStateGetter === 'function' ? newStateGetter(lastItem?.params || {}) : newStateGetter
        lastItem = { ...lastItem, params: { ...lastItem.params, ...newState } }
        return [ ...stack.slice(0, -1), lastItem ]
      })
    }, []
  )

  const resetDashboardEditorStack = useRecoilCallback(
    ({ set }) => () => {
      set(dashboardEditorStack, [ defaultDashboardEditorView ])
    }, []
  )

  return {
    blockIds,
    blockState,
    blockPropertiesState,
    createBlock,
    createElement,
    createOperation,
    dashboardEditorState,
    draggedAppState: draggedApp,
    draggedBlockState: draggedBlock,
    draggedMenuState: draggedMenu,
    draggedResourceState: draggedResource,
    draggingOverBlockIdState: draggingOverBlockId,
    draggingOverMenuIdState: draggingOverMenuId,
    elementIds,
    elementState,
    getAtomData,
    getBlocks,
    getBlocksSelector,
    getElementsSelector,
    getOperations,
    getOperationsSelector,
    moveBlockToIndex,
    nullState,
    openDashboardEditorView,
    operationIds,
    operationState,
    removeBlock,
    removeElement,
    removeOperation,
    replaceBlockAtIndex,
    resetBlockIds,
    resetElementIds,
    resetDashboardEditorStack,
    resetOperationIds,
    selectBlock,
    selectedBlockIdState: selectedBlockId,
    selectedBlockState: selectedBlock,
    selectedBlockTypeState: selectedBlockType,
    selectedMenuState: selectedMenu,
    selectMenu,
    stepBackDashboardEditor,
    saveDashboardEditorViewState,
    updateBlock,
    updateBlockProperties,
    updateOperation,
    updateView,
    viewState
  }
}

export type {
  AtomMapType,
  Block,
  DashboardEditorView,
  Element,
  Operation
}

export default useDashboard
