/* eslint-disable camelcase */
import arrayMutators from 'final-form-arrays'
import React from 'react'
import { Form } from 'react-final-form'
import { useRecoilValue } from 'recoil'
import type { Decorator } from 'final-form'

import Button from 'components/buttons/Button'
import DashboardEditorBody from 'components/dashboardEditor/base/DashboardEditorBody'
import DashboardEditorHeader from 'components/dashboardEditor/base/DashboardEditorHeader'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import Tab from 'components/tabs/Tab'
import Tabs from 'components/tabs/Tabs'
import TextField from 'components/contentEditors/generic/fields/TextField'
import ToggleInput from 'components/inputs/ToggleInput'
import useDashboard from 'hooks/useDashboard'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { css } from 'styles/stitches'
import { SidePaneFooter } from 'components/sidePane'
import { useDashboardEditorContextProvider } from './DashboardEditorProvider'
import { ViewParams, Views } from 'components/dashboardEditor/constants'
import type { ActiveViewProps } from 'components/dashboardEditor/DashboardEditor'
import FieldTypeConfig from './FieldTypeConfig'

type Params = ViewParams[Views.ADD_FIELD]

const setIdentifier = createSetIdentifier(
  'name', 'identifier'
) as Decorator<any>

const decorators = [
  setIdentifier
]

const classes = {
  wrapper: css({
    marginBottom: 30
  })
}

const AddFieldView = ({ onClose }: ActiveViewProps) => {
  const {
    dashboardEditorState,
    openDashboardEditorView,
    selectBlock,
    stepBackDashboardEditor,
    updateBlock
  } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { initialValues, block, currentIndex, isUpdating } = params! as Params

  const { urn } = useDashboardEditorContextProvider()
  const stepBack = (values?: any) => {
    if (values) {
      let updatedFields

      if (isUpdating) {
        updatedFields = block.properties?.fields.map(
          (c: any, index: number) => {
            if (index === currentIndex) return { ...c, ...values }
            return c
          }
        ) || []
      } else {
        updatedFields = [ ...(block.properties?.fields || []), values ]
      }

      updateBlock(urn, {
        ...block,
        properties: {
          ...block.properties,
          fields: updatedFields
        }
      })
    }

    selectBlock(block.id)
    stepBackDashboardEditor(2)
    openDashboardEditorView({
      target: Views.EDIT_BLOCK
    })
  }

  return (
    <>
      <DashboardEditorHeader
        subtitle={`${isUpdating ? 'Edit' : 'Add'} Field`}
        heading="Edit Form Block"
        onClose={onClose}
        onStepBack={stepBack}
      />
      <Form
        initialValues={initialValues}
        decorators={decorators}
        mutators={{
          ...arrayMutators
        }}
        onSubmit={stepBack}
        subscription={{ submitting: true, pristine: true }}
        render={({ handleSubmit, submitting, pristine }) => (
          <>
            <DashboardEditorBody>
              <Flex as="form" direction="column" onSubmit={handleSubmit} gap={16}>
                <Tabs wrapperClassName={classes.wrapper}>
                  <Tab index={0} label="General" alwaysMounted>
                    <Flex direction="column" gap={16}>
                      <TextField autoFocus shouldValidate settings={{ checkRequired: true }} name="name" label="Name" size="small" />
                      <TextField shouldValidate settings={{ checkRequired: true }} name="identifier" label="Identifier" size="small" />
                      <FormField
                        component={ToggleInput}
                        name="is_array"
                        label="Array"
                        helpText="Allow multiple values?"
                        size="small"
                        type="checkbox"
                      />
                      <FormField
                        component={ToggleInput}
                        name="is_nullable"
                        label="Nullable"
                        helpText="Allow blank values?"
                        size="small"
                        type="checkbox"
                      />
                    </Flex>
                  </Tab>
                  <Tab index={1} label="Appearance" alwaysMounted>
                    <FieldTypeConfig />
                  </Tab>
                </Tabs>
                <input type="submit" style={{ display: 'none' }} />
              </Flex>
            </DashboardEditorBody>
            <SidePaneFooter variant="small" isSticky>
              <Flex gap={24} direction="row-reverse">
                <Button size="small" type="submit" disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />
              </Flex>
            </SidePaneFooter>
          </>
        )}
      />
    </>
  )
}

export default AddFieldView
