import arrayMutators from 'final-form-arrays'
import kebabCase from 'lodash/kebabCase'
import omit from 'lodash/omit'
import React, { useState } from 'react'
import { Form, FormProps, useForm, useFormState } from 'react-final-form'
import { useRecoilValue } from 'recoil'
import type { FormApi } from 'final-form'

import Button from 'components/buttons/Button'
import ConditionalField from 'components/form/ConditionalField'
import CreateAttributeView from './CreateAttributeView'
import DashboardEditorBody from '../base/DashboardEditorBody'
import DashboardEditorHeader from '../base/DashboardEditorHeader'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import Parameter from 'models/Parameter'
import SelectInput from 'components/inputs/SelectInput'
import TextInput from 'components/inputs/TextInput'
import ToggleInput from 'components/inputs/ToggleInput'
import useDashboard, { DashboardEditorView } from 'hooks/useDashboard'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { Attribute, CreateParameterInput, ParametersListDocument, Relationship, UpdateParameterInput, useAttributesListQuery, useCreateParameterMutation, useRelationshipsListQuery, useUpdateParameterMutation, ValidationInput } from 'generated/schema'
import { DisplayType } from 'models/Attribute'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { Kind } from 'models/Relationship'
import { PARAMETERS_LIST_LIMIT } from 'models/Resource'
import { SidePaneFooter } from 'components/sidePane'
import { formatDateTimeValues } from 'components/displayTypes/DateTimeView'
import { formatValidations } from './AttributeValidations'
import type { ActiveViewProps } from '../DashboardEditor'
import type { ViewParams, Views } from '../constants'

type FormValues = CreateParameterInput | UpdateParameterInput

type Params = ViewParams[Views.CREATE_PARAMETER]

const setIdentifier = createSetIdentifier<FormValues>('name', 'identifier')

enum Mapping {
  ATTRIBUTE = 'ATTRIBUTE',
  RELATIONSHIP = 'RELATIONSHIP'
}

const decorators = [
  setIdentifier
]

const MappingOptions = [
  { label: 'Attribute', icon: 'tag', value: Mapping.ATTRIBUTE },
  { label: 'Relationship', icon: 'flow', value: Mapping.RELATIONSHIP }
]

const ParameterFieldsWithResourceId = ({ id: resourceId }: {id: string}) => {
  const form = useForm<FormValues>()
  const { initialValues } = useFormState()

  const {
    data: { attributesList = [] } = {},
    loading: attributesLoading
  } = useAttributesListQuery({
    variables: {
      filter: {
        resourceId: { eq: resourceId }
      }
    },
    skip: !resourceId
  })

  const {
    data: { relationshipsList = [] } = {},
    loading: relationshipsListLoading
  } = useRelationshipsListQuery({
    variables: {
      filter: {
        sourceId: { eq: resourceId },
        kind: { in: [ Kind.HAS_MANY, Kind.HAS_ONE ] }
      }
    },
    skip: !resourceId
  })

  const [ defaultName, setDefaultName ] = useState<string | null>(null)
  const [ defaultIdentifier, setDefaultIdentifier ] = useState<string | null>(null)
  const { relationshipId } = initialValues
  const containsId = 'id' in initialValues

  return (
    <>
      <FormField
        name="mapping"
        component={SelectInput}
        label="Mapping"
        options={MappingOptions}
        size="small"
        helpText="Map to an existing attribute or relationship to easily set up most settings."
        defaultValue={relationshipId ? Mapping.RELATIONSHIP : Mapping.ATTRIBUTE}
        isDisabled={containsId}
      />
      <ConditionalField when="mapping" is={Mapping.ATTRIBUTE}>
        <FormField
          isSearchable
          name="attributeId"
          component={SelectInput}
          label="Attribute"
          options={attributesList}
          size="small"
          valueKey="id"
          labelKey="name"
          isLoading={attributesLoading}
          onChange={(option: Attribute) => {
            setDefaultName(option.name)
            setDefaultIdentifier(kebabCase(option.name))
            form.change('attributeId', option.id)
          }}
          isDisabled={containsId}
        />
      </ConditionalField>
      <ConditionalField when="mapping" is={Mapping.RELATIONSHIP}>
        <FormField
          isSearchable
          name="relationshipId"
          component={SelectInput}
          label="Relationship"
          options={relationshipsList}
          size="small"
          valueKey="id"
          isLoading={relationshipsListLoading}
          getOptionLabel={(option: Relationship) => `${option.source.name} → ${option.name}`}
          onChange={(option: Relationship) => {
            setDefaultName(option.name)
            setDefaultIdentifier(kebabCase(option.name))
            form.change('relationshipId', option.id)
          }}
          isDisabled={containsId}
        />
      </ConditionalField>
      <FormField
        component={TextInput}
        name="name"
        label="Name"
        size="small"
        type="text"
        defaultValue={defaultName}
      />
      <FormField
        component={TextInput}
        name="identifier"
        label="Identifier"
        size="small"
        type="text"
        defaultValue={defaultIdentifier}
      />
      <ConditionalField when="mapping" is={Mapping.ATTRIBUTE}>
        <FormField
          name="isArray"
          component={ToggleInput}
          label="Array"
          helpText="Allow multiple values?"
          type="checkbox"
        />
        <FormField
          name="isNullable"
          component={ToggleInput}
          label="Nullable"
          helpText="Allow blank values?"
          type="checkbox"
        />
      </ConditionalField>
    </>
  )
}

const CreateParameterView = ({ onClose }: ActiveViewProps) => {
  const { dashboardEditorState, stepBackDashboardEditor } = useDashboard()
  const {
    params = {}
  } = useRecoilValue<DashboardEditorView<Views.CREATE_PARAMETER>>(
    dashboardEditorState
  )

  const {
    initialValues: parameter,
    operation,
    app,
    workspace,
    resource,
    currentIndex,
    block,
    view,
    ...rest
  } = params

  const { id: operationId } = operation || {}
  const { id: resourceId } = resource || {}

  const isUpdating = 'currentIndex' in params
  let formattedValidations = parameter?.validations || []

  let formattedInitialValues = parameter as FormValues
  if (parameter?.displayType && parameter.displayType === DisplayType.DATE_TIME) {
    formattedInitialValues = formatDateTimeValues(parameter as any) as FormValues
  }

  if (parameter?.validations?.length) {
    formattedValidations = formatValidations(parameter?.validations as ValidationInput[])
  }
  const queryVariables = {
    filter: {
      ...(operationId ? { operationId: { eq: operationId } } : {})
    },
    limit: PARAMETERS_LIST_LIMIT
  }

  const [ createParameter ] = useCreateParameterMutation({
    onCompleted: () => stepBackDashboardEditor(1, { block }),
    refetchQueries: [ { query: ParametersListDocument, variables: queryVariables } ]
  })

  const [ updateParameter ] = useUpdateParameterMutation({
    onCompleted: () => stepBackDashboardEditor(1, { block }),
    refetchQueries: [ { query: ParametersListDocument, variables: queryVariables } ]
  })

  const handleCreateParameter = useSubmitHandler(createParameter, {
    successAlert: { message: 'Parameter Created.' }
  })

  const handleUpdateParameter = useSubmitHandler(updateParameter, {
    successAlert: { message: 'Parameter Updated.' }
  })

  const handleSubmit = (values: FormValues, form: FormProps<FormValues>['form']) => {
    const cleanValues = omit(values, 'mapping', 'dataType', 'dataTypeKind', 'enumId', 'objectId', 'unionId')

    if (operationId) {
      if (isUpdating) {
        return handleUpdateParameter(
          cleanValues as UpdateParameterInput,
          form as FormApi<UpdateParameterInput>
        )
      }

      return handleCreateParameter(cleanValues as CreateParameterInput)
    }

    let updatedParams
    let updatedVariables

    if (isUpdating) {
      updatedParams = operation?.parameters.map(
        (p: any, index: number) => {
          if (index === currentIndex) {
            return { ...p, ...cleanValues }
          }
          return p
        }
      ) || []

      updatedVariables = view?.variables.map(
        (p: any, index: number) => {
          if (index === currentIndex) {
            return { ...p, ...cleanValues }
          }
          return p
        }
      ) || []
    } else {
      updatedParams = [ ...(operation?.parameters || []), cleanValues ]
      updatedVariables = [ ...(view?.variables || []), cleanValues ]
    }

    stepBackDashboardEditor(1, {
      app,
      workspace,
      resource,
      currentIndex,
      block,
      ...rest,
      operation: {
        ...operation,
        parameters: updatedParams
      },
      view: {
        ...view,
        variables: updatedVariables
      }
    })

    return Promise.resolve()
  }

  return (
    <>
      <DashboardEditorHeader
        heading={`Operation: ${operation?.name || 'Create New'}`}
        subtitle={`${isUpdating ? 'Update' : 'Add'} Parameter`}
        onStepBack={() => {
          stepBackDashboardEditor(1, {
            app,
            workspace,
            resource,
            operation,
            block
          })
        }}
        onClose={onClose}
      />
      <Form
        decorators={decorators}
        mutators={{
          ...arrayMutators
        }}
        initialValues={{
          operationId,
          defaultValue: {
            value: null,
            type: 'fixed'
          },
          ...formattedInitialValues as FormValues,
          // @ts-ignore
          fieldTypeSettings: formattedInitialValues?.fieldTypeSettings || {},
          displayTypeSettings: formattedInitialValues?.displayTypeSettings || {},
          validations: formattedValidations,
          // @ts-ignore - required for UI but gets omitted out while submission
          // eslint-disable-next-line max-len
          dataTypeKind: formattedInitialValues?.dataTypeKind || formattedInitialValues?.dataType?.kindformattedInitialValues?.dataType?.kind
        }}
        keepDirtyOnReinitialize
        onSubmit={handleSubmit}
        subscription={{
          submitting: true
        }}
        validate={(values) => Parameter.validate(values, [ 'identifier', 'name' ])}
        render={({ handleSubmit, submitting }) => (
          <>
            <DashboardEditorBody>
              <Flex as="form" direction="column" gap={16} onSubmit={handleSubmit}>
                {resourceId && (<ParameterFieldsWithResourceId id={resourceId} />)}
                {!resourceId && (<CreateAttributeView.FinalStep isUpdating={isUpdating} />)}
                <input type="submit" style={{ display: 'none' }} />
              </Flex>
            </DashboardEditorBody>
            <SidePaneFooter variant="small" isSticky>
              <Flex gap={16} direction="row-reverse">
                <Button size="small" type="submit" label="Submit" disabled={submitting} onClick={handleSubmit} />
              </Flex>
            </SidePaneFooter>
          </>
        )}
      />
    </>
  )
}

export default CreateParameterView
