import React, { useMemo } from 'react'
import type { SetStateAction } from 'react'

import Block from 'components/blocks/Block'
import CustomizeDisplay from 'components/dataWidgets/CustomizeDisplay'
import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'
import DataTable from 'components/dataTable/DataTable'
import Flex from 'components/layout/Flex'
import SectionLoader from 'components/loaders/SectionLoader'
import Toolbar from 'components/toolbar/Toolbar'
import useFilterColumns from 'hooks/useFilterColumns'
import { parseAndRenderSync } from 'lib/templater'
import { useDashboardQuery } from 'components/contexts/DashboardQueryContext'
import { useMasonryContext } from 'components/layout/Masonry'
import type { BlockProps } from 'components/blocks/Block'
import type { Column } from 'components/dataTable/types'
import type { FilterType } from 'components/dataWidgets/CustomizeDisplay'
import type { DataTableProps } from 'components/dataTable/DataTable'
import type { LoaderProps } from 'components/loaders/Loader'
import type { RowAction } from 'components/dataWidgets/RowActions'
import type { SummaryBarProps } from 'components/dataWidgets/SummaryBar'
import type { ToolbarProps } from 'components/toolbar/Toolbar'

type DataTableBlockProps = BlockProps
& ToolbarProps
& LoaderProps
& DataTableProps<any>
& Pick<SummaryBarProps<any>, 'batchActions' | 'hideFormulae' | 'hideSelectAll'>
& {
  actions: RowAction[],
  asFragment?: boolean,
  batchActions?: SummaryBarProps<any>['batchActions'],
  columns: Column<any>[],
  filters?: FilterType,
  setFilters?: React.Dispatch<SetStateAction<FilterType>>,
  data: any[],
  summaryBarVariant?: SummaryBarProps<any>['variant'],
  onRowClick?: (record: any, index: number) => void
}

function DataTableBlock({ asFragment, ...props }: DataTableBlockProps) {
  return asFragment
    ? <DataTableContent {...props} />
    : (
      <DataTableBlockWrapped {...props} />
    )
}

const DataTableContent = ({
  actions,
  batchActions,
  columns,
  data,
  defaultSelection,
  defaultOrder,
  empty,
  error,
  hideFormulae,
  hideSelectAll,
  loading,
  onChangePage,
  onChangePageSize,
  onRowClick,
  onRowSelect,
  onRowDragEnd,
  page,
  pageSize,
  pageSizeOptions,
  paginationMode,
  primaryElements,
  secondaryElements,
  selectionHandlerRef,
  selectionMode,
  setOrder,
  filters,
  setFilters,
  summaryBarVariant,
  title,
  totalRows,
  customizeDisplayProps,
  containerPadding,
  compact,
  ...other
}: DataTableBlockProps) => {
  const result = useDashboardQuery()
  const Loader = compact ? DashboardEditorLoader : SectionLoader
  const parsedData = useMemo(() => (
    typeof data === 'string' ? JSON.parse(parseAndRenderSync(data, result) || '[]') : data
  ), [ data, result ])

  const isFilteringEnabled = filters && setFilters

  const [ filteredColumns, toggleVisibility ] = useFilterColumns({ columns })

  const secondaryElementsWithCustomizeDisplay = (
    <>
      {isFilteringEnabled && (
        <CustomizeDisplay
          columns={filteredColumns}
          disabled={loading && !parsedData?.length}
          filters={filters}
          onFilterChange={setFilters}
          onVisibilityToggle={toggleVisibility}
        />
      )}
      {secondaryElements}
    </>
  )

  const resolvedColumns = isFilteringEnabled ? filteredColumns : columns

  return (
    <Flex
      direction="column"
    >
      <Toolbar
        primaryElements={primaryElements}
        secondaryElements={secondaryElementsWithCustomizeDisplay}
        title={title}
      />

      <Loader
        data={resolvedColumns}
        empty={empty}
        error={error}
        loading={loading}
      >
        <DataTable
          compact={compact}
          actions={actions}
          batchActions={batchActions}
          columns={resolvedColumns}
          containerPadding={other.fullWidth ? containerPadding : 0}
          data={parsedData}
          loading={loading}
          error={error}
          empty={empty}
          defaultSelection={defaultSelection}
          defaultOrder={defaultOrder}
          hideFormulae={hideFormulae}
          hideSelectAll={hideSelectAll}
          onChangePage={onChangePage}
          onChangePageSize={onChangePageSize}
          onRowClick={onRowClick}
          onRowSelect={onRowSelect}
          onRowDragEnd={onRowDragEnd}
          page={page}
          pageSize={pageSize}
          pageSizeOptions={pageSizeOptions}
          paginationMode={paginationMode}
          selectionHandlerRef={selectionHandlerRef}
          selectionMode={selectionMode}
          setOrder={setOrder}
          summaryBarVariant={summaryBarVariant}
          totalRows={totalRows}
        />
      </Loader>
    </Flex>
  )
}

const DataTableBlockWrapped = (props: DataTableBlockProps) => {
  const { containerPadding } = useMasonryContext()

  return (
    <Block
      name="Data Table"
      direction="column"
      width={{ md: '100%' }}
      {...props}
    // eslint-disable-next-line react/destructuring-assignment
      css={{ paddingX: props.fullWidth ? containerPadding : 0 }}
    >
      <DataTableContent containerPadding={containerPadding} {...props} />
    </Block>
  )
}

export type {
  DataTableBlockProps
}

export default DataTableBlock
