import React, { useRef, useState } from 'react'
import type HotTableClass from '@handsontable/react/hotTableClass'
import type { CellChange, ChangeSource } from 'handsontable/common'
import type { FieldRenderProps } from 'react-final-form'

import Button from 'components/buttons/Button'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import InputHelpText from 'components/inputHelpText/InputHelpText'
import mapArraysToObjects from 'lib/mapArraysToObjects'

const ImportRecordsDialog = React.lazy(() => import('components/views/graph/ImportRecordsDialog'))
const CSVEditor = React.lazy(() => import('components/views/graph/ImportRecordsDialog').then((module) => ({ default: module.Preview })))

type CSVInputOwnProps = {
  disabled?: boolean,
  helpText?: string,
  isTranslatable?: boolean,
  headers?: any[]
}

type CSVInputProps = FieldRenderProps<any, any> & CSVInputOwnProps

function CsvInput({
  helpText,
  input,
  isTranslatable,
  checkRequired = false,
  label,
  headers: initialHeaders = []
}: CSVInputProps) {
  const [ isOpen, setOpen ] = useState(false)

  const [ headers, setHeaders ] = useState(initialHeaders)

  const handleSubmit = async (
    { headers, rows }: {headers: string[], rows: any[], mappings: (string | null)[]}
  ) => {
    const mappedRows = mapArraysToObjects(headers, rows)
    setHeaders(headers)
    input.onChange(mappedRows)
  }

  const handleChange = (changes: CellChange[] | null, source: ChangeSource) => {
    if (source === 'edit') {
      const data = hotRef.current?.hotInstance?.getData()

      const filledRows: any[] = []

      data?.forEach((row) => {
        if (row.filter(Boolean).length) {
          filledRows.push(row)
        }
      })

      if (filledRows.length) {
        const mappedRows = mapArraysToObjects(headers, filledRows)
        input.onChange(mappedRows)
      }
    }
  }

  const parameters = headers?.map((h: any) => ({ name: h, identifier: h })) || []
  const hotRef = useRef<HotTableClass>(null)

  return (
    <Flex direction="column" gap={10}>
      {label && (
        <FieldLabel isTranslatable={isTranslatable}>
          {label}{checkRequired && <span> *</span>}
        </FieldLabel>
      )}
      <Button
        onClick={() => setOpen(true)}
        label="Upload CSV"
        mode="subtle"
        size="small"
      />
      <React.Suspense fallback={null}>
        <ImportRecordsDialog
          title="Import"
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          onSubmit={handleSubmit}
          parameters={parameters}
        />

        <Flex direction="column" css={{ height: 400 }}>
          <CSVEditor
            data={input.value
              // https://github.com/handsontable/handsontable/issues/10518
              ? JSON.parse(JSON.stringify(
                input.value.map((row: any) => headers.map((header: string) => row[header]))
              ))
              : [ [] ]}
            headers={headers}
            mappings={headers}
            parameters={parameters}
            hotRef={hotRef}
            disableValidationUI
            afterChange={handleChange}
          />
        </Flex>
      </React.Suspense>
      {helpText && <InputHelpText helpText={helpText} />}
    </Flex>
  )
}

export type {
  CSVInputProps,
  CSVInputOwnProps
}

export default CsvInput
