import arrayMutators from 'final-form-arrays'
import omit from 'lodash/omit'
import React, { useState } from 'react'
import uuid from 'uuid-random'
import { Form, useField } from 'react-final-form'
import { useRecoilValue } from 'recoil'

import Button from 'components/buttons/Button'
import CreateAttributeView from './CreateAttributeView'
import DashboardEditorBody from '../base/DashboardEditorBody'
import DashboardEditorHeader from '../base/DashboardEditorHeader'
import Flex from 'components/layout/Flex'
import Parameter from 'models/Parameter'
import Text from 'components/typography/Text'
import useDashboard, { DashboardEditorView } from 'hooks/useDashboard'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { DisplayType, ResolutionKind } from 'models/Attribute'
import { formatDateTimeValues } from 'components/displayTypes/DateTimeView'
import { formatValidations } from './AttributeValidations'
import { SidePaneFooter, SidePaneSubHeader } from 'components/sidePane'
import { useDataTypeQuery, ValidationInput } from 'generated/schema'
import type { ActiveViewProps } from '../DashboardEditor'
import type { ViewParams, Views } from '../constants'
import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'

type FormValues = any

type Params = ViewParams[Views.CREATE_DATA_TYPE_FIELD]

const setIdentifier = createSetIdentifier<FormValues>('name', 'identifier')

const decorators = [
  setIdentifier
]

const stepSubTitles = [
  'Step 1: Select field type',
  'Step 2: Enter details'
]

const DataTypeFieldTypes = [
  {
    name: 'DashX Field',
    icon: 'table',
    description: 'Fetched & stored within DashX',
    value: ResolutionKind.LOCAL
  },
  {
    name: 'Computed Field',
    icon: 'app-database-hub',
    description: 'Computed on-the-fly from other fields',
    value: ResolutionKind.COMPUTED
  }
]

const CreateDataTypeFieldForm = ({
  handleSubmit,
  pristine,
  submitting,
  isUpdating,
  appId
}: {
  handleSubmit: (values: FormValues) => void,
  pristine: boolean,
  submitting: boolean,
  isUpdating: boolean,
  appId?: string
}) => {
  const resolutionKindField = useField<ResolutionKind | null>('resolutionKind')
  const resolutionKind = resolutionKindField.input.value
  const [ currentStep, setCurrentStep ] = useState(resolutionKind ? 1 : 0)

  const isFirstStep = currentStep === 0
  const isFinalStep = currentStep === 1

  const handleStepForward = () => {
    setCurrentStep(currentStep + 1)
  }

  const handleStepBehind = () => {
    setCurrentStep(currentStep - 1)
  }

  return (
    <>
      {!isUpdating && (
      <SidePaneSubHeader size="small">
        <Text fontWeight="bold">{stepSubTitles[currentStep]}</Text>
      </SidePaneSubHeader>
      )}
      <DashboardEditorBody css={isFinalStep ? { paddingTop: 0 } : {}}>
        <Flex as="form" direction="column" onSubmit={handleSubmit}>
          {isFirstStep && (
            <CreateAttributeView.FirstStep
              typeOptions={DataTypeFieldTypes}
              handleStepForward={handleStepForward}
            />
          )}
          {isFinalStep && (
            <CreateAttributeView.FinalStep
              appId={appId}
              isUpdating={isUpdating}
              isTranslatable
            />
          )}
          <input type="submit" style={{ display: 'none' }} />
        </Flex>
      </DashboardEditorBody>
      <SidePaneFooter variant="small" isSticky>
        <Flex gap={16} direction="row-reverse">
          {isFinalStep && <Button size="small" type="submit" disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />}
          {!isFinalStep && (
            <Button
              disabled={(isFirstStep && !resolutionKind)}
              icon="arrow-right"
              onClick={handleStepForward}
              size="small"
            />
          )}
          {!isFirstStep && !isUpdating && <Button size="small" icon="arrow-left" onClick={handleStepBehind} />}
        </Flex>
      </SidePaneFooter>
    </>
  )
}

const CreateDataTypeFieldView = ({ onClose }: ActiveViewProps) => {
  const { dashboardEditorState, stepBackDashboardEditor } = useDashboard()
  const {
    params = {} as Params
  } = useRecoilValue<DashboardEditorView<Views.CREATE_DATA_TYPE_FIELD>>(
    dashboardEditorState
  )

  const {
    initialValues = {},
    parentDataType: dataType,
    currentIndex
  } = params || {} as Params

  const fields = dataType?.settings?.fields || []

  const isUpdating = 'currentIndex' in (params || {})
  let formattedValidations = fields?.[currentIndex!]?.validations || []

  let formattedInitialValues = { ...initialValues, ...fields[currentIndex!] || {} } as FormValues
  if (fields?.displayType && fields.displayType === DisplayType.DATE_TIME) {
    formattedInitialValues = formatDateTimeValues(fields as any) as FormValues
  }

  if (fields?.validations?.length) {
    formattedValidations = formatValidations(fields?.validations as ValidationInput[])
  }

  const initialDataTypeId = formattedInitialValues?.dataTypeId

  // query dataType object if not present in initial values
  const { data, loading } = useDataTypeQuery({
    variables: {
      id: initialDataTypeId
    },
    skip: formattedInitialValues.dataType || !initialDataTypeId
  })

  formattedInitialValues = {
    dataType: formattedInitialValues.dataType || data?.dataType,
    ...formattedInitialValues
  }

  const handleSubmit = (values: FormValues) => {
    const cleanValues = {
      id: uuid(),
      ...omit(values, 'mapping', 'dataType', 'dataTypeKind', 'enumId', 'objectId', 'unionId')
    }

    let updatedFields

    if (isUpdating) {
      updatedFields = dataType?.settings.fields.map(
        (p: any, index: number) => {
          if (index === currentIndex) {
            return { ...p, ...cleanValues }
          }
          return p
        }
      ) || []
    } else {
      updatedFields = [ ...(dataType?.settings?.fields || []), cleanValues ]
    }

    stepBackDashboardEditor(1, {
      initialValues: {
        ...(params.parentDataType || {}),
        settings: {
          ...(params.parentDataType || {})?.settings,
          fields: updatedFields
        }
      }
    })
  }

  return (
    <>
      <DashboardEditorHeader
        heading={`Data Type: ${dataType?.name || 'New'}`}
        subtitle={`${isUpdating ? 'Update' : 'Add'} Field`}
        onClose={onClose}
      />
      <DashboardEditorLoader
        data={initialDataTypeId ? formattedInitialValues.dataType : formattedInitialValues}
        loading={loading}
      >
        <Form
          decorators={decorators}
          mutators={{
            ...arrayMutators
          }}
          initialValues={{
            defaultValue: {
              value: null,
              type: 'fixed'
            },
            isTranslatable: false,
            // data type fields are JSON arrays and are positioned according to array index
            position: currentIndex ?? -1,
            ...(isUpdating ? {} : { resolutionSettings: {} }),
            ...formattedInitialValues as FormValues,
            dataTypeSettings: formattedInitialValues?.dataTypeSettings || {},
            fieldTypeSettings: formattedInitialValues?.fieldTypeSettings || {},
            displayTypeSettings: formattedInitialValues?.displayTypeSettings || {},
            validations: formattedValidations,
            // @ts-ignore - required for UI but gets omitted out while submission
            // eslint-disable-next-line max-len
            dataTypeKind: formattedInitialValues?.dataTypeKind || formattedInitialValues?.dataType?.kind
          }}
          keepDirtyOnReinitialize
          onSubmit={handleSubmit}
          subscription={{
            submitting: true
          }}
          validate={(values) => Parameter.validate(values, [ 'identifier', 'name' ])}
          render={(props) => (
            <CreateDataTypeFieldForm
              appId={params.parentDataType?.appId}
              {...props}
              isUpdating={isUpdating}
            />
            // <>
            //   <DashboardEditorBody>
            //     <Flex as="form" direction="column" gap={16} onSubmit={handleSubmit}>
            //       <CreateAttributeView.FinalStep isUpdating={isUpdating} />
            //       <input type="submit" style={{ display: 'none' }} />
            //     </Flex>
            //   </DashboardEditorBody>
            //   <SidePaneFooter variant="small" isSticky>
            //     <Flex gap={16} direction="row-reverse">
            //       <Button size="small" type="submit" label="Submit"
            // disabled={submitting} onClick={handleSubmit} />
            //     </Flex>
            //   </SidePaneFooter>
            // </>
          )}
        />
      </DashboardEditorLoader>
    </>
  )
}

export default CreateDataTypeFieldView
