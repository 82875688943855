import React, { useRef } from 'react'
import { useField } from 'react-final-form'
import { useRecoilValue } from 'recoil'
import type { OnDragEndResponder } from 'react-beautiful-dnd'

import DataList from 'components/dataList/DataList'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import TextRenderer from 'components/renderers/TextRenderer'
import ToggleInput from 'components/inputs/ToggleInput'
import useDashboard from 'hooks/useDashboard'
import { ActionType, Views } from './constants'
import { FieldArrayChildrenProps, useFieldArray } from 'components/form/FieldArray'

const ActionsList = ({
  loading,
  type,
  trigger,
  disableTrigger
}: {
  loading?: boolean,
  type: ActionType,
  trigger?: string,
  disableTrigger?: boolean
}) => {
  const {
    openDashboardEditorView, selectedBlockState, dashboardEditorState, blockState
  } = useDashboard()
  const { params: { block = null } = {} } = useRecoilValue<any>(
    dashboardEditorState
  )
  // eslint-disable-next-line no-underscore-dangle
  const _selectedBlock = useRecoilValue(selectedBlockState)! || block
  const selectedBlock = useRecoilValue(blockState(_selectedBlock.id))

  const fieldName = 'actions'
  const field = useField(fieldName)
  const fieldsRef = useRef<FieldArrayChildrenProps<any>>()
  useFieldArray({ name: 'actions', fieldsRef, subscription: {} })

  const computedList = (field.input.value || [])
    .map((action: any, originalIndex: number) => ({ ...action, originalIndex }))
    .filter((action: any) => action.kind === type)

  const onDragEnd: OnDragEndResponder = (result) => {
    if (
      !fieldsRef.current?.fields
      || !result.destination
      || result.destination.index === result.source.index
    ) { return }

    const sourceIndex = computedList[result.source.index]?.originalIndex
    const destinationIndex = computedList[result.destination.index]?.originalIndex

    fieldsRef.current.fields.move(sourceIndex, destinationIndex)
  }

  return (
    <Flex direction="column" gap={16}>
      <Flex justifyContent="space-between" gap={16}>
        <Text
          color="dark500"
          fontSize={10}
          fontWeight="bold"
          textTransform="uppercase"
        >
          Actions
        </Text>
        <TextLink
          as="button"
          type="button"
          fontSize={10}
          onClick={() => {
            openDashboardEditorView({
              target: Views.ADD_ACTION,
              params: {
                kind: type,
                index: field.input.value.length,
                trigger,
                disableTrigger,
                block: selectedBlock
              }
            })
          }}
          mode="distinct"
        >
          Add new
        </TextLink>
      </Flex>
      <DataList
        compact
        onRowDragEnd={onDragEnd}
        loading={loading}
        contents={[
          {
            dataKey: 'operation',
            slot: 'primary',
            renderer: ({ rowData }) => <TextRenderer rowData={rowData} dataKey="name" />
          },
          {
            dataKey: 'is_hidden',
            slot: 'toggle',
            renderer: ({ rowData }) => (
              <FormField
                invert
                type="checkbox"
                component={ToggleInput}
                name={`actions[${rowData.originalIndex}].is_hidden`}
              />
            )
          }
        ]}
        actions={[
          {
            icon: 'edit',
            title: 'Edit',
            visibilityFilter: (action) => !action.is_system,
            onClick: ({ originalIndex, ...action }: any) => openDashboardEditorView({
              target: Views.ADD_ACTION,
              params: {
                ...action,
                index: originalIndex,
                disableTrigger,
                block: selectedBlock
              }
            })
          },
          {
            icon: 'trash',
            title: 'Delete',
            visibilityFilter: (action) => !action.is_system,
            onClick: ({ originalIndex }: any) => field.input.onChange(
              field.input.value.filter((_: any, index: number) => index !== originalIndex)
            )
          },
          {
            icon: 'pad-lock',
            title: 'System action',
            visibilityFilter: (action) => action.is_system
          }
        ]}
        data={computedList}
        selectionMode="none"
      />
    </Flex>
  )
}

export default ActionsList
