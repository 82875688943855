import React from 'react'
import startCase from 'lodash/startCase'
import lowerCase from 'lodash/lowerCase'
import { defaultDataIdFromObject } from '@apollo/client'
import { useRecoilValue } from 'recoil'

import { Form } from 'react-final-form'

import client from 'client'
import DashboardEditorBody from 'components/dashboardEditor/base/DashboardEditorBody'
import DashboardEditorHeader from 'components/dashboardEditor/base/DashboardEditorHeader'
import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import MediaCard from 'components/mediaCard/MediaCard'
import Text from 'components/typography/Text'
import useDashboard, { DashboardEditorView } from 'hooks/useDashboard'
import { BLOCK_TYPE_TO_ICON_MAP, Views } from 'components/dashboardEditor/constants'
import { Parameters } from './graph/CreateOperationView'
import { useDashboardEditorContextProvider } from './DashboardEditorProvider'
import { useUpsertViewMutation, View, ViewFragmentFragmentDoc } from 'generated/schema'
import type { ActiveViewProps } from 'components/dashboardEditor/DashboardEditor'
import type { BlockType } from 'components/blocks'
import useSubmitHandler from 'hooks/useSubmitHandler'

const ViewDetailsView = ({ onClose }: ActiveViewProps) => {
  const { dashboardEditorState, openDashboardEditorView } = useDashboard()
  const {
    params: { view } = {}
  } = useRecoilValue<DashboardEditorView<Views.VIEW_DETAILS>>(dashboardEditorState)
  const { urn } = useDashboardEditorContextProvider()
  const [ upsertView ] = useUpsertViewMutation()
  const handleUpsertView = useSubmitHandler(upsertView, { successAlert: { message: 'Your changes were saved.' } })

  if (!view) return null

  const isStackedView = 'component' in view

  if (isStackedView) {
    const { style, title } = view

    return (
      <>
        <DashboardEditorHeader
          subtitle={`View: ${title}`}
          heading="Dashboard Editor"
          onClose={onClose}
        />
        <DashboardEditorBody>
          <Flex gap={16} direction="column">
            <Text
              color="dark500"
              fontSize={10}
              fontWeight="bold"
              textTransform="uppercase"
            >
              Name
            </Text>
            <Text>{title}</Text>
          </Flex>
          <Flex gap={16} direction="column">
            <Text
              color="dark500"
              fontSize={10}
              fontWeight="bold"
              textTransform="uppercase"
            >
              Default Style
            </Text>
            <Flex gap={6} alignItems="center">
              <Icon name="page-outline" />
              <Text>{startCase(lowerCase(style)) || 'Page'}</Text>
            </Flex>
          </Flex>
        </DashboardEditorBody>
      </>
    )
  }

  const initialValues: View = view.id
    ? client.cache.readFragment({
      fragment: ViewFragmentFragmentDoc,
      fragmentName: 'ViewFragment',
      id: defaultDataIdFromObject(view)
    }) || view : view

  const { name, app, resource, variables } = initialValues
  const viewName = name || app?.name || resource?.name

  return (
    <>
      <DashboardEditorHeader
        subtitle={`View: ${viewName}`}
        heading="Dashboard Editor"
        onEdit={() => openDashboardEditorView({
          target: Views.EDIT_VIEW,
          params: {
            view: initialValues
          }
        })}
        onClose={onClose}
      />
      <DashboardEditorBody>
        <Flex gap={16} direction="column">
          <Text
            color="dark500"
            fontSize={10}
            fontWeight="bold"
            textTransform="uppercase"
          >
            Name
          </Text>
          <Text>{viewName}</Text>
        </Flex>
        <Flex gap={16} direction="column">
          <Text
            color="dark500"
            fontSize={10}
            fontWeight="bold"
            textTransform="uppercase"
          >
            Default Style
          </Text>
          <Flex gap={6} alignItems="center">
            <Icon name="page-outline" />
            <Text>Page</Text>
          </Flex>
        </Flex>
        <Blocks />
        <Operations />
        <Form
          initialValues={{ urn, name, variables: variables || [] }}
          onSubmit={handleUpsertView}
          render={() => (
            <>
              <Parameters
                {...{
                  app,
                  resource,
                  view: initialValues
                }}
              />
            </>
          )}
        />
        <Actions />
      </DashboardEditorBody>
    </>
  )
}

const BlockCard = ({ urn, id }: any) => {
  const { blockState, openDashboardEditorView, removeBlock, selectBlock } = useDashboard()
  const block = useRecoilValue(blockState(id))

  const onClick = () => {
    selectBlock(id)
    openDashboardEditorView({
      target: Views.EDIT_BLOCK
    })
  }

  return (
    <MediaCard
      compact
      onClick={onClick}
      key={id}
      media={BLOCK_TYPE_TO_ICON_MAP[block.type as BlockType]}
      title={startCase(block.type)}
      height={64}
      width="full"
      actions={[
        {
          description: 'Delete',
          icon: 'trash',
          onClick: () => {
            removeBlock(urn, id)
          }
        },
        {
          description: '',
          icon: 'arrow-right',
          onClick,
          isIconAlwaysVisible: true
        }
      ]}
    />
  )
}

const Blocks = () => {
  const { blockIds: blockIdsState } = useDashboard()
  const { urn } = useDashboardEditorContextProvider()

  const blockIds = useRecoilValue(blockIdsState(urn))

  return (
    <Flex gap={16} direction="column">
      <Text
        color="dark500"
        fontSize={10}
        fontWeight="bold"
        textTransform="uppercase"
      >
        Blocks
      </Text>
      <DashboardEditorLoader
        empty={{
          variant: 'simple',
          element: (
            <Flex alignItems="center" direction="column">
              <Text fontSize={14} color="dark500">Nothing to show here.</Text>
            </Flex>
          )
        }}
        data={blockIds}
      >
        <Flex direction="column" gap={2}>
          {blockIds?.map((blockId) => <BlockCard key={blockId} id={blockId} urn={urn} />)}
        </Flex>
      </DashboardEditorLoader>
    </Flex>
  )
}

const Operations = () => (
  <Flex gap={16} direction="column">
    <Text
      color="dark500"
      fontSize={10}
      fontWeight="bold"
      textTransform="uppercase"
    >
      Operations
    </Text>
    <DashboardEditorLoader
      empty={{
        variant: 'simple',
        element: (
          <Flex alignItems="center" direction="column">
            <Text fontSize={14} color="dark500">Nothing to show here.</Text>
          </Flex>
        )
      }}
      data={[]}
    >
      <Flex />
    </DashboardEditorLoader>
  </Flex>
)

const actions = [
  {
    title: 'Add Block',
    identifier: Views.ADD_BLOCK,
    media: 'block'
  },
  {
    title: 'Add Operation',
    identifier: Views.ADD_VIEW_OPERATION,
    media: 'advance'
  }
]

const Actions = () => {
  const { openDashboardEditorView } = useDashboard()

  return (
    <Flex gap={16} direction="column">
      <Text
        color="dark500"
        fontSize={10}
        fontWeight="bold"
        textTransform="uppercase"
      >
        Actions
      </Text>
      <Flex direction="column" gap={2}>
        {actions.map((action) => {
          const { media, title, identifier } = action

          const onClick = () => openDashboardEditorView({
            target: identifier,
            params: {}
          })

          return (
            <MediaCard
              compact
              key={action.identifier}
              media={media}
              title={title}
              height={64}
              width="full"
              onClick={onClick}
              actions={[ {
                description: '',
                icon: 'arrow-right',
                isIconAlwaysVisible: true,
                onClick
              } ]}
            />
          )
        })}
      </Flex>
    </Flex>
  )
}

export default ViewDetailsView
