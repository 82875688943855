import get from 'lodash/get'
import React, { useCallback, useLayoutEffect, useRef } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import AccountNotificationsPage from 'components/pages/AccountNotificationsPage'
import AccountProfilePage from 'components/pages/AccountProfilePage'
import AccountSettingsPage from 'components/pages/AccountSettingsPage'
import AccountsPage from 'components/pages/AccountsPage'
import AppAccessPage from 'components/pages/AppAccessPage'
import AppError from 'components/errors/AppError'
import AppLoader from 'components/loaders/AppLoader'
import AppOverviewPage from 'components/pages/AppOverviewPage'
import AppPage from 'components/pages/AppPage'
import AppSchemaPage from 'components/pages/AppSchemaPage'
import AppSettingsPage from 'components/pages/AppSettingsPage'
import AppsPage from 'components/pages/AppsPage'
import BillingPage from 'components/pages/BillingPage'
import BrandingPage from 'components/pages/BrandingPage'
import CompleteOnboardingPage from 'components/pages/CompleteOnboardingPage'
import CurrentAccountProvider from 'components/contexts/CurrentAccountContext'
import DashboardPage from 'components/pages/DashboardPage'
import DashboardsPage from 'components/pages/DashboardsPage'
import DeveloperPage from 'components/pages/DeveloperPage'
import DomainPage from 'components/pages/DomainPage'
import EnvironmentsPage from 'components/pages/EnvironmentsPage'
import ExternalRoute from 'components/routes/ExternalRoute'
import ForgotPasswordPage from 'components/pages/ForgotPasswordPage'
import generateTheme, { DEFAULT_THEME } from 'lib/generateTheme'
import GraphPage from 'components/pages/GraphPage'
import GroupsPage from 'components/pages/GroupsPage'
import IntegrationsPage from 'components/pages/IntegrationPage'
import InternalRoute from 'components/routes/InternalRoute'
import LocalesPage from 'components/pages/LocalesPage'
import LoginPage from 'components/pages/LoginPage'
import LogoutPage from 'components/pages/LogoutPage'
import NotFoundPage from 'components/pages/NotFoundPage'
import OnboardingRoute from 'components/routes/OnboardingRoute'
import OverviewPage from 'components/pages/OverviewPage'
import ProductsPage from 'components/pages/ProductsPage'
import ResetPasswordPage from 'components/pages/ResetPasswordPage'
import RolesPage from 'components/pages/RolesPage'
import RootPage from 'components/pages/RootPage'
import SettingsPage from 'components/pages/SettingsPage'
import SpacesPage from 'components/pages/SpacesPage'
import StartOnboardingPage from 'components/pages/StartOnboardingPage'
import TourProvider from 'components/providers/TourProvider'
import VerifyMfaPage from 'components/pages/VerifyMfaPage'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import WorkspaceRoute from 'components/routes/WorkspaceRoute'
import { hostname } from 'lib/hostname'
import { useWorkspaceContextQuery } from 'generated/schema'
import type { ThemePalette } from 'generated/schema'

function WorkspaceContainer() {
  const themeRef = useRef<string | null>(null)

  const changeThemePalette = useCallback(
    (palette: ThemePalette) => generateTheme(palette, themeRef), []
  )

  const { data, error, loading } = useWorkspaceContextQuery({
    variables: { hostname }
  })

  const resetThemePalette = () => generateTheme(
    data?.workspace?.theme?.palette || DEFAULT_THEME.palette,
    themeRef
  )

  const resetThemePaletteRef = useRef(resetThemePalette)

  useLayoutEffect(() => {
    resetThemePaletteRef.current = resetThemePalette
  })

  useLayoutEffect(() => {
    generateTheme(data?.workspace?.theme?.palette, themeRef)
  }, [ data ])

  if (loading && !data) {
    return <AppLoader />
  }

  const isUnauth = get(error, 'networkError.statusCode') === 401 || error?.graphQLErrors.some((e) => e.extensions?.code === 'UNAUTHORIZED')

  if ((error && !isUnauth) || !data) {
    return <AppError error={error} />
  }

  return (
    <WorkspaceContext.Provider
      value={{ currentWorkspace: data.workspace, changeThemePalette, resetThemePaletteRef }}
    >
      <CurrentAccountProvider>
        <TourProvider>
          <Switch>
            <InternalRoute exact path="/" component={RootPage} />

            <ExternalRoute path="/login" component={LoginPage} />
            <ExternalRoute path="/verify-mfa" component={VerifyMfaPage} />
            <ExternalRoute path="/forgot-password" component={ForgotPasswordPage} />
            <ExternalRoute path="/reset-password" component={ResetPasswordPage} />
            <ExternalRoute path="/join" component={StartOnboardingPage} />

            <OnboardingRoute path="/onboard" component={CompleteOnboardingPage} />

            <Redirect exact path="/~personal" to="/~personal/profile" />
            <InternalRoute path="/~personal/profile" component={AccountProfilePage} />
            <InternalRoute path="/~personal/notifications" component={AccountNotificationsPage} />
            <InternalRoute path="/~personal/settings" component={AccountSettingsPage} />
            <InternalRoute path="/logout" component={LogoutPage} />

            <WorkspaceRoute path="/~workspace/app/:appId/data" component={AppSchemaPage} />
            <WorkspaceRoute path="/~workspace/app/:appId/access" component={AppAccessPage} />
            <WorkspaceRoute path="/~workspace/app/:appId/settings" component={AppSettingsPage} />
            <WorkspaceRoute exact path="/~workspace/app/:appId" component={AppPage} />
            <WorkspaceRoute path="/~workspace/app/:appId/:view" component={DashboardPage} />

            <WorkspaceRoute path="/~workspace/project/:appId/data" component={AppSchemaPage} />
            <WorkspaceRoute path="/~workspace/project/:appId/access" component={AppAccessPage} />
            <WorkspaceRoute path="/~workspace/project/:appId/resource/:resourceId" component={DashboardPage} />
            <WorkspaceRoute exact path="/~workspace/project/:appId/overview" component={AppOverviewPage} />
            <WorkspaceRoute exact path="/~workspace/project/:appId" component={AppOverviewPage} />

            <WorkspaceRoute path="/~workspace/integrations" component={IntegrationsPage} />
            <WorkspaceRoute exact path="/~workspace/apps" component={AppsPage} />
            <WorkspaceRoute path="/~workspace/app/:appId" component={AppPage} />

            <WorkspaceRoute path="/~workspace/admin/dashboards" component={DashboardsPage} />
            <Redirect exact path="/~workspace/dashboards" to="/~workspace/admin/dashboards" />
            <WorkspaceRoute path="/~workspace/admin/environments" component={EnvironmentsPage} />
            <Redirect exact path="/~workspace/environments" to="/~workspace/admin/environments" />
            <WorkspaceRoute path="/~workspace/admin/billing" component={BillingPage} />
            <Redirect exact path="/~workspace/billing" to="/~workspace/admin/billing" />
            <WorkspaceRoute path="/~workspace/admin/accounts" component={AccountsPage} />
            <Redirect exact path="/~workspace/accounts" to="/~workspace/admin/accounts" />
            <Redirect exact path="/~workspace/admin" to="/~workspace/admin/accounts" />
            <WorkspaceRoute path="/~workspace/admin/groups" component={GroupsPage} />
            <Redirect exact path="/~workspace/groups" to="/~workspace/admin/groups" />
            <WorkspaceRoute path="/~workspace/admin/roles" component={RolesPage} />
            <Redirect exact path="/~workspace/roles" to="/~workspace/admin/roles" />
            <WorkspaceRoute path="/~workspace/data" component={GraphPage} />
            <WorkspaceRoute path="/~workspace/admin/developer" component={DeveloperPage} />
            <Redirect exact path="/~workspace/developer" to="/~workspace/admin/developer" />
            <WorkspaceRoute path="/~workspace/admin/domain" component={DomainPage} />
            <Redirect exact path="/~workspace/domain" to="/~workspace/admin/domain" />
            <WorkspaceRoute path="/~workspace/admin/branding" component={BrandingPage} />
            <Redirect exact path="/~workspace/branding" to="/~workspace/admin/branding" />
            <WorkspaceRoute path="/~workspace/admin/locales" component={LocalesPage} />
            <Redirect exact path="/~workspace/locales" to="/~workspace/admin/locales" />
            <WorkspaceRoute path="/~workspace/admin/settings" component={SettingsPage} />
            <Redirect exact path="/~workspace/settings" to="/~workspace/admin/settings" />
            <WorkspaceRoute path="/~workspace/admin/products" component={ProductsPage} />
            <Redirect exact path="/~workspace/products" to="/~workspace/admin/products" />
            <WorkspaceRoute path="/~workspace/admin/spaces" component={SpacesPage} />
            <Redirect exact path="/~workspace/spaces" to="/~workspace/admin/spaces" />

            <WorkspaceRoute path="/~workspace/overview" component={OverviewPage} />
            <Redirect exact path="/~workspace" to="/~workspace/overview" />

            <InternalRoute path="/~:dashboardIdentifier?" render={DashboardPage} />

            <Route component={NotFoundPage} />
          </Switch>
        </TourProvider>
      </CurrentAccountProvider>
    </WorkspaceContext.Provider>
  )
}

export default WorkspaceContainer
