import pluralize from 'pluralize'
import React, { useCallback, useRef } from 'react'
import uuid from 'uuid-random'
import { array } from 'yup'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import type { DraggableChildrenFn } from 'react-beautiful-dnd'

import FieldArray, { FieldArrayChildrenProps } from 'components/form/FieldArray'
import FieldGroup from 'components/form/FieldGroup'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import InputHelpText from 'components/inputHelpText/InputHelpText'
import SelectInput from 'components/inputs/SelectInput'
import TextLink from 'components/links/TextLink'
import useReorderFieldArray from 'hooks/useReorderFieldArray'
import type { fieldProps } from 'components/contentEditors/generic/fields/fieldProps'
import type { SelectInputProps } from 'components/inputs/SelectInput'

type DropdownFieldProps<T> = Omit<SelectInputProps<T>, 'input' | 'meta'> & fieldProps<'dropDown'>

const getDropdownFieldSchema = <T, >(settings: DropdownFieldProps<T>['settings'] = {}, isArray?: boolean) => {
  let schema = array().ensure()

  if (settings.checkRequired && !settings.hasFallbackLocale) {
    schema = schema.required()
  }

  if (isArray) {
    if (settings.repeatedMinimum) {
      schema = schema.min(settings.repeatedMinimum)
    }
    if (settings.repeatedMaximum) {
      schema = schema.max(settings.repeatedMaximum)
    }
  }

  return schema
}

const UniqueSelectField = <T, >({
  name,
  settings,
  shouldValidate,
  isArray,
  options,
  ...others
}: DropdownFieldProps<T>) => {
  const validate = useCallback((value) => ((getDropdownFieldSchema(settings, isArray)
    .validate(value)) as Promise<any>)
    .then(() => { })
    .catch((e) => e.message),
  [ settings, isArray ])

  const { options: settingsOptions, ...restSettings } = settings || {}

  const getOptions = () => {
    if (settingsOptions) {
      return settingsOptions.filter(Boolean).map(
        ({ label, value, key, ...rest }) => {
          // when "use different values for key and label" is enabled
          // user can leave `key/value field` empty
          const finalValue = value ?? key ?? label
          return { label, value: finalValue, ...rest }
        }
      )
    }

    return options
  }

  return (
    <FormField
      component={SelectInput}
      closeMenuOnSelect={!isArray}
      isCreatable={settings?.optionsCreatable}
      isMulti={isArray}
      name={name}
      options={getOptions()}
      {...restSettings}
      {...({
        checkRequired: settings?.checkRequired,
        placeholder: settings?.placeholder,
        helpText: settings?.helpText || settings?.help_text
      })}
      {...(shouldValidate && { validate })}
      {...others}
    />
  )
}

const NotUniqueSelectField = <T, >(props: DropdownFieldProps<T>) => {
  const { name, settings, shouldValidate, label, helpText, isTranslatable } = props
  const droppableId = useRef(uuid())
  const fieldsRef = useRef<FieldArrayChildrenProps<any>>()
  const renderDraggableChildren: DraggableChildrenFn = (provided, _, rubric) => (
    <div
      {...provided.draggableProps}
      ref={provided.innerRef}
    >
      <FieldGroup
        onClick={() => fieldsRef.current?.fields.remove(rubric.source.index)}
        isDraggable
        {...provided}
      >
        <UniqueSelectField
          {...props}
          name={FieldArray.getFieldName(name, rubric.source.index)}
          isMulti={false}
          isArray={false}
          closeMenuOnSelect
          helpText=""
          label=""
        />
      </FieldGroup>
    </div>
  )

  const onDragEnd = useReorderFieldArray(fieldsRef)

  const fieldName = pluralize.singular(name || '')

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId={droppableId.current}
        renderClone={renderDraggableChildren}
      >
        {(droppableProvided) => (
          <Flex
            direction="column"
            gap={10}
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
          >
            {label && (
              <FieldLabel isTranslatable={isTranslatable}>
                {label}
              </FieldLabel>
            )}
            <FieldArray
              name={name}
              fieldsRef={fieldsRef}
              settings={settings}
              shouldValidate={shouldValidate}
            >
              {({ keys }) => keys.map((key, index) => (
                <Draggable
                  disableInteractiveElementBlocking
                  draggableId={key}
                  index={index}
                  key={key}
                >
                  {renderDraggableChildren}
                </Draggable>
              ))}
            </FieldArray>
            {droppableProvided.placeholder}
            {helpText && <InputHelpText helpText={helpText} />}
            <TextLink
              fontSize="12"
              as="button"
              type="button"
              variant="underlined"
              mode="subtle"
              alignSelf="flex-start"
              fontWeight="bold"
              color="dark500"
              onClick={() => fieldsRef.current?.fields.push(undefined)}
            >
              Add new {fieldName}
            </TextLink>
          </Flex>
        )}
      </Droppable>
    </DragDropContext>
  )
}

const DropdownField = <T, >(props: DropdownFieldProps<T>) => {
  const { isArray, settings } = props

  return (
    <>
      {isArray && settings?.repeatedAllowDuplicates ? (
        <NotUniqueSelectField {...props} />
      ) : (
        <UniqueSelectField {...props} />
      )}
    </>
  )
}

export type { DropdownFieldProps }
export { getDropdownFieldSchema }
export default DropdownField
