import React, { useContext, useEffect } from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import type { RouteProps } from 'react-router-dom'

import GlobalContext from 'components/contexts/GlobalContext'
import PageLoader from 'components/loaders/PageLoader'
import useClientQuery from 'hooks/useClientQuery'
import WorkspaceContext from 'components/contexts/WorkspaceContext'
import { addWorkspace, isElectron } from 'lib/electron'
import { REFERRER_QUERY, SET_REFERRER_MUTATION } from 'client/state/referrer'
import { useCurrentAccountContext } from 'components/contexts/CurrentAccountContext'
import type { Account } from 'generated/schema'

enum SessionState {
  LOGGED_IN = 'logged-in',
  LOGGED_OUT = 'logged-out'
}

type ProtectedRouteProps = RouteProps & {
  layout: React.JSXElementConstructor<any>,
  layoutProps?: Object,
  requiredSessionState: SessionState,
  requiredAccountState?: Account['status'],
  // Any one from the passed roleIds must be present
  requiredRoleIds?: string[]
}

function ProtectedRoute({
  component,
  layout: Layout,
  layoutProps,
  requiredSessionState,
  requiredAccountState,
  requiredRoleIds,
  render,
  ...other
}: ProtectedRouteProps) {
  const { pathname, search, hash } = useLocation()
  const [
    setReferrer,
    { loading: setReferrerLoading }
  ] = useMutation(SET_REFERRER_MUTATION)

  const { currentWorkspace } = useContext(WorkspaceContext)! || {}
  const { openFailureAlert } = useContext(GlobalContext)!

  const { data: { referrer: { url } } } = useClientQuery(REFERRER_QUERY)
  const currentAccount = useCurrentAccountContext()
  const workspaceStatus = currentWorkspace?.status
  const accountStatus = currentAccount?.status

  const Component = (component || render) as React.JSXElementConstructor<{}>

  useEffect(() => {
    if (requiredSessionState === SessionState.LOGGED_OUT && currentAccount) {
      setReferrer({ variables: { url: null } })
    }

    if (requiredSessionState === SessionState.LOGGED_IN
      && !currentAccount
      && pathname !== '/logout'
    ) {
      setReferrer({ variables: { url: (pathname || '') + (search || '') + (hash || '') } })
    }
  }, [ hash, pathname, requiredSessionState, search, setReferrer, currentAccount ])

  useEffect(() => {
    if (isElectron && currentWorkspace) {
      addWorkspace({ currentWorkspace })
    }
  }, [ currentWorkspace ])

  if (currentWorkspace?.status === 'INACTIVE') {
    openFailureAlert({
      title: 'Workspace is inactive',
      message: 'Please contact your workspace administrator to reactivate it.'
    })
  }

  const renderRouteChildren = () => {
    if (setReferrerLoading) {
      return <PageLoader loading />
    }

    if (requiredSessionState === SessionState.LOGGED_OUT && currentAccount) {
      const route = url || '/'
      return <Redirect to={route} />
    }

    if (requiredSessionState === SessionState.LOGGED_IN && !currentAccount) {
      return <Redirect to="/login" />
    }

    if (requiredAccountState === 'ONBOARDING' && accountStatus === 'ENABLED') {
      return <Redirect to="/" />
    }

    if (requiredAccountState === 'ENABLED' && accountStatus !== 'ENABLED') {
      return <Redirect to="/onboard" />
    }

    if (requiredSessionState === SessionState.LOGGED_IN && workspaceStatus === 'INACTIVE' && pathname !== '/logout' && currentAccount) {
      return <Redirect to="/logout" />
    }

    if (requiredSessionState === SessionState.LOGGED_IN && workspaceStatus === 'SUSPENDED' && pathname !== '/~workspace/billing') {
      return <Redirect to="/~workspace/billing" />
    }

    if (requiredRoleIds?.length && !requiredRoleIds.some((roleId) => (
      currentAccount?.roleMemberships.find((rm) => rm.roleId === roleId)?.roleId
    ))) {
      return <Redirect to="/" />
    }

    return (
      <Layout {...layoutProps}>
        <Component />
      </Layout>
    )
  }

  return (
    <Route {...other} render={renderRouteChildren} />
  )
}

export { SessionState }

export type { ProtectedRouteProps }

export default ProtectedRoute
