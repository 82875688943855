import camelCase from 'lodash/camelCase'
import dayjs from 'dayjs'
import kebabCase from 'lodash/kebabCase'
import omit from 'lodash/omit'
import React, { useContext, useEffect } from 'react'
import uuid from 'uuid-random'

import Button, { ButtonProps } from 'components/buttons/Button'
import Chip from 'components/chip/Chip'
import Flex from 'components/layout/Flex'
import FormBlock from 'components/blocks/FormBlock'
import IconButton from 'components/buttons/IconButton'
import RecordVersionsListView from './RecordVersionsListView'
import SectionLoader from 'components/loaders/SectionLoader'
import Text from 'components/typography/Text'
import useRecordPublishing, { PublishingAction } from 'hooks/useRecordPublishing'
import { GetMediaFieldStateContext } from 'components/contexts/MediaFieldContext'
import { OperationMethod, ResolveViewDocument, useInternalFetchRecordQuery, useOperationQuery, useOperationsListQuery, useResourceQuery, useUpsertViewMutation } from 'generated/schema'
import { OPERATIONS_LIST_LIMIT } from 'models/Resource'
import { useViewDispatch } from 'hooks/useViewContext'
import type { CustomRecord, InternalAddRecordInput, InternalEditRecordInput, Operation, Resource } from 'generated/schema'
import type { SwitcherResultProps } from 'hooks/useSwitcherState'
import type { ViewProps } from 'components/views'
import Masonry from 'components/layout/Masonry'
import useDashboard from 'hooks/useDashboard'
import DashboardContext from 'components/contexts/DashboardContext'
import { Views } from 'components/dashboardEditor/constants'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { useGenericView } from '../GenericView'
import { DashboardView } from 'components/pages/DashboardPage'

type FormValues = InternalAddRecordInput['arguments'] | InternalEditRecordInput['arguments']

type WithResource = {
  resource: Resource,
  operationId: Operation['id']
}

type WithResourceId = {
  resourceId: string,
  operationMethod: OperationMethod,
  resource?: Resource
}

type Params = (WithResource | WithResourceId) & {
  record?: Partial<CustomRecord>,
  switcher?: SwitcherResultProps['switcher'],
  titleAttributeIdentifier?: string,
  onDelete?: () => void,
  onSubmit?: (values: any) => void
}

const prefix = 'arguments'

const PublishButton = ({ isPublished, ...rest }: { isPublished: boolean } & ButtonProps) => {
  const uploadingMediaFields = useContext(GetMediaFieldStateContext)

  return (
    <Button
      disabled={uploadingMediaFields.length}
      label={isPublished ? 'Unpublish' : 'Publish'}
      variant={isPublished ? 'outline' : 'filled'}
      size="small"
      {...rest}
    />
  )
}

const generateTitleBlock = (title: string) => {
  const blockId = uuid()
  const blockType = 'TitleBlock'

  return {
    id: blockId,
    identifier: 'title-block',
    properties: {
      heading: title
    },
    type: blockType
  }
}

function AddRecordView({
  onRequestClose,
  params: {
    record: cachedRecord,
    switcher,
    onDelete,
    onSubmit,
    ...params
  },
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const { openView } = useViewDispatch()
  const { updateBlockProperties } = useDashboard()

  const resourceId = 'resourceId' in params ? params.resourceId : params.resource.id
  const urn = `resource::${resourceId}::graph/GenericResourceFormView`
  const {
    activeView,
    error,
    footerEl,
    loading,
    parsedTitle,
    setFooterEl,
    setTitle
  } = useGenericView(urn)

  const {
    data: { resource = params.resource } = {},
    loading: resourceLoading,
    error: resourceError
  } = useResourceQuery({
    variables: {
      id: resourceId
    },
    skip: !resourceId || 'resource' in params
  })

  const {
    data: operationsData
  } = useOperationsListQuery({
    variables: {
      filter: {
        resourceId: { eq: resourceId }
      },
      order: [ {
        position: 'asc'
      } ],
      limit: OPERATIONS_LIST_LIMIT
    },
    skip: !resourceId || 'operationId' in params
  })

  const operations = operationsData?.operationsList || []
  const mutationOperation = operations.find((op) => op.graphqlKind === 'MUTATION' && op.method === (params as WithResourceId).operationMethod)
  const operationId = (params as WithResource).operationId || mutationOperation?.id

  const {
    data: { operation } = {}
  } = useOperationQuery({
    variables: { id: operationId },
    skip: !operationId
  })

  const title = parsedTitle || resource?.name || 'Resource'

  const isUpdating = cachedRecord?.id !== undefined
  const isPublishingEnabled = resource?.isPublishingEnabled

  const {
    data: { internalFetchRecord: record = cachedRecord } = {},
    loading: recordLoading,
    error: recordError
  } = useInternalFetchRecordQuery({
    variables: {
      input: {
        preview: true,
        resourceId,
        targetEnvironment: switcher?.data.environment?.id,
        arguments: {
          id: cachedRecord?.data?.id
        }
      }
    },
    skip: !cachedRecord?.data?.id
  })

  const initialValues = record?.data || {} as FormValues

  useEffect(() => {
    let cachedRecord: any
    let cachedOnSubmit: any
    updateBlockProperties((b) => {
      cachedRecord = b.currentRecord
      cachedOnSubmit = b.onSubmit
      return ({
        ...b,
        currentRecord: record?.data,
        currentOnSubmit: onSubmit
          ? (values: any) => {
            onSubmit(values)
            onRequestClose()
          } : cachedOnSubmit
      })
    })

    return () => {
      updateBlockProperties((b) => ({
        ...b,
        currentRecord: cachedRecord,
        currentOnSubmit: cachedOnSubmit
      }))
    }
  }, [ record, updateBlockProperties, onSubmit, onRequestClose ])

  const duplicateButton = (
    <IconButton
      key="duplicate"
      name="duplicate"
      description="Duplicate"
      variant="dark"
      size={24}
      onClick={() => {
        openView({
          title,
          component: AddRecordView,
          params: {
            resource: resource as Resource,
            record: { data: omit(record?.data || {}, 'id', 'createdAt', 'updatedAt') } as CustomRecord,
            switcher,
            operationId
          },
          style: 'PANEL'
        })
      }}
    />

  )

  const historyButton = (
    <IconButton
      key="revision-history"
      name="clock-outline"
      description="Revision History"
      variant="dark"
      size={24}
      onClick={() => {
        if (record?.id) {
          openView({
            title: 'Version History',
            component: RecordVersionsListView,
            style: 'PANEL',
            params: {
              recordId: record.id,
              latestId: record.latestId,
              publishedId: record.publishedId,
              targetEnvironment: switcher?.data.environment?.id,
              resource: resource as Resource
            }
          })
        }
      }}
    />
  )

  const deleteButton = onDelete && (
    <IconButton
      key="delete-content"
      name="trash"
      description="Delete Content"
      variant="dark"
      size={24}
      onClick={() => {
        onDelete()
      }}
    />
  )

  const isPublished = isPublishingEnabled
    && !!record?.publishedId
    && record.publishedId === record.latestId

  const versionStatus = isPublishingEnabled && (
    <Chip
      label={isPublished ? 'published' : 'current draft'}
      variant={isPublished ? 'positive' : 'dark100'}
    />
  )

  const shouldShowPublishing = isPublishingEnabled && isUpdating

  const [ handlePublishing ] = useRecordPublishing()

  const onPublishingClick = () => (
    handlePublishing({
      action: isPublished ? PublishingAction.UNPUBLISH : PublishingAction.PUBLISH,
      id: record?.latestId
    })
  )

  const { selectBlock, openDashboardEditorView } = useDashboard()
  const { openDashboardEditor } = useContext(DashboardContext)!

  const [ upsertView ] = useUpsertViewMutation({
    refetchQueries: [ ResolveViewDocument ],
    onCompleted: ({ upsertView: view }) => {
      const formBlock = view.blocks[1]

      selectBlock(formBlock.id)
      openDashboardEditor()
      openDashboardEditorView({
        target: Views.EDIT_BLOCK
      })
    }
  })

  const handleUpsertView = useSubmitHandler(upsertView)

  const generateResourceFormBlock = () => {
    const blockId = uuid()
    const blockType = 'FormBlock'

    return {
      id: blockId,
      type: blockType,
      identifier: `${kebabCase(blockType).toLowerCase()}-${blockId.slice(0, 8)}`,
      properties: {
        operation: operationId,
        children: operation?.parameters.map((p) => ({
          id: p.id,
          type: 'FormFieldBlock',
          identifier: `form-field-block-${p.id.slice(0, 8)}`,
          properties: {
            name: p.name,
            identifier: operation.resourceId ? camelCase(p.identifier) : p.identifier,
            is_array: p.isArray,
            is_nullable: p.isNullable,
            field_type: p.fieldType,
            field_type_settings: {
              ...p.dataType?.settings || {},
              ...p.fieldTypeSettings
            },
            default_value: p.defaultValue
          }
        }))
      }
    }
  }

  const handleEditResourceView = () => {
    const titleBlock = generateTitleBlock(title)
    const formBlock = generateResourceFormBlock()

    handleUpsertView({
      urn,
      blocks: [
        titleBlock,
        formBlock
      ]
    })
  }

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, SubHeader, Footer, Body }) => (
        <>
          <Header title={isUpdating ? `Update ${title}` : `Add ${title}`} onCloseClick={onRequestClose}>
            {isUpdating && (
            <>
              {duplicateButton}
              {deleteButton}
            </>
            )}
          </Header>
          {isUpdating && (
            <SubHeader justifyContent="space-between" gap={16}>
              {record?.data.updatedAt && (
                <Text
                  color="dark500"
                  css={{ paddingRight: 16 }}
                  fontSize={14}
                  title={dayjs(record.data.updatedAt.en_US).format('MMM D, YYYY h:mm A')}
                >
                  Last modified {dayjs(record.data.updatedAt.en_US).fromNow()}
                </Text>
              )}
              <Flex gap={16} grow={1}shrink={0} alignItems="center" justifyContent="flex-end">
                {versionStatus}
                {shouldShowPublishing && (
                  <PublishButton
                    isPublished={Boolean(isPublished)}
                    onClick={onPublishingClick}
                  />
                )}
                {historyButton}
              </Flex>
            </SubHeader>
          )}
          <Body>
            <SectionLoader
              loading={loading || recordLoading || resourceLoading}
              error={error || recordError || resourceError}
              data={resource && (isUpdating ? record : {})}
            >
              <Masonry css={{ margin: -32 }}>
                {activeView?.blocks?.length ? (
                  <DashboardView
                    activeUrn={urn}
                    activeView={activeView!}
                    setTitle={setTitle}
                    footerEl={footerEl}
                  />
                ) : (
                  <FormBlock
                    key={record?.latestId}
                    initialValues={initialValues}
                    operationId={operationId}
                    resourceId={resourceId}
                    isTranslatable={resource?.isTranslatable}
                    footerEl={footerEl}
                    switcher={switcher}
                    prefix={prefix}
                    isPublishingEnabled={isPublishingEnabled}
                    publishedId={record?.publishedId}
                    latestId={record?.latestId}
                    onEdit={handleEditResourceView}
                    hideActionCard={!operation}
                    isViewBlock
                  />
                )}
              </Masonry>
            </SectionLoader>
          </Body>
          <Footer>
            <Footer.Right ref={setFooterEl} />
          </Footer>
        </>
      )}
    </View>
  )
}

export default AddRecordView
