import React from 'react'

import FormField from 'components/form/FormField'
import Grid from 'components/layout/Grid'
import SelectInput from 'components/inputs/SelectInput'
import { PlainTextRenderer } from './PlainTextView'
import { Attribute, AttributeFragmentFragment, useAttributesListQuery, useInternalFetchRecordQuery, useInternalSearchRecordsQuery, useResourceQuery } from 'generated/schema'
import { ATTRIBUTES_LIST_LIMIT } from 'models/Resource'
import ReferenceRenderer from 'components/renderers/ReferenceRenderer'
import Flex from 'components/layout/Flex'

type ConfigurationsProps = {
  fieldPrefix?: string
}

const ViewOptions = [
  {
    label: 'Dialog',
    value: 'dialog'
  },
  {
    label: 'Side Pane',
    value: 'side_pane'
  },
  {
    label: 'Inline',
    value: 'inline'
  },
  {
    label: 'Title Field',
    value: 'title_field'
  }
]

type RendererProps = {
  attribute: AttributeFragmentFragment,
  displaySettings: Attribute['displayTypeSettings'],
  data: any
}

const Renderer = ({ displaySettings, data, attribute }: RendererProps) => {
  const resourceId = attribute.fieldTypeSettings.references?.[0]

  const { data: { resource } = {} } = useResourceQuery({
    variables: {
      id: resourceId
    },
    skip: !resourceId
  })

  const { data: { attributesList: attributes = [] } = {} } = useAttributesListQuery({
    variables: {
      filter: {
        resourceId: { eq: resourceId }
      },
      order: [ {
        position: 'asc'
      } ],
      limit: ATTRIBUTES_LIST_LIMIT
    },
    skip: !resourceId
  })

  const recordIds = [ data ].filter(Boolean).flat()
  const { data: { internalSearchRecords } = {} } = useInternalSearchRecordsQuery({
    variables: {
      input: {
        resourceId,
        preview: true,
        filter: {
          id: {
            in: recordIds
          }
        }
      }
    },
    skip: !recordIds.length
  })

  if (resource && attributes && internalSearchRecords) {
    return (
      <Flex grow={1}>
        {internalSearchRecords.map((record) => (
          <ReferenceRenderer
            key={record.id}
            prefix="data"
            suffix="en_US"
            dataKey="data"
            rowData={{
              data: {
                [resource.identifier]: record
              }
            }}
            attributeProps={{
              attributes,
              resource
            } as any}
          />
        ))}
      </Flex>
    )
  }

  return (
    <PlainTextRenderer displaySettings={displaySettings} data={data} />
  )
}

const Configurations = ({ fieldPrefix = 'displayTypeSettings.' }: ConfigurationsProps) => (
  <Grid alignItems="center" gap={16}>
    <FormField
      name={`${fieldPrefix}view_style`}
      component={SelectInput}
      label="View Style"
      size="small"
      options={ViewOptions}
    />
  </Grid>
)

export default {
  Configurations
}

export { Renderer as ReferenceRenderer }
