import { useEffect } from 'react'
import { useRecoilCallback } from 'recoil'

import useDashboard, { Block } from './useDashboard'

const updateBlockIds = (blockId: string) => (currentBlockIds: string[]) => (
  currentBlockIds.find((id) => id === blockId)
    ? currentBlockIds
    : [ ...currentBlockIds, blockId ]
).filter(Boolean)

const useCheckIncomingBlocksIntoRecoilState = (
  urn: string, blocks: readonly any[]
) => {
  const { updateBlock, blockState, blockIds } = useDashboard()

  const setBlock = useRecoilCallback(({ set }) => (
    async (block: Block, append = false) => {
      set(blockState(block.id), block)

      if (append) {
        set(blockIds(urn!), updateBlockIds(block.id))
      }
    }
  ), [ urn ])

  useEffect(() => {
    // checking in blocks in recoil state
    const recurse = (blocks: readonly any[], append = false) => {
      blocks?.forEach((block) => {
        if (block.properties.tabs?.length) {
          block.properties.tabs.forEach((tab: any) => {
            recurse(tab.children || [])
          })
        }

        if (block.properties.columns?.length) {
          block.properties.columns.forEach((column: any) => {
            recurse(column.children || [])
          })
        }

        if (block.properties.children?.length) {
          recurse(block.properties.children)
        }

        setBlock(block, append)
      })
    }
    recurse(blocks, true)
  }, [ blocks, setBlock, updateBlock, urn ])
}

export default useCheckIncomingBlocksIntoRecoilState
