import React from 'react'
import { Field } from 'react-final-form'

import CsvInput from 'components/inputs/CsvInput'
import type { CSVInputOwnProps } from 'components/inputs/CsvInput'
import type { fieldProps } from 'components/contentEditors/generic/fields/fieldProps'

type CSVFieldProps = CSVInputOwnProps & fieldProps<'csv'>

function CsvField({ name, settings, ...others }: CSVFieldProps) {
  return (
    <Field
      component={CsvInput}
      name={name}
      headers={settings?.headers}
      {...others}
    />
  )
}

export default CsvField
