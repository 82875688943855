import React from 'react'
import { FormSpy } from 'react-final-form'
import { useRecoilValue } from 'recoil'

import useDashboard, { DashboardEditorView } from 'hooks/useDashboard'
import { useDashboardEditorContextProvider } from './DashboardEditorProvider'
import type { Views } from './constants'

const LiveBlockEditorOrchestrator = () => {
  const { selectedBlockState, updateBlock, dashboardEditorState } = useDashboard()
  const { params: { block } = {} } = useRecoilValue<DashboardEditorView<Views.EDIT_BLOCK>>(
    dashboardEditorState
  )
  const selectedBlock = useRecoilValue(selectedBlockState)! || block
  const { urn } = useDashboardEditorContextProvider()
  return (
    <FormSpy
      onChange={
        ({
          // eslint-disable-next-line camelcase
          values: { _identifier, visibility_criteria, actions, ...properties },
          valid,
          dirty
        }) => {
          if (!dirty && !valid) return
          updateBlock(urn, {
            ...selectedBlock,
            actions,
            identifier: _identifier,
            visibility_criteria,
            properties: {
              ...selectedBlock.properties,
              ...properties
            }
          })
        }
    }
    />
  )
}

export default LiveBlockEditorOrchestrator
