import React, { useCallback } from 'react'
import { string } from 'yup'
import type { StringSchema } from 'yup'

import ButtonGroupInput from 'components/inputs/ButtonGroupInput'
import FormField from 'components/form/FormField'
import type { ButtonGroupInputProps } from 'components/inputs/ButtonGroupInput'
import type { fieldProps } from 'components/contentEditors/generic/fields/fieldProps'

type SegmentedControlFieldProps = Omit<ButtonGroupInputProps, 'input' | 'meta'> & fieldProps<'dropDown'>

const getSchema = (settings: SegmentedControlFieldProps['settings'] = {}, isNullable: boolean) => {
  let schema: StringSchema<string | null | undefined> = string().nullable(isNullable)
  if (settings.checkRequired && !settings.hasFallbackLocale) {
    schema = schema.required()
  }

  return schema
}

const SegmentedControlField = ({
  name,
  settings,
  shouldValidate,
  isNullable = false,
  ...others
}: SegmentedControlFieldProps) => {
  const validate = useCallback((value: string) => getSchema(settings, isNullable)
    .validate(value)
    .then(() => { })
    .catch((e) => e.message), [ settings, isNullable ])

  return (
    <FormField
      {...(shouldValidate && { validate })}
      {...settings}
      {...others}
      name={name}
      component={ButtonGroupInput}
      labelPosition="top"
    />
  )
}

export type { SegmentedControlFieldProps }
export { getSchema as getSegmentedControlFieldSchema }
export default SegmentedControlField
