import React from 'react'
import { Field, Form } from 'react-final-form'

import Account from 'models/Account'
import AccountRenderer from 'components/renderers/AccountRenderer'
import AssignRoleView from './AssignRoleView'
import Button from 'components/buttons/Button'
import Dashboard from 'models/Dashboard'
import DataList from 'components/dataList/DataList'
import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import useConfirmation from 'hooks/useConfirmation'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { DashboardsListDocument, DashboardsListQueryVariables, RoleMembershipsListDocument, useCreateDashboardMutation, useDestroyRoleMembershipMutation, useRoleMembershipsListQuery, useRoleQuery, useUpdateDashboardMutation } from 'generated/schema'
import { Kind, ROLE_IDS } from 'models/Role'
import { useViewDispatch } from 'hooks/useViewContext'
import type { CreateDashboardInput, UpdateDashboardInput } from 'generated/schema'
import type { FormPropsWithId } from 'hooks/useSubmitHandler'
import type { ViewProps } from 'components/views'

type DashboardFormValues = CreateDashboardInput | UpdateDashboardInput

type Params = {
  initialValues: any,
  queryVariables: DashboardsListQueryVariables,
  mode?: 'OVERVIEW' | 'ACCESS'
}

function DashboardRoleManagement({
  title,
  subtitle,
  roleMemberships,
  loading,
  error,
  roleId,
  objectId
}: any) {
  const { openView } = useViewDispatch()
  const { data: { role } = {} } = useRoleQuery({ variables: { id: roleId } })
  const openAssignAccountRole = () => {
    openView({
      title: 'Assign Role',
      component: AssignRoleView,
      params: {
        roleId,
        role,
        roleMemberships,
        mode: 'account',
        kind: Kind.RESOURCE,
        objectId
      },
      style: 'DIALOG'
    })
  }

  const confirm = useConfirmation({ style: 'DIALOG' })

  const [ destroyRoleMembership ] = useDestroyRoleMembershipMutation({
    refetchQueries: [
      RoleMembershipsListDocument
    ]
  })

  const handleDestroyRoleMembership = useSubmitHandler(destroyRoleMembership, {
    successAlert: {
      message: 'Member successfully removed'
    }
  })

  return (
    <>
      <Flex justifyContent="space-between">
        <Flex direction="column" gap={4}>
          <Text fontWeight="bold">{title}</Text>
          <Text fontSize={12}>{subtitle}</Text>
        </Flex>
        <Button icon="add-thin" size="small" onClick={openAssignAccountRole} disabled={!role} />
      </Flex>
      <DataList
        loading={loading}
        error={error}
        data={roleMemberships}
        contents={[
          {
            dataKey: 'account',
            renderer: AccountRenderer,
            slot: 'primary'
          }
        ]}
        actions={[
          {
            icon: 'trash',
            title: 'Delete',
            onClick: (record: any) => {
              confirm({
                action: 'delete',
                recordDescription: Account.getFullName(record.account),
                onConfirmClick: () => handleDestroyRoleMembership({
                  id: record.id
                }),
                recordType: 'role member'
              })
            }
          }
        ]}
        selectionMode="none"
      />
    </>
  )
}

function DashboardFormView({
  onRequestClose,
  params: { initialValues = {}, queryVariables, mode = 'OVERVIEW' },
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const isUpdating = 'id' in initialValues
  const [ createDashboard ] = useCreateDashboardMutation({
    onCompleted: onRequestClose,
    refetchQueries: [
      DashboardsListDocument
    ]
  })
  const [ updateDashboard ] = useUpdateDashboardMutation({
    onCompleted: onRequestClose,
    refetchQueries: [
      DashboardsListDocument
    ]
  })

  const handleCreateDashboardSubmit = useSubmitHandler(createDashboard, {
    update: {
      strategy: 'APPEND',
      query: DashboardsListDocument,
      queryVariables,
      dataKey: 'dashboardsList',
      mutation: 'createDashboard'
    }
  })

  const handleUpdateDashboardSubmit = useSubmitHandler(updateDashboard, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateDashboard',
      typename: 'Dashboard',
      defaultValue: {
        menuElements: []
      },
      override: (values: UpdateDashboardInput) => ({
        ...initialValues,
        ...values
      })
    }
  })

  const handleDashboardFormSubmit = (values: DashboardFormValues, form: FormPropsWithId<UpdateDashboardInput>['form']) => {
    if ('id' in values) {
      return handleUpdateDashboardSubmit(values, form)
    }

    return handleCreateDashboardSubmit(values as CreateDashboardInput)
  }

  // eslint-disable-next-line no-nested-ternary
  const title = mode === 'ACCESS'
    ? 'Edit Dashboard Access'
    : isUpdating
      ? 'Edit Dashboard'
      : 'Create Dashboard'

  const { data, loading, error } = useRoleMembershipsListQuery({
    variables: {
      filter: {
        objectId: {
          eq: initialValues.id
        },
        roleId: {
          in: [
            ROLE_IDS.DASHBOARD_MANAGER,
            ROLE_IDS.DASHBOARD_MEMBER,
            ROLE_IDS.WORKSPACE_DASHBOARDS_MANAGER
          ]
        }
      }
    },
    skip: !initialValues.id
  })

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body, Footer }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          <Form
            subscription={{
              submitting: true,
              pristine: true
            }}
            initialValues={initialValues}
            onSubmit={handleDashboardFormSubmit}
            validate={(values) => Dashboard.validate(values, [ 'name' ])}
            render={({ handleSubmit, submitting, pristine }) => (
              <>
                <Body>
                  <Flex direction="column" gap={16}>
                    {mode === 'OVERVIEW' && (
                    <Flex as="form" onSubmit={handleSubmit} gap={16} direction="column">
                      <Field autoFocus name="name" label="Name" component={TextInput} type="text" />
                    </Flex>
                    )}
                    {mode === 'ACCESS' && (
                    <>
                      <DashboardRoleManagement
                        title="Dashboard Managers"
                        subtitle="Can edit the dashboard, share with others"
                        roleMemberships={data?.roleMembershipsList
                          .filter((rm) => rm.roleId === ROLE_IDS.DASHBOARD_MANAGER) || []}
                        loading={loading}
                        error={error}
                        roleId={ROLE_IDS.DASHBOARD_MANAGER}
                        objectId={initialValues.id}
                      />
                      <DashboardRoleManagement
                        title="Dashboard Members"
                        subtitle="Can use the dashboard"
                        roleMemberships={data?.roleMembershipsList
                          .filter((rm) => rm.roleId === ROLE_IDS.DASHBOARD_MEMBER) || []}
                        loading={loading}
                        error={error}
                        roleId={ROLE_IDS.DASHBOARD_MEMBER}
                        objectId={initialValues.id}
                      />
                      <DashboardRoleManagement
                        title="Workspace Dashboards Manager"
                        subtitle="These users automatically inherit access to this dashboard"
                        roleMemberships={data?.roleMembershipsList.filter((rm) => (
                          rm.roleId === ROLE_IDS.WORKSPACE_DASHBOARDS_MANAGER
                        )) || []}
                        loading={loading}
                        error={error}
                        roleId={ROLE_IDS.WORKSPACE_DASHBOARDS_MANAGER}
                        objectId={initialValues.id}
                      />
                    </>
                    )}
                  </Flex>
                </Body>
                <Footer>
                  <Flex gap={24}>
                    <Button type="submit" label="Save Changes" disabled={submitting || pristine} onClick={handleSubmit} />
                  </Flex>
                </Footer>
              </>
            )}
          />

        </>
      )}
    </View>
  )
}

export default DashboardFormView
