import React from 'react'
import { Field, Form } from 'react-final-form'
import { useRecoilValue } from 'recoil'

import Button from 'components/buttons/Button'
import DashboardEditorBody from 'components/dashboardEditor/base/DashboardEditorBody'
import DashboardEditorHeader from 'components/dashboardEditor/base/DashboardEditorHeader'
import Flex from 'components/layout/Flex'
import TextInput from 'components/inputs/TextInput'
import useDashboard from 'hooks/useDashboard'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { Parameters } from './graph/CreateOperationView'
import { SidePaneFooter } from 'components/sidePane'
import { useDashboardEditorContextProvider } from './DashboardEditorProvider'
import { useUpsertViewMutation, View } from 'generated/schema'
import type { ActiveViewProps } from 'components/dashboardEditor/DashboardEditor'
import type { ViewParams, Views } from 'components/dashboardEditor/constants'

type Params = ViewParams[Views.EDIT_VIEW]

const EditViewView = ({ onClose }: ActiveViewProps) => {
  const { dashboardEditorState } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { view = {} } = params! as Params
  const { name, app, resource, variables } = view as View
  const viewName = name || app?.name || resource?.name

  const { urn } = useDashboardEditorContextProvider()
  const [ upsertView ] = useUpsertViewMutation()
  const handleUpsertView = useSubmitHandler(upsertView, { successAlert: { message: 'Your changes were saved.' } })

  return (
    <>
      <DashboardEditorHeader
        subtitle="Edit View"
        heading={`View: ${viewName}`}
        onClose={onClose}
      />
      <Form
        initialValues={{ urn, name, variables: variables || [] }}
        onSubmit={handleUpsertView}
        render={({ handleSubmit, submitting, values }) => (
          <>
            <DashboardEditorBody>
              <Flex as="form" direction="column" onSubmit={handleSubmit} gap={16}>
                <Field autoFocus checkRequired name="name" label="Name" component={TextInput} size="small" />
                <Field name="urn" component="input" type="hidden" style={{ display: 'none' }} />
                <Parameters
                  {...{
                    app,
                    resource,
                    view: {
                      ...view,
                      ...values
                    }
                  }}
                />
                <input type="submit" style={{ display: 'none' }} />
              </Flex>
            </DashboardEditorBody>
            <SidePaneFooter variant="small" isSticky>
              <Flex gap={24} direction="row-reverse">
                <Button size="small" type="submit" disabled={submitting} label="Submit" onClick={handleSubmit} />
              </Flex>
            </SidePaneFooter>
          </>
        )}
      />
    </>
  )
}

export default EditViewView
