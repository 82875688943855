import get from 'lodash/get'
import React from 'react'

import Flex from 'components/layout/Flex'
import { getAppIcon } from 'models/App'
import type { Installation } from 'generated/schema'
import type { RendererOptions } from 'components/dataTable/types'

const APP_ICON_SIZE = 16

function IntegrationNameRenderer({
  dataKey,
  rowData,
  style
}: RendererOptions<Installation>) {
  const { app } = rowData || get(rowData, dataKey) || {}
  const { identifier } = app || {}
  const integrationName = get(rowData, dataKey)?.name || get(rowData, dataKey)

  const hasSameAppName = app?.name === integrationName

  return (
    <Flex gap={8} alignItems="center" style={style}>
      <img style={{ width: `${APP_ICON_SIZE}px` }} src={getAppIcon(identifier)} alt={identifier} />
      <span style={style} title={integrationName}>
        {integrationName} {!hasSameAppName && app?.name && `(${app.name})`}
      </span>
    </Flex>
  )
}

export default IntegrationNameRenderer
