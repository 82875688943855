import React from 'react'
import { Field, Form, FormProps } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import type { Decorator, FormApi } from 'final-form'

import App from 'models/App'
import Button from 'components/buttons/Button'
import Flex from 'components/layout/Flex'
import IconInput from 'components/inputs/IconInput'
import TextInput from 'components/inputs/TextInput'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { App as AppType, AppListDocument, AppListQueryVariables, CreateAppInput, CreateAppMutation, UpdateAppInput, useCreateAppMutation, useUpdateAppMutation } from 'generated/schema'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import type { ViewProps } from 'components/views'

type FormValues = CreateAppInput & {id?: string}

type ViewParamsType = {
  initialValues: AppType | FormValues,
  queryVariables: AppListQueryVariables
}

const setIdentifier = createSetIdentifier(
  'name', 'identifier'
) as Decorator<FormValues>

function CreateAppView({
  closeView, onRequestClose, params, viewStyleComponent: View, ...other
}: ViewProps<ViewParamsType>) {
  const { initialValues, queryVariables, ...rest } = params
  const { push } = useHistory()

  const isUpdating = 'id' in initialValues
  const isProject = initialValues.kind === 'PROJECT'

  const title = `${isUpdating ? 'Edit' : 'New'} ${isProject ? 'Project' : 'App'}`

  const [ createApp ] = useCreateAppMutation({
    onCompleted: async (data: CreateAppMutation) => {
      const { id } = data.createApp
      onRequestClose()
      push({
        pathname: 'apps',
        search: `id=${id}`
      })
    }
  })

  const [ updateApp ] = useUpdateAppMutation({
    onCompleted: onRequestClose
  })

  const handleCreateApp = useSubmitHandler(createApp, {
    update: {
      strategy: 'APPEND',
      query: AppListDocument,
      dataKey: 'appsList',
      mutation: 'createApp',
      queryVariables
    }
  })

  const handleUpdateApp = useSubmitHandler(updateApp, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateApp',
      typename: 'App'
    }
  })

  const handleSubmit = (
    values: FormValues, form: FormProps<FormValues>['form']
  ) => {
    if ('id' in initialValues) {
      return handleUpdateApp(values as UpdateAppInput, form as FormApi<any>)
    }
    return handleCreateApp(values as CreateAppInput)
  }

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body, Footer }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          <Form
            decorators={[
              setIdentifier
            ]}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={(values) => App.validate(values, [ 'name', 'identifier' ])}
            subscription={{ submitting: true, pristine: true }}
            render={({ handleSubmit, submitting, pristine }) => (
              <>
                <Body>
                  <Flex direction="column" gap={16}>
                    <Field autoFocus checkRequired name="name" label="Name" component={TextInput} size="small" />
                    <Field checkRequired name="identifier" label="Identifier" component={TextInput} size="small" />
                    <Field
                      component={IconInput}
                      name="icon"
                      label="Icon"
                      placeholder="Choose Icon"
                      size="small"
                      type="text"
                    />
                  </Flex>
                </Body>
                <Footer>
                  <Flex alignItems="center" grow={1} justifyContent="flex-end">
                    <Flex gap={16}>
                      <Button disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />
                    </Flex>
                  </Flex>
                </Footer>
              </>
            )}
            {...rest}
          />
        </>
      )}
    </View>
  )
}

CreateAppView.defaultStyle = 'PANEL' as const

export default CreateAppView
