import React from 'react'
import { Field, Form } from 'react-final-form'
import { useRecoilValue } from 'recoil'
import type { Decorator } from 'final-form'

import App, { APP_LIST_LIMIT } from 'models/App'
import Button from 'components/buttons/Button'
import DashboardEditorBody from '../base/DashboardEditorBody'
import DashboardEditorHeader from '../base/DashboardEditorHeader'
import Flex from 'components/layout/Flex'
import IconInput from 'components/inputs/IconInput'
import TextInput from 'components/inputs/TextInput'
import useDashboard from 'hooks/useDashboard'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { AppListDocument, CreateAppInput, CreateAppMutation, useCreateAppMutation } from 'generated/schema'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { SidePaneFooter } from 'components/sidePane'
import { ViewParams, Views } from '../constants'
import type { ActiveViewProps } from '../DashboardEditor'

type FormValues = CreateAppInput

type Params = ViewParams[Views.CREATE_PROJECT]

const setIdentifier = createSetIdentifier(
  'name', 'identifier'
) as Decorator<FormValues>

const CreateProjectView = ({ onClose }: ActiveViewProps) => {
  const { dashboardEditorState, openDashboardEditorView } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { initialValues = {} } = params! as Params

  const [ createApp ] = useCreateAppMutation({
    onCompleted: async ({ createApp }: CreateAppMutation) => {
      openDashboardEditorView({ target: Views.PROJECT_DETAILS, params: { app: createApp } })
    }
  })

  const handleCreateApp = useSubmitHandler(createApp, {
    update: {
      strategy: 'APPEND',
      query: AppListDocument,
      dataKey: 'appsList',
      mutation: 'createApp',
      queryVariables: {
        limit: APP_LIST_LIMIT,
        order: [ { position: 'asc' } ]
      }
    }
  })

  const handleSubmit = (
    values: FormValues
  ) => handleCreateApp(values as CreateAppInput)

  return (
    <>
      <DashboardEditorHeader
        subtitle="New Project"
        heading="Projects"
        onClose={onClose}
      />
      <Form
        decorators={[
          setIdentifier
        ]}
        initialValues={{
          ...initialValues as FormValues,
          kind: 'PROJECT'
        }}
        onSubmit={handleSubmit}
        validate={(values) => App.validate(values, [ 'name', 'identifier' ])}
        subscription={{ submitting: true, pristine: true }}
        render={({ handleSubmit, submitting, pristine }) => (
          <>
            <DashboardEditorBody>
              <Flex as="form" direction="column" onSubmit={handleSubmit} gap={16}>
                <Field autoFocus checkRequired name="name" label="Name" component={TextInput} size="small" />
                <Field checkRequired name="identifier" label="Identifier" component={TextInput} size="small" />
                <Field
                  component={IconInput}
                  name="icon"
                  label="Icon"
                  placeholder="Choose Icon"
                  size="small"
                  type="text"
                />
                <input type="submit" style={{ display: 'none' }} />
              </Flex>
            </DashboardEditorBody>
            <SidePaneFooter variant="small" isSticky>
              <Flex gap={16} direction="row-reverse">
                <Button size="small" type="submit" disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />
              </Flex>
            </SidePaneFooter>
          </>
        )}
      />
    </>
  )
}

export default CreateProjectView
