import camelCase from 'lodash/camelCase'
import compact from 'lodash/compact'
import get from 'lodash/get'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'
import React, { useEffect, useState } from 'react'
import uniqBy from 'lodash/uniqBy'
import { OnChange } from 'react-final-form-listeners'
import { useForm } from 'react-final-form'
import { useRecoilValue } from 'recoil'

import * as schema from 'generated/schema'
import AddRecordView from 'components/views/graph/AddRecordView'
import Block from './Block'
import Flex from 'components/layout/Flex'
import JSONParseOr from 'lib/JSONParseOr'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import useDashboard from 'hooks/useDashboard'
import useExecuteOperationQuery from './wrappers/useExecuteOperationQuery'
import useSearch from 'hooks/useSearch'
import { FieldIdentifier } from 'models/Field'
import { ParameterField } from 'components/resource/GenericParamFields'
import { safeParseLiquid } from 'lib/templater'
import { useDashboardViewContext } from 'components/contexts/DashboardViewContext'
import { useViewDispatch } from 'hooks/useViewContext'
import type { BlockProps } from './Block'

type FormFieldBlockProps = BlockProps

const recordsMapper = (record: schema.CustomRecord & any) => mapValues(record.data, 'en_US')

function FormFieldBlock({
  blockRef,
  name,
  identifier,
  is_array: isArray,
  is_nullable: isNullable,
  field_type: fieldType,
  field_type_settings: fieldTypeSettings,
  block,
  options,
  ...others
}: FormFieldBlockProps) {
  const { switcher } = useDashboardViewContext()
  const { blockPropertiesState, updateBlockProperties } = useDashboard()

  const blockProperties = useRecoilValue(blockPropertiesState)

  const {
    resource: resourceId,
    operation: operationId,
    parameters: paramProperties,
    is_async: isAsync,
    filter
  } = fieldTypeSettings?.data_source_settings || {}

  const { data: { attributesList = [] } = {} } = schema.useAttributesListQuery({
    variables: {
      filter: {
        resourceId: {
          eq: resourceId
        }
      }
    },
    skip: !resourceId
  })

  const parsedFilters = JSONParseOr(safeParseLiquid(filter, blockProperties), {})

  const searchRecordsVariables: schema.InternalSearchRecordsQueryVariables = {
    input: {
      preview: true,
      resourceId,
      limit: isAsync ? 60 : 999,
      targetEnvironment: switcher?.data.environment?.id,
      filter: filter
        ? parsedFilters
        : undefined
    }
  }

  const searchableAttributes: string[] = compact((attributesList.map(
    (attr: any) => {
      if (!attr?.isArray
        && attr?.fieldType === FieldIdentifier.TEXT
      ) return camelCase(attr?.identifier)
      return ''
    }
  ) || []))

  const [ {
    data: { internalSearchRecords: searchRecords = [] } = {},
    loading,
    error
  }, onSearch ] = useSearch<
    schema.InternalSearchRecordsQuery,
    schema.InternalSearchRecordsQueryVariables
  >({
    query: schema.InternalSearchRecordsDocument,
    queryOptions: {
      variables: searchRecordsVariables,
      skip: !resourceId
    },
    keys: searchableAttributes
  })
  const {
    data: executeOperationData,
    loading: executeOperationLoading,
    error: executeOperationError
  } = useExecuteOperationQuery({
    context: blockProperties,
    operationId,
    arguments: paramProperties,
    targetEnvironment: switcher?.data.environment?.id
  })
  const labelKey = fieldTypeSettings?.label_key
  const valueKey = fieldTypeSettings?.value_key
  const metaKey = fieldTypeSettings?.meta_key

  const [ searchText, setSearchText ] = useState('')
  const filteredOperationRecords = executeOperationData?.executeQueryOperation?.filter(
    (record: any) => get(record, labelKey || '')
      ?.toLowerCase()
      .includes(searchText.toLowerCase())
        || get(record, valueKey || '')
          ?.toLowerCase()
          .includes(searchText.toLowerCase())
  )

  const settingsOptions = fieldTypeSettings?.options
  const getOptions = () => {
    if (resourceId) {
      return uniqBy(
        searchRecords
          .map(recordsMapper)
          .concat(defaultRecords.map(recordsMapper)),
        valueKey
      )
    }

    if (operationId) {
      return filteredOperationRecords
    }

    if (settingsOptions) {
      return settingsOptions
        .filter(Boolean)
        .map(({ label, value, key }: any) => {
          // when "use different values for key and label" is enabled
          // user can leave `key/value field` empty
          const finalValue = value ?? key ?? label
          return { label, value: finalValue }
        })
    }

    return options
  }

  const setSelectedOption = (selectedOption: Record<any, any>) => {
    updateBlockProperties(
      (currentBlockProperties) => ({
        [block.identifier]: {
          ...currentBlockProperties[block.identifier],
          selectedOption
        }
      })
    )
  }

  const parsedDefaultValue = JSONParseOr(
    safeParseLiquid(fieldTypeSettings?.default_value, blockProperties)
  )

  const parsedValue = JSONParseOr(
    safeParseLiquid(fieldTypeSettings?.value?.expression, blockProperties)
  )

  const isDefaultValuePresent = parsedDefaultValue !== undefined
    && parsedDefaultValue !== null
    && parsedDefaultValue.trim?.() !== ''

  const isValuePresent = parsedValue !== undefined
    && parsedValue !== null
    && parsedValue.trim?.() !== ''

  const [ { data: { internalSearchRecords: defaultRecords = [] } = {} } ] = useSearch<
    schema.InternalSearchRecordsQuery,
    schema.InternalSearchRecordsQueryVariables
  >({
    query: schema.InternalSearchRecordsDocument,
    queryOptions: {
      variables: {
        input: {
          resourceId,
          targetEnvironment: switcher?.data.environment?.id,
          filter: {
            [valueKey]: { eq: parsedDefaultValue }
          }
        }
      },
      skip: !resourceId || !isDefaultValuePresent || !isAsync
    },
    keys: searchableAttributes
  })
  const { openView } = useViewDispatch()

  const onAdd = () => openView({
    title: name,
    component: AddRecordView,
    params: {
      resourceId,
      switcher,
      operationMethod: 'CREATE',
      record: {
        data: mapValues(pickBy(mapValues(parsedFilters, 'eq'), Boolean), (v) => ({ en_US: v }))
      }
    },
    style: 'PANEL'
  })

  const showCustomLabel = !!resourceId

  const customLabel = (
    <Flex justifyContent="space-between" gap={16}>
      <Text
        color="dark500"
        fontSize={10}
        fontWeight="bold"
        textTransform="uppercase"
      >
        {fieldTypeSettings?.label || name}
      </Text>
      <Flex gap={8} alignItems="center">
        <TextLink
          as="button"
          type="button"
          fontSize={10}
          onClick={onAdd}
          mode="distinct"
        >
          Add new
        </TextLink>

      </Flex>
    </Flex>
  )

  const form = useForm()

  useEffect(() => {
    updateBlockProperties(
      (currentBlockProperties) => ({
        [block.identifier]: {
          ...currentBlockProperties[block.identifier],
          value: form.getState().initialValues[identifier]
        }
      })
    )

    return () => {
      updateBlockProperties(
        (currentBlockProperties) => ({
          [block.identifier]: {
            ...currentBlockProperties[block.identifier],
            value: undefined
          }
        })
      )
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Cleanup the form value when the block is removed
  useEffect(() => () => {
    if (identifier) form.change(identifier, undefined)
  }, [ form, identifier ])

  return (
    <Block direction="column" gap={10} masonryItemRef={blockRef} {...others}>
      {showCustomLabel && customLabel}
      <ParameterField
        prefix=""
        parameter={{
          name: !showCustomLabel ? name! : '',
          identifier,
          isArray,
          isNullable,
          fieldType,
          defaultValue: isDefaultValuePresent ? {
            type: 'fixed',
            value: parsedDefaultValue
          } : undefined,
          fieldTypeSettings: {
            ...fieldTypeSettings,
            ...(isValuePresent ? { value: parsedValue } : { value: undefined }),
            readOnly: fieldTypeSettings?.is_readonly,
            label: !showCustomLabel ? fieldTypeSettings?.label : '',
            labelKey,
            valueKey,
            metaKey,
            options: getOptions(),
            defaultOptions: getOptions(),
            onInputChange: setSearchText,
            loadOptions: isAsync
              ? (inputValue: string, callback: any) => {
                onSearch(inputValue, (data) => (
                  callback(data.internalSearchRecords.map(recordsMapper))))
              }
              : undefined,
            isLoading: loading || executeOperationLoading,
            hasError: error || executeOperationError,
            onAfterSelect: setSelectedOption,
            appendIcon: fieldTypeSettings?.icon_suffix?.name,
            appendText: fieldTypeSettings?.text_suffix?.value,
            prependIcon: fieldTypeSettings?.icon_prefix?.name,
            prependText: fieldTypeSettings?.text_prefix?.value
          }
        } as any}
      />
      <OnChange name={identifier}>
        {(value) => {
          updateBlockProperties(
            (currentBlockProperties) => ({
              [block.identifier]: {
                ...currentBlockProperties[block.identifier],
                value
              }
            })
          )
        }}
      </OnChange>
    </Block>
  )
}

export type { FormFieldBlockProps }

export default FormFieldBlock
