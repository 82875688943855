import React from 'react'
import { useField } from 'react-final-form'

import FormField from 'components/form/FormField'
import RadioInput from 'components/inputs/RadioInput'
import type { fieldProps } from 'components/contentEditors/generic/fields/fieldProps'
import type { RadioInputProps } from 'components/inputs/RadioInput'
import Flex from 'components/layout/Flex'
import FieldLabel from 'components/form/FieldLabel'
import FieldError from 'components/form/FieldError'
import InputHelpText from 'components/inputHelpText/InputHelpText'

type RadioFieldProps = Omit<RadioInputProps, 'input' | 'meta'> & fieldProps<'dropDown'>

const RadioField = ({ name, settings, label, ...others }: RadioFieldProps) => {
  const settingsOptions = settings?.options
    ?.filter(Boolean)
    .map(({ label, value, key }: any) => {
      // when "use different values for key and label" is enabled
      // user can leave `key/value field` empty
      const finalValue = value ?? key ?? label
      return { label, value: finalValue }
    })
  const field = useField(name, { subscription: {} })
  return (
    <Flex direction="column" gap={10}>
      <FieldLabel checkRequired={settings?.checkRequired} isTranslatable={settings?.isTranslatable}>
        {label}
      </FieldLabel>
      <Flex gap="24" wrap="wrap">
        {settingsOptions?.map((option) => (
          <FormField
            key={option.value}
            component={RadioInput}
            name={name}
            type="radio"
            {...others}
            label={option.label}
            value={option.value}
          />
        ))}
      </Flex>
      <FieldError error={FieldError.getError(field.meta)} />
      {settings?.helpText && <InputHelpText helpText={settings.helpText} />}
    </Flex>
  )
}

export default RadioField
