/* eslint-disable camelcase */
import kebabCase from 'lodash/kebabCase'
import orderBy from 'lodash/orderBy'
import pluralize from 'pluralize'
import React, { useCallback, useRef } from 'react'
import uuid from 'uuid-random'
import { BeforeCapture, DragDropContext, Draggable, DraggableChildrenFn, DraggableProvided, Droppable } from 'react-beautiful-dnd'
import { Field as FormField, FieldRenderProps, useField, useForm } from 'react-final-form'
import { object } from 'yup'

import DrawerBlock, { DRAWER_HEIGHT_CLOSED_SMALL } from 'components/blocks/DrawerBlock'
import FieldArray from 'components/form/FieldArray'
import FieldError from 'components/form/FieldError'
import FieldWrapper from 'components/contentEditors/generic/fields/FieldWrapper'
import Flex from 'components/layout/Flex'
import FormValuesField from 'components/form/FormValuesField'
import HintBox from 'components/hints/HintBox'
import IconButton from 'components/buttons/IconButton'
import InputHelpText from 'components/inputHelpText/InputHelpText'
import ParameterFields from 'components/resource/ParameterFields'
import SectionLoader from 'components/loaders/SectionLoader'
import TextLink from 'components/links/TextLink'
import useComponentDidMount from 'hooks/useComponentDidMount'
import useReorderFieldArray from 'hooks/useReorderFieldArray'
import { css, styled } from 'styles/stitches'
import { DataTypeFragmentFragment as DataTypeFragment, useDataTypesListQuery } from 'generated/schema'
import { ResolutionKind } from 'models/Attribute'
import type { FieldArrayChildrenProps } from 'components/form/FieldArray'
import type { fieldProps } from 'components/contentEditors/generic/fields/fieldProps'
import type { TextInputProps } from 'components/inputs/TextInput'
import type { Locale } from 'hooks/useActiveLocales'

type EmbeddedFieldProps = Omit<TextInputProps, 'input' | 'meta'> & fieldProps<'text'> & {
  currentLocale: Locale,
  defaultLocale: Locale,
  dataType: DataTypeFragment,
  targetEnvironmentId: string
  // resourceId?: string
}
// eslint-disable-next-line camelcase
type FieldData = { data_type_id: string, position?: number, data: object }

const EMBEDDED_ACTION_VERTICAL_MARGIN = 10
const EMBEDDED_CONTAINER_VERTICAL_MARGIN = 5
const EMBEDDED_DRAWER_EXPANDED_MARGIN = 10

const StyledDeleteIcon = styled(IconButton, {
  color: 'dark200'
})

const StyledAddEmbeddedFieldContainer = styled(Flex, {
  whiteSpace: 'nowrap',
  width: '100%',
  flexWrap: 'wrap',
  marginTop: EMBEDDED_CONTAINER_VERTICAL_MARGIN,

  '& [data-icon]': {
    color: 'primary400',
    display: 'flex'
  },

  '& [data-name]': {
    color: 'dark500',
    marginBottom: EMBEDDED_ACTION_VERTICAL_MARGIN
  }
})

type NestedFieldContainerProps = FieldRenderProps<Record<string, any>, any> & {
  dataType?: DataTypeFragment,
  dataTypes?: DataTypeFragment[],
  currentLocale?: Locale,
  defaultLocale?: Locale,
  targetEnvironmentId?: string,
  dragHandleProps?: DraggableProvided['dragHandleProps'],
  index?: number,
  isDragging?: boolean,
  isArray?: boolean,
  onRemove?: () => void
  // resourceId?: string
}

const getDrawerOpenedClassName = (index?: number) => css({
  marginBottom: EMBEDDED_DRAWER_EXPANDED_MARGIN,
  marginTop: index ? EMBEDDED_DRAWER_EXPANDED_MARGIN : 0
})

// To ensure that drawer placeholder height is DRAWER_HEIGHT_CLOSED_SMALL
// even if the drawer being dragged is open
const onBeforeCapture = ({ draggableId }: BeforeCapture) => {
  const element = document.querySelector(`[data-rbd-draggable-id="${draggableId}"]`) as HTMLElement

  element.style.setProperty('height', `${DRAWER_HEIGHT_CLOSED_SMALL}px`, 'important') //
}

const getEmbeddedFieldSchema = (settings: EmbeddedFieldProps['settings'] = {}) => {
  let schema = object()
    // happens when converting from not-repeated > repeated
    .typeError('must be an object')

  if (settings.checkRequired && !settings.hasFallbackLocale) {
    schema = schema.required()
  }

  return schema
}

const initialData = {}

const FILE_DATA_TYPE_ID = 'fdc39a93-3e52-46ba-8f62-b08b356be40f'

const NestedFieldContainer = ({
  dataType,
  dataTypes = [],
  currentLocale,
  defaultLocale,
  targetEnvironmentId,
  dragHandleProps,
  index,
  input,
  isArray,
  meta,
  onRemove,
  resourceId
}: NestedFieldContainerProps) => {
  const {
    data_type_id,
    dataTypeId = data_type_id
  } = input.value || ({} as any)

  const currentDataType = dataType || dataTypes.find(({ id }) => id === dataTypeId)
  const currentTitleFieldId = currentDataType?.settings.title_field_id

  const titleField = (currentDataType?.settings.fields || [])
    .find(({ id }: any) => currentTitleFieldId === id)

  const titleFieldIdentifier = titleField?.identifier || 'id'
  let titleFieldKey = [
    input.name,
    'data',
    titleFieldIdentifier,
    currentLocale?.identifier || defaultLocale?.identifier
  ].filter(Boolean).join('.')

  if (titleField?.dataTypeId === FILE_DATA_TYPE_ID) titleFieldKey += '.name'

  const fields = (currentDataType?.settings.fields || [])
    .filter((field: any) => field.resolutionKind !== ResolutionKind.COMPUTED)

  const removeField = (e: React.MouseEvent) => {
    e.stopPropagation()
    if (isArray) onRemove?.()
    else {
      input.onChange(undefined)
    }
  }

  const drawerAction = (
    <StyledDeleteIcon
      onClick={removeField}
      variant="dark"
      description="remove"
      name="trash"
      size={16}
    />
  )

  const error = FieldError.getError(meta)

  return (
    <>
      {currentDataType && (
        <FormValuesField fieldNames={[ titleFieldKey ]}>
          {(values) => (
            <DrawerBlock
              as={Flex}
              dragHandleProps={dragHandleProps}
              drawerAction={drawerAction}
              headerHeight="small"
              headerPadding="small"
              title={currentDataType.name!}
              openedClassName={getDrawerOpenedClassName(index)}
              subtitle={values[titleFieldKey]}
              key={`${kebabCase(currentDataType.name)}-${index || 0}`}
              contentPadding="small"
            >
              {() => (
                <Flex direction="column">
                  <FormField name={`${input.name}.data_type_id`} component="input" type="hidden" alwaysDirty initialValue={currentDataType.id} />
                  <FormField name={`${input.name}.data`} size="small" component="input" type="hidden" alwaysDirty initialValue={initialData} />

                  <Flex gap={20} direction="column">
                    <SectionLoader
                      empty={{ title: 'There are no fields.' }}
                      data={fields}
                    >
                      <ParameterFields
                        currentLocale={currentLocale}
                        defaultLocale={defaultLocale}
                        parameters={fields}
                        isUpdating={false}
                        prefix={`${input.name}.data`}
                        targetEnvironmentId={targetEnvironmentId}
                      />
                    </SectionLoader>
                  </Flex>
                </Flex>
              )}
            </DrawerBlock>
          )}
        </FormValuesField>
      )}
      {error && typeof error === 'string' && <HintBox size="small" variant="error" icon="error">{error}</HintBox>}
    </>
  )
}

const EmbeddedField = ({
  currentLocale,
  defaultLocale,
  label,
  name,
  dataType,
  helpText,
  isArray,
  isTranslatable,
  settings,
  shouldValidate,
  targetEnvironmentId,
  resourceId
}: EmbeddedFieldProps) => {
  const droppableId = useRef(uuid())
  const fieldsRef = useRef<FieldArrayChildrenProps<FieldData>>()
  const { input, meta } = useField(name)
  const { change } = useForm()
  const dataTypeIds = dataType.settings?.data_type_ids || []

  const validate = useCallback((value) => getEmbeddedFieldSchema(settings)
    .validate(value)
    .then(() => { })
    .catch((e) => e.message),
  [ settings ])

  // The embedded field value from backend isn't sorted by position
  // Need to remove this when we get values in order of position
  useComponentDidMount(() => {
    if (Array.isArray(input.value)) {
      const sortedInputValue = orderBy(input.value, [ 'position' ], [ 'asc' ])
      change(input.name, sortedInputValue)
    }
  })

  const isPolymorphic = dataTypeIds.length > 0

  const { data: { dataTypesList } = {}, loading, error } = useDataTypesListQuery({
    variables: {
      filter: {
        id: {
          in: dataTypeIds
        }
      }
    },
    skip: !dataTypeIds?.length
  })

  const onDragEnd = useReorderFieldArray(fieldsRef)

  const addEmbeddedField = (dataType: DataTypeFragment) => {
    if (isArray) {
      fieldsRef.current?.fields.push({ data_type_id: dataType.id, data: {} })
    } else change(name, { data_type_id: dataType.id })
  }

  const showAddEmbeddedFieldAction = isArray || !dataType

  const renderDraggableChildren: DraggableChildrenFn = (provided, snapshot, rubric) => (
    <div
      {...provided.draggableProps}
      ref={provided.innerRef}
    >
      <NestedFieldContainer
        currentLocale={currentLocale}
        defaultLocale={defaultLocale}
        targetEnvironmentId={targetEnvironmentId}
        dragHandleProps={provided.dragHandleProps}
        isArray
        index={rubric.source.index}
        dataTypes={dataTypesList as DataTypeFragment[]}
        dataType={isPolymorphic ? undefined : dataType}
        isDragging={snapshot.isDragging}
        onRemove={() => fieldsRef.current?.fields.remove(rubric.source.index)}
        input={{
          // Optimzations: using FormField is slower
          name: FieldArray.getFieldName(name, rubric.source.index),
          value: fieldsRef.current?.fields.value?.[rubric.source.index]!,
          onChange: (value) => fieldsRef.current?.fields.update(rubric.source.index, value),
          onBlur: () => {},
          onFocus: () => {}
        }}
        meta={fieldsRef.current?.meta!}
        resourceId={resourceId}
        {...(shouldValidate && { validate })}
      />
    </div>
  )

  return (
    <FieldWrapper label={label} isTranslatable={isTranslatable}>
      <SectionLoader data={dataType || dataTypesList} loading={loading} error={error} empty={{ title: 'There are no data types.' }}>
        {helpText && <InputHelpText helpText={helpText} />}
        {!isArray && (
          <NestedFieldContainer
            currentLocale={currentLocale}
            defaultLocale={defaultLocale}
            targetEnvironmentId={targetEnvironmentId}
            resourceId={resourceId}
            dataType={isPolymorphic ? undefined : dataType}
            dataTypes={dataTypesList as DataTypeFragment[]}
            input={input}
            meta={meta}
          />
        )}

        {isArray && (
          <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
            <Droppable
              renderClone={renderDraggableChildren}
              droppableId={droppableId.current}
            >
              {(droppableProvided) => (
                <Flex
                  direction="column"
                  gap={2}
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                >
                  <FieldArray
                    name={name}
                    fieldsRef={fieldsRef}
                    settings={settings}
                  >
                    {({ keys }) => keys.map((key, index) => (
                      <Draggable
                        disableInteractiveElementBlocking
                        draggableId={key}
                        index={index}
                        key={key}
                      >
                        {renderDraggableChildren}
                      </Draggable>
                    ))}
                  </FieldArray>
                  {droppableProvided.placeholder}
                  <div />
                </Flex>
              )}
            </Droppable>
          </DragDropContext>
        )}

        {showAddEmbeddedFieldAction && (
        <>
          <StyledAddEmbeddedFieldContainer
            alignItems="center"
            gap={10}
          >
            {isPolymorphic ? dataTypesList?.map((dataType) => (
              <TextLink
                fontSize={12}
                data-name
                as="button"
                type="button"
                variant="underlined"
                mode="subtle"
                fontWeight="bold"
                onClick={() => addEmbeddedField(dataType)}
                key={dataType.id}
              >
                {isArray ? 'Add' : 'Enter'} {pluralize.singular((dataType.name) || '')}
              </TextLink>
            )) : (
              <TextLink
                fontSize={12}
                data-name
                as="button"
                type="button"
                variant="underlined"
                mode="subtle"
                fontWeight="bold"
                onClick={() => addEmbeddedField(dataType)}
                key={dataType.name}
              >
                {isArray ? 'Add' : 'Enter'} {pluralize.singular((dataType.name) || '')}
              </TextLink>
            )}
          </StyledAddEmbeddedFieldContainer>
        </>
        )}
      </SectionLoader>
    </FieldWrapper>
  )
}

export type { EmbeddedFieldProps }
export default EmbeddedField
