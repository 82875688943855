/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'

import Card from 'components/card/Card'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import GenericObjectRender from './GenericObjectRenderer'
import Grid from 'components/layout/Grid'
import Label from 'components/typography/Label'
import rgba from 'lib/rgba'
import Text from 'components/typography/Text'
import ToggleInput from 'components/inputs/ToggleInput'
import { colorVars } from 'styles/theme'
import { Attribute, useDataTypeQuery } from 'generated/schema'
import IconButton from 'components/buttons/IconButton'
import TextRenderer from 'components/renderers/TextRenderer'
import { getRenderer } from 'components/blocks/DetailsBlock'

type ConfigurationsProps = {
  fieldPrefix?: string
}

type RendererProps = {
  attribute?: Attribute,
  displaySettings: Attribute['displayTypeSettings'],
  data: any
}

const Renderer = ({ attribute, data, displaySettings = { fontWeight: 'semibold' } }: RendererProps) => {
  const [ isOpen, setIsOpen ] = useState(false)

  const { data: { dataType = attribute?.dataType } = {}, loading, error } = useDataTypeQuery({
    variables: {
      id: attribute?.dataTypeId
    },
    skip: !!attribute?.dataType || !attribute
  })

  if (!attribute) {
    return <GenericObjectRender data={data} displaySettings={displaySettings} />
  }

  if (!dataType) {
    if (loading) {
      return (
        <Card
          width="100%"
          css={{
            border: '1px solid',
            borderColor: rgba(colorVars.dark100rgb, 0.5)
          }}
          padding="compact"
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            grow={1}
            direction="row"
            gap={8}
          >

            <Text>Loading...</Text>
          </Flex>
        </Card>
      )
    }

    if (error) {
      return (
        <Card
          width="100%"
          css={{
            border: '1px solid',
            borderColor: rgba(colorVars.dark100rgb, 0.5)
          }}
          padding="compact"
        >
          <Flex
            justifyContent="space-between"
            alignItems="center"
            grow={1}
            direction="row"
            gap={8}
          >

            <Text>An unexpected error occurred.</Text>
          </Flex>
        </Card>
      )
    }
  }

  if (dataType?.kind === 'OBJECT') {
    if (attribute.isArray) {
      return data?.length
        ? (
          <Flex direction="column" gap={6}>
            {data.map((datum: any, index: number) => (
              <Renderer
                key={index}
                attribute={{ ...attribute, isArray: false }}
                displaySettings={attribute.displayTypeSettings}
                data={datum}
              />
            )) }
          </Flex>
        )
        : <Text>-</Text>
    }

    const titleFieldId = dataType.settings?.title_field_id
    const titleField = dataType.settings?.fields?.find((field: any) => field.id === titleFieldId)
    if (!data) return null
    return (
      <Flex direction="column" gap={10}>
        {!isOpen
          ? (
            <Card
              width="100%"
              css={{
                border: '1px solid',
                borderColor: rgba(colorVars.dark100rgb, 0.5)
              }}
              onClick={() => setIsOpen(!isOpen)}
              padding="compact"
            >
              <Flex
                justifyContent="space-between"
                alignItems="center"
                grow={1}
                direction="row"
                gap={8}
              >
                <Text lineHeight="compact" fontWeight="semibold" grow={1} fontSize="14" truncate css={{ width: 0 }}>
                  {(titleField && data.data[titleField.identifier]?.en_US) || <TextRenderer dataKey="data" rowData={data} />}
                </Text>
                <IconButton description="Open" color="dark500" size={12} name="arrow-right" css={{ margin: -6 }} />
              </Flex>
            </Card>
          )
          : (
            <Card
              width="100%"
              css={{
                border: '1px solid',
                borderColor: rgba(colorVars.dark100rgb, 0.5)
              }}
              padding="compact"
            >
              <Flex
                justifyContent="space-between"
                grow={1}
                direction="row"
                gap={8}
              >
                <Flex grow={1} direction="column" gap="16">
                  {dataType?.settings?.fields?.map((field: any, index: number) => (
                    <Flex key={index} direction="column" gap="10">
                      <Label>{field.name}</Label>
                      <Renderer
                        key={index}
                        attribute={field}
                        displaySettings={field.displayTypeSettings}
                        data={data.data[field.identifier]?.en_US}
                      />
                    </Flex>
                  ))}
                </Flex>
                <IconButton onClick={() => setIsOpen(!isOpen)} color="dark500" size={12} name="arrow-down" description="Close" css={{ margin: -6 }} />
              </Flex>
            </Card>
          )}
      </Flex>
    )
  }

  const NestedRenderer = getRenderer(attribute.displayType as any) as any

  return <NestedRenderer attribute={attribute} data={data} displaySettings={{ fontWeight: 'semibold', ...displaySettings }} />
}

const Configurations = ({ fieldPrefix = 'displayTypeSettings.' }: ConfigurationsProps) => (
  <Grid alignItems="center" gap={16}>
    <FormField
      name={`${fieldPrefix}collapsible`}
      component={ToggleInput}
      label="Collapsible?"
      type="checkbox"
    />
    <FormField
      name={`${fieldPrefix}show_container`}
      component={ToggleInput}
      label="Show container?"
      type="checkbox"
    />
  </Grid>
)

export default {
  Configurations
}

export { Renderer as EmbeddedRenderer }
