/* eslint-disable no-nested-ternary */
import arrayMutators from 'final-form-arrays'
import kebabCase from 'lodash/kebabCase'
import React, { useEffect, useMemo } from 'react'
import { Form, FormRenderProps, useForm } from 'react-final-form'
import { useRecoilValue } from 'recoil'
import type { Decorator } from 'final-form'

import Button from 'components/buttons/Button'
import CodeEditorInput from 'components/inputs/CodeEditorInput'
import DashboardEditorBody from './base/DashboardEditorBody'
import DashboardEditorHeader from './base/DashboardEditorHeader'
import FieldTypeConfig from './FieldTypeConfig'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import LiveBlockEditorOrchestrator from './LiveBlockEditorOrchestrator'
import Tab from 'components/tabs/Tab'
import Tabs from 'components/tabs/Tabs'
import TextField from 'components/contentEditors/generic/fields/TextField'
import ToggleInput from 'components/inputs/ToggleInput'
import useDashboard, { DashboardEditorView } from 'hooks/useDashboard'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { css } from 'styles/stitches'
import { SidePaneFooter } from 'components/sidePane'
import { useDashboardEditorContextProvider } from './DashboardEditorProvider'
import { useUpsertViewMutation } from 'generated/schema'
import type { ActiveViewProps } from './DashboardEditor'
import type { Views } from './constants'

const classes = {
  wrapper: css({
    marginBottom: 30
  })
}

const EditBlockForm = ({ handleSubmit, submitting }: FormRenderProps) => {
  const form = useForm()
  const { viewState } = useDashboard()
  const { urn } = useDashboardEditorContextProvider()
  const view = useRecoilValue(viewState(urn))
  const viewName = [ view.app?.name, view.resource?.name, view.name ].filter(Boolean).join(' > ')
  const title = viewName ? `View: ${viewName}` : 'Dashboard Editor'
  const subtitle = 'Edit Form Field Block'

  return (
    <>
      <DashboardEditorHeader
        heading={title}
        subtitle={subtitle}
        onClose={form.mutators.onClose}
      />
      <DashboardEditorBody css={{ paddingTop: 0 }}>
        <Flex as="form" direction="column" onSubmit={handleSubmit}>
          <Tabs wrapperClassName={classes.wrapper}>
            <Tab index={0} label="General" alwaysMounted>
              <Flex direction="column" gap={16}>
                <TextField autoFocus shouldValidate settings={{ checkRequired: true }} name="name" label="Name" size="small" />
                <TextField shouldValidate settings={{ checkRequired: true }} name="identifier" label="Identifier" size="small" />
                <FormField
                  component={ToggleInput}
                  name="is_array"
                  label="Array"
                  helpText="Allow multiple values?"
                  size="small"
                  type="checkbox"
                />
                <FormField
                  component={ToggleInput}
                  name="is_nullable"
                  label="Nullable"
                  helpText="Allow blank values?"
                  size="small"
                  type="checkbox"
                />
              </Flex>
            </Tab>
            <Tab index={1} label="Appearance" alwaysMounted>
              <FieldTypeConfig />
            </Tab>
            <Tab index={2} label="Advanced" alwaysMounted>
              <Flex direction="column" gap={24}>
                <FormField
                  name="_identifier"
                  label="Identifier"
                  size="small"
                />
                <FormField
                  name="visibility_criteria.expression"
                  label="Visibility Criteria"
                  size="small"
                  component={CodeEditorInput}
                />
              </Flex>
            </Tab>
          </Tabs>
          <LiveBlockEditorOrchestrator />
          <input type="submit" style={{ display: 'none' }} />
        </Flex>
      </DashboardEditorBody>

      <SidePaneFooter variant="small" isSticky>
        <Button disabled={submitting} type="submit" label="Save Changes" size="small" onClick={handleSubmit} />
      </SidePaneFooter>
    </>
  )
}

const setIdentifier = createSetIdentifier(
  'name', 'identifier'
) as Decorator<any>

const decorators = [
  setIdentifier
]

const DEFAULT_VISIBILITY_CRITERIA = Object.freeze({
  kind: 'LIQUID',
  expression: ''
})

function AddFormFieldBlockView({ onClose }: ActiveViewProps) {
  const {
    selectBlock,
    selectedBlockState,
    getBlocks,
    getOperations,
    dashboardEditorState,
    blockState,
    resetDashboardEditorStack
  } = useDashboard()

  const { params: { block } = {} } = useRecoilValue<DashboardEditorView<Views.EDIT_BLOCK>>(
    dashboardEditorState
  )

  // eslint-disable-next-line no-underscore-dangle
  const _selectedBlock = useRecoilValue(selectedBlockState)! || block
  const selectedBlock = useRecoilValue(blockState(_selectedBlock.id))

  const [ upsertView ] = useUpsertViewMutation()

  const handleUpsertBlock = useSubmitHandler(upsertView, { successAlert: { message: 'Your changes were saved.' } })
  const { urn } = useDashboardEditorContextProvider()

  const handleSubmit = async () => {
    const [ blocks, operations ] = await Promise.all([
      getBlocks(urn),
      getOperations()
    ])

    return handleUpsertBlock({
      urn,
      blocks,
      operations
    })
  }

  const renderForm = useMemo(() => (props: FormRenderProps) => (
    <EditBlockForm {...props} />
  ), [])

  useEffect(() => () => selectBlock(null), [ selectBlock ])

  useEffect(() => {
    if (!selectedBlock) {
      resetDashboardEditorStack()
    }
  }, [ selectedBlock, resetDashboardEditorStack ])

  if (!selectedBlock) return null

  return (
    <Form
      key={selectedBlock.id}
      decorators={decorators}
      mutators={{ ...arrayMutators, onClose }}
      keepDirtyOnReinitialize
      initialValues={{
        _identifier: selectedBlock.identifier || `${kebabCase(selectedBlock.type).toLowerCase()}-${selectedBlock.id.slice(0, 8)}`,
        // seed identifier to be used as form field name
        identifier: `${selectedBlock.properties.field_type || 'text-field'}-${selectedBlock.id.slice(0, 8)}`,
        visibility_criteria: selectedBlock.visibility_criteria || DEFAULT_VISIBILITY_CRITERIA,
        ...selectedBlock.properties,
        actions: selectedBlock.actions
      }}
      subscription={{ submitting: true }}
      onSubmit={handleSubmit}
      render={renderForm}
    />
  )
}

export default AddFormFieldBlockView
