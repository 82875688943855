import pluralize from 'pluralize'
import React, { useRef } from 'react'
import uuid from 'uuid-random'
import { BeforeCapture, DragDropContext, Draggable, DraggableChildrenFn, DraggableProvided, Droppable } from 'react-beautiful-dnd'
import { ErrorBoundary } from 'react-error-boundary'
import { Field as FormField, FieldRenderProps } from 'react-final-form'

import DrawerBlock, { DRAWER_HEIGHT_CLOSED_SMALL } from 'components/blocks/DrawerBlock'
import FieldArray from 'components/form/FieldArray'
import FieldError from 'components/form/FieldError'
import FieldWrapper from 'components/contentEditors/generic/fields/FieldWrapper'
import Flex from 'components/layout/Flex'
import FormValuesField from 'components/form/FormValuesField'
import HintBox from 'components/hints/HintBox'
import IconButton from 'components/buttons/IconButton'
import ParameterFields from './ParameterFields'
import reportError from 'lib/reportError'
import SectionLoader from 'components/loaders/SectionLoader'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import useReorderFieldArray from 'hooks/useReorderFieldArray'
import { css, styled } from 'styles/stitches'
import { Kind } from 'models/Relationship'
import { PARAMETERS_LIST_LIMIT } from 'models/Resource'
import { useParametersListQuery, Parameter, useOperationsListQuery, Operation, Relationship } from 'generated/schema'
import type { FieldArrayChildrenProps } from 'components/form/FieldArray'
import type { Locale } from 'hooks/useActiveLocales'

const WRAPPER_ACTION_VERTICAL_MARGIN = 10
const WRAPPER_VERTICAL_MARGIN = 5
const DRAWER_EXPANDED_MARGIN = 10

type RelativeParamFieldsProps = {
  currentLocale?: Locale,
  defaultLocale?: Locale,
  isUpdating: boolean,
  operation?: Operation,
  parameters: Parameter[],
  prefix: string,
  resourceId?: string,
  targetEnvironmentId?: string
}

type FieldData = object

type FieldContainerProps = FieldRenderProps<Record<string, any>, any> & {
  currentLocale?: Locale,
  defaultLocale?: Locale,
  dragHandleProps: DraggableProvided['dragHandleProps'],
  index?: number,
  isDragging?: boolean,
  isUpdating: boolean,
  onRemove: () => void,
  operation: Operation,
  operationId: Operation['id'],
  parameter: Parameter,
  prefix: string,
  resourceId: string,
  targetAttribute: Relationship['targetAttribute'],
  targetEnvironmentId?: string
}

const StyledDeleteIcon = styled(IconButton, {
  color: 'dark200'
})

const StyledWrapper = styled(Flex, {
  whiteSpace: 'nowrap',
  width: '100%',
  flexWrap: 'wrap',
  marginTop: WRAPPER_VERTICAL_MARGIN,

  '& [data-icon]': {
    color: 'primary400',
    display: 'flex'
  },

  '& [data-name]': {
    color: 'dark500',
    marginBottom: WRAPPER_ACTION_VERTICAL_MARGIN
  }
})

const getDrawerOpenedClassName = (index?: number) => css({
  marginBottom: DRAWER_EXPANDED_MARGIN,
  marginTop: index ? DRAWER_EXPANDED_MARGIN : 0
})

const ErrorFallback = () => (
  <Text>
    An unexpected error occured, our engineering team is looking into it
  </Text>
)

const onBeforeCapture = ({ draggableId }: BeforeCapture) => {
  const element = document.querySelector(`[data-rbd-draggable-id="${draggableId}"]`) as HTMLElement
  element.style.setProperty('height', `${DRAWER_HEIGHT_CLOSED_SMALL}px`, 'important')
}

const initialData = {}

const FieldContainer = ({
  currentLocale,
  defaultLocale,
  dragHandleProps,
  index,
  input,
  isDragging,
  isUpdating,
  meta,
  onRemove,
  operation,
  parameter,
  prefix,
  resourceId,
  targetAttribute,
  targetEnvironmentId
}: FieldContainerProps) => {
  const { name } = parameter
  const { initial } = meta
  const error = FieldError.getError(meta)

  const {
    data: { parametersList = [] } = {},
    loading: parametersListLoading,
    error: parametersListError
  } = useParametersListQuery({
    variables: {
      filter: {
        operationId: { eq: operation?.id },
        identifier: { neq: targetAttribute.identifier }
      },
      order: [ {
        position: 'asc'
      } ],
      limit: PARAMETERS_LIST_LIMIT
    },
    skip: !operation
  })

  const drawerAction = (
    <StyledDeleteIcon
      onClick={onRemove}
      variant="dark"
      description="remove"
      name="trash"
      size={16}
    />
  )

  return (
    <>
      <FormValuesField fieldNames={[ name ]}>
        {(values) => (
          <DrawerBlock
            as={Flex}
            dragHandleProps={dragHandleProps}
            drawerAction={drawerAction}
            headerHeight="small"
            headerPadding="small"
            defaultOpened={!isDragging && !initial}
            title={pluralize.singular(name)}
            openedClassName={getDrawerOpenedClassName(index)}
            subtitle={values[name]}
          >
            {() => (
              <Flex direction="column">
                <FormField name={`${prefix}.${input.name}`} component="input" type="hidden" alwaysDirty initialValue={initialData} />
                <Flex gap={20} direction="column">
                  <SectionLoader
                    empty={{ title: 'There are no fields.' }}
                    loading={parametersListLoading}
                    error={parametersListError}
                    data={parametersList}
                  >
                    <ParameterFields
                      currentLocale={currentLocale}
                      defaultLocale={defaultLocale}
                      isUpdating={isUpdating}
                      prefix={`${prefix}.${input.name}`}
                      resourceId={resourceId}
                      targetEnvironmentId={targetEnvironmentId}
                      parameters={parametersList as Parameter[]}
                      operation={operation as Operation}
                    />
                  </SectionLoader>
                </Flex>
              </Flex>
            )}
          </DrawerBlock>
        )}
      </FormValuesField>
      {error && typeof error === 'string' && <HintBox size="small" variant="error" icon="error">{error}</HintBox>}
    </>
  )
}

const RelationshipField = ({
  currentLocale,
  defaultLocale,
  isUpdating,
  parameter,
  prefix,
  resourceId,
  targetEnvironmentId
}: RelativeParamFieldsProps & { parameter: Parameter }) => {
  const droppableId = useRef(uuid())
  const fieldsRef = useRef<FieldArrayChildrenProps<FieldData>>()
  const { id, name, identifier, relationship } = parameter
  const { target, targetAttribute, kind } = relationship || {}

  const isHasOne = kind === Kind.HAS_ONE
  const isFieldsAvailable = fieldsRef.current?.keys?.length! > 0

  const showAddNew = !isHasOne || !isFieldsAvailable

  const {
    data: { operationsList = [] } = {}
  } = useOperationsListQuery({
    variables: {
      filter: {
        resourceId: { eq: target?.id }
      }
    },
    skip: !target
  })

  const createOperation = operationsList.find((op) => op.graphqlKind === 'MUTATION' && op.method === 'CREATE')

  const onDragEnd = useReorderFieldArray(fieldsRef)

  const renderDraggableChildren: DraggableChildrenFn = (provided, snapshot, rubric) => (
    <div
      {...provided.draggableProps}
      ref={provided.innerRef}
    >
      <FormField
        component={FieldContainer}
        currentLocale={currentLocale}
        defaultLocale={defaultLocale}
        dragHandleProps={provided.dragHandleProps}
        index={rubric.source.index}
        isDragging={snapshot.isDragging}
        isUpdating={isUpdating}
        name={FieldArray.getFieldName(identifier, rubric.source.index)}
        onRemove={() => fieldsRef.current?.fields.remove(rubric.source.index)}
        operation={createOperation}
        parameter={parameter}
        prefix={prefix}
        resourceId={resourceId}
        targetAttribute={targetAttribute}
        targetEnvironmentId={targetEnvironmentId}
      />
    </div>
  )

  return (
    <StyledWrapper direction="column" gap={isFieldsAvailable ? 16 : 8} key={id}>
      <Text fontSize={14} fontWeight="bold">{name}</Text>
      <FieldWrapper>
        <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
          <Droppable
            renderClone={renderDraggableChildren}
            droppableId={droppableId.current}
          >
            {(droppableProvided) => (
              <Flex
                direction="column"
                gap={2}
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                <FieldArray
                  name={`${prefix}.${identifier}`}
                  fieldsRef={fieldsRef}
                >
                  {({ keys }) => keys.map((key, index) => (
                    <Draggable
                      disableInteractiveElementBlocking
                      draggableId={key}
                      index={index}
                      key={key}
                      // TODO: enable dragging once drag issues are resolved
                      isDragDisabled
                    >
                      {renderDraggableChildren}
                    </Draggable>
                  ))}
                </FieldArray>
                {droppableProvided.placeholder}
                <div />
              </Flex>
            )}
          </Droppable>
        </DragDropContext>
      </FieldWrapper>
      {showAddNew && (
        <TextLink
          alignSelf="flex-start"
          fontSize={12}
          as="button"
          type="button"
          variant="underlined"
          mode="subtle"
          fontWeight="bold"
          onClick={() => fieldsRef.current?.fields.push({})}
        >
          {`Add new ${pluralize.singular(name)}`}
        </TextLink>
      )}
    </StyledWrapper>
  )
}

const RelativeParamFields = (props: RelativeParamFieldsProps) => {
  const { parameters, prefix } = props

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={reportError}>
      {parameters.map((parameter) => (
        <RelationshipField key={`${prefix}.${parameter.id}`} parameter={parameter} {...props} />
      ))}
    </ErrorBoundary>
  )
}

export default RelativeParamFields
