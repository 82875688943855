import React, { useContext } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { Link } from 'react-router-dom'

import * as mixins from 'styles/mixins'
import Dashboard from 'models/Dashboard'
import DashboardContext from 'components/contexts/DashboardContext'
import Flex from 'components/layout/Flex'
import Icon, { withPreload } from 'components/icons/Icon'
import IconButton from 'components/buttons/IconButton'
import InternalContext from 'components/contexts/InternalContext'
import rgba from 'lib/rgba'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import { Color, colorVars } from 'styles/theme'
import { css, styled } from 'styles/stitches'
import { PERSONAL_DASHBOARD, WORKSPACE_DASHBOARD } from 'lib/generateDashboard'
import { Popover, PopoverBody, PopoverContainer, PopoverDivider, PopoverFooter, PopoverItem, PopoverSubheader } from 'components/popover'
import { ROLE_IDS } from 'models/Role'
import { TourGuideContext } from 'components/providers/TourProvider'
import { useCurrentAccountContext } from 'components/contexts/CurrentAccountContext'
import { useDashboardsListQuery } from 'generated/schema'

type ColorMap = { [key: string]: Color }

const POPOVER_WIDTH = 188

const POPOVER_ITEM_ICON_COLORS: ColorMap = {
  normal: 'dark100',
  hover: 'primary300'
}

const StyledDashboardSwitcherItem = styled(Flex, {
  ...mixins.transition('simple'),
  cursor: 'pointer',
  width: 'calc(100% - 20px)',
  position: 'relative',
  left: -1,
  height: 44,
  paddingX: 14,
  userSelect: 'none',

  backgroundColor: rgba(colorVars.light100rgb, 0.13),
  borderRadius: 8,

  '& [data-collapsible]': {
    ...mixins.transition('simple'),
    opacity: 0.7
  },

  '& [data-arrow]': {
    ...mixins.transition('fastIn')
  },

  variants: {
    active: {
      false: {
        '&:hover': {
          backgroundColor: rgba(colorVars.secondary400rgb, 0.3)
        }
      },
      true: {
        backgroundColor: rgba(colorVars.light100rgb, 0.33),

        '& [data-arrow]': {
          transform: 'rotate(180deg)'
        },
        '& [data-collapsible]': {
          opacity: 1
        }
      }
    }
  }
})

const StyledDashboardPopover = styled(Popover, {
  borderRadius: 4,
  width: POPOVER_WIDTH
})

const StyledIcon = styled(Icon, {
  color: POPOVER_ITEM_ICON_COLORS.normal
})

const popoverItemClass = css({
  backgroundColor: 'light100',
  paddingX: 18,
  paddingY: 12,

  '& [data-text]': {
    width: 0,
    flexGrow: 1
  },

  '&[data-active="true"]': {
    border: 'none'
  }
})

const ICONS_MAP = {
  dashboard: withPreload(StyledIcon, 'dashboard'),
  manage: withPreload(StyledIcon, 'manage')
} as const

function DashboardSwitcherItem({ icon }: { icon?: React.ReactNode }) {
  const { showNextStep } = useContext(TourGuideContext)!
  const { data: { dashboardsList = [] } = {}, loading } = useDashboardsListQuery({
    variables: {
      order: [ { position: 'asc' } ],
      limit: 100
    }
  })

  const { openDashboardEditor, openJumpMenu } = useContext(DashboardContext)!
  const { currentDashboard } = useContext(InternalContext)!
  const currentAccount = useCurrentAccountContext()!

  const showWorkspaceDashboard = currentAccount.roleMemberships.some((rm) => (
    rm.roleId === ROLE_IDS.WORKSPACE_OWNER
  ))

  if (loading && dashboardsList.length === 0) {
    return null
  }

  return (
    <PopoverContainer
      onPopoverOpen={showNextStep}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [ 0, 4 ]
          }
        }
      ]}
    >
      {({ isActive, closePopover, openPopover, ...otherToggleProps }) => (
        <StyledDashboardSwitcherItem
          as="button"
          alignItems="center"
          gap={14}
          active={isActive}
          {...otherToggleProps}
          className="tg--dashboard-switcher"
        >
          <Flex as={Text} color="light100" shrink={0}>
            {icon || <Icon name="dashboard" data-icon size={20} />}
          </Flex>
          <Text data-collapsible grow={1} color="light100" fontSize={12} truncate>
            {currentDashboard?.name}
          </Text>
          <Flex as={Text} data-collapsible shrink={0} color="light100">
            <Icon name="arrow-down" data-arrow size={10} />
          </Flex>
        </StyledDashboardSwitcherItem>
      )}
      {(popoverProps) => (
        <StyledDashboardPopover {...popoverProps}>
          <PopoverSubheader
            css={{
              paddingX: 18,
              paddingY: 12
            }}
            title={(
              <Flex as="span" gap={12} alignItems="center" justifyContent="space-between">
                Dashboards
                <IconButton
                  as={Link}
                  css={{
                    margin: -6 // inset
                  }}
                  to="/~workspace/admin/dashboards"
                  name="settings"
                  description="Manage dashboards"
                  onClick={() => popoverProps.closePopover()}
                />
              </Flex>
            )}
          />
          <PopoverDivider />
          <PopoverBody>
            <Scrollbars autoHeight hideTracksWhenNotNeeded autoHeightMax="70vh">
              {dashboardsList.map((dashboard) => {
                const ResolvedIcon = ICONS_MAP[Dashboard.dashboardIcon(dashboard)]
                return (
                  <PopoverItem
                    key={dashboard.identifier}
                    icon={<ResolvedIcon size={16} />}
                    text={dashboard.name}
                    to={`/~${dashboard.identifier}`}
                    forceActive={dashboard.identifier === currentDashboard?.identifier}
                    focusActive
                    scrollActiveIntoView
                    onClick={() => popoverProps.closePopover()}
                    css={popoverItemClass}
                  />
                )
              })}
              <PopoverDivider />

              {showWorkspaceDashboard && (
                <PopoverItem
                  icon={<ICONS_MAP.manage size={16} />}
                  text={WORKSPACE_DASHBOARD.name}
                  to={`/~${WORKSPACE_DASHBOARD.identifier}`}
                  forceActive={WORKSPACE_DASHBOARD.identifier === currentDashboard?.identifier}
                  focusActive
                  scrollActiveIntoView
                  onClick={() => popoverProps.closePopover()}
                  css={popoverItemClass}
                />
              )}

              <PopoverItem
                icon={<ICONS_MAP.manage size={16} />}
                text={PERSONAL_DASHBOARD.name}
                to={`/~${PERSONAL_DASHBOARD.identifier}`}
                forceActive={PERSONAL_DASHBOARD.identifier === currentDashboard?.identifier}
                focusActive
                scrollActiveIntoView
                onClick={() => popoverProps.closePopover()}
                css={popoverItemClass}
              />
            </Scrollbars>
          </PopoverBody>
          <PopoverFooter direction="column">
            <PopoverItem
              text="Quick Jump"
              append={(
                <Flex gap={2}>
                  <Text
                    as="kbd"
                    fontSize={12}
                  >
                    ⌘
                  </Text>
                  <Text
                    as="kbd"
                    fontSize={12}
                    fontFamily="code"
                  >
                    K
                  </Text>
                </Flex>
              )}
              onClick={() => {
                openJumpMenu()
                popoverProps.closePopover()
              }}
              css={popoverItemClass}
            />
            <PopoverItem
              text="Edit Dashboard"
              append={(
                <Flex gap={2}>
                  <Text
                    as="kbd"
                    fontSize={12}
                  >
                    ⌘
                  </Text>
                  <Text
                    as="kbd"
                    fontSize={12}
                  >
                    ⇧
                  </Text>
                  <Text
                    as="kbd"
                    fontSize={12}
                    fontFamily="code"
                  >
                    E
                  </Text>
                </Flex>
              )}
              onClick={() => {
                openDashboardEditor()
                popoverProps.closePopover()
              }}
              css={popoverItemClass}
            />
          </PopoverFooter>
        </StyledDashboardPopover>
      )}
    </PopoverContainer>
  )
}

export default DashboardSwitcherItem
