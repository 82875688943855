import React from 'react'
import { Field, Form, FormProps } from 'react-final-form'
import type { Decorator, FormApi } from 'final-form'

import App from 'models/App'
import Button from 'components/buttons/Button'
import IconInput from 'components/inputs/IconInput'
import TextInput from 'components/inputs/TextInput'
import Flex from 'components/layout/Flex'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { CreateSpaceInput, SpacesListDocument, SpacesListQueryVariables, UpdateSpaceInput, useCreateSpaceMutation, useUpdateSpaceMutation } from 'generated/schema'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import type { ViewProps } from 'components/views'

type FormValues = Partial<CreateSpaceInput> | UpdateSpaceInput

type ViewParamsType = {
  initialValues: FormValues,
  queryVariables: SpacesListQueryVariables
}

const setIdentifier = createSetIdentifier(
  'name', 'identifier'
) as Decorator<FormValues>

function CreateSpaceView({
  closeView, onRequestClose, params, viewStyleComponent: View, ...other
}: ViewProps<ViewParamsType>) {
  const { initialValues, queryVariables, ...rest } = params

  const isUpdating = 'id' in initialValues

  const title = `${isUpdating ? 'Edit' : 'New'} Space`

  const [ createSpace ] = useCreateSpaceMutation({
    onCompleted: onRequestClose
  })

  const [ updateSpace ] = useUpdateSpaceMutation({
    onCompleted: onRequestClose
  })

  const handleCreateSpace = useSubmitHandler(createSpace, {
    update: {
      strategy: 'APPEND',
      query: SpacesListDocument,
      dataKey: 'spacesList',
      mutation: 'createSpace',
      queryVariables
    }
  })

  const handleUpdateSpace = useSubmitHandler(updateSpace, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateSpace',
      typename: 'Space'
    }
  })

  const handleSubmit = (
    values: FormValues, form: FormProps<FormValues>['form']
  ) => {
    if ('id' in initialValues) {
      return handleUpdateSpace(values as UpdateSpaceInput, form as FormApi<any>)
    }
    return handleCreateSpace(values as CreateSpaceInput)
  }

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body, Footer }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          <Form
            decorators={[
              setIdentifier
            ]}
            initialValues={initialValues}
            keepDirtyOnReinitialize
            onSubmit={handleSubmit}
            validate={(values) => App.validate(values, [ 'name', 'identifier' ])}
            subscription={{ submitting: true, pristine: true }}
            render={({ handleSubmit, submitting, pristine }) => (
              <>
                <Body>
                  <Flex direction="column" as="form" onSubmit={handleSubmit}>
                    <Flex direction="column" gap={16}>
                      <Field autoFocus checkRequired name="name" label="Name" component={TextInput} size="small" helptext="Example: Website, Acme Marketing, Site, Acme Portal" />
                      <Field checkRequired name="identifier" label="Identifier" component={TextInput} size="small" helpText="Used in code. Avoid modifying this." />
                      <Field
                        component={IconInput}
                        name="icon"
                        label="Icon"
                        placeholder="Choose Icon"
                        size="small"
                        type="text"
                      />
                    </Flex>
                    <input type="submit" style={{ display: 'none' }} />
                  </Flex>
                </Body>
                <Footer>
                  <Flex alignItems="center" grow={1} justifyContent="flex-end">
                    <Button disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />
                  </Flex>
                </Footer>
              </>
            )}
            {...rest}
          />
        </>
      )}
    </View>
  )
}

CreateSpaceView.defaultStyle = 'PANEL' as const

export default CreateSpaceView
