/* eslint-disable no-case-declarations */
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useRecoilValue } from 'recoil'
import { useForm } from 'react-final-form'

import * as schema from 'generated/schema'
import Block from './Block'
import Button from 'components/buttons/Button'
import GenericView from 'components/views/GenericView'
import JSONParseOr from 'lib/JSONParseOr'
import useDashboard from 'hooks/useDashboard'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { Behavior } from 'components/dashboardEditor/AddActionView'
import { safeParseLiquid } from 'lib/templater'
import { useDashboardViewContext } from 'components/contexts/DashboardViewContext'
import { useViewDispatch } from 'hooks/useViewContext'
import type { BlockProps } from './Block'
import type { ButtonProps } from 'components/buttons/Button'

type ButtonBlockProps = BlockProps & ButtonProps

const SubmitButton = ({ label, icon, href }: ButtonBlockProps) => {
  const form = useForm()

  return (
    <Button
      label={label}
      icon={icon}
      href={href}
      onClick={form.submit}
    />
  )
}

function ButtonBlock({
  blockRef, type, label, icon, href, actions, ...other
}: ButtonBlockProps) {
  const { switcher } = useDashboardViewContext()

  const { blockPropertiesState } = useDashboard()
  const blockProperties = useRecoilValue(blockPropertiesState)

  const [ executeAction ] = schema.useExecuteMutationOperationMutation()

  const handleExecuteOperation = useSubmitHandler(executeAction, {
    successAlert: {
      message: 'Operation executed successfully',
      title: 'Success'
    }
  })

  const { openView } = useViewDispatch()

  const parsedActions = actions?.map((action: any) => {
    const shouldRun = JSONParseOr(safeParseLiquid(action.run_if || 'true', blockProperties).trim())
    switch (action.behavior) {
      case Behavior.RUN_OPERATION:
        const input = typeof action.input === 'string'
          ? JSONParseOr(safeParseLiquid(action.input, blockProperties)) || {}
          : Object.fromEntries(
            Object.entries(action.input || {}).map(([ key, value ]: any[]) => [
              key,
              JSONParseOr(safeParseLiquid(value, blockProperties)) || null
            ])
          )

        const handleRunOperationClick = () => handleExecuteOperation({
          arguments: input,
          operationId: action.operation,
          targetEnvironment: switcher?.data.environment?.id
        })

        return ({
          icon: action.icon,
          run: handleRunOperationClick,
          // disabled: Object.values(input).every((v) => !v),
          name: action.identifier,
          shouldRun
        })

      case Behavior.REDIRECT:
        const url = safeParseLiquid(action.url, blockProperties)

        return ({
          icon: action.icon,
          href: url,
          target: action.target,
          name: action.identifier,
          shouldRun,
          run: () => window.open(url, action.target, 'noopener noreferrer')
        })

      case Behavior.OPEN_VIEW:
        const handleOpenViewClick = () => {
          const params = Object.fromEntries(
            Object.entries(action.input || {}).map(([ key, value ]: any[]) => [
              key,
              JSONParseOr(safeParseLiquid(value, blockProperties)) || null
            ])
          )

          openView({
            component: GenericView,
            style: action.view_style,
            params: {
              viewUrn: action.view_urn,
              params
            }
          })
        }

        return ({
          icon: action.icon,
          run: handleOpenViewClick,
          name: action.identifier,
          shouldRun
        })

      default:
        return null
    }
  }) || []

  return (
    <Block masonryItemRef={blockRef} {...other}>
      {type === 'SUBMIT' ? (
        <ErrorBoundary
          fallback={(
            <Button
              label={label || parsedActions[0]?.name}
              icon={icon || parsedActions[0]?.icon}
              href={href}
            />
          )}
        >
          <SubmitButton
            label={label || parsedActions[0]?.name}
            icon={icon || parsedActions[0]?.icon}
            href={href}
          />
        </ErrorBoundary>
      ) : (
        <Button
          label={label || parsedActions[0]?.name}
          icon={icon || parsedActions[0]?.icon}
          href={href}
          onClick={() => Promise.all(parsedActions.map((action: any) => {
            if (action.shouldRun) return action.run()
            return {}
          }))}
        />
      )}
    </Block>
  )
}

export type { ButtonBlockProps }

export default ButtonBlock
