import omit from 'lodash/omit'
import React, { Fragment, memo, useEffect, useMemo, useRef, useState } from 'react'
import sortBy from 'lodash/sortBy'
import { DragDropContext, DraggableProvided, Droppable, DroppableProvided } from 'react-beautiful-dnd'
import get from 'lodash/get'
import { useRect } from '@reach/rect'

import * as mixins from 'styles/mixins'
import CheckboxInput from 'components/inputs/CheckboxInput'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import IconButton from 'components/buttons/IconButton'
import IconRenderer from 'components/renderers/IconRenderer'
import RowActions from 'components/dataWidgets/RowActions'
import Text from 'components/typography/Text'
import TextRenderer from 'components/renderers/TextRenderer'
import useHover from 'hooks/useHover'
import { colorVars } from 'styles/theme'
import { styled, css } from 'styles/stitches'
import { useDataManagerContext, useNestedDataManagerContext } from 'hooks/useDataManagerContext'
import { VirtualizedList } from 'components/dataList/DataList'
import type { Content } from 'components/dataList/DataList'

type VirtualItemData = {
  size?: any,
  start?: number,
  index?: number
}

type DataListItemProps = Partial<DraggableProvided> & {
  contents: Content[],
  id?: any,
  isDragging?: boolean,
  level?: number,
  nestedAction?: Pick<Content, 'dataKey' | 'renderer'>,
  virtualItemData: VirtualItemData,
  variant?: 'normal' | 'detailed'
}

const DATA_LIST_CONTROL_MARGIN_X = 20
const DATA_LIST_ITEM_BORDER_RADIUS = 6
const DATA_LIST_ITEM_DIVIDER_MARGIN_Y = 10
const DATA_LIST_ITEM_DIVIDER_SPACING = 20
const DATA_LIST_ITEM_ICON_SPACING = 6
const DATA_LIST_NORMAL_ITEM_HEIGHT = 50
const DATA_LIST_DETAILED_ITEM_HEIGHT = 70
const DATA_LIST_ITEM_PADDING_X = 16
const DATA_LIST_LEVEL_MARKER_SIZE = 3

const nestedProperties = [ 'index', 'childrenCount', 'isOpen' ]

const StyledListItemContentWrapper = styled(Flex, {
  paddingX: DATA_LIST_ITEM_PADDING_X
})

const StyledListItemContent = styled(Flex, {
  size: [ '100%' ]
})

const StyledListItemContentDivider = styled(Flex, {
  marginY: DATA_LIST_ITEM_DIVIDER_MARGIN_Y
})

const StyledListItem = styled(Flex, {
  ...mixins.transition('fastIn', 'background'),

  backgroundColor: 'light100',

  '&:first-child': {
    borderTopLeftRadius: DATA_LIST_ITEM_BORDER_RADIUS,
    borderTopRightRadius: DATA_LIST_ITEM_BORDER_RADIUS
  },

  '&:last-child': {
    borderBottomLeftRadius: DATA_LIST_ITEM_BORDER_RADIUS,
    borderBottomRightRadius: DATA_LIST_ITEM_BORDER_RADIUS
  },

  [`& ${StyledListItemContent}`]: {
    borderBottom: '1px solid light700'
  },

  [`&:last-child ${StyledListItemContent}`]: {
    borderBottom: 'none'
  },

  variants: {
    variant: {
      normal: {
        height: DATA_LIST_NORMAL_ITEM_HEIGHT
      },
      detailed: {
        height: DATA_LIST_DETAILED_ITEM_HEIGHT
      }
    },
    selectable: {
      true: {
        cursor: 'pointer'
      }
    },
    hovered: {
      true: {
        backgroundColor: 'light200'
      }
    },
    selected: {
      true: {
        ...mixins.shadow('medium', colorVars.dark600rgb, 0.3),

        backgroundColor: 'light400',
        zIndex: 'above'
      }
    },
    dragging: {
      true: {
        ...mixins.shadow('medium', colorVars.dark1000rgb, 0.0784),

        borderRadius: DATA_LIST_ITEM_BORDER_RADIUS
      }
    },
    isChild: {
      true: {
        paddingRight: 0
      }
    },
    isParent: {
      true: {
        borderTop: 'none'
      }
    }
  }
})

const StyledListItemNestedActionWrapper = styled(Flex, {
  backgroundColor: 'light100',
  marginRight: DATA_LIST_ITEM_PADDING_X,

  variants: {
    variant: {
      normal: {
        height: DATA_LIST_NORMAL_ITEM_HEIGHT
      },
      detailed: {
        height: DATA_LIST_DETAILED_ITEM_HEIGHT
      }
    }
  }
})

const StyledListItemNestedAction = styled(Flex, {
  borderBottom: '1px solid light700',
  width: '100%'
})

const StyledListItemToggler = styled(IconButton, {
  ...mixins.transition('simple', 'transform'),

  transform: 'rotate(0deg)',

  variants: {
    open: {
      true: {
        transform: 'rotate(180deg)'
      }
    }
  }
})

const StyledLevelMarker = styled(Flex, {
  position: 'relative',

  '&::before': {
    background: 'dark100',
    borderRadius: 30,
    content: '""',
    left: DATA_LIST_ITEM_PADDING_X / 2,
    position: 'absolute',
    size: [ DATA_LIST_LEVEL_MARKER_SIZE ],
    top: '50%',
    transform: 'translateY(-50%)'
  }
})

const bringForward = css({
  position: 'relative',
  zIndex: 'above'
})

const DataListItem = React.forwardRef<HTMLDivElement, DataListItemProps>(({
  contents,
  draggableProps,
  dragHandleProps,
  id,
  isDragging = false,
  level = 0,
  nestedAction,
  virtualItemData,
  variant = 'normal'
}, ref) => {
  const [ onHoverProps, isHovered, setIsHovered ] = useHover()

  const listItemControlsRef = useRef<HTMLDivElement>(null)
  const { width: listControlWidth = 0 } = useRect(listItemControlsRef) || {}

  const {
    actions,
    data,
    deselectRow,
    isDraggable,
    onRowSelect,
    selection,
    selectionMode,
    onDragEnd,
    selectRow
  } = useDataManagerContext()

  const {
    collapsedItemMap,
    idToElementMap,
    parentIdToElementMap,
    setCollapsedItemMap
  } = useNestedDataManagerContext()

  const { index, size, start = 0 } = virtualItemData
  const absoluteIndex = idToElementMap?.[id]?.index
  const currentIndex = absoluteIndex || index

  const isChild = level > 0

  const datum = data[currentIndex]
  const datumId = datum?.id || ''
  const immediateChildren = parentIdToElementMap[datumId]

  // Stripping off injected properties to prevent bleeding into functions called from DataList
  const datumWithoutNestedProps = omit(datum, ...nestedProperties)

  const sortedImmediateChildren = immediateChildren ? sortBy(immediateChildren, 'position') : undefined

  const isRowSelected = selection.indexOf(data[currentIndex]?.id) !== -1

  const [ isOpened, setOpened ] = useState(!collapsedItemMap[datumId])

  useEffect(() => {
    setCollapsedItemMap((prevHash: any) => ({ ...prevHash, [datumId]: !isOpened }))
  }, [ datumId, isOpened, setCollapsedItemMap ])

  const toggleNestedItemCollapse = () => {
    setOpened((isOpenedCurrent) => !isOpenedCurrent)
  }

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      toggleNestedItemCollapse()
    }
  }

  // eslint-disable-next-line no-nested-ternary
  const onRowSelectProps = {
    onClick: () => {
      // If a different row is selected only then we run the below block
      if (!isRowSelected) {
        selectRow(datumId)
      } else {
        deselectRow(datumId)
      }
      onRowSelect?.(datum, !isRowSelected)
    }
  }

  const renderCheckbox = () => {
    const handleChange = (e: React.FormEvent<HTMLDivElement>) => {
      e.preventDefault()
      e.stopPropagation()

      onRowSelectProps.onClick()
    }

    return (
      <div role="presentation" onClick={handleChange}>
        <CheckboxInput
          input={{
            checked: isRowSelected,
            onChange: handleChange
          }}
        />
      </div>
    )
  }

  const dragHandle = (
    <IconButton
      description="Drag handle"
      hideTooltip
      name="drag"
      size={12}
      variant="dark"
      {...dragHandleProps}
    />
  )

  const toggleHandle = (
    <StyledListItemToggler
      description="Toggle handle"
      name="arrow-down"
      size={12}
      variant="dark"
      hideTooltip
      open={isOpened}
      onClick={toggleNestedItemCollapse}
      onKeyUp={handleKeyUp}
    />
  )

  const renderSlot = ({
    dataKey,
    flexGrow,
    flexShrink,
    slot,
    renderer = slot === 'icon' ? IconRenderer : TextRenderer,
    width
  }: Content<any>,
  isLastSlot: boolean = false) => {
    const rendererOptions = {
      index,
      dataKey,
      rowData: datum,
      selection
    }

    const spacingStyles = {
      flexGrow,
      flexShrink,
      width,
      truncate: true
    }

    const value = get(datum, dataKey)

    const slotContent = (
      <Text
        truncate
        alignSelf="center"
        as="div"
        color={slot === 'secondary' ? 'dark400' : undefined}
        fontSize={(variant === 'detailed' && slot === 'secondary') ? 12 : 14}
        fontWeight={slot === 'primary' ? 'bold' : 'regular'}
        key={`${slot}-${dataKey}`}
        style={spacingStyles}
        title={typeof value === 'string' ? value : undefined}
      >
        {renderer(rendererOptions)}
      </Text>
    )

    if (!isLastSlot) {
      return slotContent
    }

    if (!actions.length) return slotContent

    return (
      <Fragment key={`${slot}-${dataKey}`}>
        {slotContent}
      </Fragment>
    )
  }

  const renderContent = () => {
    const iconContent = contents.find((c) => c.slot === 'icon')
    const primaryContent = contents.find((c) => c.slot === 'primary')
    const secondaryContent = contents.find((c) => c.slot === 'secondary')
    const metaContent = contents.find((c) => c.slot === 'meta')
    const toggleContent = contents.find((c) => c.slot === 'toggle')

    return (
      <StyledListItemContent
        alignItems="center"
        {...onHoverProps}
      >
        <Flex
          alignSelf="stretch"
          grow={1}
          style={{ width: 0 }}
        >
          {iconContent && renderSlot(
            iconContent,
            !primaryContent && !secondaryContent && !metaContent && !toggleContent
          )}

          {iconContent && (
            <StyledListItemContentDivider alignSelf="stretch">
              <Divider spacing={DATA_LIST_ITEM_ICON_SPACING} orientation="vertical" variant="whitespace" />
            </StyledListItemContentDivider>
          )}

          {variant === 'normal' ? (
            <>
              {primaryContent && renderSlot(
                primaryContent,
                !secondaryContent && !metaContent && !toggleContent
              )}

              {variant === 'normal' && primaryContent && secondaryContent && (
                <StyledListItemContentDivider alignSelf="stretch">
                  <Divider spacing={DATA_LIST_ITEM_DIVIDER_SPACING} orientation="vertical" />
                </StyledListItemContentDivider>
              )}

              {secondaryContent && renderSlot(secondaryContent, !metaContent && !toggleContent)}
            </>
          ) : (
            <Flex
              alignSelf="center"
              direction="column"
              gap={4}
              grow={1}
              style={{ width: 0 }}
            >
              {primaryContent && renderSlot(
                { ...primaryContent, width: '100%' },
                !secondaryContent && !metaContent && !toggleContent
              )}

              {secondaryContent && renderSlot({ ...secondaryContent, width: '100%' }, !metaContent && !toggleContent)}
            </Flex>
          )}

        </Flex>

        <Flex alignItems="center" css={{ height: '100%' }}>
          <Flex alignItems="center" gap={12}>
            {metaContent && renderSlot(metaContent, !toggleContent)}
            {toggleContent && renderSlot(toggleContent, true)}
          </Flex>
          {!!actions.filter(
            (action) => (action.visibilityFilter ? action.visibilityFilter?.(datum) : true)
          ).length && (
            <RowActions
              record={datumWithoutNestedProps}
              actions={actions}
              isHovered={isHovered}
              offset={DATA_LIST_ITEM_PADDING_X}
              index={currentIndex}
            />
          )}
        </Flex>
      </StyledListItemContent>
    )
  }

  const vListProps = useMemo(() => ({
    contents, data: sortedImmediateChildren!, idMap: idToElementMap, isDraggable, nestedAction
  }), [ contents, sortedImmediateChildren, idToElementMap, isDraggable, nestedAction ])

  if (!datum) {
    return null
  }

  return (
    <StyledListItem
      onFocusCapture={() => setIsHovered(true)}
      onBlurCapture={() => setIsHovered(false)}
      alignItems="center"
      gap={20}
      isChild={isChild}
      isParent={Boolean(sortedImmediateChildren)}
      selectable={selectionMode === 'single' || selectionMode === 'multiple'}
      hovered={isHovered && selectionMode === 'single' && !isRowSelected}
      selected={isRowSelected}
      dragging={isDragging}
      ref={ref}
      {...draggableProps}
      style={{
        left: 0,
        top: `${start}px`,
        position: size ? 'absolute' : 'relative',
        height: `${size}px`,
        width: '100%',
        ...draggableProps?.style
      }}
      variant={variant}
      {...onRowSelectProps}
    >
      <Flex direction="column" basis="100%">
        <StyledListItemContentWrapper
          gap={DATA_LIST_CONTROL_MARGIN_X}
          style={{
            width: '100%',
            height: variant === 'normal' ? DATA_LIST_NORMAL_ITEM_HEIGHT : DATA_LIST_DETAILED_ITEM_HEIGHT,
            paddingLeft: DATA_LIST_ITEM_PADDING_X
          }}
        >
          {[ ...Array(level).keys() ].map((e) => <StyledLevelMarker key={e} />)}
          {(selectionMode === 'multiple' || isDraggable) && (
            <Flex alignItems="center" ref={listItemControlsRef} gap={4} className={bringForward}>
              {isDraggable && dragHandle}
              {selectionMode === 'multiple' && renderCheckbox()}
              {sortedImmediateChildren && toggleHandle}
            </Flex>
          )}

          {renderContent()}
        </StyledListItemContentWrapper>

        {sortedImmediateChildren && isOpened && (
          <>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable
                droppableId={id}
                mode="virtual"
                type={`level-${level}`}
                renderClone={(droppableCloneProvided, snapshot, rubric) => (
                  <DataListItem
                    {...droppableCloneProvided}
                    contents={contents}
                    id={rubric.draggableId}
                    isDragging={snapshot.isDragging}
                    key={rubric.draggableId}
                    level={level + 1}
                    nestedAction={nestedAction}
                    ref={droppableCloneProvided.innerRef}
                    variant={variant}
                    virtualItemData={{ index: rubric.source.index }}
                  />
                )}
              >
                {(provided: DroppableProvided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <VirtualizedList
                      level={level + 1}
                      {...vListProps}
                    />
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            {nestedAction && (
              <StyledListItemNestedActionWrapper
                style={{
                  paddingLeft: listControlWidth
                    + DATA_LIST_CONTROL_MARGIN_X
                    + DATA_LIST_ITEM_PADDING_X * level
                    + DATA_LIST_ITEM_PADDING_X * 2
                }}
                variant={variant}
              >
                <StyledListItemNestedAction>
                  {renderSlot(nestedAction as Content<any>)}
                </StyledListItemNestedAction>
              </StyledListItemNestedActionWrapper>
            )}
          </>
        )}

      </Flex>
    </StyledListItem>
  )
})

DataListItem.displayName = 'DataListItem'

export type { DataListItemProps }

export { DATA_LIST_NORMAL_ITEM_HEIGHT, DATA_LIST_DETAILED_ITEM_HEIGHT }

export default memo(DataListItem)
