/* eslint-disable camelcase */
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import React, { useRef, useEffect, useLayoutEffect, useState, useMemo, useCallback } from 'react'
import type { FieldRenderProps } from 'react-final-form'

import BaseDurationInput, { initialFieldValues, InputFieldValue, InputType } from 'components/inputs/DurationInput/BaseDurationInput'
import DatePicker from 'components/date/DatePicker'
import { Popover, PopoverContainer } from 'components/popover'
import { DATE_FORMATS, SAVE_FORMATS } from 'components/contentEditors/generic/fields/fieldProps'
import type { DatePickerQuickLinksType } from 'components/date/DatePicker'
import type { MeridiemType } from 'components/date/TimePicker'

dayjs.extend(utc)
dayjs.extend(customParseFormat)

type DateInputOwnProps = {
  localizedFormat?: DATE_FORMATS,
  helpText?: string,
  isTranslatable?: boolean,
  quickLinks?: DatePickerQuickLinksType[],
  size?: 'small' | 'normal' | 'large',
  isClearable?: boolean,
  isNullable?: boolean,
  hidePicker?: boolean
}

type WithTime = {
  withTime: true,
  inputFormat?: DATE_FORMATS.DD_MM_YYYY_HH_mm | DATE_FORMATS.MM_DD_YYYY_HH_mm,
  saveFormat?: SAVE_FORMATS.TIMESTAMP
}

type WithoutTime = {
  withTime: false,
  inputFormat?: DATE_FORMATS.DD_MM_YYYY | DATE_FORMATS.MM_DD_YYYY,
  saveFormat?: SAVE_FORMATS.DATE
}

type WithoutTimeAndFormat = {
  withTime?: undefined,
  inputFormat?: DATE_FORMATS,
  saveFormat?: SAVE_FORMATS
}

type DateInputProps = FieldRenderProps<string, HTMLInputElement> & (
  | DateInputOwnProps & WithTime
  | DateInputOwnProps & WithoutTime
  | DateInputOwnProps & WithoutTimeAndFormat
)

const DEFAULT_DATE_DISPLAY_FORMAT = DATE_FORMATS.MM_DD_YYYY
const DEFAULT_TIME_FORMAT = 'hh:mm A'

const SERVER_DATE_FORMAT = 'YYYY-MM-DD'
const SERVER_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss'

/**
 * UX improvements for better typing experience
 * @param inputValue
 * @param type
 */
const shouldEarlyReturn = (inputValue: InputFieldValue, type: keyof InputFieldValue) => {
  const value = inputValue[type]

  if (!value) {
    return false
  }

  const numValue = parseInt(value, 10)

  if (type === 'DD') {
    if (value === '00' || numValue > 31) {
      return true
    }
  }

  if (type === 'MM') {
    if (value === '00' || numValue > 12) {
      return true
    }
  }

  if (type === 'HH') {
    if (numValue > 23) {
      return true
    }
  }

  if (type === 'hh') {
    if (numValue > 12) {
      return true
    }
  }

  if (type === 'mm') {
    if (numValue > 59) {
      return true
    }
  }

  return false
}

const padInputValue = (inputValue: InputFieldValue, type: keyof InputFieldValue) => {
  const value = inputValue[type]

  if (!value) {
    return
  }

  const numValue = parseInt(value, 10)

  if (type === 'DD') {
    if (numValue > 3 && numValue < 10) {
      inputValue.DD = value.padStart(2, '0')
    }
  }

  if (type === 'MM') {
    if (numValue > 1 && numValue < 10) {
      inputValue.MM = value.padStart(2, '0')
    }
  }

  if (type === 'HH') {
    if (numValue > 2 && numValue < 10) {
      inputValue.HH = value.padStart(2, '0')
    }
  }

  if (type === 'hh') {
    if (numValue > 1 && numValue < 10) {
      inputValue.hh = value.padStart(2, '0')
    }
  }

  if (type === 'mm') {
    if (numValue > 5 && numValue < 10) {
      inputValue.mm = value.padStart(2, '0')
    }
  }

  if (type === 'ss') {
    if (numValue > 5 && numValue < 10) {
      inputValue.mm = value.padStart(2, '0')
    }
  }

  if (type === 'A') {
    if (value === 'a' || value === 'A') inputValue.A = 'AM'
    if (value === 'p' || value === 'P') inputValue.A = 'PM'
  }
}

const isWithTime = (inputFormat: DATE_FORMATS, saveFormat?: SAVE_FORMATS) => (
  inputFormat.indexOf(':mm') > 0 || saveFormat === SAVE_FORMATS.TIMESTAMP
)

function DateInput({
  localizedFormat: _localizedFormat,
  disabled,
  input,
  inputFormat: _inputFormat,
  meta,
  quickLinks = [],
  saveFormat,
  withTime: _withTime,
  isClearable = false,
  hidePicker = false,
  ...others
}: DateInputProps) {
  let localizedFormat = _localizedFormat
  let inputFormat = _inputFormat
  let withTime = _withTime

  if (!inputFormat) {
    switch (withTime) {
      case true:
        localizedFormat = _localizedFormat || DATE_FORMATS.DD_MM_YYYY_hh_mm_A
        break
      case false:
        localizedFormat = _localizedFormat || DATE_FORMATS.DD_MM_YYYY
        break
      case undefined:
        withTime = isWithTime(localizedFormat || DEFAULT_DATE_DISPLAY_FORMAT, saveFormat)
        localizedFormat = _localizedFormat
        || (withTime ? DATE_FORMATS.DD_MM_YYYY_hh_mm_A : DATE_FORMATS.DD_MM_YYYY)
        break
    }

    inputFormat = localizedFormat
  } else {
    switch (withTime) {
      case undefined:
        withTime = isWithTime(inputFormat, saveFormat)
    }
  }

  const isTwelveHourFormat = Boolean(inputFormat?.includes('hh:mm A') || inputFormat?.includes('hh:mm:ss A'))
  const hourKeyIdentifier = (isTwelveHourFormat ? 'hh' : 'HH') as InputType

  const [ inputFieldValue, setInputFieldValue ] = useState<InputFieldValue>(initialFieldValues)
  const serverFormat = withTime ? SERVER_DATETIME_FORMAT : SERVER_DATE_FORMAT
  const inputLength = withTime ? 22 : 10

  const selectedDate = useMemo(() => {
    const parsedDate = input.value && withTime
      ? dayjs(input.value, serverFormat).utc(!input.value.includes('+')).local()
      : dayjs(input.value, serverFormat)

    return parsedDate?.isValid() ? parsedDate : undefined
  }, [ input.value, serverFormat, withTime ])

  const [ dateFormat, timeFormat, meridiemFormat ] = inputFormat.split(' ')

  const handleSelectDate = useCallback((date: Dayjs) => {
    onChangeRef.current(
      withTime
        ? dayjs(date, inputFormat).utc(false).format(serverFormat)
        : dayjs(date, inputFormat).format(serverFormat)
    )
  }, [ serverFormat, withTime, inputFormat ])

  const skipUpdateRef = useRef(false)

  useEffect(() => {
    const setDate = (inputDate: Dayjs) => {
      const [ date, time, meridiem ] = dayjs(inputDate, withTime ? SERVER_DATETIME_FORMAT : SERVER_DATE_FORMAT).format(inputFormat).split(' ')

      const setValues = (types: (keyof InputFieldValue)[] = [], values: string[]) => (
        types.map((type, index) => {
          setInputFieldValue((val) => (val[type] === values[index]
            ? val
            : {
              ...val,
              [type]: values[index]
            }))

          return null
        })
      )

      setValues(dateFormat.split('/') as (keyof InputFieldValue)[], date.split('/'))

      if (withTime) {
        setValues(timeFormat.split(':') as (keyof InputFieldValue)[], time.split(':'))
        setValues([ 'A' ], [ meridiem ])
      }
    }

    if (selectedDate) {
      setDate(selectedDate)
      skipUpdateRef.current = true
    }
  }, [ selectedDate, withTime, dateFormat, timeFormat, meridiemFormat, inputFormat ])

  const dateInputsRef = useRef<Array<HTMLInputElement>>([])

  const dateInputDidBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const targetElement = e.currentTarget

    if (targetElement.value.length > 0) {
      if (targetElement.dataset.inputType === 'YYYY' && targetElement.value.length < 4) {
        const padTwenty = targetElement.value.padStart(targetElement.maxLength, '200')
        const padNineteen = targetElement.value.padStart(targetElement.maxLength, '199')

        const currentYear = new Date().getFullYear()
        setInputFieldValue((val) => ({
          ...val,
          [targetElement.dataset.inputType!]:
          Math.abs(currentYear - Number.parseInt(padTwenty, 10))
          < Math.abs(currentYear - Number.parseInt(padNineteen, 10))
            ? padTwenty
            : padNineteen
        }))
      } else {
        setInputFieldValue((val) => ({
          ...val,
          [targetElement.dataset.inputType!]: targetElement.value.padStart(targetElement.maxLength, '0')
        }))
      }
    }
  }

  const onChangeRef = useRef(input.onChange)

  useLayoutEffect(() => {
    onChangeRef.current = input.onChange
  })

  useEffect(() => {
    if (skipUpdateRef.current) {
      skipUpdateRef.current = false
      return
    }

    skipUpdateRef.current = false

    const getDate = (inputValue: InputFieldValue) => {
      const computeInput = (types: (keyof InputFieldValue)[] = []) => (
        types.map((t) => inputValue[t])
      )

      const date = computeInput(dateFormat?.split('/') as (keyof InputFieldValue)[]).join('/')

      if (!withTime) return [ date, dateFormat ]

      const computedTime = computeInput(timeFormat?.split(':') as (keyof InputFieldValue)[]).filter(Boolean).join(':')
      const time = computedTime.length >= 5 ? computedTime : ''
      const computedMeridiem = inputValue.A || ''
      const meridiem = computedMeridiem.length > 1 ? computedMeridiem : ''
      const timeZone = dayjs().format('Z')
      const convertedTime = dayjs(`${date} ${time} ${meridiem}`, inputFormat).format(timeFormat)

      // https://day.js.org/docs/en/display/format
      // we always convert the time to 24 hour format
      if (time || convertedTime) return [ `${date}T${isTwelveHourFormat ? convertedTime : time}${timeZone}`, `${dateFormat}T${timeFormat}Z` ]

      return [ date, dateFormat ]
    }

    const [ date, format ] = getDate(inputFieldValue)

    if (
      !dayjs(date, format, true).isValid()
      && inputFormat !== DATE_FORMATS.MM_YYYY // make it work for month year input
    ) {
      // user has input invalid values
      const combinedInputLength = Object.values(inputFieldValue).filter(Boolean).reduce((a, i = '') => a + i.length, 0)
      if (combinedInputLength > 0) {
        // set invalid value so validation error is raised
        onChangeRef.current('')
      } else {
        // clears stale value
        onChangeRef.current(null)
      }
      return
    }

    if (
      date.length < inputLength
       && inputFormat !== DATE_FORMATS.MM_YYYY // make it work for month year input
    ) {
      return
    }

    handleSelectDate(dayjs(date, withTime ? `${dateFormat}T${timeFormat}Z` : dateFormat))
  }, [
    dateFormat,
    handleSelectDate,
    inputFieldValue,
    inputLength,
    inputFormat,
    others.isNullable,
    timeFormat,
    withTime,
    isTwelveHourFormat
  ])

  return (
    <PopoverContainer
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [ 12, 20 ]
          }
        }
      ]}
      placement="auto-end"
    >
      {({ isActive, openPopover, closePopover, ...otherToggleProps }) => {
        const toggleProps = disabled ? {} : otherToggleProps

        return (
          <BaseDurationInput
            isClearable={isClearable}
            disabled={disabled}
            icon="calendar"
            input={input}
            inputFormat={inputFormat}
            meta={meta}
            onInputBlur={dateInputDidBlur}
            inputsRef={dateInputsRef}
            inputValue={inputFieldValue}
            padInputValue={padInputValue}
            shouldEarlyReturn={shouldEarlyReturn}
            setInputValue={setInputFieldValue}
            pickerProps={toggleProps}
            {...others}
          />
        )
      }}
      {hidePicker
        ? () => null
        : ({ closePopover, ...otherPopoverProps }) => (
          <Popover
            {...otherPopoverProps}
            closePopover={closePopover}
            withArrow
          >
            <DatePicker
              activeDay={inputFieldValue.DD ? parseInt(inputFieldValue.DD, 10) : undefined}
              activeMonth={inputFieldValue.MM ? parseInt(inputFieldValue.MM, 10) : undefined}
              activeYear={inputFieldValue.YYYY ? parseInt(inputFieldValue.YYYY, 10) : undefined}
              activeHour={inputFieldValue[hourKeyIdentifier]
                ? parseInt(inputFieldValue[hourKeyIdentifier]!, 10)
                : undefined}
              activeMinute={inputFieldValue.mm ? parseInt(inputFieldValue.mm, 10) : undefined}
              activeSecond={inputFieldValue.ss ? parseInt(inputFieldValue.ss, 10) : undefined}
              activeMeridiem={inputFieldValue.A as MeridiemType}
              onChangeDay={(day: number) => {
                setInputFieldValue((val) => ({
                  ...val,
                  DD: day.toString().padStart(2, '0')
                }))
              }}
              onChangeMonth={(month: number) => {
                setInputFieldValue((val) => ({
                  ...val,
                  MM: month.toString().padStart(2, '0')
                }))
              }}
              onChangeYear={(year: number) => {
                setInputFieldValue((val) => ({
                  ...val,
                  YYYY: year.toString()
                }))
              }}
              onChangeHour={(hour: number) => setInputFieldValue((val) => ({
                ...val,
                [hourKeyIdentifier]: hour.toString().padStart(2, '0')
              }))}
              onChangeMinute={(minute: number) => setInputFieldValue((val) => ({
                ...val,
                mm: minute.toString().padStart(2, '0')
              }))}
              onChangeSecond={(second: number) => setInputFieldValue((val) => ({
                ...val,
                ss: second.toString().padStart(2, '0')
              }))}
              onChangeMeridiem={(meridiem: MeridiemType) => setInputFieldValue((val) => ({
                ...val,
                A: meridiem
              }))}
              compact
              hideOutOfMonth
              inputFormat={inputFormat}
              defaultSelectedDate={selectedDate}
              quickLinks={quickLinks}
              onSelectDate={(date: Dayjs) => {
                handleSelectDate(date)

                if (!withTime) {
                  closePopover?.()
                }
              }}
              withTime={withTime}
              onClose={closePopover}
            />
          </Popover>
        )}
    </PopoverContainer>
  )
}

export {
  DEFAULT_DATE_DISPLAY_FORMAT,
  DEFAULT_TIME_FORMAT,
  SERVER_DATETIME_FORMAT,
  SERVER_DATE_FORMAT
}

export type { DateInputProps }

export default DateInput
