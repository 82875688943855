import composeRefs from '@seznam/compose-react-refs'
import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDroppable } from '@dnd-kit/core'
import { useRect } from '@reach/rect'
import { useRecoilValue } from 'recoil'
import { ErrorBoundary } from 'react-error-boundary'

import * as mixins from 'styles/mixins'
import Text from 'components/typography/Text'
import useDashboard from 'hooks/useDashboard'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
// import blocks from 'components/blocks'
import blockWrappers from 'components/blocks/wrappers'
import reportError from 'lib/reportError'
import useSubmitHandler from 'hooks/useSubmitHandler'
import * as queries from 'generated/schema' // Todo lazy import()
import { useDashboardViewContext } from 'components/contexts/DashboardViewContext'
import DashboardContext from 'components/contexts/DashboardContext'
import { Views } from 'components/dashboardEditor/constants'
import { safeParseLiquid } from 'lib/templater'
import JSONParseOr from 'lib/JSONParseOr'

type RenderBlockProps = {
  id: string,
  containerId?: string,
  setTitle?: React.Dispatch<React.SetStateAction<string>>,
  footerEl?: HTMLElement | null
}

const BlockComponent = ({
  id,
  containerId,
  setTitle,
  footerEl
}: RenderBlockProps) => {
  const {
    getOperations,
    removeBlock,
    selectBlock,
    blockState,
    updateBlock,
    openDashboardEditorView,
    blockPropertiesState
  } = useDashboard()

  const blockProperties = useRecoilValue(blockPropertiesState)

  const {
    editMode,
    openDashboardEditor,
    selectedSideMenuElement,
    selectedTopMenuElement
  } = useContext(DashboardContext)!

  const { activeUrn } = useDashboardViewContext()

  const selectedMenuElement = selectedSideMenuElement || selectedTopMenuElement
  const selectedViewUrn = selectedMenuElement?.viewUrn!

  const block = useRecoilValue(blockState(id))

  const [ upsertView ] = queries.useUpsertViewMutation()

  const handleUpsertBlock = useSubmitHandler(upsertView)

  const onEditBlock = () => {
    selectBlock(block.id)
    openDashboardEditor()
    openDashboardEditorView({
      target: Views.EDIT_BLOCK,
      params: {
        block
      }
    })
  }

  const onRemoveBlock = () => {
    getOperations().then((operations) => {
      removeBlock(activeUrn, block.id).then((blocks) => {
        handleUpsertBlock({
          urn: selectedViewUrn,
          operations: operations.filter(
            (operation: any) => operation.blockId !== block.id
          ) || [],
          blocks
        })
      })
    })
  }

  const onResize = (columns: any[]) => {
    const updatedBlock = {
      ...block,
      properties: {
        ...block.properties,
        columns
      }
    } as any

    return updateBlock(activeUrn, updatedBlock)
      .then((blocks) => getOperations()
        .then((operations) => handleUpsertBlock({
          urn: selectedViewUrn,
          operations,
          blocks
        })))
  }
  const blockHeading = block?.properties?.heading
  const blockType = block?.type

  useEffect(() => {
    const isTitleBlock = blockType === 'TitleBlock'
    let oldTitle: string

    if (isTitleBlock && setTitle) {
      setTitle((value) => {
        oldTitle = value
        return blockHeading
      })

      return () => setTitle(oldTitle)
    }

    return () => {}
  }, [ blockHeading, blockType, setTitle ])

  const Block = useMemo(() => React.lazy(() => import(`components/blocks/${blockType}.tsx`)), [ blockType ])

  // We set block as null while deleting to update UI
  if (!block) return null

  const isVisible = JSONParseOr(safeParseLiquid(block.visibility_criteria?.expression || 'true', blockProperties).trim())
  if (!isVisible) return null

  const BlockWrapper = blockWrappers[`${block.type}Wrapper` as keyof typeof blockWrappers]
  if (!Block) {
    console.error(`Missing block type: ${block.type}`)
    return null
  }

  const isTitleBlock = block.type === 'TitleBlock'

  if (isTitleBlock && setTitle) {
    return null
  }

  const commonProps = {
    'data-id': block?.id,
    id,
    containerId,
    block,
    hideActionCard: block.type === 'ColumnsBlock' && block.properties.columns?.length,
    position: block.position,
    resizeEnabled: editMode,
    isViewBlock: isTitleBlock,
    onEdit: onEditBlock,
    onRemove: onRemoveBlock,
    onResize,
    width: { md: '100%' },
    footerEl
  }

  if (!BlockWrapper) {
    return (
      <React.Suspense fallback={null}>
        <Block
          {...commonProps}
          {...(block.properties as any)}
        />
      </React.Suspense>
    )
  }

  return (
    <ErrorBoundary FallbackComponent={() => null} onError={reportError}>
      <BlockWrapper
        {...commonProps}
      />
    </ErrorBoundary>
  )
}

const Dropzone = ({ id, containerId }: any) => {
  const { draggingOverBlockIdState, draggedBlockState } = useDashboard()
  const draggingOverBlockId = useRecoilValue(draggingOverBlockIdState)
  const draggedBlock = useRecoilValue(draggedBlockState)

  const { setNodeRef, isOver } = useDroppable({
    id: `${id}__dropzone`,
    data: {
      sortable: {
        containerId
      }
    }
  })

  const isVisible = (isOver || draggingOverBlockId === id) && !!draggedBlock

  return (
    <Flex
      ref={setNodeRef}
      alignItems="center"
      css={{
        position: 'relative' as const,
        color: 'dark700',
        height: 32,
        width: '100%',
        marginTop: -16,
        marginBottom: -16,
        opacity: isVisible ? 1 : 0
      }}
    >
      <Flex
        css={{
          width: '100%',
          borderBottom: '2px dashed dark700',
          '& > [data-icon]': {
            position: 'absolute' as const,
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'light100'
          }
        }}
      >
        <Icon
          data-icon
          name="add-outline-round"
          size={16}
        />
      </Flex>
    </Flex>
  )
}

const Column = ({ id, items, resizeHandle, ...props }: any) => {
  const ref = useRef<HTMLDivElement>(null)
  const rect = useRect(ref)
  const innerWidth = rect?.width

  const [ visible, setVisible ] = useState(false)

  useEffect(() => {
    setVisible(true)

    const timeoutId = setTimeout(() => {
      setVisible(false)
    }, 3000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [ innerWidth ])

  const { setNodeRef } = useDroppable({
    id
  })

  return (
    <Flex
      ref={composeRefs(setNodeRef, ref)}
      direction="column"
      grow={0}
      shrink={0}
      {...props}
    >
      {ref.current?.style?.width && (
        <Flex css={{
          position: 'absolute',
          top: 10,
          right: 10,
          opacity: visible ? 0.9 : 0,
          backgroundColor: 'dark700',
          padding: '2px 4px',
          zIndex: 1,
          ...mixins.transition('simple')
        } as any}
        >
          <Text color="light100" fontSize={12}>
            {ref.current?.style.width}
          </Text>
        </Flex>
      )}
      {items?.map((block: any, index: number) => (
        <Fragment key={block?.id}>
          <BlockComponent
            containerId={id}
            id={block?.id}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          />
          <Dropzone containerId={id} id={block?.id} index={index} />
        </Fragment>
      ))}
      {resizeHandle}
    </Flex>
  )
}

export { BlockComponent }
export default Column
