function mapArraysToObjects(keys: string[], valueArrays: any[][]): Record<string, any>[] {
  const result: Record<string, any>[] = []

  valueArrays.forEach((values) => {
    const obj: Record<string, any> = {}

    keys?.forEach((key, index) => {
      obj[key] = values[index]
    })

    result.push(obj)
  })

  return result
}

export default mapArraysToObjects
