import React from 'react'

import FormFieldBlock from 'components/blocks/FormFieldBlock'
import type { FormFieldBlockProps } from 'components/blocks/FormFieldBlock'

const FormFieldBlockWrapper: React.FC<FormFieldBlockProps> = ({ block, ...props }) => (
  <FormFieldBlock
    {...props}
    {...block.properties}
    block={block}
  />
)

export default FormFieldBlockWrapper
