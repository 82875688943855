/* eslint-disable no-case-declarations */
/* eslint-disable camelcase */
import camelCase from 'lodash/camelCase'
import compact from 'lodash/compact'
import get from 'lodash/get'
import mapValues from 'lodash/mapValues'
import mergeWith from 'lodash/mergeWith'
import omit from 'lodash/omit'
import pickBy from 'lodash/pickBy'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import set from 'lodash/set'
import { useRecoilValue } from 'recoil'
import type { ErrorResponse } from '@apollo/client/link/error'

import * as schema from 'generated/schema'
import AddRecordView from 'components/views/graph/AddRecordView'
import BooleanRenderer from 'components/renderers/BooleanRenderer'
import Button from 'components/buttons/Button'
import ChipRenderer from 'components/renderers/ChipRenderer'
import CodeRenderer from 'components/renderers/CodeRenderer'
import ColorRenderer from 'components/renderers/ColorRenderer'
import CurrencyRenderer from 'components/renderers/CurrencyRenderer'
import DataTableBlock from 'components/blocks/DataTableBlock'
import DateRenderer from 'components/renderers/DateRenderer'
import EmbeddedRenderer from 'components/renderers/EmbeddedRenderer'
import Flex from 'components/layout/Flex'
import GenericElement from 'components/elementViews/GenericElement'
import GenericView from 'components/views/GenericView'
import getPropertyToElementMap from 'lib/getPropertyToElementMap'
import IconButton from 'components/buttons/IconButton'
import JSONParseOr from 'lib/JSONParseOr'
import MediaRenderer from 'components/renderers/MediaRenderer'
import NumberRenderer from 'components/renderers/NumberRenderer'
import ProgressIndicator from 'components/dataWidgets/ProgressIndicator'
import ReferenceRenderer from 'components/renderers/ReferenceRenderer'
import RefreshButton from 'components/buttons/RefreshButton'
import GenericResourceDetailsView from 'components/views/graph/GenericResourceDetailsView'
import SearchBar from 'components/searchbar/SearchBar'
import TextRenderer from 'components/renderers/TextRenderer'
import useConfirmation from 'hooks/useConfirmation'
import useDashboard, { Operation } from 'hooks/useDashboard'
import useExecuteOperationQuery from './useExecuteOperationQuery'
import usePager from 'hooks/usePager'
import useSearch from 'hooks/useSearch'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { Behavior } from 'components/dashboardEditor/AddActionView'
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'components/dataWidgets/Pager'
import { DisplayType } from 'models/Attribute'
import { FieldIdentifier } from 'models/Field'
import { generateLinkRenderer } from 'components/renderers/LinkRenderer'
import { ImportButton } from 'components/views/graph/GenericResourceRecordsList'
import { Kind } from 'models/Relationship'
import { PARAMETERS_LIST_LIMIT, RELATIONSHIPS_LIST_LIMIT } from 'models/Resource'
import { parseAndRender, safeParseLiquid } from 'lib/templater'
import { useDashboardViewContext } from 'components/contexts/DashboardViewContext'
import { useViewDispatch } from 'hooks/useViewContext'
import type { BlockProps } from 'components/blocks/Block'
import type { Column, RendererOptions } from 'components/dataTable/types'
import type { DataTableBlockProps } from 'components/blocks/DataTableBlock'
import type { FilterType } from 'components/dataWidgets/CustomizeDisplay'
import type { RowSelectionFn } from 'components/providers/DataManagerProvider'
import GlobalContext from 'components/contexts/GlobalContext'
import parseError from 'lib/parseError'

interface Renderer<T extends object> {
  renderer: ({ dataKey, rowData }: RendererOptions<T>) => JSX.Element
}

const DEFAULT_ORDER: Array<Record<string, 'asc' | 'desc'>> = [
  { createdAt: 'desc' }
]

const FIELD_TYPE_TO_RENDERER_MAP: Record<FieldIdentifier, Renderer<any>> = {
  [FieldIdentifier.TEXT]: { renderer: TextRenderer },
  [FieldIdentifier.NUMBER]: { renderer: NumberRenderer },
  [FieldIdentifier.DATE]: { renderer: DateRenderer },
  [FieldIdentifier.BOOLEAN]: { renderer: BooleanRenderer },
  [FieldIdentifier.MEDIA]: { renderer: MediaRenderer },
  [FieldIdentifier.FILE]: { renderer: MediaRenderer },
  [FieldIdentifier.DROPDOWN]: { renderer: ChipRenderer },
  [FieldIdentifier.SEGMENTED_CONTROL]: { renderer: ChipRenderer },
  [FieldIdentifier.CHECKBOX]: { renderer: BooleanRenderer },
  [FieldIdentifier.COLOR]: { renderer: ColorRenderer },
  [FieldIdentifier.CURRENCY]: { renderer: CurrencyRenderer },
  [FieldIdentifier.DURATION]: { renderer: DateRenderer },
  [FieldIdentifier.EMAIL]: { renderer: TextRenderer },
  [FieldIdentifier.JSON]: { renderer: CodeRenderer },
  [FieldIdentifier.LOCATION]: { renderer: TextRenderer },
  [FieldIdentifier.PASSWORD]: { renderer: TextRenderer },
  [FieldIdentifier.PHONE]: { renderer: TextRenderer },
  [FieldIdentifier.RADIO]: { renderer: ChipRenderer },
  [FieldIdentifier.EMBEDDED]: { renderer: EmbeddedRenderer },
  [FieldIdentifier.REFERENCE]: { renderer: ReferenceRenderer },
  [FieldIdentifier.RELATIONSHIP]: { renderer: TextRenderer },
  [FieldIdentifier.SLUG]: { renderer: TextRenderer },
  [FieldIdentifier.SWITCH]: { renderer: BooleanRenderer },
  [FieldIdentifier.MARKDOWN]: { renderer: TextRenderer },
  [FieldIdentifier.UID]: { renderer: TextRenderer },
  [FieldIdentifier.CODE]: { renderer: CodeRenderer }
}

const STABLE_EMPTY_ARRAY = Object.freeze([])

const ExportRecordsButton = ({ resourceId, fileName }: any) => {
  const { loading, refetch } = schema.useInternalExportRecordsQuery({
    variables: {
      input: {
        resourceId,
        preview: true
      }
    },
    skip: true
  })

  const { openFailureAlert } = useContext(GlobalContext)!

  const exportRecords = () => {
    refetch()
      .then((result) => {
        const anchor = document.createElement('a')
        anchor.href = result.data.internalExportRecords.url
        anchor.setAttribute('download', fileName)
        anchor.click()
        anchor.remove()
      }).catch((error: ErrorResponse) => {
        const { alert } = parseError(error)
        if (alert) openFailureAlert(alert)
      })
  }

  return (
    <IconButton
      name="upload"
      description="Export"
      onClick={exportRecords}
      size={24}
      disabled={loading}
    />
  )
}

const DataTableBlockWrapper: React.FC<BlockProps & Partial<DataTableBlockProps>> = ({
  block, containerId, ...blockProps
}) => {
  const [ filters, setFilters ] = useState<FilterType>({})
  const { openView } = useViewDispatch()
  const {
    blockPropertiesState,
    operationState,
    updateBlockProperties
  } = useDashboard()
  const confirm = useConfirmation({ style: 'DIALOG' })

  const { switcher } = useDashboardViewContext()

  const { properties, layout, identifier, actions } = block
  const blockProperties = useRecoilValue(blockPropertiesState)
  const currentBlockProperties = blockProperties[identifier] || {}
  const targetEnvironment = switcher?.data.environment?.id

  const {
    data_source_settings: dataSourceSettings = {},
    heading: title,
    selection_mode: selectionMode = 'MULTIPLE',
    search,
    show_refresh: showRefresh,
    hide_import: hideImport,
    hide_export: hideExport,
    hide_create: hideCreate,
    enableCrud,
    enableExport,
    enableFilter,
    enableSearch,
    columns,
    primaryElements: primaryElementsData = STABLE_EMPTY_ARRAY,
    pagination,
    ...rest
  } = properties

  const relatedColumns = columns?.filter((c: any) => c.kind === 'RELATION')
  const relatedColumnRelationships = relatedColumns?.map((c: any) => c.relations)

  const {
    data: { relationshipsList = STABLE_EMPTY_ARRAY } = {},
    loading: relationshipsListLoading
  } = schema.useRelationshipsListQuery({
    variables: {
      filter: {
        id: { in: [].concat(...(relatedColumnRelationships || [])) }
      },
      limit: RELATIONSHIPS_LIST_LIMIT
    },
    skip: !relatedColumns?.length
  })

  const idToRelationshipsMap = getPropertyToElementMap(relationshipsList, 'id')

  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = usePager({
    initialPageSize: pagination?.page_size || 10
  })

  const rowActions = actions?.filter((action: any) => action.kind === 'ROW') || []
  const toolbarActions = actions?.filter((action: any) => action.kind === 'TOOLBAR') || []
  const canSearch = search?.is_enabled
  const enablePagination = pagination?.is_enabled

  const {
    resource: resourceId,
    operation: operationId,
    parameters: paramProperties
  } = dataSourceSettings

  const { width } = layout || {}

  const { data: { resource } = {}, loading: resourcesListLoading } = schema.useResourceQuery({
    variables: { id: resourceId },
    skip: !resourceId
  })

  const {
    data: { relationshipsList: relationships = STABLE_EMPTY_ARRAY } = {}
  } = schema.useRelationshipsListQuery({
    variables: {
      filter: {
        sourceId: { eq: resourceId }
      },
      order: [ {
        position: 'asc'
      } ],
      limit: RELATIONSHIPS_LIST_LIMIT
    },
    skip: !resourceId
  })

  const {
    data: { executeQueryOperation = STABLE_EMPTY_ARRAY, operation } = {},
    loading: executeOperationLoading,
    error: executeOperationError,
    refetch: refetchOperationQuery,
    variables
  } = useExecuteOperationQuery({
    context: blockProperties,
    operationId,
    arguments: paramProperties,
    targetEnvironment,
    page,
    pageSize
  })

  const { data: { operationsList: operations = [] } = {} } = schema.useOperationsListQuery({
    variables: {
      filter: { resourceId: { eq: resourceId } }
    },
    skip: !resourceId
  })

  const {
    data: { dataTypesList } = {}
  } = schema.useDataTypesListQuery()

  const hasFilterableContent = columns?.some((attr: schema.Attribute) => attr.isFilterable)

  const canFilter = hasFilterableContent

  const aggregateOperation = operations.find((operation) => operation.method === 'AGGREGATE')
  const createOperation = operations.find((operation) => operation.method === 'CREATE')
  const destroyOperation = operations.find((operation) => operation.method === 'DESTROY')
  const listOperation = operations.find((operation) => operation.method === 'LIST')
  const updateOperation = operations.find((operation) => operation.method === 'UPDATE')

  const canPaginate = operations.findIndex((operation) => operation.method === 'AGGREGATE') !== -1 && enablePagination

  const listOperationData: Operation = useRecoilValue(operationState(listOperation?.identifier || ''))
  const aggregateOperationData: Operation = useRecoilValue(
    operationState(aggregateOperation?.identifier || '')
  )

  const {
    data: { parametersList = [] } = {}
  } = schema.useParametersListQuery({
    variables: {
      filter: {
        operationId: { eq: destroyOperation?.id }
      },
      order: [ {
        position: 'asc'
      } ],
      limit: PARAMETERS_LIST_LIMIT
    },
    skip: !destroyOperation?.id
  })

  const [ deleteRecord ] = schema.useInternalDeleteRecordMutation({
    refetchQueries: [
      schema.InternalSearchRecordsDocument
    ]
  })

  const openAddRecordView = () => {
    openView({
      title: resource?.name,
      component: AddRecordView,
      params: {
        resourceId,
        resource: resource as schema.Resource,
        record: {
          data: mapValues(pickBy(mapValues(parsedFilters, 'eq'), Boolean), (v) => ({ en_US: v }))
        },
        switcher,
        operationId: createOperation!.id
      },
      style: 'PANEL'
    })
  }

  const openEditView = (record: schema.CustomRecord) => {
    openView({
      title: resource?.name,
      component: AddRecordView,
      params: {
        resource: resource! as schema.Resource,
        record,
        switcher,
        operationId: updateOperation!.id,
        onDelete: () => openDeleteConfirmDialog(record)
      },
      style: 'PANEL'
    })
  }

  const [ order, setOrder ] = useState<typeof DEFAULT_ORDER>()

  const attributeIds = columns?.map((column: any) => column.attribute).filter(Boolean) || []

  const {
    data: { attributesList = [] } = {},
    loading: attributesListLoading
  } = schema.useAttributesListQuery({
    variables: {
      filter: {
        id: { in: attributeIds }
      }
    },
    skip: attributeIds.length === 0
  })

  const idToAttributesMap = getPropertyToElementMap(attributesList, 'id')

  const titleAttribute = attributesList.find(
    (attr) => attr.id === resource?.titleAttributeId
  )

  const attributes = attributeIds.map((id: string) => idToAttributesMap[id]).filter(Boolean)

  const relatedFields = useMemo(() => {
    const fields: any = []

    relatedColumns?.forEach((col: any) => {
      if (col.relations.length < 2) return col

      const relationIdentifiers = col.relations.map(
        (rel: string) => idToRelationshipsMap[rel]?.identifier
      )

      const nestedFields = relationIdentifiers.reduceRight(
        (acc: any, key: string, index: number, arr: string[]) => {
          if (index === arr.length - 1) {
            return { [key]: [ idToAttributesMap[col.attribute]?.identifier || '' ] }
          }

          return { [key]: acc }
        }, {}
      )

      fields.push(nestedFields)
      return col
    })

    if (fields.length > 1) {
      // eslint-disable-next-line consistent-return
      const optimizedFields = mergeWith(fields[0], ...fields, (obj: any, src: any) => {
        if (Array.isArray(obj)) return obj.concat(src)
      })

      return optimizedFields
    }

    return fields
  }, [ relatedColumns, idToRelationshipsMap, idToAttributesMap ])

  const parsedFilters = JSONParseOr(
    safeParseLiquid(
      dataSourceSettings.filter,
      blockProperties
    ), {}
  ) || {}

  const defaultFields = JSONParseOr(
    safeParseLiquid(
      dataSourceSettings.fields,
      blockProperties
    ), []
  ) || []

  const searchRecordsVariables: schema.InternalSearchRecordsQueryVariables = {
    input: {
      preview: true,
      resourceId,
      filter: {
        ...(parsedFilters),
        ...filters
      },
      fields: attributes.length
        ? attributes
          .filter((attr: any) => attr.resourceId === resourceId)
          .map((attr: any) => {
            if (attr.fieldType === 'reference-field') {
              const relationship = relationships?.find((rel) => rel.sourceAttributeId === attr.id)
              if (relationship) {
                return [
                  { [relationship.identifier]: '*' },
                  attr.identifier
                ]
              }
              return [ attr.identifier ]
            }
            return [ attr.identifier ]
          })
          .reduce((acc: any, val: any) => acc.concat(val), []).sort()
          .concat(relatedFields)
          .concat(defaultFields)
        : defaultFields,
      // eslint-disable-next-line max-len
      order: order || (dataSourceSettings.order ? [ JSONParseOr(safeParseLiquid(dataSourceSettings.order, blockProperties), {}) ] : undefined),
      limit: canPaginate ? pageSize : 1000,
      page,
      targetEnvironment
    }
  }

  const handleDelete = useSubmitHandler(deleteRecord)

  const openDeleteConfirmDialog = (record: any) => {
    const prefix = 'data'
    const currentLocale = 'en_US'

    confirm({
      action: 'delete',
      onConfirmClick: () => {
        const formParams = {}

        const requiredParams = parametersList?.map(
          (param) => {
            const paramName = `${prefix}.${camelCase(param.identifier)}.${currentLocale}`
            const paramValue = get(record, paramName)
            if (paramValue) {
              return set(formParams, camelCase(param.identifier), paramValue)
            }
            return null
          }
        ).reduce((acc, curr) => ({ ...acc, ...curr }), {})

        return handleDelete({
          resourceId,
          targetEnvironment,
          arguments: requiredParams
        }, undefined, undefined, {
          optimisticResponse: {
            response: 'DESTROY',
            mutation: 'internalDeleteRecord',
            typename: 'CustomRecord',
            defaultValue: record
          } as any,
          update: {
            strategy: 'REMOVE',
            dataKey: 'searchRecords',
            mutation: 'internalDeleteRecord',
            query: schema.InternalSearchRecordsDocument,
            queryVariables: searchRecordsVariables
          } as any
        })
      },
      recordDescription: titleAttribute?.identifier
        ? `${get(record, `${prefix}.${titleAttribute?.identifier}.${currentLocale}`)}`
        : `${get(record, `${prefix}.id.${currentLocale}`)}`
    })
  }

  const searchableAttributes: string[] = compact((attributes.map(
    (attr: any) => {
      if (!attr.isArray
        && attr.fieldType === FieldIdentifier.TEXT
      ) return camelCase(attr.identifier)
      return ''
    }
  ) || []))

  const [ {
    data: { internalSearchRecords: searchRecords = [] } = {},
    loading: listLoading,
    error: listError,
    refetch: refetchRecords
  }, onSearch ] = useSearch<
    schema.InternalSearchRecordsQuery, schema.InternalSearchRecordsQueryVariables
  >({
    query: schema.InternalSearchRecordsDocument,
    queryOptions: {
      variables: searchRecordsVariables,
      skip: !resourceId || !attributes.length
    },
    keys: searchableAttributes
  })

  const {
    data: { internalSummarizeRecords: summarizeRecords } = {}
  } = schema.useInternalSummarizeRecordsQuery({
    variables: {
      input: {
        resourceId,
        filter: searchRecordsVariables.input.filter,
        targetEnvironment
      }
    },
    skip: !resourceId
  })

  useEffect(() => {
    handlePageChange(1)
  }, [ handlePageChange, targetEnvironment ])

  const handleRowSelect: RowSelectionFn = (record, isSelected) => {
    let updatedBlockProperties

    if (selectionMode === 'SINGLE') {
      updatedBlockProperties = {
        [identifier]: {
          ...currentBlockProperties,
          currentRow: isSelected ? record : null
        }
      }
    }

    if (selectionMode === 'MULTIPLE') {
      const currentSelectedRows = currentBlockProperties?.selectedRows || []

      updatedBlockProperties = {
        [identifier]: {
          ...currentBlockProperties,
          selectedRows: isSelected
            ? (currentSelectedRows).concat(record)
            : (currentSelectedRows).filter((r: any) => r.id !== record.id)
        }
      }
    }

    if (updatedBlockProperties) updateBlockProperties(updatedBlockProperties)
  }

  const getRelationPrefix = (relations: string[]) => {
    const relationIdentifiers = relations.map((rel) => camelCase(
      idToRelationshipsMap[rel].identifier
    ))

    return `data.${relationIdentifiers.join('.data.')}.data`
  }

  let prefix = resourceId ? 'data' : undefined
  const suffix = resourceId ? 'en_US' : undefined
  const TitleRenderer = generateLinkRenderer({
    as: TextRenderer,
    onClick: openEditView
  })

  const tableColumns: Column[] = columns?.map(({
    attribute,
    data_type: dataType,
    label,
    value,
    is_visible: isVisible = true,
    is_orderable: isOrderable = false,
    display_type: displayType = DisplayType.PLAIN_TEXT,
    display_type_settings: displayTypeSettings,
    relations,
    width
  }: {
      attribute?: string,
      data_type?: string,
      label: string,
      value?: string,
      display_type?: DisplayType,
      display_type_settings?: any,
      is_visible: boolean,
      is_orderable?: boolean,
      width: string | number,
      relations: any,
      kind: string
    }, idx: number) => {
    const currentAttribute = idToAttributesMap[attribute!]

    let dataKey: string
    let template: Column['template']
    let title
    let sortable = isOrderable || !!operationId // temp: enable for all operation driven tables
    let fieldType
    let isArray

    // @ts-ignore
    // eslint-disable-next-line max-len
    let rendererProps = FIELD_TYPE_TO_RENDERER_MAP[displayType] || FIELD_TYPE_TO_RENDERER_MAP[currentAttribute?.fieldType] || TextRenderer

    let fieldProps
    let attributeProps
    if (currentAttribute) {
      const relationship = relationships.find((rel) => (
        rel.sourceId === resource?.id && rel.sourceAttributeId === attribute
      ))

      const oneToOneRelationship = relationships.find(
        (rel) => rel.sourceAttributeId === currentAttribute.id
        && [ Kind.BELONGS_TO, Kind.HAS_ONE ].includes(rel.kind)
      )

      const OneToOneReferenceRenderer = (props: RendererOptions) => {
        const { rowData, dataKey: key } = props
        const titleAttribute = oneToOneRelationship?.target.attributes.find(
          (attr) => oneToOneRelationship?.target.titleAttributeId === attr.id
        )

        const LinkRenderer = generateLinkRenderer({
          as: TextRenderer,
          onClick: () => {
            const title = titleAttribute
              ? get(rowData, `data.${camelCase(oneToOneRelationship?.target.identifier)}.data.${camelCase(titleAttribute?.identifier)}.en_US`)
              : oneToOneRelationship!.target.name
            const key = camelCase(oneToOneRelationship?.sourceAttribute.identifier || '')

            openView({
              title,
              component: GenericResourceDetailsView,
              style: GenericResourceDetailsView.defaultStyle,
              params: {
                title,
                recordId: rowData?.data[key]?.en_US || rowData?.data[key]?.data?.id.en_US,
                resourceId: oneToOneRelationship?.targetId
              }
            })
          }
        })

        return (
          <LinkRenderer
            {...props}
            // eslint-disable-next-line no-nested-ternary
            dataKey={titleAttribute ? camelCase(titleAttribute.identifier)
              : relationship ? oneToOneRelationship?.targetAttribute.identifier || key
                : key}
            prefix={`data.${camelCase(oneToOneRelationship?.identifier)}.data`}
          />
        )
      }

      prefix = relations ? getRelationPrefix(relations) : 'data'
      dataKey = camelCase(currentAttribute.identifier)
      title = label || (currentAttribute.fieldType === FieldIdentifier.REFERENCE
        ? (relationship?.name || currentAttribute.name) : currentAttribute.name)
      sortable = currentAttribute.isOrderable
      fieldType = currentAttribute.fieldType
      isArray = currentAttribute.isArray
      fieldProps = currentAttribute.dataType?.settings || {}
      attributeProps = {
        resource: (relationship?.target),
        attributes: (relationship?.target.attributes)
      }
      const isTitleAttribute = attribute === titleAttribute?.id

      if (isTitleAttribute) {
        rendererProps = {
          renderer: TitleRenderer
        }
      } else if (relationship?.kind === (Kind.BELONGS_TO || Kind.HAS_ONE)) {
        rendererProps = {
          renderer: OneToOneReferenceRenderer
        }
      }
    } else {
      template = value
      title = label || 'Untitled'
      dataKey = label
      fieldType = dataTypesList?.find((d) => d.id === dataType)?.kind
      isArray = false
    }

    return ({
      dataKey, // needs to remain separate from prefix and suffix for sorting to work
      prefix,
      suffix,
      template,
      title,
      sortable,
      hidden: !isVisible,
      fieldType,
      style: idx === (columns?.length - 1)
        ? { flexGrow: 1, width, flexBasis: width, justifyContent: [ DisplayType.NUMERIC, DisplayType.PHONE_NUMBER, DisplayType.CURRENCY, DisplayType.DURATION ].includes(displayType) ? 'flex-end' : 'flext-start' }
        : { width, flexBasis: width },
      isArray,
      fieldProps,
      displayTypeSettings: displayTypeSettings || {},
      attributeProps,
      ...rendererProps
    })
  }).filter(({ dataKey, template }: Column) => !!dataKey || !!template) || []

  const filterProps = {
    filters,
    setFilters
  }

  const [ searchText, setSearch ] = useState('')

  const primaryElements = (primaryElementsData.length || canSearch) && (
    <Flex gap={8}>
      <GenericElement elements={primaryElementsData} />
      {canSearch && (
        <SearchBar
          placeholder="Search"
          loading={listOperationData.loading}
          onChange={(e) => {
            onSearch(e)
            setSearch(typeof e === 'string' ? e : e.target.value)
          }}
        />
      )}
    </Flex>
  )

  const [ executeAction ] = schema.useExecuteMutationOperationMutation()

  const handleExecuteOperation = useSubmitHandler(executeAction, {
    successAlert: {
      message: 'Operation executed successfully',
      title: 'Success'
    }
  })

  const exportCSV = () => {
    const headers = tableColumns
      .map((column) => column.title)
      .join(',')
    const body = processedOperationRecords
      .map((record) => (
        tableColumns
          .map((column) => `"${record[column.dataKey]}"`)
          .join(',')
      ))

    const content = [ headers ].concat(body)
      .join('\n')

    const blob = new Blob([ content ], { type: 'text/csv;charset=utf-8;' })
    const url = URL.createObjectURL(blob)

    const anchor = document.createElement('a')
    anchor.href = url
    anchor.setAttribute('download', `${title || identifier}-${(new Date()).toISOString()}.csv`)
    anchor.click()
    anchor.remove()
  }

  const { activeView } = useDashboardViewContext()

  const [ exportBlock, { loading: exporting } ] = schema.useExportBlockMutation()
  const handleExportBlock = useSubmitHandler(exportBlock)

  const exportPaginatedCSV = () => handleExportBlock({
    blockId: block.id,
    targetEnvironment,
    arguments: omit(variables?.input.arguments || {}, 'page', 'pageSize'),
    viewId: activeView.id
  }).then((result) => {
    const url = result.data?.exportBlock.url

    const anchor = document.createElement('a')
    anchor.href = url
    anchor.setAttribute('download', `${title || identifier}-${(new Date()).toISOString()}.csv`)
    anchor.click()
    anchor.remove()
  })

  const [ processedOperationRecords, setProcessedOperationRecord ] = useState<any[]>([])
  const [ processing, setProcessing ] = useState(false)

  const columnsRef = useRef(tableColumns)
  const secondaryElements = (
    <Flex gap={16} alignItems="center">
      {toolbarActions.map((action: any) => {
        if (action.is_hidden) {
          return null
        }

        if (action.is_system) {
          if (action.identifier === 'CREATE' && createOperation) {
            return <Button icon="add-thin" onClick={openAddRecordView} size="small" />
          }

          if (action.identifier === 'REFRESH') {
            return (
              <RefreshButton
                name="refresh"
                description="Refresh"
                onClick={() => {
                  if (operationId) return refetchOperationQuery()
                  if (resourceId) return refetchRecords()
                  return null
                }}
                size={24}
              />
            )
          }

          if (action.identifier === 'IMPORT' && createOperation) {
            return (
              <ImportButton
                resource={resource}
                operationId={createOperation.id}
                targetEnvironment={targetEnvironment}
              />
            )
          }

          if (action.identifier === 'EXPORT') {
            if (operationId && processedOperationRecords.length) {
              return (
                <IconButton
                  name="upload"
                  description={exporting ? 'Exporting...' : 'Export'}
                  onClick={operation?.method === 'PAGED_LIST' ? exportPaginatedCSV : exportCSV}
                  size={24}
                  disabled={!operation || exporting}
                />
              )
            }

            if (resourceId) {
              return (
                <ExportRecordsButton
                  resourceId={resourceId}
                  fileName={`${title || identifier}-${(new Date()).toISOString()}.csv`}
                />
              )
            }
          }
        }

        switch (action.behavior) {
          case Behavior.RUN_OPERATION:
            const input = Object.fromEntries(
              Object.entries(action.input || {}).map(([ key, value ]: any[]) => [
                key,
                JSONParseOr(safeParseLiquid(value, blockProperties))
              ])
            )

            const handleRunOperationClick = () => handleExecuteOperation({
              arguments: input,
              operationId: action.operation,
              targetEnvironment
            })

            if (action.display_style === 'PRIMARY') {
              return (
                <Button
                  icon={action.icon}
                  disabled={Object.values(input).every((v) => !v)}
                  onClick={handleRunOperationClick}
                  size="small"
                />
              )
            }

            return (
              <IconButton
                disabled={Object.values(input).every((v) => !v)}
                name={action.identifier}
                description={action.name}
                onClick={handleRunOperationClick}
                size={24}
              />
            )

          case Behavior.REDIRECT:
            const url = safeParseLiquid(action.url, {
              ...blockProperties,
              environment: targetEnvironment || ''
            })

            if (action.display_style === 'PRIMARY') {
              return (
                <Button
                  icon={action.icon}
                  href={url}
                  target={action.target}
                  size="small"
                />
              )
            }

            return (
              <IconButton
                name={action.identifier}
                description={action.name}
                onClick={() => window.open(url, action.target, 'noopener noreferrer')}
                size={24}
              />
            )

          case Behavior.OPEN_VIEW:
            const handleOpenViewClick = () => {
              const params = Object.fromEntries(
                Object.entries(action.input || {}).map(([ key, value ]: any[]) => [
                  key,
                  JSONParseOr(safeParseLiquid(value, blockProperties)) || null
                ])
              )

              openView({
                component: GenericView,
                style: action.view_style,
                params: {
                  viewUrn: action.view_urn,
                  params
                }
              })
            }

            if (action.display_style === 'PRIMARY') {
              return (
                <Button
                  icon={action.icon}
                  size="small"
                  onClick={handleOpenViewClick}
                />
              )
            }

            return (
              <IconButton
                name={action.identifier}
                description={action.name}
                onClick={handleOpenViewClick}
                size={24}
              />
            )
          default:
            return null
        }
      })}
    </Flex>
  )
  useEffect(() => {
    columnsRef.current = tableColumns
  })

  useEffect(() => {
    let executeOperationRecords = executeQueryOperation.records || executeQueryOperation
    executeOperationRecords = Array.isArray(executeOperationRecords)
      ? executeOperationRecords
      : [ executeOperationRecords ]
    if (!Array.isArray(executeOperationRecords)) {
      setProcessedOperationRecord((curr) => (curr.length ? [] : curr))
      return undefined
    }

    setProcessing(true)

    let isLive = true

    Promise.all(
      executeOperationRecords.map(async (record: any, index: number) => {
        if (!record) return null

        const row = await Promise.all(
          columnsRef.current
            .map(async (column, j) => ({
              ...record,
              id: record.id || index + 1,
              [column.dataKey]: columns[j].value !== undefined
                ? JSONParseOr(
                  (await (
                    parseAndRender(columns[j].value, { record })
                      .catch(() => columns[j].value)
                  ))?.trim()
                )
                : get(record, column.dataKey)
            }))
        )

        if (!isLive) return null

        return row.reduce((acc: any, record) => ({ ...acc, ...record }), {})
      })
    ).then((records) => {
      if (!isLive) return

      const filteredRecords = searchText.trim() ? records.filter((record: any) => (
        columnsRef.current.some((column: any) => (
          get(record, column.dataKey)?.toLowerCase?.().includes(searchText.toLowerCase())
        ))
      )) : records

      const orderedRecords = order?.length
        ? filteredRecords.slice().sort((a: any, b: any) => (
          order.reduce((acc: any, o: any) => {
            if (!acc) {
              const [ [ key, direction ] ] = Object.entries(o) as unknown as [string, string]
              const [ first, second ] = [ a[key], b[key] ]
              let comparison = 0

              if (first === second) return comparison

              if (typeof first === 'string' || typeof second === 'string') {
                comparison = first.toString().localeCompare(second)
              } else {
                comparison = first > second ? 1 : -1
              }

              if (direction === 'asc') {
                return comparison
              }

              return -comparison
            }

            return acc
          }, 0)
        ))
        : filteredRecords

      setProcessedOperationRecord(
        orderedRecords
      )
    }).finally(() => {
      if (isLive) setProcessing(false)
    })

    return () => {
      isLive = false
      setProcessing(false)
    }
  }, [ executeQueryOperation, searchText, columns, order ])

  const paginationProps = {
    page,
    pageSize,
    pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
    paginationMode: enablePagination ? 'finite' : 'infinite',
    totalRows: (aggregateOperationData as any)?.[aggregateOperation?.identifier || '']?.count
      || summarizeRecords?.count
      || (executeQueryOperation?.paging?.totalCount || processedOperationRecords.length)
  }

  const paginatedOperationRecords = enablePagination && operation?.method !== 'PAGED_LIST'
    ? processedOperationRecords.slice(
      (page - 1) * pageSize,
      page * pageSize
    ) : processedOperationRecords

  return (
    <>
      <DataTableBlock
        key={resourceId}
        actions={rowActions?.map((action: any) => {
          if (action.is_system) {
            if (action.identifier === 'EDIT' && updateOperation) {
              return ({
                icon: 'edit',
                name: 'Edit',
                onClick: openEditView,
                visibilityFilter: () => !action.is_hidden
              })
            }
            if (action.identifier === 'DELETE' && destroyOperation) {
              return ({
                icon: 'trash',
                name: 'Delete',
                onClick: openDeleteConfirmDialog,
                visibilityFilter: () => !action.is_hidden
              })
            }
          }

          switch (action.behavior) {
            case Behavior.RUN_OPERATION:
              const handleRunOperationClick = (record: any) => {
                const input = Object.fromEntries(
                  Object.entries(action.input || {}).map(([ key, value ]: any[]) => [
                    key,
                    JSONParseOr(safeParseLiquid(value, { ...blockProperties, record }))
                  ])
                )

                return handleExecuteOperation({
                  arguments: input,
                  operationId: action.operation,
                  targetEnvironment
                })
              }

              return {
                icon: action.icon,
                title: action.name,
                isSecondary: action.display_style === 'SECONDARY',
                onClick: handleRunOperationClick,
                visibilityFilter: () => !action.is_hidden
              }

            case Behavior.REDIRECT:
              return {
                icon: action.icon,
                title: action.name,
                isSecondary: action.display_style === 'SECONDARY',
                onClick: (record: any) => {
                  const url = safeParseLiquid(action.url, {
                    ...blockProperties,
                    record
                  })
                  window.open(url, action.target, 'noopener noreferrer')
                },
                visibilityFilter: () => !action.is_hidden
              }

            case Behavior.OPEN_VIEW:
              const handleOpenViewClick = (record: any) => {
                const params = Object.fromEntries(
                  Object.entries(action.input || {}).map(([ key, value ]: any[]) => [
                    key,
                    JSONParseOr(safeParseLiquid(value, {
                      ...blockProperties,
                      record
                    })) || null
                  ])
                )

                openView({
                  component: GenericView,
                  style: action.view_style,
                  params: {
                    viewUrn: action.view_urn,
                    params
                  }
                })
              }

              return {
                icon: action.icon,
                title: action.name,
                isSecondary: action.display_style === 'SECONDARY',
                onClick: handleOpenViewClick,
                visibilityFilter: () => !action.is_hidden
              }

            default:
              return null
          }
        }).filter(Boolean)}
        columns={tableColumns}
        loading={listLoading || processing
        || executeOperationLoading || resourcesListLoading
        || attributesListLoading || relationshipsListLoading}
        error={listError || executeOperationError}
        data={
        operationId
          ? paginatedOperationRecords as any[]
          : searchRecords as any[] || []
      }
        title={title}
        onChangePage={handlePageChange}
        onChangePageSize={handlePageSizeChange}
        onRowSelect={handleRowSelect}
        primaryElements={primaryElements}
        secondaryElements={secondaryElements}
        selectionMode={selectionMode?.toLowerCase() || 'multiple'}
        width={width || { md: '100%' }}
        fullWidth={!containerId}
        compact={!!containerId}
        withPageControls={!containerId}
        defaultOrder={order}
        setOrder={setOrder}
        {...((canPaginate || enablePagination) && paginationProps)}
        {...(canFilter && filterProps)}
        {...blockProps}
        {...rest}
      />
      <ProgressIndicator
        loading="Exporting records..."
        visible={exporting}
      />
    </>
  )
}

export { FIELD_TYPE_TO_RENDERER_MAP, ExportRecordsButton }

export default DataTableBlockWrapper
