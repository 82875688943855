import arrayMutators from 'final-form-arrays'
import React, { useRef, useState } from 'react'
import { Field, Form, FormProps } from 'react-final-form'
import type { FormApi } from 'final-form'

import AttributeModel from 'models/Attribute'
import BackLink from 'components/links/BackLink'
import Button from 'components/buttons/Button'
import ConditionalField from 'components/form/ConditionalField'
import FieldArray, { FieldArrayChildrenProps } from 'components/form/FieldArray'
import FieldGroup from 'components/form/FieldGroup'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import FormValuesField from 'components/form/FormValuesField'
import IconInput from 'components/inputs/IconInput'
import Portal from 'components/portal/Portal'
import SelectInput from 'components/inputs/SelectInput'
import Text from 'components/typography/Text'
import TextAreaInput from 'components/inputs/TextAreaInput'
import TextInput from 'components/inputs/TextInput'
import TextLink from 'components/links/TextLink'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { Attribute, CreateResourceInput, Resource, ResourcesListDocument, ResourcesListQueryVariables, UpdateResourceInput, useAttributesListQuery, useCreateResourceMutation, useUpdateResourceMutation } from 'generated/schema'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { DIRECTION_OPTIONS, ORDER_STYLE_OPTIONS, OrderStyle } from 'models/Resource'
import type { FieldIdentifier } from 'models/Field'
import type { SidePaneRenderProps } from 'components/sidePane/SidePane'
import type { ViewProps } from 'components/views'

type FormValues = CreateResourceInput | UpdateResourceInput

type CreateResourceFormParams = {
  initialValues: FormValues,
  queryVariables: ResourcesListQueryVariables,
  onComplete?: (resource: Resource) => void,
  onBackClick: () => void
}

const setIdentifier = createSetIdentifier<FormValues>('name', 'identifier')
const FIELD_ORDERINGS_FIELD = 'orderAttributes'

const getValidOrderingOptions = (
  attributesList: Resource['attributes'], fieldOrderings: UpdateResourceInput['orderAttributes']
) => attributesList
  .filter((attr) => AttributeModel.isOrderableField(attr.fieldType as FieldIdentifier))
  .map(
    (field) => ({ ...field, isDisabled: fieldOrderings?.find((f) => f?.id === field?.id) })
  )

function CreateResourceForm({
  onRequestClose,
  params: { initialValues, onBackClick, onComplete },
  viewProps
}: ViewProps<CreateResourceFormParams> & { viewProps: SidePaneRenderProps }) {
  const [ footerEl, setFooterEl ] = useState<HTMLDivElement | null>(null)
  const fieldsRef = useRef<FieldArrayChildrenProps<UpdateResourceInput['orderAttributes']>>()

  const isUpdating = 'id' in initialValues

  const title = `${isUpdating ? 'Edit' : 'Build'} Resource`
  const subtitle = 'Step 2: Enter details'

  const {
    data: { attributesList = [] } = {},
    loading: attributesListLoading
  } = useAttributesListQuery({
    variables: {
      filter: {
        resourceId: { eq: isUpdating && initialValues.id }
      }
    },
    skip: !isUpdating
  })

  const [ createResource ] = useCreateResourceMutation({
    onCompleted: (data) => {
      onComplete?.(data.createResource as Resource)
      onRequestClose()
    },
    refetchQueries: [ ResourcesListDocument ]
  })

  const handleCreateResource = useSubmitHandler(createResource, {
    successAlert: { message: 'Resource Created.' }
  })

  const [ updateResource ] = useUpdateResourceMutation({
    onCompleted: onRequestClose,
    refetchQueries: [ ResourcesListDocument ]
  })

  const handleUpdateResource = useSubmitHandler(updateResource, {
    successAlert: { message: 'Resource Updated.' }
  })

  const handleSubmit = (values: FormValues, form: FormProps<FormValues>['form']) => {
    if (isUpdating) {
      return handleUpdateResource(
        values as UpdateResourceInput,
        form as FormApi<UpdateResourceInput>
      )
    }
    return handleCreateResource(values as CreateResourceInput)
  }

  return (
    <>
      <viewProps.Header
        onCloseClick={onRequestClose}
      >
        <Flex direction="column" alignItems="stretch" gap={8} grow={1} justifyContent="space-between">
          {!isUpdating && <BackLink onClick={onBackClick} />}
          <Text color="dark900" fontSize={24} fontWeight="bold" letterSpacing="compact">
            {title}
          </Text>
        </Flex>
      </viewProps.Header>
      {!isUpdating && (
        <viewProps.SubHeader>
          <Text fontWeight="regular" fontSize={14} color="dark700">{subtitle}</Text>
        </viewProps.SubHeader>
      )}
      <viewProps.Body>
        <Form
          initialValues={initialValues}
          decorators={[
            setIdentifier
          ]}
          mutators={{
            ...arrayMutators
          }}
          keepDirtyOnReinitialize
          onSubmit={handleSubmit}
          subscription={{
            submitting: true,
            pristine: true
          }}
          render={({ handleSubmit, submitting, pristine }) => (
            <Flex as="form" gap={16} direction="column" onSubmit={handleSubmit}>
              <FormField
                autoFocus
                checkRequired
                component={TextInput}
                name="name"
                label="Name"
                size="small"
                type="text"
              />
              <FormField
                checkRequired
                component={TextInput}
                name="identifier"
                label="Identifier"
                size="small"
                type="text"
              />
              <Field
                component={IconInput}
                name="icon"
                label="Choose Icon"
                size="small"
                type="text"
              />
              <FormField
                component={TextAreaInput}
                name="description"
                label="Description"
                size="small"
                type="text"
                rows={3}
              />
              {isUpdating && (
              <>
                <FormValuesField fieldNames={[ 'titleAttributeId', 'subtitleAttributeId', 'polymorphicAttributeId' ]}>
                  {(values) => (
                    <>
                      <Field
                        isLoading={attributesListLoading}
                        isDisabled={!attributesList.length}
                        isClearable
                        name="titleAttributeId"
                        label="Title Attribute"
                        component={SelectInput}
                        options={attributesList}
                        isOptionDisabled={(option: Record<any, any>) => (
                          option.id === values.subtitleAttributeId
                            || option.id === values.polymorphicAttributeId
                        )}
                        labelKey="name"
                        valueKey="id"
                        size="small"
                      />
                      <Field
                        isLoading={attributesListLoading}
                        isDisabled={!attributesList.length}
                        isClearable
                        name="subtitleAttributeId"
                        label="Subtitle Attribute"
                        component={SelectInput}
                        options={attributesList}
                        isOptionDisabled={(option: Record<any, any>) => (
                          option.id === values.titleAttributeId
                            || option.id === values.polymorphicAttributeId
                        )}
                        labelKey="name"
                        valueKey="id"
                        size="small"
                      />
                      <Field
                        isLoading={attributesListLoading}
                        isDisabled={!attributesList.length}
                        isClearable
                        name="polymorphicAttributeId"
                        label="Polymorphic Attribute"
                        component={SelectInput}
                        options={attributesList}
                        isOptionDisabled={(option: Record<any, any>) => (
                          option.id === values.titleAttributeId
                            || option.id === values.subtitleAttributeId
                        )}
                        labelKey="name"
                        valueKey="id"
                        size="small"
                      />
                    </>
                  )}
                </FormValuesField>

                {isUpdating && (
                  <Field
                    name="orderStyle"
                    label="Ordering"
                    component={SelectInput}
                    options={ORDER_STYLE_OPTIONS}
                    isOptionDisabled={(option: Record<any, any>) => (
                      option.value === OrderStyle.SPECIFIC_FIELDS && !attributesList.length
                    )}
                    size="small"
                  />
                )}
                {isUpdating && (
                  <ConditionalField when="orderStyle" is={ORDER_STYLE_OPTIONS[1].value}>
                    <Flex direction="column" gap={16}>
                      <FieldArray
                        name={FIELD_ORDERINGS_FIELD}
                        fieldsRef={fieldsRef}
                      >
                        {({ keys, fields }) => keys.map((key, index) => (
                          <FieldGroup
                            key={key}
                            onClick={() => fieldsRef.current?.fields.remove(index)}
                          >
                            <Field
                              name={`${FieldArray.getFieldName(FIELD_ORDERINGS_FIELD, index)}.id`}
                              options={getValidOrderingOptions(attributesList as Attribute[], fields.value as UpdateResourceInput['orderAttributes'])}
                              component={SelectInput}
                              size="small"
                              labelKey="name"
                              valueKey="id"
                            />
                            <Field
                              name={`${FieldArray.getFieldName(FIELD_ORDERINGS_FIELD, index)}.direction`}
                              component={SelectInput}
                              options={DIRECTION_OPTIONS}
                              size="small"
                            />
                          </FieldGroup>
                        ))}
                      </FieldArray>
                      <TextLink
                        fontSize="12"
                        as="button"
                        type="button"
                        variant="underlined"
                        mode="subtle"
                        alignSelf="flex-start"
                        fontWeight="bold"
                        color="dark500"
                        onClick={() => fieldsRef.current?.fields.push({ direction: 'ASC', id: '' } as any)}
                      >
                        Add field
                      </TextLink>
                    </Flex>
                  </ConditionalField>
                )}
              </>
              )}
              <input type="submit" style={{ display: 'none' }} />
              {footerEl && (
                <Portal target={footerEl}>
                  <Button type="submit" label="Save" disabled={submitting || pristine} onClick={handleSubmit} />
                </Portal>
              )}
            </Flex>
          )}
        />
      </viewProps.Body>
      <viewProps.Footer>
        <Flex direction="row-reverse" ref={setFooterEl} />
      </viewProps.Footer>
    </>
  )
}

export default CreateResourceForm
