/* eslint-disable react/no-array-index-key */
import camelCase from 'lodash/camelCase'
import isObject from 'lodash/isObject'
import React, { useState } from 'react'
import startCase from 'lodash/startCase'
import { test } from 'uuid-random'

import Card from 'components/card/Card'
import Flex from 'components/layout/Flex'
import IconButton from 'components/buttons/IconButton'
import Label from 'components/typography/Label'
import rgba from 'lib/rgba'
import Text from 'components/typography/Text'
import { colorVars } from 'styles/theme'
import { PlainTextRenderer } from './PlainTextView'
import type { Attribute } from 'generated/schema'

type RendererProps = {
  displaySettings: Attribute['displayTypeSettings'],
  data: any
}

const renderData = (data: any): any => {
  if (Array.isArray(data)) {
    return (
      data.map(renderData).join(', ')
    )
  }

  if (isObject(data)) {
    // @ts-ignore
    return Object.keys(data)
      .filter((key) => !test(data[key] as string))
      .map((key, index, { length }) => (
        <Text as="span" key={key}>
          <Label>{startCase(camelCase(key))}</Label>&nbsp;-&nbsp;
          <Text as="span" fontWeight="semibold">{renderData(data[key])}</Text>
          {index < length - 1 && ' • '}
        </Text>
      ))
  }

  return data
}

const GenericObjectRender = ({ data, displaySettings = { fontWeight: 'semibold' } }: RendererProps) => {
  const [ isOpen, setIsOpen ] = useState(false)

  if (Array.isArray(data)) {
    if (data.some(isObject)) {
      return (
        <>
          {data.map((d, i) => (
            <GenericObjectRender
              key={i}
              data={d}
              displaySettings={displaySettings}
            />
          ))}
        </>
      )
    }

    return <PlainTextRenderer data={data} displaySettings={displaySettings} />
  }

  if (isObject(data)) {
    return (
      <Flex direction="column" gap={10}>
        {!isOpen
          ? (
            <Card
              width="100%"
              css={{
                border: '1px solid',
                borderColor: rgba(colorVars.dark100rgb, 0.5)
              }}
              onClick={() => setIsOpen(!isOpen)}
              padding="compact"
            >
              <Flex
                justifyContent="space-between"
                alignItems="center"
                grow={1}
                direction="row"
                gap={8}
              >
                <Text lineHeight="compact" grow={1} fontSize="14" truncate css={{ width: 0 }}>
                  {renderData(data)}
                </Text>
                <IconButton description="Open" color="dark500" size={12} name="arrow-right" css={{ margin: -6 }} />
              </Flex>
            </Card>
          )
          : (
            <Card
              width="100%"
              css={{
                border: '1px solid',
                borderColor: rgba(colorVars.dark100rgb, 0.5)
              }}
              padding="compact"
            >
              <Flex
                justifyContent="space-between"
                grow={1}
                direction="row"
                gap={8}
              >
                <Flex grow={1} direction="column" gap="16">
                  {(Object.keys(data)).map((key: any, index: number) => (
                    <Flex key={index} direction="column" gap="10">
                      <Label>{startCase(camelCase(key))}</Label>
                      <GenericObjectRender
                        key={index}
                        displaySettings={displaySettings}
                        // @ts-ignore
                        data={data[key]}
                      />
                    </Flex>
                  ))}
                </Flex>
                <IconButton onClick={() => setIsOpen(!isOpen)} color="dark500" size={12} name="arrow-down" description="Close" css={{ margin: -6 }} />
              </Flex>
            </Card>
          )}
      </Flex>
    )
  }

  return <PlainTextRenderer data={data} displaySettings={displaySettings} />
}

export default GenericObjectRender
