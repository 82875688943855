import arrayMutators from 'final-form-arrays'
import React, { useContext, useEffect, useRef, useState } from 'react'
import uniq from 'lodash/uniq'
import { Field, Form } from 'react-final-form'
import { string } from 'yup'

import AddIntegrationView from 'components/views/AddIntegrationView'
import BackLink from 'components/links/BackLink'
import BaseModel from 'models/BaseModel'
import Button from 'components/buttons/Button'
import DataListBlock from 'components/blocks/DataListBlock'
import DrawerBlock from 'components/blocks/DrawerBlock'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import FormValuesField from 'components/form/FormValuesField'
import InternalContext from 'components/contexts/InternalContext'
import parseError from 'lib/parseError'
import Portal from 'components/portal/Portal'
import SearchBar from 'components/searchbar/SearchBar'
import SelectInput from 'components/inputs/SelectInput'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import TextLink from 'components/links/TextLink'
import usePager from 'hooks/usePager'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { APP_CATEGORIES_ID } from 'models/App'
import { ConfigurationStatus, ConfigurationsListDocument, DatabaseTable, GenerateGraphInput, Installation, Resource, ResourcesListDocument, ResourcesListQueryVariables, UpdateConfigurationInput, useConfigurationsListQuery, useDatabaseTablesAggregateQuery, useDatabaseTablesListQuery, useGenerateGraphMutation, useUpdateConfigurationMutation } from 'generated/schema'
import { DatabaseTableNameWithSchemaNameRenderer, EnvironmentPicker } from 'components/dashboardEditor/graph/ImportFromDatabaseView'
// import { SET_SWITCHER_MUTATION } from 'client/state/environmentSwitcher'
import { useSearchText } from 'hooks/useSearch'
import { useViewDispatch } from 'hooks/useViewContext'
import type { SidePaneRenderProps } from 'components/sidePane/SidePane'
import type { Content } from 'components/dataList/DataList'
import type { ViewProps } from 'components/views'
import type { SelectionHandlerProps } from 'components/providers/DataManagerProvider'

type FormValues = GenerateGraphInput

type GenerateResourceFormParams = {
  initialValues: Pick<FormValues, 'appId' | 'originKind' | 'originIds'>,
  queryVariables: ResourcesListQueryVariables,
  onBackClick: () => void,
  onComplete?: (resource: Resource) => void
}

const DEFAULT_TITLE = 'There are no tables.'
const DEFAULT_SUBTITLE = 'Refresh or re-sync your schema.'
const DEFAULT_ERROR_SUBTITLE = 'Please contact support if the problem persists.'

const contents: Content<DatabaseTable>[] = [
  { dataKey: 'name', slot: 'primary', renderer: DatabaseTableNameWithSchemaNameRenderer }
]

const DatabaseTablesList = ({
  installationId, targetEnvironment, selectionHandlerRef, setIsSyncing
}: {
  installationId: string,
  targetEnvironment: string,
  selectionHandlerRef: React.RefObject<SelectionHandlerProps>,
  setIsSyncing: (arg: boolean) => void
}) => {
  const [ page, pageSize, handlePageChange, handlePageSizeChange ] = usePager()

  const {
    data: configurationData,
    loading: configurationLoading,
    error: configurationError,
    startPolling,
    stopPolling
  } = useConfigurationsListQuery({
    variables: {
      filter: {
        installationId: { eq: installationId },
        environmentId: { eq: targetEnvironment }
      },
      limit: 1
    }
  })

  const configuration = configurationData?.configurationsList[0]

  const [ updateConfiguration, {
    loading: updateConfigurationLoading
  } ] = useUpdateConfigurationMutation({
    update: (cache, { data, errors }) => {
      if (!data || errors) return

      cache.writeQuery({
        query: ConfigurationsListDocument,
        data: {
          configurationsList: [ data.updateConfiguration ]
        },
        variables: {
          filter: {
            installationId: { eq: installationId },
            environmentId: { eq: targetEnvironment }
          },
          limit: 1
        }
      })
    }
  })

  const handleReSync = useSubmitHandler(updateConfiguration, {
    optimisticResponse: {
      response: 'UPDATE',
      mutation: 'updateConfiguration',
      typename: 'Configuration'
    },
    successAlert: { message: 'Sync initiated.' }
  })

  let syncStatus: ConfigurationStatus | 'IN_PROGRESS' | 'NOT_STARTED' = 'NOT_STARTED'

  if (updateConfigurationLoading) {
    syncStatus = 'IN_PROGRESS'
  } else if (configuration) {
    syncStatus = configuration.status
  }

  const { searchText, handleSearch, fetchOptions } = useSearchText()

  const queryVariables = {
    filter: {
      integrationId: { eq: installationId },
      environmentId: { eq: targetEnvironment },
      name: {
        contains: searchText.trim()
      }
    },
    page,
    limit: pageSize
  }

  const {
    data,
    loading,
    error,
    refetch
  } = useDatabaseTablesListQuery({
    variables: queryVariables,
    context: {
      fetchOptions
    }
  })

  const { data: aggregateData } = useDatabaseTablesAggregateQuery({
    variables: { filter: queryVariables.filter }
  })

  useEffect(() => {
    if (syncStatus === 'PENDING') {
      setIsSyncing(true)
      startPolling(1000)
    }
  }, [ syncStatus, startPolling, targetEnvironment, installationId, setIsSyncing ])

  useEffect(() => {
    if (syncStatus === 'COMPLETED') {
      setIsSyncing(false)
      stopPolling()
      refetch()
    }
  }, [ refetch, selectionHandlerRef, stopPolling, syncStatus, setIsSyncing ])

  const EmptyState = () => {
    const errorState = configurationError || error
    const loaderTitle = (() => {
      if (errorState) {
        const { alert } = parseError(errorState)

        return alert?.title || alert?.message
      }

      return DEFAULT_TITLE
    })()

    const loaderSubtitle = (() => {
      if (errorState) {
        const { alert } = parseError(errorState)

        return (alert?.title && alert?.message) || DEFAULT_ERROR_SUBTITLE
      }

      return DEFAULT_SUBTITLE
    })()

    return (
      <Flex alignItems="center" direction="column" gap={16}>
        <Flex alignItems="center" direction="column" gap={8}>
          <Text fontWeight="bold">{loaderTitle}</Text>
          <Text fontSize={14}>{loaderSubtitle}</Text>
        </Flex>
        <Flex alignItems="center" direction="row" gap={16}>

          <Button
            disabled={loading}
            label="Re-fetch tables"
            size="small"
            mode="subtle"
            onClick={() => {
              selectionHandlerRef.current?.deselectAllRows()
              refetch()
            }}
          />
        </Flex>
      </Flex>
    )
  }

  return (
    <DataListBlock
      asFragment
      actions={[]}
      title="Tables"
      primaryElements={
        <SearchBar placeholder="Quick Search..." onChange={handleSearch} />
      }
      secondaryElements={(
        <Button
          icon="refresh"
          size="small"
          variant="outline"
          label="Re-sync"
          disabled={![ 'NOT_STARTED', 'FAILED', 'COMPLETED', 'FINISHED' ].includes(syncStatus)}
          onClick={() => handleReSync({
            id: configuration?.id, settings: configuration?.settings
          } as UpdateConfigurationInput)}
        />
      )}
      contents={contents}
      data={data?.databaseTablesList || [] as DatabaseTable[]}
      loading={loading || configurationLoading || ![ 'NOT_STARTED', 'FAILED', 'COMPLETED', 'FINISHED' ].includes(syncStatus)}
      error={error || configurationError}
      selectionHandlerRef={selectionHandlerRef}

      empty={{
        element: <EmptyState />
      }}
      page={page}
      pageSize={pageSize}
      onChangePage={handlePageChange}
      onChangePageSize={handlePageSizeChange}
      pageSizeOptions={[ 5, 10, 25, 50 ]}
      paginationMode="finite"
      totalRows={aggregateData?.databaseTablesAggregate.count || 0}
      selectionMode="multiple"
    />
  )
}

function GenerateResourceForm({
  onRequestClose,
  params: { initialValues, onBackClick, onComplete },
  viewProps
}: ViewProps<GenerateResourceFormParams> & { viewProps: SidePaneRenderProps }) {
  const {
    currentDashboard,
    refetchContextQueries,
    installationsList = []
  } = useContext(InternalContext)!
  const { openView } = useViewDispatch()
  // const [ createSwitcherState ] = useMutation(SET_SWITCHER_MUTATION)

  /* const createResourceEnvironment = useCallback(
    async (resourceId: string, environment: Environment | null) => {
      try {
        await createSwitcherState({
          variables: { id: resourceId, environment }
        })
      } catch (err) {
        // Handle any errors
        console.error('Error occurred during createResourceEnvironment mutation:', err)
        throw err
      }
    },
    [
      createSwitcherState
    ]
  ) */

  const selectionHandlerRef = useRef<SelectionHandlerProps>(null)

  const [ footerEl, setFooterEl ] = useState<HTMLDivElement | null>(null)

  const [ isSyncing, setIsSyncing ] = useState(false)

  const title = 'Generate Resource'
  const subtitle = 'Step 2: Select an origin'

  const [ generateResource ] = useGenerateGraphMutation({
    onCompleted: (data) => {
      onComplete?.(data.generateGraph.resources[0] as Resource)
      onRequestClose()
    },
    refetchQueries: [
      ResourcesListDocument
    ]
  })

  const handleGenerateResource = useSubmitHandler(generateResource, {
    successAlert: { message: 'Resource Generated.' }
  })

  const handleSubmit = (values: FormValues) => handleGenerateResource({
    ...values,
    originIds: uniq(selectionHandlerRef.current?.selection) || []
  })/* .then(() => createResourceEnvironment(
    values.installationId,
    environmentsList.find((env) => env.id === values.targetEnvironment) || null
  )) */

  const addNewIntegration = () => openView({
    title: 'Add Integration',
    component: AddIntegrationView,
    style: 'PANEL',
    params: {
      dashboardId: currentDashboard?.id,
      appCategoryIds: [ APP_CATEGORIES_ID.Databases ],
      queryVariables: {
        limit: 100
      }
    }
  })

  const installationOptions = installationsList.filter(
    (installation) => installation.app.appCategoryId === APP_CATEGORIES_ID.Databases
  )

  return (
    <>
      <viewProps.Header
        onCloseClick={onRequestClose}
      >
        <Flex direction="column" gap={8}>
          <BackLink onClick={onBackClick} />
          <Text color="dark900" fontSize={24} fontWeight="bold" letterSpacing="compact">
            {title}
          </Text>
        </Flex>
      </viewProps.Header>
      <viewProps.SubHeader>
        <Text fontWeight="regular" fontSize={14} color="dark700">{subtitle}</Text>
      </viewProps.SubHeader>
      <Form
        initialValues={{
          installationId: installationOptions[0]?.id,
          ...initialValues
        }}
        onSubmit={handleSubmit}
        mutators={{
          ...arrayMutators
        }}
        subscription={{
          submitting: true
        }}
        validate={(values) => BaseModel.validateSchema(values, {
          installationId: string().required(),
          targetEnvironment: string().required()
        })}
        render={({ handleSubmit, submitting }) => (
          <viewProps.Body>
            <Flex as="form" gap={16} direction="column" onSubmit={handleSubmit}>
              <Flex gap={8} direction="column">
                <Flex alignItems="center" justifyContent="space-between">
                  <FieldLabel htmlFor="installationId">
                    Database
                  </FieldLabel>

                  <TextLink
                    fontSize="12"
                    as="button"
                    type="button"
                    // mode="subtle"
                    alignSelf="flex-start"
                    fontWeight="bold"
                    color="dark500"
                    onClick={() => refetchContextQueries()}
                  >
                    Refresh
                  </TextLink>
                </Flex>
                <Field
                  isSearchable
                  autoFocus
                  component={SelectInput}
                  name="installationId"
                  // label="Databases"
                  prependIcon="search"
                  placeholder="Choose a database"
                  size="small"
                  variant="light"
                  labelKey="name"
                  metaKey="kind"
                  valueKey="id"
                  options={installationOptions}
                  getOptionLabel={(option: Installation) => {
                    const { app } = option
                    const { name } = option
                    const hasSameAppName = app.name === name
                    return `${name} ${!hasSameAppName ? `(${app.name})` : ''}`
                  }}
                />
                <TextLink
                  fontSize="12"
                  as="button"
                  type="button"
                  variant="underlined"
                  mode="subtle"
                  alignSelf="flex-start"
                  fontWeight="bold"
                  color="dark500"
                  onClick={() => addNewIntegration()}
                >
                  Add new
                </TextLink>
              </Flex>
              <FormValuesField fieldNames={[ 'installationId' ]}>
                {({ installationId }) => installationId && (
                  <EnvironmentPicker installationId={installationId} />
                )}
              </FormValuesField>
              <FormValuesField fieldNames={[ 'targetEnvironment', 'installationId' ]}>
                {({ targetEnvironment, installationId }) => targetEnvironment && installationId && (
                  <>
                    <DatabaseTablesList
                      installationId={installationId}
                      targetEnvironment={targetEnvironment}
                      selectionHandlerRef={selectionHandlerRef}
                      setIsSyncing={setIsSyncing}
                    />
                    <DrawerBlock as={Flex} title="Advanced Settings">
                      {() => (
                        <Flex direction="column" gap={8}>
                          <Field
                            name="settings.primaryKey"
                            label="Primary Key"
                            component={TextInput}
                            size="small"
                          />
                          <Field
                            isClearable
                            name="settings.transformCase"
                            label="Name Casing"
                            component={SelectInput}
                            size="small"
                            options={[
                              { label: 'Sentence Case', meta: 'Sentence Case', value: 'SENTENTCE' },
                              { label: 'Pascal Case', meta: 'PascalCase', value: 'PASCAL' },
                              { label: 'Camel Case', meta: 'camelCase', value: 'CAMEL' },
                              { label: 'Snake Case', meta: 'snake_case', value: 'SNAKE' },
                              { label: 'Kebab Case', meta: 'kebab-case', value: 'KEBAB' }
                            ]}
                          />
                        </Flex>
                      )}
                    </DrawerBlock>
                  </>
                )}
              </FormValuesField>
              {footerEl && (
                <Portal target={footerEl}>
                  <FormValuesField fieldNames={[ 'installationId' ]}>
                    {({ installationId }) => installationId && (
                      <Button
                        type="submit"
                        label="Generate"
                        disabled={submitting || !installationId || isSyncing}
                        onClick={handleSubmit}
                      />
                    )}
                  </FormValuesField>
                </Portal>
              )}
            </Flex>
          </viewProps.Body>
        )}
      />
      <viewProps.Footer>
        <Flex direction="row-reverse" ref={setFooterEl} />
      </viewProps.Footer>
    </>
  )
}

export default GenerateResourceForm
