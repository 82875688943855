import React, { useCallback, useMemo, useRef } from 'react'
import uuid from 'uuid-random'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import type { ReactNode, RefObject } from 'react'
import type { DroppableProvided } from 'react-beautiful-dnd'

import * as mixins from 'styles/mixins'
import DataListItem, { DataListItemProps, DATA_LIST_DETAILED_ITEM_HEIGHT, DATA_LIST_NORMAL_ITEM_HEIGHT } from 'components/dataList/DataListItem'
import DataManagerProvider from 'components/providers/DataManagerProvider'
import Flex from 'components/layout/Flex'
import Pager from 'components/dataWidgets/Pager'
import SectionLoader from 'components/loaders/SectionLoader'
import SummaryBar from 'components/dataWidgets/SummaryBar'
import useVirtual from 'hooks/useVirtual'
import { styled } from 'styles/stitches'
import { useDataManagerContext, useNestedDataManagerContext } from 'hooks/useDataManagerContext'
import type { DataManagerProviderProps, DEFAULT_ROW_DATA } from 'components/providers/DataManagerProvider'
import type { PagerProps } from 'components/dataWidgets/Pager'
import type { SummaryBarProps } from 'components/dataWidgets/SummaryBar'
import type { VirtualItem } from 'hooks/useVirtual'
import type { LoaderProps } from 'components/loaders/Loader'

import listItemSkeletonImage from 'assets/images/list-item-skeleton-colored.svg'
import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'

type Content<T = any> = {
  title?: string,
  dataKey: Extract<keyof T, string>,
  hidden?: boolean,
  fieldType?: string,
  fieldProps?: any,
  isRepeated?: boolean,
  settings?: any,
  flexGrow?: number,
  flexShrink?: number,
  renderer?: (options: RendererOptions<T>) => ReactNode,
  slot: ContentSlot,
  width?: number | string
}

type DataListProps<T extends DEFAULT_ROW_DATA> =
  PagerProps
  & LoaderProps
  & DataManagerProviderProps<T>
  & Pick<SummaryBarProps<T>, 'batchActions' | 'hideFormulae' | 'hideSelectAll'>
  & {
    maxHeight?: number,
    overflow?: 'auto' | 'hidden',
    contents: Content[],
    nestedAction?: Pick<Content, 'dataKey' | 'renderer'>,
    scrollParentRef?: RefObject<HTMLElement>,
    dataListItemVariant?: DataListItemProps['variant'],
    summaryBarVariant?: SummaryBarProps<any>['variant'],
    compact?: boolean
}

type RendererOptions<T extends DEFAULT_ROW_DATA = any> = {
  index?: number,
  dataKey: Extract<keyof T, string>,
  rowData: T,
  selection?: T['id'][]
}

type ContentSlot = 'icon' | 'primary' | 'secondary' | 'meta' | 'toggle'

type VirtualizedListProps<T> = Pick<DataListProps<T>, 'contents' | 'data' | 'scrollParentRef' | 'dataListItemVariant' | 'pageSize'> & {
  isDraggable: boolean,
  nestedAction?: Pick<Content, 'dataKey' | 'renderer'>,
  level?: number
}

const StyledVirtualizedList = styled('div', {
  ...mixins.transition('fluid', 'all', 0.2),

  backgroundImage: `url(${listItemSkeletonImage})`,
  backgroundRepeat: 'no-repeat repeat',
  position: 'relative',
  width: '100%',
  borderRadius: 6,

  variants: {
    variant: {
      detailed: {
        backgroundSize: '100% 70px'
      },
      normal: {
        backgroundSize: '100% 50px'
      }
    }
  }
})

const VirtualizedList = React.memo(<T extends DEFAULT_ROW_DATA = any>({
  contents,
  data,
  dataListItemVariant = 'normal',
  isDraggable,
  level = 0,
  nestedAction,
  scrollParentRef,
  pageSize
}: VirtualizedListProps<T>
) => {
  const containerParentRef = useRef<HTMLDivElement | null>(null)

  const { idToElementMap } = useNestedDataManagerContext()

  const estimateSize = useCallback((i) => {
    const childrenCount = idToElementMap?.[data[i]?.id!]?.childrenCount
    const itemHeight = dataListItemVariant === 'normal' ? DATA_LIST_NORMAL_ITEM_HEIGHT : DATA_LIST_DETAILED_ITEM_HEIGHT
    return itemHeight + itemHeight * (childrenCount || 0)
  },
  [ data, dataListItemVariant, idToElementMap ])

  const { totalSize, virtualItems } = useVirtual({
    containerParentRef,
    estimateSize,
    overscan: 5,
    scrollParentRef,
    size: data?.length || pageSize
  })

  const renderDataListItem = (virtualItem: VirtualItem) => {
    const key = data[virtualItem.index]?.id ?? virtualItem.index

    if (!isDraggable) {
      return (
        <DataListItem
          contents={contents}
          key={key}
          id={key}
          level={level}
          virtualItemData={virtualItem}
          nestedAction={nestedAction}
          variant={dataListItemVariant}
        />
      )
    }

    const draggableId = key.toString()

    return (
      <Draggable
        disableInteractiveElementBlocking
        draggableId={draggableId}
        index={virtualItem.index}
        key={draggableId}
      >
        {(provided, { isDragging }) => (
          <DataListItem
            {...provided}
            contents={contents}
            isDragging={isDragging}
            key={key}
            id={key}
            level={level}
            nestedAction={nestedAction}
            ref={provided.innerRef}
            virtualItemData={virtualItem}
            variant={dataListItemVariant}
          />
        )}
      </Draggable>
    )
  }

  return (
    <StyledVirtualizedList
      ref={containerParentRef}
      style={{ height: `${totalSize}px` }}
      variant={dataListItemVariant}
    >
      {virtualItems.map(renderDataListItem)}
    </StyledVirtualizedList>
  )
})

function DataListBody<T extends DEFAULT_ROW_DATA>({
  contents,
  nestedAction,
  scrollParentRef,
  dataListItemVariant,
  pageSize,
  ...others
}: Pick<VirtualizedListProps<T>, 'contents' | 'nestedAction' | 'scrollParentRef' | 'dataListItemVariant' | 'pageSize'>) {
  const {
    data,
    isDraggable
  } = useDataManagerContext()

  const droppableId = useRef(uuid())
  const baseLevelData = useMemo(() => data.filter((datum) => !datum.parentId), [ data ])

  const vListProps = {
    contents,
    data: baseLevelData,
    dataListItemVariant,
    isDraggable,
    nestedAction,
    scrollParentRef,
    pageSize
  }

  if (!isDraggable) {
    return <VirtualizedList {...vListProps} />
  }

  return (
    <Droppable
      droppableId={droppableId.current}
      mode="virtual"
      type="level-0"
      renderClone={(droppableCloneProvided, snapshot, rubric) => (
        <DataListItem
          {...droppableCloneProvided}
          contents={contents}
          id={rubric.draggableId}
          isDragging={snapshot.isDragging}
          key={rubric.draggableId}
          nestedAction={nestedAction}
          ref={droppableCloneProvided.innerRef}
          virtualItemData={{ index: rubric.source.index }}
          variant={dataListItemVariant}
        />
      )}
    >
      {(provided: DroppableProvided) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          {...others}
        >
          <VirtualizedList {...vListProps} />
        </div>
      )}
    </Droppable>
  )
}

function DataList<T extends DEFAULT_ROW_DATA>({
  actions = [],
  batchActions = [],
  contents,
  data,
  loading,
  error,
  empty,
  defaultSelection,
  dataListItemVariant,
  hideFormulae,
  hideSelectAll,
  onChangePage,
  onChangePageSize,
  onRowDragEnd,
  onRowDragUpdate,
  onRowSelect,
  page,
  pageSize,
  pageSizeOptions,
  paginationMode,
  withPageControls,
  scrollParentRef,
  nestedAction,
  selectionHandlerRef,
  selectionMode = 'multiple',
  totalRows,
  summaryBarVariant = 'fixed',

  maxHeight = undefined,
  overflow = 'hidden',
  compact,
  ...others
}: DataListProps<T>) {
  const showPager = paginationMode === 'finite'
  const showSummary = !!batchActions.length
  const Loader = compact ? DashboardEditorLoader : SectionLoader
  return (
    <Flex direction="column" gap={24} css={{ maxHeight, overflow }}>
      <DataManagerProvider
        actions={actions}
        data={data}
        loading={loading}
        defaultSelection={defaultSelection}
        onRowDragEnd={onRowDragEnd}
        onRowDragUpdate={onRowDragUpdate}
        onRowSelect={onRowSelect}
        selectionHandlerRef={selectionHandlerRef}
        selectionMode={selectionMode}
        totalRows={totalRows}
      >
        <Loader
          data={data}
          error={error}
          loading={loading}
          empty={empty}
        >
          <DataListBody
            contents={contents}
            nestedAction={nestedAction}
            scrollParentRef={scrollParentRef}
            dataListItemVariant={dataListItemVariant}
            pageSize={pageSize}
            {...others}
          />
        </Loader>
        {(showPager || showSummary) && (
          <Flex wrap="wrap-reverse" justifyContent={summaryBarVariant === 'normal' ? 'space-between' : 'center'}>
            {showSummary && (
            <SummaryBar
              batchActions={batchActions}
              hideFormulae={hideFormulae}
              hideSelectAll={hideSelectAll}
              variant={summaryBarVariant}
            />
            )}
            {showPager && (
              <Pager
                data={data}
                loading={loading}
                onChangePage={onChangePage}
                onChangePageSize={onChangePageSize}
                page={page}
                pageSize={pageSize}
                pageSizeOptions={pageSizeOptions}
                paginationMode={paginationMode}
                totalRows={totalRows}
                withPageControls={withPageControls}
              />
            )}
          </Flex>
        )}

      </DataManagerProvider>
    </Flex>
  )
}

export default DataList

export {
  VirtualizedList
}

export type {
  Content,
  ContentSlot,
  DataListProps,
  RendererOptions
}
