import React, {
  createContext,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react'
import groupBy from 'lodash/groupBy'
import has from 'lodash/has'
import { DragDropContext, OnDragEndResponder } from 'react-beautiful-dnd'
import type { DropResult, ResponderProvided } from 'react-beautiful-dnd'
import type { PropsWithChildren, Dispatch, Ref, SetStateAction } from 'react'

import getPropertyToElementMap, { TMap } from 'lib/getPropertyToElementMap'
import type { RowAction } from 'components/dataWidgets/RowActions'

type DataManagerContextValue<T extends DEFAULT_ROW_DATA = any> = {
  // Data
  data: T[],
  loading: boolean,

  // Row actions
  actions: RowAction<T>[],

  // Selection
  defaultSelection?: T['id'] | T['id'][],

  deselectAllRows: () => void,
  deselectRow: (id: ID) => void,
  onRowSelect?: RowSelectionFn,
  selectAllRows: () => void,
  selection: ID[],
  selectionMode?: SelectionMode,
  selectRow: (id: ID | ID[]) => void,
  totalRows?: number,

  // Sorting
  setSortBy: Function,
  sortBy?: string[],
  sortDirections: SortDirection[],
  toggleSortDirection: (dataKey: string) => void,

  // Drag-and-drop
  isDraggable: boolean,
  isDragging: boolean,
  onDragEnd: (result: DropResult, provided: ResponderProvided) => void,
  onDragUpdate?: (result: DropResult, provided: ResponderProvided) => void
}

type ID = number | string

type CollapsedItemMap = Record<ID, boolean>

type NestedDataManagerContextValue<T extends DEFAULT_ROW_DATA = any> = {
  parentIdToElementMap?: T & { index: number},
  idToElementMap?: TMap<T & { index: number, childrenCount: number, isClosed?: boolean }>,
  collapsedItemMap: CollapsedItemMap,
  setCollapsedItemMap: Dispatch<SetStateAction<CollapsedItemMap>>
}

type DEFAULT_ROW_DATA = { id?: ID, index?: number, parentId?: ID }

type RowSelectionFn<T = any> = (record: T, isSelected: Boolean) => void

type SelectionMode = 'none' | 'single' | 'multiple'

type SortDirection = 'asc' | 'desc'

const SELECTION_MODE_OPTIONS: {label: string, value: SelectionMode}[] = [
  { label: 'None', value: 'none' },
  { label: 'Single', value: 'single' },
  { label: 'Multiple', value: 'multiple' }
]

const DataManagerContext = createContext({} as DataManagerContextValue)

const NestedDataManagerContext = createContext({} as NestedDataManagerContextValue)

type DataManagerProviderProps<T extends DEFAULT_ROW_DATA> = {
  data: readonly T[],
  loading?: boolean,

  actions?: RowAction<T>[],

  onRowSelect?: RowSelectionFn,
  onRowDeselect?: RowSelectionFn,
  selectionMode?: SelectionMode,
  selectionHandlerRef?: Ref<any>,
  setOrder?: React.Dispatch<SetStateAction<Array<Record<string, 'asc' | 'desc'>> | undefined>>,
  defaultSelection?: T['id'] | T['id'][],
  defaultOrder?: Array<Record<string, 'asc' | 'desc'>>,
  totalRows?: number,

  onRowDragEnd?: OnDragEndResponder,
  onRowDragUpdate?: (result: DropResult, provided?: ResponderProvided) => void
}

type SelectionHandlerProps = {
  deselectAllRows: () => void,
  deselectRow: (id: ID) => void,
  selectAllRows: () => void,
  selection: ID[],
  selectRow: (id: ID | ID[]) => void
}

const isValidID = (value: any) => (
  typeof value === 'number' || typeof value === 'string'
)

function getDataManagerMaps<T extends
  {id?: any, isOpen?: boolean}
>(data: T[], collapsedItemMap: any) {
  const parentIdMap = groupBy<T>(
    data.filter((datum) => has(datum, 'parentId')),
    'parentId'
  )

  function getChildrenCount(id: any, level: number): number {
    const immediate = parentIdMap[id!]
    const skip = collapsedItemMap[id]

    if (!immediate || skip) {
      return 0
    }

    return immediate.reduce(
      (acc, datum) => acc + getChildrenCount(datum?.id, level + 1),
      immediate.length + 1
    )
  }

  const dataWithChildrenCount = data?.map(
    (datum, index) => datum && (
      { ...datum, index, childrenCount: getChildrenCount(datum.id, 0) }
    )
  )

  const idToElementMap = getPropertyToElementMap<typeof dataWithChildrenCount[0]>(dataWithChildrenCount, 'id')

  const parentIdToElementMap = groupBy<T>(dataWithChildrenCount, 'parentId')

  return {
    dataWithChildrenCount, idToElementMap, parentIdToElementMap
  }
}

function getNestedInitialData<T>(data: readonly T[]) {
  return data?.map((datum) => datum && ({ ...datum, isOpen: true })) || []
}

function DataManagerProvider<T extends DEFAULT_ROW_DATA>({
  data,
  loading = false,

  actions = [],

  onRowSelect,
  selectionMode,
  selectionHandlerRef,
  defaultSelection = [],
  defaultOrder,
  setOrder,
  totalRows,

  onRowDragEnd,
  onRowDragUpdate: onDragUpdate,

  children
}: PropsWithChildren<DataManagerProviderProps<T>>) {
  const [ selection, setSelection ] = useState<ID[]>(
    (isValidID(defaultSelection) ? [ defaultSelection ] : defaultSelection) as ID[]
  )

  const [ collapsedItemMap, setCollapsedItemMap ] = useState<CollapsedItemMap>({})

  const selectRow = useCallback((id: ID | ID[]) => {
    if (selectionMode === 'none') return
    setSelection((currentSelection) => (selectionMode === 'single' ? [ id ].flatMap((i) => i) : currentSelection.concat(id)))
  }, [ selectionMode ])

  const selectAllRows = useCallback(() => {
    setSelection(data.map((datum) => datum?.id).filter((id) => !!id) as ID[])
  }, [ data ])

  const deselectRow = useCallback((id: ID) => {
    setSelection((currentSelection) => currentSelection.filter((rowId) => rowId !== id))
  }, [])

  const deselectAllRows = useCallback(() => {
    setSelection([])
  }, [])

  const nestedData = useMemo(() => getNestedInitialData(data), [ data ])

  const {
    parentIdToElementMap,
    idToElementMap,
    dataWithChildrenCount
  } = useMemo(
    () => getDataManagerMaps(nestedData, collapsedItemMap),
    [ collapsedItemMap, nestedData ]
  )

  useImperativeHandle(selectionHandlerRef, () => ({
    deselectAllRows,
    deselectRow,
    selectAllRows,
    selection,
    selectRow
  }), [ deselectAllRows, deselectRow, selectAllRows, selection, selectRow ])

  // eslint-disable-next-line no-nested-ternary
  const defaultSortBy = defaultOrder instanceof Array
    ? defaultOrder.length > 0 ? defaultOrder.map((o) => Object.keys(o)[0]) : []
    : Object.keys(defaultOrder || {})

  // Fix this to handle multilevel sorting
  const [ sortBy, setSortBy ] = useState(defaultSortBy)
  const [ sortDirections, setSortDirections ] = useState<SortDirection[]>(
    // eslint-disable-next-line no-nested-ternary
    defaultOrder
      // eslint-disable-next-line no-nested-ternary
      ? defaultOrder instanceof Array
        ? defaultOrder.length > 0 ? defaultOrder.map((o) => Object.values(o)[0]) : []
        : Object.values(defaultOrder || {})
      : []
  )

  const toggleSortDirection = useCallback((dataKey: string) => {
    setSortDirections((currentSortDirections) => {
      const index = sortBy.findIndex((column) => column === dataKey)
      const sortByKey = sortBy[index]
      const currentSortDirection = currentSortDirections[index]

      // Is not already sorted
      if (dataKey !== sortByKey) {
        // add 'asc' sort order rule
        setSortBy((/* currentSortByKeys */) => (
          [ dataKey ] // currentSortByKeys.concat(dataKey)
        ))
        return [ 'asc' ]// currentSortDirections.concat('asc')
      }

      // If already sorted
      if (currentSortDirection === 'asc') {
        // toggle 'asc' to 'desc'
        return [ 'desc' ] // currentSortDirections.slice(0, index).concat('desc').concat(currentSortDirections.slice(index + 1))
      }

      if (currentSortDirection === 'desc') {
        // remove sort order rule
        setSortBy(
          (/* currentSortByKeys */) => []
          /* currentSortByKeys.slice(0, index)
            .concat(currentSortByKeys.slice(index + 1)) */
        )

        return [] /* currentSortDirections.slice(0, index)
          .concat(currentSortDirections.slice(index + 1)) */
      }

      return currentSortDirections
    })
  }, [ sortBy ])

  const isDraggable = Boolean(onRowDragEnd)
  const [ isDragging, setIsDragging ] = useState(false)

  const onDragEnd = useCallback((result: DropResult) => {
    if (!result.destination || result.destination.index === result.source.index) {
      return
    }

    onRowDragEnd?.(result, data)
  }, [ data, onRowDragEnd ])

  useEffect(() => {
    if (!setOrder) return

    const newOrder = (sortBy as Array<'asc' | 'desc'>)
      .map((column, i) => ({
        [column]: sortDirections[i]
      }))

    setOrder(
      newOrder.length > 0
        ? newOrder
        : undefined
    )
  }, [ sortDirections, sortBy, setOrder ])

  const value = {
    actions,
    data: dataWithChildrenCount,
    loading,
    defaultSelection,
    defaultOrder,
    deselectAllRows,
    deselectRow,
    isDraggable,
    isDragging,
    onDragEnd,
    onRowSelect,
    selectAllRows,
    selection,
    selectionMode,
    selectRow,
    setSortBy,
    setOrder,
    sortBy,
    sortDirections,
    totalRows,
    toggleSortDirection
  }

  const nestedValue = {
    collapsedItemMap,
    idToElementMap,
    parentIdToElementMap,
    setCollapsedItemMap
  }

  return (
    <NestedDataManagerContext.Provider value={nestedValue}>
      <DataManagerContext.Provider value={value}>
        <DragDropContext
          onBeforeCapture={() => setIsDragging(true)}
          onDragEnd={(result, provided) => {
            setIsDragging(false)
            onDragEnd(result, provided)
          }}
          onDragUpdate={onDragUpdate}
        >
          {children}
        </DragDropContext>
      </DataManagerContext.Provider>
    </NestedDataManagerContext.Provider>
  )
}

export default memo(DataManagerProvider) as typeof DataManagerProvider

export { DataManagerContext, NestedDataManagerContext, SELECTION_MODE_OPTIONS }

export type {
  DataManagerContextValue,
  DataManagerProviderProps,
  DEFAULT_ROW_DATA,
  ID,
  RowSelectionFn,
  SelectionHandlerProps,
  SelectionMode,
  SortDirection
}
