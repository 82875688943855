import get from 'lodash/get'
import React from 'react'

import AccountModel from 'models/Account'
import AccountAvatar from 'components/avatars/AccountAvatar'
import Flex from 'components/layout/Flex'
import Text from 'components/typography/Text'
import { styled } from 'styles/stitches'
import type { Account } from 'generated/schema'
import type { RendererOptions } from 'components/dataTable/types'

const StyledText = styled(Text, {
  color: 'currentColor',
  flexGrow: 1
})

type AccountRendererProps<T> = (RendererOptions<Account> | RendererOptions<T>) & {
  noDataMessage?: string
}

function AccountRenderer<T>({
  rowData,
  dataKey,
  noDataMessage = 'No account'
}: AccountRendererProps<T>) {
  const account = getAccount(rowData, dataKey)

  if (!account) {
    return <StyledText as="span">{noDataMessage}</StyledText>
  }

  const name = AccountModel.getFullName(account)

  return (
    <Flex gap={16} alignItems="center" grow={1} css={{ width: 0 }}>
      <AccountAvatar account={account} />
      <StyledText
        as="span"
        title={name}
        truncate
      >
        {name || account.email}
      </StyledText>
    </Flex>
  )
}

function getAccount(rowData: any, dataKey: string) {
  if (rowData?.__typename === 'Account') return rowData

  const data = get(rowData, dataKey)
  return (data?.__typename === 'Account') ? data : null
}

export default AccountRenderer
