import { object } from 'yup'

import BaseModel from 'models/BaseModel'
import isIdentifier from 'lib/formValidators/isIdentifier'
import type { ChipProps } from 'components/chip/Chip'

enum ContentVersionStatus {
  // publishing workflow enabled
  PUBLISHED = 'Published',
  CURRENT_DRAFT = 'Current Draft',
  OLDER_DRAFT = 'Older Draft',
  // publishing workflow disabled
  CURRENT = 'Current',
  OLDER = 'Older'
}

const contentVersionStatusVariants: Record<string, ChipProps['variant']> = {
  // publishing workflow enabled
  [ContentVersionStatus.PUBLISHED]: 'positive',
  [ContentVersionStatus.CURRENT_DRAFT]: 'neutral',
  [ContentVersionStatus.OLDER_DRAFT]: 'light',
  // publishing workflow disabled
  [ContentVersionStatus.CURRENT]: 'neutral',
  [ContentVersionStatus.OLDER]: 'light'
}

type ContentVersion = {
  index: number,
  status: ContentVersionStatus,
  name: string
}

class Content extends BaseModel {
  static schema = object({
    identifier: isIdentifier()
  })

  static fallbackLocale = 'en_US'
}

export type {
  ContentVersion
}

export {
  ContentVersionStatus,
  contentVersionStatusVariants
}

export default Content
