import uuid from 'uuid-random'
import React, { useState } from 'react'
import { Form, FormSpy, useField } from 'react-final-form'
import { string } from 'yup'
import { useRecoilValue } from 'recoil'

import type { Decorator } from 'final-form'

import BaseModel from 'models/BaseModel'
import Button from 'components/buttons/Button'
import CodeEditorInput from 'components/inputs/CodeEditorInput'
import ConditionalField from 'components/form/ConditionalField'
import DashboardEditorBody from 'components/dashboardEditor/base/DashboardEditorBody'
import DashboardEditorHeader from 'components/dashboardEditor/base/DashboardEditorHeader'
import DrawerBlock from 'components/blocks/DrawerBlock'
import DropdownField from 'components/contentEditors/generic/fields/DropdownField'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import IconInput from 'components/inputs/IconInput'
import SearchSelectField from 'components/contentEditors/generic/fields/SearchSelectField'
import SelectInput from 'components/inputs/SelectInput'
import TextInput from 'components/inputs/TextInput'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import useDashboard, { DashboardEditorView } from 'hooks/useDashboard'
import ViewModel from 'models/ViewModel'
import {
  Operation,
  OperationsListDocument,
  OperationsListQuery,
  OperationsListQueryVariables,
  useOperationQuery,
  useParametersListQuery,
  useViewQuery,
  View,
  ViewsListDocument,
  ViewsListQuery,
  ViewsListQueryVariables
} from 'generated/schema'
import { useDashboardEditorContextProvider } from './DashboardEditorProvider'
import { SidePaneFooter } from 'components/sidePane'
import { ActionType, Views } from 'components/dashboardEditor/constants'
import type { ActiveViewProps } from 'components/dashboardEditor/DashboardEditor'
import ButtonGroupInput from 'components/inputs/ButtonGroupInput'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'

const setIdentifier = createSetIdentifier(
  'name', 'identifier'
) as Decorator<any>

enum BlockActionDisplayStyle {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY'
}

const blockActionDisplayStyleOptions = [
  { label: 'Primary', value: BlockActionDisplayStyle.PRIMARY },
  { label: 'Secondary', value: BlockActionDisplayStyle.SECONDARY }
]

const validate = (values: any) => BaseModel.validateSchema(values, {
  identifier: string().required().typeError("can't be blank"),
  name: string().required().typeError("can't be blank")
})

const ParametersList = (
  { operationId, parameters = [] }: { operationId: string, readonly parameters?: any[] }
) => {
  const {
    data: { parametersList = parameters } = {},
    loading: parametersListLoading
  } = useParametersListQuery({
    variables: {
      filter: {
        operationId: {
          eq: operationId
        }
      }
    },
    skip: !operationId
  })

  return (
    <DashboardEditorLoader loading={parametersListLoading} data={parametersList}>
      <Flex direction="column" gap={16}>
        {parametersList.map((param) => (
          <FormField
            name={`input.${param.identifier}`}
            label={param.name}
            component={
              param.fieldType === 'json-field' ? CodeEditorInput : TextInput
            }
            language={param.fieldType === 'json-field' ? 'json' : undefined}
            size="small"
            type="text"
          />
        ))}
      </Flex>
    </DashboardEditorLoader>
  )
}

const RunOperationFields = () => {
  const operationField = useField('operation')
  const { data } = useOperationQuery({
    variables: {
      id: operationField.input.value
    },
    skip: !operationField.input.value
  })

  const inputField = useField('input')

  const [ mode, setMode ] = useState<'EXPRESSION' | 'OBJECT'>(() => (
    typeof inputField.input.value === 'string' ? 'EXPRESSION' : 'OBJECT'
  ))

  return (
    <>
      <SearchSelectField<OperationsListQuery, OperationsListQueryVariables>
        checkRequired
        isClearable
        isSearchable
        preload
        name="operation"
        label="Operation"
        prependIcon="search"
        placeholder="Start typing to search"
        size="small"
        variant="light"
        options={data?.operation ? [ data.operation ] : []}
        getOptionLabel={(option: Operation) => `${option.app?.name ? `${option.app?.name} > ` : ''}${option.name}`}
        valueKey="id"
        query={OperationsListDocument}
        dataKey="operationsList"
        keys={[ 'name', 'identifier' ]}
      />
      {!!data?.operation?.parameters?.length && (
        <ConditionalField when="operation" is>
          <ButtonGroupInput
            options={[ { label: 'Expression', value: 'EXPRESSION' }, { label: 'Object', value: 'OBJECT' } ]}
            input={{
              name: '',
              onBlur: () => {},
              onFocus: () => {},
              onChange: (value) => setMode(value),
              value: mode
            }}
            meta={{}}
          />
          {mode === 'OBJECT' ? (
            <DrawerBlock defaultOpened title="Parameters" as={Flex}>
              {() => (
                <Flex direction="column">
                  <ParametersList operationId={data.operation.id} />
                </Flex>
              )}
            </DrawerBlock>
          ) : (
            <FormField
              label="Parameters"
              name="input"
              component={
                CodeEditorInput
              }
              size="small"
              type="text"
            />
          )}
        </ConditionalField>
      )}
    </>
  )
}

const OpenViewFields = () => {
  const { openDashboardEditorView } = useDashboard()

  const viewField = useField('id')
  const viewUrnField = useField('view_urn')

  const { data } = useViewQuery({
    variables: {
      id: viewField.input.value
    },
    skip: !viewField.input.value
  })

  const onAdd = () => openDashboardEditorView({
    target: Views.CREATE_VIEW,
    params: {}
  })

  return (
    <>
      <Flex justifyContent="space-between" gap={10}>
        <Text
          color="dark500"
          fontSize={10}
          fontWeight="bold"
          textTransform="uppercase"
        >
          View *
        </Text>
        <TextLink
          as="button"
          type="button"
          fontSize={10}
          onClick={onAdd}
          mode="distinct"
        >
          Add new
        </TextLink>
      </Flex>
      <SearchSelectField<ViewsListQuery, ViewsListQueryVariables>
        checkRequired
        isClearable
        isSearchable
        preload
        name="id"
        prependIcon="search"
        placeholder="Start typing to search"
        size="small"
        variant="light"
        options={data?.view ? [ data.view ] : []}
        getOptionLabel={(option: View) => `${option.app?.name ? `${option.app?.name} > ` : ''}${option.name}`}
        valueKey="id"
        query={ViewsListDocument}
        queryOptions={{
          variables: {
            filter: {
              kind: {
                eq: 'CUSTOM'
              }
            }
          }
        }}
        dataKey="viewsList"
        keys={[ 'name' ]}
        onChange={(view: View) => {
          viewField.input.onChange(view?.id)
          if (view) {
            viewUrnField.input.onChange(ViewModel.urn(view))
          }
        }}
      />
      <FormField
        type="hidden"
        component="input"
        name="view_urn"
        defaultValue={data && data.view ? ViewModel.urn(data.view as View) : null}
      />
      <FormField
        name="view_style"
        label="View Style"
        component={SelectInput}
        options={[
          // { label: 'Main', value: 'MAIN' },
          { label: 'Panel', value: 'PANEL' },
          { label: 'Dialog', value: 'DIALOG' }
        ]}
        size="small"
        defaultValue="PANEL"
        required
      />
      <DrawerBlock defaultOpened title="Parameters" as={Flex}>
        {() => (
          <Flex direction="column">
            <ParametersList parameters={data?.view.variables} />
          </Flex>
        )}
      </DrawerBlock>
    </>
  )
}

enum Trigger {
  CLICK = 'CLICK',
  CHANGE = 'CHANGE',
  LOAD = 'LOAD',
  SUBMIT = 'SUBMIT',
}

enum Behavior {
  RUN_OPERATION = 'RUN_OPERATION',
  OPEN_VIEW = 'OPEN_VIEW',
  REDIRECT = 'REDIRECT'
}

const AddActionView = ({ onClose }: ActiveViewProps) => {
  const {
    dashboardEditorState,
    selectBlock,
    updateBlock,
    stepBackDashboardEditor
  } = useDashboard()
  const {
    params: {
      block,
      kind,
      icon,
      index,
      identifier,
      name,
      operation,
      behavior = Behavior.RUN_OPERATION,
      trigger = Trigger.CLICK,
      disableTrigger = false,
      url,
      target,
      id,
      input
    } = {}
  } = useRecoilValue<DashboardEditorView<Views.ADD_ACTION>>(dashboardEditorState)

  const { urn } = useDashboardEditorContextProvider()

  const pushUpdates = (values?: any) => {
    if (!values) {
      return
    }

    const updatedValues = {
      ...values,
      id: values.id || uuid(),
      kind,
      placement: 'RIGHT',
      is_bulk: kind === ActionType.ROW ? false : undefined
    }

    updateBlock(urn, {
      ...block!,
      actions: [
        ...(block!.actions || []).slice(0, index),
        updatedValues,
        ...(block!.actions || []).slice(index! + 1)
      ]
    })
  }

  const stepBack = (values?: any) => {
    pushUpdates(values)

    stepBackDashboardEditor(1)
    selectBlock(block!.id)
  }

  return (
    <>
      <DashboardEditorHeader
        subtitle="Add Action"
        heading="Edit Table Block"
        onClose={onClose}
        onStepBack={() => stepBack()}
      />
      <Form
        key="ADD_ACTION"
        initialValues={{
          identifier,
          name,
          operation,
          id,
          icon,
          input,
          behavior,
          trigger,
          url,
          target
        }}
        decorators={[
          setIdentifier
        ]}
        validate={validate}
        onSubmit={stepBack}
        subscription={{ submitting: true, pristine: true }}
        render={({ handleSubmit, submitting, pristine }) => (
          <>
            <DashboardEditorBody>
              <Flex
                as="form"
                direction="column"
                onSubmit={handleSubmit}
                gap={16}
              >
                <FormField
                  name="trigger"
                  label="Trigger"
                  component={SelectInput}
                  options={[
                    { label: 'Click', value: Trigger.CLICK },
                    { label: 'Change', value: Trigger.CHANGE },
                    { label: 'Load', value: Trigger.LOAD },
                    { label: 'Submit', value: Trigger.SUBMIT }
                  ]}
                  size="small"
                  defaultValue={Trigger.CLICK}
                  isDisabled={disableTrigger}
                />
                <FormField
                  name="behavior"
                  label="Behavior"
                  component={SelectInput}
                  options={[
                    { label: 'Run Operation', value: Behavior.RUN_OPERATION },
                    { label: 'Open Link', value: Behavior.REDIRECT },
                    { label: 'Open View', value: Behavior.OPEN_VIEW }
                  ]}
                  size="small"
                  defaultValue={Behavior.RUN_OPERATION}
                />
                <ConditionalField when="behavior" is={Behavior.RUN_OPERATION}>
                  <RunOperationFields />
                </ConditionalField>
                <ConditionalField when="behavior" is={Behavior.REDIRECT}>
                  <FormField
                    checkRequired
                    name="url"
                    label="URL"
                    component={TextInput}
                    size="small"
                  />
                  <FormField
                    name="target"
                    label="Open in"
                    component={SelectInput}
                    options={[
                      { label: 'Same Tab', value: '_self' },
                      { label: 'New Tab', value: '_blank' }
                    ]}
                    size="small"
                    defaultValue="SELF"
                  />
                </ConditionalField>
                <ConditionalField when="behavior" is={Behavior.OPEN_VIEW}>
                  <OpenViewFields />
                </ConditionalField>
                <DropdownField
                  checkRequired
                  name="display_style"
                  label="Display Style"
                  options={blockActionDisplayStyleOptions}
                  defaultValue={BlockActionDisplayStyle.PRIMARY}
                  size="small"
                />
                <FormField
                  checkRequired
                  name="name"
                  label="Name"
                  component={TextInput}
                  size="small"
                />
                <FormField
                  checkRequired
                  name="identifier"
                  label="Identifier"
                  size="small"
                  type="text"
                />
                {kind !== 'GENERIC' && (
                  <FormField
                    component={IconInput}
                    name="icon"
                    label="Icon"
                    placeholder="Choose Icon"
                    size="small"
                    type="text"
                  />
                )}
                <FormField
                  name="run_if"
                  label="Only Run When"
                  size="small"
                  component={CodeEditorInput}
                />
                <input type="submit" style={{ display: 'none' }} />
                <FormSpy
                  onChange={({ values, valid, dirty }) => {
                    if (!dirty && !valid) return
                    pushUpdates(values)
                  }}
                />
              </Flex>
            </DashboardEditorBody>
            <SidePaneFooter variant="small" isSticky>
              <Flex gap={24} direction="row-reverse">
                <Button
                  size="small"
                  type="submit"
                  disabled={submitting || pristine}
                  label="Submit"
                  onClick={handleSubmit}
                />
              </Flex>
            </SidePaneFooter>
          </>
        )}
      />
    </>
  )
}

export { Behavior }

export default AddActionView
