import React from 'react'

import Flex from 'components/layout/Flex'
import Loader, { LoaderProps } from 'components/loaders/Loader'
import Text from 'components/typography/Text'

const LOADER_WRAPPER_PADDING = 32

function Wrapper({ children }: React.PropsWithChildren<{}>) {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      css={{
        border: '1px dashed dark100',
        borderRadius: 6,
        padding: LOADER_WRAPPER_PADDING,
        width: '100%'
      }}
    >
      {children}
    </Flex>
  )
}

function DashboardEditorLoader({ empty, ...props }: LoaderProps) {
  return (
    <Loader
      wrapper={Wrapper}
      size="compact"
      {...props}
      empty={empty || {
        variant: 'simple',
        element: (
          <Flex alignItems="center" direction="column">
            <Text fontSize={14} color="dark500">Nothing to show here.</Text>
          </Flex>
        )
      }}
    />
  )
}

export default DashboardEditorLoader

export { LOADER_WRAPPER_PADDING }
