import React, { useContext, useMemo, useState } from 'react'

import Button from 'components/buttons/Button'
import Chip from 'components/chip/Chip'
import Divider from 'components/divider/Divider'
import Flex from 'components/layout/Flex'
import Grid from 'components/layout/Grid'
import InstallAppForm from 'components/forms/InstallAppForm'
import IntegrationView from 'components/views/IntegrationView'
import InternalContext from 'components/contexts/InternalContext'
import MediaCard from 'components/mediaCard/MediaCard'
import PageLoader from 'components/loaders/PageLoader'
import SimpleLoader from 'components/loaders/SimpleLoader'
import Text from 'components/typography/Text'
import useFuzzySearch from 'hooks/useFuzzySearch'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { APP_LIST_LIMIT, getAppIcon } from 'models/App'
import { DashboardsListDocument, CreateInstallationInput, InstallationsListDocument, UpdateInstallationInput, useAppListQuery, useCreateInstallationMutation, useInstallationsAggregateQuery, InstallationsListQueryVariables, useAppCategoriesListQuery } from 'generated/schema'
import { MarkdownRenderer } from 'components/markdown'
import { useViewDispatch } from 'hooks/useViewContext'
import type { App } from 'generated/schema'
import type { ViewStyleComponentRenderProps, ViewProps } from 'components/views'

const TITLE = 'Add Integration'

type FormValues = CreateInstallationInput | UpdateInstallationInput

type Params = {
  dashboardId: string,
  selectedApp?: App,
  appCategoryIds?: App['meta']['integrationCategoryIds'],
  queryVariables?: InstallationsListQueryVariables
}

function AddIntegrationView({
  closeView,
  onAfterClose,
  onRequestClose,
  params: { queryVariables, selectedApp: initialSelectedApp, appCategoryIds = [] },
  viewStyleComponent: View,
  ...other
}: ViewProps<Params>) {
  const { openView } = useViewDispatch()
  const [ selectedApp, setSelectedApp ] = useState<App | null | undefined>(initialSelectedApp)
  const [ isInstalling, setIsInstalling ] = useState(false)
  const { currentDashboard } = useContext(InternalContext)!

  const { data: { appCategoriesList = [] } = {} } = useAppCategoriesListQuery({
    variables: {
      filter: {
        kind: { eq: 'INTEGRATION' }
      },
      limit: APP_LIST_LIMIT,
      order: [ { position: 'asc' } ]
    }
  })

  const { data, error, loading } = useAppListQuery({
    variables: {
      filter: {
        kind: { eq: 'INTEGRATION' },
        ...(appCategoryIds
          && appCategoryIds?.length > 0
          && { appCategoryId: { in: appCategoryIds } }
        )
      },
      limit: APP_LIST_LIMIT,
      order: [ { name: 'asc' } ]
    }
  })

  const appCategories = useMemo(() => appCategoriesList || [], [ appCategoriesList ])
  const appsList = useMemo(() => data?.appsList || [], [ data ])

  const {
    data: { installationsAggregate } = {},
    loading: installationsLoading
  } = useInstallationsAggregateQuery({
    variables: {
      filter: {
        appId: { eq: selectedApp?.id }
      }
    },
    skip: !selectedApp?.id
  })

  const isInstalled = useMemo(() => (installationsAggregate?.count || 0) >= 1,
    [ installationsAggregate ])

  const { matches, handleChange } = useFuzzySearch<App>(appsList, { key: 'name' })

  const resetSelection = () => setSelectedApp(null)
  const resetInstalling = () => {
    if (selectedApp?.kind === 'EXTENSION') {
      setIsInstalling(false)
    } else {
      setSelectedApp(null)
      setIsInstalling(false)
    }
  }

  const [ installApp ] = useCreateInstallationMutation({
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      onRequestClose()
      closeView()
      openView({
        title: data?.createInstallation.name || 'App',
        component: IntegrationView,
        style: IntegrationView.defaultStyle,
        params: {
          installationId: data.createInstallation.id
        }
      })
    },
    refetchQueries: [
      DashboardsListDocument, InstallationsListDocument
    ]
  })

  const handleInstallApp = useSubmitHandler(installApp, {
    update: {
      strategy: 'APPEND',
      query: InstallationsListDocument,
      queryVariables,
      dataKey: 'installationsList',
      mutation: 'createInstallation'
    },
    successAlert: { message: 'Integration was installed successfully.' }
  })

  const handleSubmit = (
    values: CreateInstallationInput | UpdateInstallationInput
  ) => handleInstallApp(values as CreateInstallationInput)

  const formInitialValues = useMemo(() => ({
    appId: selectedApp?.id,
    name: selectedApp?.name
  } as FormValues),
  [
    selectedApp
  ])

  const renderAppCard = (app: App) => {
    const { id, identifier, name } = app

    const appLogo = <img style={{ width: '32px' }} src={getAppIcon(identifier)} alt={identifier} />

    return (
      <MediaCard
        key={id}
        media={appLogo}
        onClick={() => {
          setSelectedApp(app)
          if (![ 'EXTENSION' ].includes(app.kind)) setIsInstalling(true)
        }}
        actions={[]}
        title={name}
        titlePosition="top"
        width="full"
      />
    )
  }

  const renderAppCards = (appList: App[], appCategroyId: number) => (
    <Grid gap={24} columns={3}>
      {appList.map((app) => app.appCategoryId === appCategroyId && renderAppCard(app))}
    </Grid>
  )

  const appsListDirectoryView = ({ Body, Header, SearchBar }: ViewStyleComponentRenderProps) => (
    <>
      <Header
        title={TITLE}
        onCloseClick={onRequestClose}
      />
      <SearchBar placeholder="Search for an Integration..." onChange={handleChange} />
      <Body>
        <PageLoader
          data={appsList}
          error={error}
          loading={loading}
        >
          <Flex direction="column" gap={36}>
            {appCategories.map((category) => {
              if (!matches.filter(
                (match) => match.appCategoryId === category.id
              ).length) return <></>

              return (
                <>
                  <Flex justifyContent="space-between">
                    <Flex direction="column" gap={16}>
                      <Text
                        color="dark900"
                        fontSize={24}
                        fontWeight="bold"
                        letterSpacing="compact"
                      >
                        {category.name}
                      </Text>
                      {category.description && (
                      <Text color="dark500" fontSize={14}>
                        {category.description}
                      </Text>
                      )}
                    </Flex>
                  </Flex>

                  <Flex direction="column" gap={14}>
                    {renderAppCards(matches, category.id)}
                  </Flex>
                </>
              )
            })}
          </Flex>
        </PageLoader>
      </Body>
    </>
  )

  const renderInstallButton = () => (
    isInstalled
      ? (
        <Flex gap={20} alignItems="center">
          <Chip label="Installed" variant="positive" icon="tick-circle" />
          {(![ 'EXTENSION' ].includes(selectedApp?.kind || '')) && (
            <>
              <Divider orientation="vertical" variant="ruler" />
              <Button variant="outline" size="small" label="Install Another" onClick={() => setIsInstalling(true)} />
            </>
          )}
        </Flex>
      )
      : <Button label="Install App" onClick={() => setIsInstalling(true)} />
  )

  const selectedAppView = ({ Body, Header, SubHeader }: ViewStyleComponentRenderProps) => {
    const { identifier, name, summary } = selectedApp || {}

    const appLogo = <img style={{ width: '32px' }} src={getAppIcon(identifier)} alt={identifier} />

    return (
      <>
        <Header
          title="Back"
          onCloseClick={onRequestClose}
          onBackArrowClick={resetSelection}
        />
        <SubHeader>
          <Flex alignItems="stretch" grow={1} justifyContent="space-between">
            <Flex alignItems="center" gap={20}>
              {identifier && (
                <>
                  {appLogo}
                  <Divider orientation="vertical" />
                </>
              )}
              <Flex direction="column" gap={8}>
                <Text color="dark900" fontSize={24} fontWeight="bold" letterSpacing="compact">
                  {name}
                </Text>
              </Flex>
            </Flex>
            {installationsLoading ? <Flex alignItems="center"><SimpleLoader size="small" /></Flex> : renderInstallButton()}
          </Flex>
        </SubHeader>
        <Body>
          <MarkdownRenderer source={summary!} />
        </Body>
      </>
    )
  }

  const installAppView = (viewComponents: ViewStyleComponentRenderProps) => {
    const { identifier, name } = selectedApp || {}
    const { Header, SubHeader } = viewComponents
    const appLogo = <img style={{ width: '32px' }} src={getAppIcon(identifier)} alt={identifier} />

    return (
      <>
        <Header
          title="Back"
          onCloseClick={onRequestClose}
          onBackArrowClick={resetInstalling}
        />
        <SubHeader>
          <Flex alignItems="stretch" grow={1} justifyContent="space-between">
            <Flex alignItems="center" gap={20}>
              {identifier && (
                <>
                  {appLogo}
                  <Divider orientation="vertical" />
                </>
              )}
              <Flex direction="column" gap={8}>
                <Text color="dark900" fontSize={24} fontWeight="bold" letterSpacing="compact">
                  {name}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </SubHeader>
        <InstallAppForm
          currentDashboard={currentDashboard}
          viewComponents={viewComponents}
          initialValues={formInitialValues}
          onSubmit={handleSubmit}
          selectedApp={selectedApp as App}
        />
      </>
    )
  }

  return (
    <View
      contentLabel={TITLE}
      onAfterClose={onAfterClose}
      onRequestClose={onRequestClose}
      {...other}
    >
      {(renderProps) => {
        if (selectedApp && !isInstalling) return selectedAppView(renderProps)
        if (selectedApp && isInstalling) return installAppView(renderProps)
        return appsListDirectoryView(renderProps)
      }}
    </View>
  )
}

AddIntegrationView.defaultStyle = 'PANEL' as const

export default AddIntegrationView
