import React from 'react'
import { Form } from 'react-final-form'
import { string } from 'yup'
import type { FormApi } from 'final-form'

import BaseModel from 'models/BaseModel'
import Button from 'components/buttons/Button'
import ColorInput from 'components/inputs/ColorInput'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import TextInput from 'components/inputs/TextInput'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { CreateIssueLabelInput, IssueLabelsListDocument, IssueLabelsListQueryVariables, UpdateIssueLabelInput, useCreateIssueLabelMutation, useUpdateIssueLabelMutation } from 'generated/schema'
import type { ViewProps } from 'components/views'

type FormValues = Partial<CreateIssueLabelInput> | UpdateIssueLabelInput

type ViewParamsType = {
  initialValues: FormValues,
  queryVariables: IssueLabelsListQueryVariables
}

function CreateIssueLabelView({
  onRequestClose,
  params,
  viewStyleComponent: View,
  ...other
}: ViewProps<ViewParamsType>) {
  const { initialValues, queryVariables, ...rest } = params

  const isUpdating = 'id' in initialValues

  const title = `${isUpdating ? 'Edit' : 'New'} Issue Label`

  const [ createIssueLabel ] = useCreateIssueLabelMutation({
    onCompleted: onRequestClose,
    refetchQueries: [
      { query: IssueLabelsListDocument, variables: queryVariables }
    ]
  })

  const handleCreateIssueLabel = useSubmitHandler(createIssueLabel, {
    update: {
      strategy: 'APPEND',
      query: IssueLabelsListDocument,
      dataKey: 'issueLabelsList',
      mutation: 'createIssueLabel',
      queryVariables
    },
    successAlert: {
      title: 'Success',
      message: 'Issue Label created successfully.'
    }
  })

  const [ updateIssueLabel ] = useUpdateIssueLabelMutation({
    onCompleted: onRequestClose,
    refetchQueries: [
      { query: IssueLabelsListDocument, variables: queryVariables }
    ]
  })

  const handleUpdateIssueLabel = useSubmitHandler(updateIssueLabel, {
    successAlert: {
      title: 'Success',
      message: 'Issue Label updated successfully.'
    }
  })

  const handleSubmit = (values: FormValues, form: FormApi<FormValues>) => {
    if (isUpdating) {
      return handleUpdateIssueLabel(
        values as UpdateIssueLabelInput,
         form as FormApi<UpdateIssueLabelInput>
      )
    }
    const createValues = { ...initialValues, ...values }
    return handleCreateIssueLabel(createValues as CreateIssueLabelInput)
  }

  return (
    <View contentLabel={title} onRequestClose={onRequestClose} {...other}>
      {({ Header, Body, Footer }) => (
        <>
          <Header title={title} onCloseClick={onRequestClose} />
          <Form
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validate={(values) => BaseModel.validateSchema(values, {
              name: string().required('Name is required'),
              color: string().required('Color is required')
            })}
            subscription={{ submitting: true, pristine: true }}
            render={({ handleSubmit, submitting, pristine }) => (
              <>
                <Body>
                  <Flex direction="column" as="form" onSubmit={handleSubmit}>
                    <Flex direction="row" gap={16}>
                      <FormField
                        checkRequired
                        component={TextInput}
                        label="Name"
                        name="name"
                      />
                      <FormField
                        checkRequired
                        component={ColorInput}
                        label="Color"
                        name="color"
                      />
                    </Flex>
                  </Flex>
                </Body>
                <Footer>
                  <Flex alignItems="center" grow={1} justifyContent="flex-start">
                    <Button disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />
                  </Flex>
                </Footer>
              </>
            )}
            {...rest}
          />
        </>
      )}
    </View>
  )
}

CreateIssueLabelView.defaultStyle = 'DIALOG' as const

export default CreateIssueLabelView
