import omit from 'lodash/omit'
import React from 'react'
import { Field, Form } from 'react-final-form'
import { useRecoilValue } from 'recoil'
import type { Decorator, FormApi } from 'final-form'

import Button from 'components/buttons/Button'
import ConditionalField from 'components/form/ConditionalField'
import DashboardEditorBody from 'components/dashboardEditor/base/DashboardEditorBody'
import DashboardEditorHeader from 'components/dashboardEditor/base/DashboardEditorHeader'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import HintBox from 'components/hints/HintBox'
import Installation from 'models/Installation'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import TextLink from 'components/links/TextLink'
import ToggleInput from 'components/inputs/ToggleInput'
import useDashboard from 'hooks/useDashboard'
import useSubmitHandler from 'hooks/useSubmitHandler'
import { Installation as InstallationType, CreateInstallationInput, DashboardsListDocument, InstallationsListDocument, useCreateInstallationMutation, useEnvironmentsListQuery, useUpdateInstallationMutation, UpdateInstallationInput, useConfigurationsListQuery } from 'generated/schema'
import { ConfigurationField } from 'components/views/AddConfigurationView'
import { createSetIdentifier } from 'lib/formDecorators/setIdentifier'
import { getAppIcon } from 'models/App'
import { SidePaneFooter } from 'components/sidePane'
import { ViewParams, Views } from 'components/dashboardEditor/constants'
import type { ActiveViewProps } from 'components/dashboardEditor/DashboardEditor'

const setIdentifier = createSetIdentifier(
  'name', 'identifier'
) as Decorator<CreateInstallationInput>

type Params = ViewParams[Views.ADD_INTEGRATION_FORM]

const AddIntegrationFormView = ({ onClose }: ActiveViewProps) => {
  const { dashboardEditorState, openDashboardEditorView } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { initialValues = {}, operation, integration, appCategoryIds } = params! as Params
  const { selectedApp } = initialValues as Params['initialValues']
  const { id: appId, name: appName, fields, identifier } = selectedApp

  const { data: { environmentsList = [] } = {} } = useEnvironmentsListQuery()

  const {
    data: configurationsData
  } = useConfigurationsListQuery({
    variables: {
      filter: {
        installationId: { eq: integration?.id }
      }
    },
    skip: !integration?.id
  })

  const configurationsList = configurationsData?.configurationsList || []

  const queryVariables = {
    limit: 100
  }

  const [ installApp ] = useCreateInstallationMutation({
    awaitRefetchQueries: true,
    onCompleted: ({ createInstallation }) => {
      openDashboardEditorView({
        // eslint-disable-next-line no-nested-ternary
        target: operation ? Views.CREATE_OPERATION
          : appCategoryIds ? Views.IMPORT_FROM_DATABASE : Views.INTEGRATION_DETAILS,
        params: {
          ...params,
          ...(operation ? { initialValues: operation } : {}),
          integration: createInstallation as InstallationType
        }
      })
    },
    refetchQueries: [
      { query: DashboardsListDocument }
    ]
  })

  const [ updateInstallation ] = useUpdateInstallationMutation()

  const handleInstallApp = useSubmitHandler(installApp, {
    update: {
      strategy: 'APPEND',
      query: InstallationsListDocument,
      queryVariables,
      dataKey: 'installationsList',
      mutation: 'createInstallation'
    },
    successAlert: { message: 'Integration was installed successfully.' }
  })

  const handleUpdateInstallation = useSubmitHandler(updateInstallation, {
    successAlert: { message: 'Integration updated successfully.' }
  })

  const handleSubmit = (
    values: CreateInstallationInput | UpdateInstallationInput,
    form?: FormApi<CreateInstallationInput | UpdateInstallationInput>
  ) => {
    if ('id' in values) {
      return handleUpdateInstallation(values, form as any)
    }

    return handleInstallApp(values as CreateInstallationInput)
  }

  const initialConfigurationValues: Record<any, any> = configurationsList
    .reduce((acc, configuration) => ({
      ...acc,
      environments: {
        ...acc.environments,
        [configuration.environmentId]: true
      },
      settings: {
        ...acc.settings,
        [configuration.environmentId]: configuration.settings
      }
    }), { environments: {}, settings: {} })

  return (
    <>
      <DashboardEditorHeader
        subtitle={appName}
        heading="Add Integration"
        onClose={onClose}
        icon={(
          <img
            style={{ width: '32px' }}
            src={getAppIcon(identifier)}
            alt={identifier || ''}
          />
        )}
      />
      <Form
        keepDirtyOnReinitialize
        decorators={[
          setIdentifier
        ]}
        initialValues={{
          appId,
          ...integration as any,
          ...initialConfigurationValues
        }}
        validate={(values) => Installation.validate(values, [ 'name', 'identifier' ])}
        subscription={{ submitting: true, pristine: true }}
        render={({ handleSubmit, submitting, pristine }) => (
          <>
            <DashboardEditorBody>
              <Flex as="form" onSubmit={handleSubmit} direction="column" gap={16}>
                <Flex direction="column" gap={16}>
                  <Text color="dark500" fontSize={10} fontWeight="bold" textTransform="uppercase">Details</Text>
                  <Flex direction="column" gap={16}>
                    <FormField alwaysDirty type="hidden" component="input" name="configurations" />
                    <Field
                      autoFocus
                      component={TextInput}
                      label="Name"
                      name="name"
                      size="small"
                      type="text"
                    />
                    <Field
                      component={TextInput}
                      label="Identifier"
                      name="identifier"
                      size="small"
                      type="text"
                      helpText="Used in code. Avoid modifying this."
                    />
                    <input type="submit" style={{ display: 'none' }} />
                  </Flex>
                </Flex>
                <Flex direction="column" gap={16}>
                  <Text color="dark500" fontSize={10} fontWeight="bold" textTransform="uppercase">Environments</Text>
                  <HintBox size="compact">
                    <Text fontSize={12}>For assistance, read our{' '}
                      <TextLink href="https://docs.dashx.com">
                        {appName} integration docs
                      </TextLink>
                      , or{' '}
                      <TextLink href="https://docs.dashx.com/support">
                        contact support
                      </TextLink>
                      .
                    </Text>
                  </HintBox>
                  <Flex direction="column" gap={16}>
                    {environmentsList?.map((environment) => (
                      <Flex key={environment.id} direction="column" alignItems="center" gap={16}>
                        <FormField
                          name={`environments.${environment.id}`}
                          component={ToggleInput}
                          label={environment.name}
                          labelPosition="left"
                          type="checkbox"
                        />
                        <ConditionalField when={`environments.${environment.id}`} is>
                          {fields.map(
                            (field) => <ConfigurationField key={field.name} field={field} prefix={`settings.${environment.id}`} />
                          )}
                        </ConditionalField>
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
              </Flex>
            </DashboardEditorBody>
            <SidePaneFooter variant="small" isSticky>
              <Flex direction="row-reverse">
                <Button size="small" type="submit" onClick={handleSubmit} disabled={pristine || submitting} label="Submit" />
              </Flex>
            </SidePaneFooter>
          </>
        )}
        onSubmit={(values: any, form: any) => {
          const parsedConfigurations = Object.keys(values?.environments || {})
            .filter((e: any) => values.environments[e] === true)
            .map((e: any) => ({
              environmentId: e,
              settings: values?.settings?.[e] || {}
            }))

          const parsedFormValues = omit(values, 'environments', 'settings')

          return handleSubmit({
            ...parsedFormValues,
            configurations: parsedConfigurations
          } as any, form)
        }}
      />
    </>
  )
}

export default AddIntegrationFormView
