import React, { createContext, PropsWithChildren, useContext } from 'react'

import AppLoader from 'components/loaders/AppLoader'
import useClientQuery from 'hooks/useClientQuery'
import { AccountFragmentFragment, CurrentAccountQuery, useCurrentAccountQuery } from 'generated/schema'
import { SESSION_QUERY, SessionQuery } from 'client/state/session'

const currentAccountContext = createContext<AccountFragmentFragment | undefined>(undefined)

const useCurrentAccountContext = () => useContext(currentAccountContext)

const DEFAULT_CURRENT_ACCOUNT_FALLBACK = Object.freeze({}) as CurrentAccountQuery

const CurrentAccountProvider = ({ children }: PropsWithChildren<{}>) => {
  const {
    data: {
      session: {
        isMfaRequired,
        mfaVerifiedAt,
        token
      }
    }
  } = useClientQuery<SessionQuery>(SESSION_QUERY)

  const isMfaRequested = isMfaRequired && !mfaVerifiedAt

  const skip = !token || isMfaRequested as boolean

  const {
    data: { currentAccount } = DEFAULT_CURRENT_ACCOUNT_FALLBACK,
    loading
  } = useCurrentAccountQuery({
    skip
  })

  if (!currentAccount && loading) {
    return <AppLoader />
  }

  return (
    <currentAccountContext.Provider value={currentAccount}>
      {children}
    </currentAccountContext.Provider>
  )
}

export { useCurrentAccountContext }
export default CurrentAccountProvider
