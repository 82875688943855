import { Liquid } from 'liquidjs'

const liquid = new Liquid()

export const parse = liquid.parse.bind(liquid)
export const render = liquid.render.bind(liquid)
export const renderSync = liquid.renderSync.bind(liquid)
export const parseAndRender = liquid.parseAndRender.bind(liquid)
export const parseAndRenderSync = liquid.parseAndRenderSync.bind(liquid)

export const safeParseLiquid: typeof parseAndRenderSync = (str, ...params) => {
  try {
    return parseAndRenderSync(str || '', ...params).trim()
  } catch (e) {
    console.error(e)

    return str
  }
}
