/* eslint-disable camelcase */
import React, { Suspense, useMemo } from 'react'
import startCase from 'lodash/startCase'
import { Form, useField, useForm, useFormState } from 'react-final-form'
import { useRecoilValue } from 'recoil'

import { AttributeFragmentFragment, DataType, DataTypeFragmentFragment, useAttributeQuery, useDataTypesListQuery, useRelationshipQuery } from 'generated/schema'
import AttributeModel from 'models/Attribute'
import Button from 'components/buttons/Button'
import componentLoader from 'lib/componentLoader'
import DashboardEditorBody from 'components/dashboardEditor/base/DashboardEditorBody'
import DashboardEditorHeader from 'components/dashboardEditor/base/DashboardEditorHeader'
import DashboardEditorLoader from 'components/loaders/DashboardEditorLoader'
import FieldLabel from 'components/form/FieldLabel'
import Flex from 'components/layout/Flex'
import FormField from 'components/form/FormField'
import Label from 'components/typography/Label'
import Loader from 'components/loaders/Loader'
import SelectInput from 'components/inputs/SelectInput'
import Text from 'components/typography/Text'
import TextInput from 'components/inputs/TextInput'
import TextLink from 'components/links/TextLink'
import useDashboard, { DashboardEditorView } from 'hooks/useDashboard'
import { DataSource } from './EditBlockView'
import { DataTypeKind } from 'models/DataType'
import { DISPLAY_TYPES, getAllowedDisplayTypes, getDisplayViewFileName, useDataTypes } from './graph/CreateAttributeView'
import { FieldIdentifier } from 'models/Field'
import { SidePaneFooter } from 'components/sidePane'
import { useDashboardEditorContextProvider } from './DashboardEditorProvider'
import { ViewParams, Views } from 'components/dashboardEditor/constants'
import type { ActiveViewProps } from 'components/dashboardEditor/DashboardEditor'

type Params = ViewParams[Views.ADD_ROW]

const DisplayTypeSettings = (
  { attribute, dataTypes }: { attribute?: AttributeFragmentFragment, dataTypes?: DataType[] }
) => {
  const form = useForm()
  const displayType = useField('display_type').input.value
  const displayViewFileName = displayType ? getDisplayViewFileName(displayType) : null

  const dataTypeId = useField('data_type').input.value
  const dataType = attribute?.dataType.kind || dataTypes?.find((d) => d.id === dataTypeId)?.kind

  const allowedDisplayTypes = dataType
    ? getAllowedDisplayTypes(
      dataType as DataTypeKind,
      attribute?.fieldType as FieldIdentifier || FieldIdentifier.TEXT
    ) : DISPLAY_TYPES

  const Settings = useMemo(() => (displayViewFileName ? React.lazy(
    () => componentLoader(`displayTypes/${displayViewFileName}`, { suppressAlert: true })
      .catch(() => componentLoader('displayTypes/PlainTextView'))
      .then((module) => ({ default: module.default?.Configurations }))
  ) : () => <></>), [ displayViewFileName ])

  return (
    <>
      <FormField
        alwaysDirty
        isClearable
        component={SelectInput}
        name="display_type"
        label="Renderer"
        size="small"
        options={allowedDisplayTypes}
        placeholder={startCase(attribute?.displayType.toLowerCase())}
        defaultValue={allowedDisplayTypes[0]?.value}
        onChange={(option: any) => {
          form.change('display_type', option?.value || null)
          form.change('display_type_settings', {})
        }}
      />
      {AttributeModel.hasDisplayTypeSettings(displayType) && (
        <Suspense fallback={<Loader loading />}>
          <Settings fieldPrefix="display_type_settings." />
        </Suspense>
      )}
    </>
  )
}

const DataTypeSettings = () => {
  const dataTypeId = useField('data_type').input.value

  const { change } = useForm()
  const { values } = useFormState()
  const {
    dataTypeOptions,
    complexDataTypes,
    loading,
    selectedDataType,
    selectedDataTypeId
  } = useDataTypes({ dataTypeId, dataTypeKind: values.dataTypeKind })

  const {
    dashboardEditorState,
    openDashboardEditorView,
    saveDashboardEditorViewState
  } = useDashboard()

  const { params } = useRecoilValue<DashboardEditorView<Views.ADD_ROW>>(
    dashboardEditorState
  )
  return (
    <Loader loading={loading} data={selectedDataType}>
      <FormField
        alwaysDirty
        checkRequired
        component={SelectInput}
        name="dataTypeKind"
        label="Data Type"
        size="small"
        options={dataTypeOptions}
        loading={loading}
        labelKey="name"
        valueKey="kind"
        onChange={(option: typeof dataTypeOptions[number]) => {
          const [ defaultDisplayType ] = getAllowedDisplayTypes(
                option.kind as DataTypeKind
          )

          change('display_type', defaultDisplayType?.value)
          change('dataTypeKind', option.kind)
          change('data_type', option.id)
        }}
        defaultValue={selectedDataType?.kind}
      />
      <FormField alwaysDirty name="data_type" component="input" type="hidden" defaultValue={selectedDataTypeId} value={selectedDataTypeId} />
      {values.dataTypeKind === DataTypeKind.ENUM && (
        <Flex direction="column" gap={10}>
          <Flex justifyContent="space-between">
            <FieldLabel>
              Select Enum
            </FieldLabel>
            <Flex gap={8}>
              {dataTypeId && (
              <TextLink
                as="button"
                type="button"
                fontSize={10}
                onClick={() => {
                  saveDashboardEditorViewState({
                    ...params,
                    initialValues: {
                      ...(params?.initialValues || {}),
                      ...values
                    }
                  })
                  openDashboardEditorView({
                    target: Views.CREATE_DATA_TYPE,
                    params: {
                      heading: selectedDataType?.name || values.label,
                      initialValues: selectedDataType,
                      sourceKind: 'ROW'
                    }
                  })
                }}
              >
                Edit
              </TextLink>
              )}
              <TextLink
                as="button"
                type="button"
                fontSize={10}
                onClick={() => {
                  saveDashboardEditorViewState({
                    ...params,
                    initialValues: {
                      ...(params?.initialValues || {}),
                      ...values
                    }
                  })
                  openDashboardEditorView({
                    target: Views.CREATE_DATA_TYPE,
                    params: {
                      heading: values.label || selectedDataType?.name,
                      initialValues: {
                        kind: DataTypeKind.ENUM
                      } as DataTypeFragmentFragment,
                      sourceKind: 'ROW'
                    }
                  })
                }}
              >
                Add new
              </TextLink>
            </Flex>
          </Flex>
          <FormField
            alwaysDirty
            checkRequired
            component={SelectInput}
            name="data_type"
            size="small"
            options={complexDataTypes}
            loading={loading}
            labelKey="name"
            valueKey="id"
            onChange={(option: typeof dataTypeOptions[number]) => {
              const [ defaultDisplayType ] = getAllowedDisplayTypes(
                  option.kind as DataTypeKind
              )

              change('display_type', defaultDisplayType?.value)
              change('data_type', option.id)
            }}
            defaultValue={dataTypeId}
          />
        </Flex>
      )}
      {values.dataTypeKind === DataTypeKind.OBJECT && (
      <Flex direction="column" gap={10}>
        <Flex justifyContent="space-between">
          <FieldLabel>
            Select an Object
          </FieldLabel>
          <Flex gap={8}>
            {dataTypeId && (
            <TextLink
              as="button"
              type="button"
              fontSize={10}
              onClick={() => {
                saveDashboardEditorViewState({
                  ...params,
                  initialValues: {
                    ...(params?.initialValues || {}),
                    ...values
                  }
                })
                openDashboardEditorView({
                  target: Views.CREATE_DATA_TYPE,
                  params: {
                    heading: values.label || selectedDataType?.name,
                    initialValues: selectedDataType,
                    sourceKind: 'ROW'
                  }
                })
              }}
            >
              Edit
            </TextLink>
            )}
            <TextLink
              as="button"
              type="button"
              fontSize={10}
              onClick={() => {
                saveDashboardEditorViewState({
                  ...params,
                  initialValues: {
                    ...(params?.initialValues || {}),
                    ...values
                  }
                })
                openDashboardEditorView({
                  target: Views.CREATE_DATA_TYPE,
                  params: {
                    heading: values.label || selectedDataType?.name,
                    initialValues: {
                      kind: DataTypeKind.OBJECT
                    } as DataTypeFragmentFragment,
                    sourceKind: 'ROW'
                  }
                })
              }}
            >
              Add new
            </TextLink>
          </Flex>
        </Flex>
        <FormField
          alwaysDirty
          checkRequired
          component={SelectInput}
          name="data_type"
          size="small"
          options={complexDataTypes}
          loading={loading}
          labelKey="name"
          valueKey="id"
          onChange={(option: typeof dataTypeOptions[number]) => {
            const [ defaultDisplayType ] = getAllowedDisplayTypes(
                    option.kind as DataTypeKind
            )

            change('display_type', defaultDisplayType?.value)
            change('data_type', option.id)
          }}
          defaultValue={dataTypeId}
        />
      </Flex>
      )}
      {values.dataTypeKind === DataTypeKind.UNION && (
      <Flex direction="column" gap={10}>
        <Flex justifyContent="space-between">
          <FieldLabel>
            Select a Union type
          </FieldLabel>
          <Flex gap={8}>
            {dataTypeId && (
            <TextLink
              as="button"
              type="button"
              fontSize={10}
              onClick={() => {
                saveDashboardEditorViewState({
                  ...params,
                  initialValues: {
                    ...(params?.initialValues || {}),
                    ...values
                  }
                })
                openDashboardEditorView({
                  target: Views.CREATE_DATA_TYPE,
                  params: {
                    heading: selectedDataType?.name || values.label,
                    initialValues: selectedDataType,
                    sourceKind: 'ROW'
                  }
                })
              }}
            >
              Edit
            </TextLink>
            )}
            <TextLink
              as="button"
              type="button"
              fontSize={10}
              onClick={() => {
                saveDashboardEditorViewState({
                  ...params,
                  initialValues: {
                    ...(params?.initialValues || {}),
                    ...values
                  }
                })
                openDashboardEditorView({
                  target: Views.CREATE_DATA_TYPE,
                  params: {
                    heading: values.label || selectedDataType?.name,
                    initialValues: {
                      kind: DataTypeKind.UNION
                    } as DataTypeFragmentFragment,
                    sourceKind: 'ROW'
                  }
                })
              }}
            >
              Add new
            </TextLink>
          </Flex>
        </Flex>
        <FormField
          alwaysDirty
          checkRequired
          component={SelectInput}
          name="data_type"
          size="small"
          options={complexDataTypes}
          loading={loading}
          labelKey="name"
          valueKey="id"
          onChange={(option: typeof dataTypeOptions[number]) => {
            const [ defaultDisplayType ] = getAllowedDisplayTypes(
                    option.kind as DataTypeKind
            )

            change('display_type', defaultDisplayType?.value)
            change('data_type', option.id)
          }}
          defaultValue={dataTypeId}
        />
      </Flex>
      )}
    </Loader>
  )
}

const AddRowView = ({ onClose }: ActiveViewProps) => {
  const {
    dashboardEditorState,
    openDashboardEditorView,
    selectBlock,
    stepBackDashboardEditor,
    updateBlock
  } = useDashboard()
  const { params = {} } = useRecoilValue(dashboardEditorState)
  const { initialValues, block, currentIndex, isUpdating, type, kind } = params! as Params

  const { attribute: attributeId, relationship: relationshipId } = initialValues || {}

  const isResourceType = type === DataSource.RESOURCE

  const {
    data: { attribute } = {},
    loading: loadingAttribute
  } = useAttributeQuery({
    variables: { id: attributeId },
    skip: !attributeId
  })

  const {
    data: { relationship } = {},
    loading: loadingRelationship
  } = useRelationshipQuery({
    variables: { id: relationshipId },
    skip: !relationshipId
  })

  const {
    data: { dataTypesList } = {},
    loading: loadingDataTypes
  } = useDataTypesListQuery()

  const { urn } = useDashboardEditorContextProvider()
  const stepBack = (values?: any) => {
    if (values && (values.label || values.value || values[kind])) {
      const rows = block.properties?.rows || []
      let updatedRows

      if (isUpdating) {
        updatedRows = rows.map(
          (c: any, index: number) => {
            if (index === currentIndex) return { ...c, ...values }
            return c
          }
        ) || []
      } else {
        updatedRows = [ ...rows, values ]
      }

      updateBlock(urn, {
        ...block,
        properties: {
          ...block.properties,
          rows: updatedRows
        }
      })
    }

    selectBlock(block.id)
    stepBackDashboardEditor(2)
    openDashboardEditorView({
      target: Views.EDIT_BLOCK
    })
  }

  return (
    <>
      <DashboardEditorHeader
        subtitle={`${isUpdating ? 'Edit' : 'Add'} Row`}
        heading="Edit Block"
        onClose={onClose}
        onStepBack={stepBack}
      />
      <Form
        initialValues={initialValues}
        onSubmit={stepBack}
        subscription={{ submitting: true, pristine: true }}
        render={({ handleSubmit, submitting, pristine, form }) => (
          <>
            <DashboardEditorBody>
              <DashboardEditorLoader
                empty={{
                  variant: 'simple',
                  element: (
                    <Flex alignItems="center" direction="column">
                      <Text fontSize={14} color="dark500">Missing attribute or data type.</Text>
                    </Flex>
                  )
                }}
                data={attribute || relationship || dataTypesList || []}
                loading={loadingAttribute || loadingRelationship || loadingDataTypes}
              >
                <Flex as="form" direction="column" onSubmit={handleSubmit} gap={16}>
                  {isResourceType && (
                    <Flex direction="column" gap={6}>
                      <Label fontSize={10} fontWeight="bold">Linked {startCase(kind)}</Label>
                      {attribute && (
                        <Flex justifyContent="space-between">
                          <Text fontSize={14} fontWeight="bold">{`${attribute?.resource.name}: ${attribute?.name}`}</Text>
                        </Flex>
                      )}
                      {relationship && (
                        <Flex justifyContent="space-between">
                          <Text fontSize={14} fontWeight="bold">{`${relationship?.source.name}: ${relationship?.name}`}</Text>
                        </Flex>
                      )}
                      {!isUpdating && !attribute && !relationship && (
                        <TextLink
                          as="button"
                          alignSelf="flex-start"
                          type="button"
                          fontSize={12}
                          onClick={() => openDashboardEditorView({
                            target: Views.LINK_ATTRIBUTE,
                            params: {
                              parentResourceId: block.properties.data_source_settings?.resource,
                              resourceId: block.properties?.data_source_settings?.resource,
                              initialValues: form.getState().values,
                              block,
                              type,
                              currentIndex,
                              isColumnUpdating: isUpdating
                            }
                          })}
                          mode="distinct"
                        >
                          Link {startCase(kind)}
                        </TextLink>
                      )}
                    </Flex>
                  )}
                  {!isResourceType && (
                    <DataTypeSettings />
                  )}
                  <FormField
                    name="label"
                    label="Label"
                    component={TextInput}
                    placeholder={attribute?.name || relationship?.name}
                    size="small"
                  />
                  {!isResourceType && <FormField checkRequired name="value" label="Value" component={TextInput} size="small" />}
                  {attribute && (
                  <DisplayTypeSettings
                    attribute={attribute}
                    dataTypes={dataTypesList as DataType[]}
                  />
                  )}
                  <input type="submit" style={{ display: 'none' }} />
                </Flex>
              </DashboardEditorLoader>

            </DashboardEditorBody>
            <SidePaneFooter variant="small" isSticky>
              <Flex gap={24} direction="row-reverse">
                <Button size="small" type="submit" disabled={submitting || pristine} label="Submit" onClick={handleSubmit} />
              </Flex>
            </SidePaneFooter>
          </>
        )}
      />
    </>
  )
}

export default AddRowView
