import React, { useContext } from 'react'
import uuid from 'uuid-random'
import { useRecoilValue } from 'recoil'

import * as mixins from 'styles/mixins'
import DashboardContext from 'components/contexts/DashboardContext'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import useDashboard from 'hooks/useDashboard'
import { ELEMENT_TYPE_TO_ICON_MAP, Views } from 'components/dashboardEditor/constants'
import { Popover, PopoverBody, PopoverContainer, PopoverItem, PopoverProps } from 'components/popover'
import { styled } from 'styles/stitches'
import { useDashboardViewContext } from 'components/contexts/DashboardViewContext'

const StyledAddElementButton = styled(Flex, {
  ...mixins.transition('simple'),

  cursor: 'pointer',
  borderRadius: 6,
  alignItems: 'center',
  justifyContent: 'center',
  color: 'dark100',
  opacity: 0,

  '[data-masonry-item]:hover &': {
    opacity: 1
  },

  '& > [data-icon]': {
    ...mixins.transition('simple'),
    borderRadius: 5,
    color: 'dark100'
  },

  '& > [data-label]': {
    ...mixins.transition('simple'),
    color: 'dark500'
  },

  '&:hover': {
    '& > [data-icon]': {
      backgroundColor: 'dark900',
      color: 'light100'
    }
  },

  variants: {
    alwaysVisible: {
      true: {
        opacity: 1
      }
    },
    isActive: {
      true: {
        opacity: 1,
        '& > [data-icon]': {
          backgroundColor: 'dark900',
          color: 'light100'
        }
      }
    }
  }
})

type AddElementButtonProps = { id: string, variant: 'primary' | 'secondary', alwaysVisible?: boolean }

const AddElementButton = ({ id, variant, alwaysVisible = false }: AddElementButtonProps) => {
  const { dashboardEditorState } = useDashboard()
  const { target: dashboardEditorActiveView } = useRecoilValue(dashboardEditorState)
  const isInspecting = dashboardEditorActiveView === Views.EDIT_COMPONENT
  const { editMode } = useContext(DashboardContext)!

  if (isInspecting || !editMode) {
    return null
  }

  return (
    <PopoverContainer
      placement={variant === 'primary' ? 'bottom-start' : 'bottom-end'}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [ 0, 6 ]
          }
        }
      ]}
    >
      {({ isActive, ref, closePopover, openPopover, ...otherToggleProps }) => (
        <StyledAddElementButton
          direction="column"
          grow={1}
          gap={6}
          isActive={isActive}
          alwaysVisible={alwaysVisible}
          {...otherToggleProps}
        >
          <Icon
            data-icon
            name="add-outline-round"
            size={20}
            ref={ref}
          />
        </StyledAddElementButton>
      )}
      {(popoverProps) => (
        <AddElementMenu
          id={id}
          variant={variant}
          {...popoverProps}
        />
      )}
    </PopoverContainer>
  )
}

const AddElementMenu = React.forwardRef<HTMLDivElement, PopoverProps & AddElementButtonProps>(({
  id,
  variant,
  ...menuProps
}, ref) => {
  const { updateBlock, selectBlock, openDashboardEditorView, blockState } = useDashboard()
  const block = useRecoilValue(blockState(id))
  const { activeUrn } = useDashboardViewContext()
  const { openDashboardEditor } = useContext(DashboardContext)!
  const variantKey = `${variant}_elements`

  const onAdd = (element: any) => {
    updateBlock(activeUrn, {
      ...block,
      properties: {
        ...block.properties,
        [variantKey]: (block.properties[variantKey] || []).concat(element)
      }
    } as any, true)
    selectBlock(id)
    openDashboardEditorView({
      target: Views.EDIT_BLOCK
    })
    openDashboardEditor()
  }
  return (
    <Popover autoFocus ref={ref} {...menuProps}>
      <PopoverBody css={{ width: 240 }}>
        <PopoverItem
          size="small"
          icon={ELEMENT_TYPE_TO_ICON_MAP.EnvironmentSwitcher}
          onClick={() => {
            const elementId = uuid()
            onAdd({
              id: elementId,
              name: 'Environment Switcher',
              identifier: `environment-switcher-${elementId.slice(0, 8)}`,
              type: 'EnvironmentSwitcher',
              blockId: id,
              properties: {}
            })
          }}
          text="Environment"
        />
        <PopoverItem
          size="small"
          icon={ELEMENT_TYPE_TO_ICON_MAP.InstallationSwitcher}
          onClick={() => {
            const elementId = uuid()
            onAdd({
              id: elementId,
              name: 'Installation Switcher',
              identifier: `installation-switcher-${elementId.slice(0, 8)}`,
              type: 'InstallationSwitcher',
              blockId: id,
              properties: {}
            })
          }}
          text="Installation"
        />
      </PopoverBody>
    </Popover>
  )
})

export default AddElementButton
