import { date } from 'yup'

const INVALID_DATE = new Date('')

const isValidDate = (isNullable: boolean = false) => {
  const schema = date().nullable(isNullable)

  return schema.transform((value, originalValue?: string) => {
    if (isNullable && originalValue === null) return undefined

    const [ originalDate, originalTime ] = originalValue?.split('T') || []

    const dateFields = originalDate?.split('-') || []
    const timeFields = originalTime?.split('+')[0]?.split(':')

    if (
      dateFields.length === 0 && (timeFields ? timeFields.length === 0 : true)
    ) {
      // user didn't enter anything
      // return undefined so validation error doesn't show
      return undefined
    }

    const [ year, month, day ] = dateFields
    if (!year || !month || !day) return INVALID_DATE
    if (year.length !== 4 || month.length !== 2 || day.length !== 2) return INVALID_DATE

    if (timeFields) {
      const [ hour, minute ] = timeFields
      if (!hour || !minute) return INVALID_DATE
    }

    return value
  })
}

export default isValidDate
