import capitalize from 'lodash/capitalize'
import groupBy from 'lodash/groupBy'
import kebabCase from 'lodash/kebabCase'
import last from 'lodash/last'
import orderBy from 'lodash/orderBy'
import uuid from 'uuid-random'
import { getMenuElements } from 'dashx-fixtures'
import { klona } from 'klona'

import getPropertyToElementMap from 'lib/getPropertyToElementMap'
import parseViewUrn from 'hooks/parseViewUrn'
import { parseAndRenderSync } from 'lib/templater'
import { useApps } from 'components/dashboardEditor/app/AppsView'
import { WorkspaceContextQuery, DashboardsListQuery, DashboardFragmentFragment as DashboardFragment, MenuElementFragmentFragment, MenuElement, useResourcesListQuery } from 'generated/schema'
import type { TMap } from 'lib/getPropertyToElementMap'

type ComputedMenuElement = MenuElementFragmentFragment & {
  concatenatedNames?: string,
  fullPath?: string,
  parentIds?: string[],
  viewUrn?: string,
  isVisible?: boolean,
  renderedName?: string,
  renderedIcon?: string
}

type GroupedMenuElementsMap = {
  [key: string]: ComputedMenuElement[]
}

type MenuElementMap = {
  [key: string]: ComputedMenuElement
}

const MENU_ELEMENT_OPTIMISTIC_PROPERTIES: MenuElement = {
  dashboardId: null,
  isRepeated: false,
  isSticky: false,
  parentId: null,
  placement: 'SIDE',
  target: 'VIEW',
  kind: 'ITEM',
  icon: '',
  position: 999999,
  id: null,
  actions: [],
  createdAt: null,
  updatedAt: null,
  viewUrn: '',
  viewStyle: 'MAIN',
  name: '',
  path: '',
  url: '',
  query: ''
}

const WORKSPACE_MENU_ELEMENTS = [
  'overview',
  'apps',
  'data',
  '~admin>'
]

const WORKSPACE_SUBMENU_ELEMENTS = {
  projects: [
    'overview'
  ],
  extensions: [
    'overview'
  ],
  team: [
  ],
  admin: [
    '#team',
    'accounts',
    'groups',
    'roles',
    '#advanced',
    'products',
    'spaces',
    'environments',
    'dashboards',
    '#other',
    'developer',
    'billing',
    'settings'
  ]
}

const PERSONAL_MENU_ELEMENTS = [
  'profile',
  'notifications',
  '~settings'
]

const WORKSPACE_MENU_ELEMENT_ICONS_MAP: Record<string, string> = {
  overview: 'work-from-home',
  projects: 'projects',
  extensions: 'apps',
  apps: 'apps',
  integrations: 'integration',
  accounts: 'accounts',
  groups: 'groups',
  roles: 'roles',
  dashboards: 'dashboard',
  environments: 'environment',
  billing: 'billing',
  interface: 'interface',
  data: 'graph',
  developer: 'developer',
  admin: 'settings',
  team: 'accounts'
}

const PERSONAL_MENU_ELEMENT_ICONS_MAP: Record<string, string> = {
  profile: 'people',
  notifications: 'notification',
  settings: 'settings'
}

const PERSONAL_DASHBOARD_ID = 'e4354802-af8a-46f4-9c54-6201cb17cfea'
const WORKSPACE_DASHBOARD_ID = '70c3feef-b3de-466a-a8a0-767d4ab61907'
const PROJECTS_MENU_ELEMENT_ID = '40c106b8-6bf9-430a-9a35-f33347246c7b'
const EXTENSIONS_MENU_ELEMENT_ID = '473ecf75-59ce-479e-a7d4-0875f601974e'

function getId(path: string) {
  if (path === 'projects>') return PROJECTS_MENU_ELEMENT_ID
  if (path === 'extensions>') return EXTENSIONS_MENU_ELEMENT_ID

  return uuid()
}

const parsePath = (
  path: string, index: number, parentIds?: string[]
): ComputedMenuElement | ComputedMenuElement[] => {
  const p = last(path.split('/'))
  const isSeparator = p === ' ' || p === '-'
  const isSticky = p?.startsWith('~') || false
  const isGroup = p?.startsWith('#')
  const isSubmenu = path.endsWith('>')
  const parsedPath = path.replace('#', '').replace('~', '').replace('>', '')

  const commonElementProperties: ComputedMenuElement = {
    dashboardId: WORKSPACE_DASHBOARD_ID,
    icon: WORKSPACE_MENU_ELEMENT_ICONS_MAP[parsedPath],
    id: getId(path),
    path: parsedPath === '' ? 'workspace' : kebabCase(parsedPath),
    isRepeated: false,
    isVisible: true,
    isSticky,
    parentId: parentIds?.[0],
    parentIds: parentIds || [],
    placement: 'SIDE',
    position: index,
    target: 'VIEW',
    kind: 'ITEM'
  }

  if (isGroup) {
    return {
      ...commonElementProperties,

      name: capitalize(last(parsedPath.split('/'))),
      kind: 'GROUP'
    } as ComputedMenuElement
  }

  if (isSeparator) {
    return {
      ...commonElementProperties,
      kind: 'SEPARATOR',
      separatorStyle: parsedPath === ' ' ? 'WHITESPACE' : 'RULER'
    } as ComputedMenuElement
  }

  if (isSubmenu) {
    const sub = WORKSPACE_SUBMENU_ELEMENTS[parsedPath as keyof typeof WORKSPACE_SUBMENU_ELEMENTS]

    return [
      {
        ...commonElementProperties,
        name: capitalize(last(parsedPath.split('/'))),
        target: 'SUBMENU'
      },
      ...(sub?.map((path) => parsePath(
        `${parsedPath}/${path}`,
        index,
        [ ...commonElementProperties.parentIds!, commonElementProperties.id ]
      )) || []
      )
    ] as ComputedMenuElement[]
  }

  return {
    ...commonElementProperties,

    name: parsedPath === '' ? 'Workspace' : capitalize(last(parsedPath.split('/'))),
    target: 'VIEW',
    path: parsedPath
  } as ComputedMenuElement
}

const generateWorkspaceMenuElements = (): ComputedMenuElement[] => {
  const children = WORKSPACE_MENU_ELEMENTS.map((path, index) => parsePath(path, index)).flat()

  return children
}

const generatePersonalMenuElements = (): ComputedMenuElement[] => {
  const children = PERSONAL_MENU_ELEMENTS.map((path, index) => {
    const isSeparator = path === ' ' || path === '-'
    const isSticky = path.startsWith('~')
    const isGroup = path.startsWith('#')
    const parsedPath = path.replace('#', '').replace('~', '')

    const commonElementProperties: ComputedMenuElement = {
      dashboardId: PERSONAL_DASHBOARD_ID,
      icon: PERSONAL_MENU_ELEMENT_ICONS_MAP[parsedPath],
      id: uuid(),
      path: parsedPath === '' ? 'personal' : parsedPath,
      isRepeated: false,
      isVisible: true,
      isSticky,
      parentId: undefined,
      placement: 'SIDE',
      position: index,
      target: 'VIEW',
      kind: 'ITEM'
    }

    if (isGroup) {
      return {
        ...commonElementProperties,

        name: capitalize(last(parsedPath.split('/'))),
        kind: 'GROUP'
      } as ComputedMenuElement
    }

    if (isSeparator) {
      return {
        ...commonElementProperties,

        kind: 'SEPARATOR',
        separatorStyle: parsedPath === ' ' ? 'WHITESPACE' : 'RULER'
      } as ComputedMenuElement
    }

    return {
      ...commonElementProperties,

      name: parsedPath === '' ? 'Personal' : capitalize(last(parsedPath.split('/'))),
      target: 'VIEW',
      path: parsedPath
    } as ComputedMenuElement
  })

  return children
}

const PERSONAL_DASHBOARD: DashboardFragment = {
  id: PERSONAL_DASHBOARD_ID,
  identifier: 'personal',
  menuElements: generatePersonalMenuElements(),
  name: 'Personal',
  position: 1
}

const WORKSPACE_DASHBOARD: DashboardFragment = {
  id: WORKSPACE_DASHBOARD_ID,
  identifier: 'workspace',
  menuElements: generateWorkspaceMenuElements(),
  name: 'Workspace',
  position: -1
}

const DEFAULT_SUBMENU_ICON = 'menu-group'
const DEFAULT_VIEW_ICON = 'page-outline'
const DEFAULT_ROOT_ICON = 'app'
const DEFAULT_RESOURCE_ICON = 'graph'

const getDefaultIcon = (menuElement: ComputedMenuElement) => {
  if (menuElement.position === 0) {
    return DEFAULT_ROOT_ICON
  } if (menuElement.target === 'SUBMENU') {
    return DEFAULT_SUBMENU_ICON
  } if (menuElement.viewUrn && parseViewUrn(menuElement.viewUrn)?.type === 'resource') {
    return DEFAULT_RESOURCE_ICON
  }
  return DEFAULT_VIEW_ICON
}

const getInjectRecursiveProperties = (idToMenuElementMap: TMap<ComputedMenuElement>, idToDashboardMap: TMap<DashboardsListQuery['dashboardsList'][number]>, currentWorkspace?: WorkspaceContextQuery['workspace']) => {
  // Let's not mutate idToMenuElementMap directly
  const menuElementMap = klona(idToMenuElementMap)

  return function injectRecursiveProperties(menuElement: ComputedMenuElement): ComputedMenuElement {
    const computedMenuElement = menuElementMap[menuElement.id]

    // Parse templated name
    if (menuElement.name) {
      computedMenuElement.renderedName = parseAndRenderSync(menuElement.name, {
        _dashboard: idToDashboardMap[menuElement.dashboardId],
        _workspace: currentWorkspace
      })
    }

    if (!menuElement.target) {
      // Early return
      return menuElement
    }

    // Set default icon for first-level elements
    if (!menuElement.parentId && !menuElement.icon) {
      computedMenuElement.renderedIcon = getDefaultIcon(menuElement)
    } else {
      computedMenuElement.renderedIcon = menuElement.icon
    }

    const parentElement = menuElementMap[menuElement.parentId]
    if (parentElement) {
      // Path - Recursive condition

      // if (parentElement.fullPath) {
      //   computedMenuElement.fullPath = `${parentElement.fullPath}/${menuElement.path}`
      // } else if (menuElement.target !== 'URL') {
      //   computedMenuElement.fullPath = `${injectRecursiveProperties(parentElement)
      // .fullPath}/${menuElement.path}`
      // }

      // Parents - Recursive condition

      if (parentElement.parentIds) {
        computedMenuElement.parentIds = [
          ...parentElement.parentIds!,
          menuElement.id
        ]
      } else {
        computedMenuElement.parentIds = [
          ...(injectRecursiveProperties(parentElement).parentIds || []),
          menuElement.id
        ]
      }

      // Concatenated names - Recursive condition

      if (parentElement.concatenatedNames) {
        computedMenuElement.concatenatedNames = (
          `${parentElement.concatenatedNames!}/${computedMenuElement.renderedName}`
        )
      } else {
        computedMenuElement.concatenatedNames = (
          `${injectRecursiveProperties(parentElement).concatenatedNames!}/${computedMenuElement.renderedName}`
        )
      }
    } else {
      // Path - Base condition

      // Parents - Base condition
      computedMenuElement.parentIds = [ menuElement.id ]

      // Concatenated names - Base condition
      computedMenuElement.concatenatedNames = computedMenuElement.renderedName
    }

    const { dashboardId } = menuElement
    if (dashboardId) {
      if (idToDashboardMap[dashboardId]) {
        if (menuElement.path) {
          computedMenuElement.fullPath = `/~${idToDashboardMap[dashboardId].identifier}/${menuElement.path}`
        } else if (menuElement.url) {
          computedMenuElement.fullPath = menuElement.url
        } else {
          computedMenuElement.fullPath = `/~${idToDashboardMap[dashboardId].identifier}`
        }
      }
    } else {
      computedMenuElement.fullPath = `/${menuElement.path}`
    }

    return computedMenuElement
  }
}

const generateDashboard = (dashboards: DashboardsListQuery['dashboardsList'] = [], currentWorkspace?: WorkspaceContextQuery['workspace']) => {
  const menuElements = dashboards
    .flatMap((dashboard) => dashboard.menuElements.map((ele) => ({ isVisible: true, ...ele })))

  const idToMenuElementMap = getPropertyToElementMap<ComputedMenuElement>(menuElements as ComputedMenuElement[], 'id')
  const idToDashboardMap = getPropertyToElementMap<DashboardsListQuery['dashboardsList'][number]>(dashboards, 'id')

  const injectRecursiveProperties = getInjectRecursiveProperties(
    idToMenuElementMap,
    idToDashboardMap,
    currentWorkspace
  )

  const allMenuElements = orderBy(menuElements.map((menuElement) => {
    idToMenuElementMap[menuElement.id] = injectRecursiveProperties(
      menuElement as ComputedMenuElement
    )
    return idToMenuElementMap[menuElement.id]
  }), [ 'parentId', 'position' ], [ 'desc', 'asc' ])

  const parentIdToMenuElementsMap: GroupedMenuElementsMap = groupBy(allMenuElements, 'parentId')

  return {
    parentIdToMenuElementsMap,
    idToMenuElementMap,
    menuElements: allMenuElements
  }
}

const splitMenuElements = (dashboards: DashboardsListQuery['dashboardsList'] = [], dashboardIdentifier = '', menuElements: ComputedMenuElement[]) => {
  const currentDashboard = dashboards.find(
    (dashboard) => dashboard.identifier === dashboardIdentifier
  )

  const [ sideMenuElements, topMenuElements ] = (
    menuElements.reduce(([ sideElements, topElements ], menuElement) => {
      if ((menuElement.dashboardId === currentDashboard?.id || !menuElement.dashboardId) && menuElement.placement === 'SIDE') {
        sideElements.push(menuElement)
      } else if ((menuElement.dashboardId === currentDashboard?.id || !menuElement.dashboardId) && menuElement.placement === 'TOP') {
        topElements.push(menuElement)
      }
      return [ sideElements, topElements ]
    }, [ [] as typeof menuElements, [] as typeof menuElements ])
  )

  return {
    currentDashboard,
    sideMenuElements,
    topMenuElements
  }
}

const getRedirectionPath = (
  menuElement: MenuElementFragmentFragment,
  currentDashboard: DashboardFragment,
  currentWorkspace: WorkspaceContextQuery['workspace']
) => {
  if (menuElement.target && menuElement.target !== 'URL') {
    const idToDashboardMap = currentDashboard
      ? getPropertyToElementMap<typeof currentDashboard>([ currentDashboard ], 'id')
      : {}

    const idToMenuElementMap = getPropertyToElementMap<typeof menuElement>([ menuElement ], 'id')

    const injectRecursiveProperties = getInjectRecursiveProperties(
      idToMenuElementMap,
      idToDashboardMap,
      currentWorkspace
    )

    const computedMenuElement = injectRecursiveProperties(menuElement)

    return computedMenuElement.fullPath
  }

  return null
}

const useGenerateProjectMenuElements = (skip = false) => {
  const {
    appsList: projectAppsList
  } = useApps('PROJECT', skip)

  const { data } = useResourcesListQuery({
    skip,
    variables: {
      limit: 1000,
      filter: {
        appId: {
          in: projectAppsList.map((app) => app.id)
        }
      }
    }
  })

  if (projectAppsList.length === 0) {
    return []
  }

  const projectMenus = projectAppsList
    .map((app, i) => {
      const overviewId = uuid()
      const resourcesId = uuid()
      const setupId = uuid()
      const schemaId = uuid()
      const accessId = uuid()

      const resourceMenus = (data?.resourcesList || [])
        .filter((resource) => resource.appId === app.id)
        .map((resource, j) => ({
          name: resource.name,
          icon: app.icon || 'app-custom',
          dashboardId: WORKSPACE_DASHBOARD.id,
          id: resource.id,
          path: `project/${app.id}/resource/${resource.id}`,
          isRepeated: false,
          isVisible: true,
          isSticky: false,
          parentId: app.id,
          parentIds: [ app.id, resource.id ],
          placement: 'SIDE',
          position: (i + 1) * 1000 + j * 10 + 1,
          target: 'VIEW',
          kind: 'ITEM',
          viewUrn: `resource::${resource?.id}::graph/GenericResourceView`
        }))

      return [
        {
          name: app.name,
          icon: app.icon || 'app-custom',
          dashboardId: WORKSPACE_DASHBOARD.id,
          id: app.id,
          path: `project/${app.id}`,
          isRepeated: false,
          isVisible: true,
          isSticky: false,
          parentId: null,
          parentIds: [],
          placement: 'SIDE',
          position: 1000 + i,
          target: 'SUBMENU',
          kind: 'ITEM'
        },
        {
          name: 'Overview',
          icon: app.icon || 'app-custom',
          dashboardId: WORKSPACE_DASHBOARD.id,
          id: overviewId,
          path: `project/${app.id}/overview`,
          isRepeated: false,
          isVisible: true,
          isSticky: false,
          parentId: app.id,
          parentIds: [ app.id, overviewId ],
          placement: 'SIDE',
          position: 1000 + i,
          target: 'VIEW',
          kind: 'ITEM'
        },
        ...(resourceMenus.length ? [
          {
            name: 'Resources',
            dashboardId: WORKSPACE_DASHBOARD.id,
            id: resourcesId,
            isRepeated: false,
            isVisible: true,
            isSticky: false,
            parentId: app.id,
            parentIds: [ app.id, resourcesId ],
            placement: 'SIDE',
            position: (i + 1) * 1000,
            kind: 'GROUP'
          },
          ...resourceMenus
        ] : []),
        {
          name: 'Setup',
          dashboardId: WORKSPACE_DASHBOARD.id,
          id: setupId,
          isRepeated: false,
          isVisible: true,
          isSticky: false,
          parentId: app.id,
          parentIds: [ app.id, schemaId ],
          placement: 'SIDE',
          position: 10_000_000,
          kind: 'GROUP'
        },
        {
          name: 'Data',
          dashboardId: WORKSPACE_DASHBOARD.id,
          id: schemaId,
          path: `project/${app.id}/data`,
          isRepeated: false,
          isVisible: true,
          isSticky: false,
          parentId: app.id,
          parentIds: [ app.id, schemaId ],
          placement: 'SIDE',
          position: 20_000_000,
          target: 'VIEW',
          kind: 'ITEM'
        },
        {
          name: 'Access',
          dashboardId: WORKSPACE_DASHBOARD.id,
          id: accessId,
          path: `project/${app.id}/access`,
          isRepeated: false,
          isVisible: true,
          isSticky: false,
          parentId: app.id,
          parentIds: [ app.id, accessId ],
          placement: 'SIDE',
          position: 30_000_000,
          target: 'VIEW',
          kind: 'ITEM'
        }
      ]
    }).flat() as ComputedMenuElement[]

  return [ {
    name: 'Projects',
    dashboardId: WORKSPACE_DASHBOARD.id,
    id: 'your-apps',
    path: '',
    isRepeated: false,
    isVisible: true,
    isSticky: false,
    parentId: undefined,
    parentIds: [],
    placement: 'SIDE',
    position: 1000,
    target: 'VIEW',
    kind: 'GROUP' as const
  } as ComputedMenuElement ].concat(projectMenus)
}

const useGenerateAppMenuElements = (skip = false) => {
  const {
    appCategoriesList,
    installedAppIds,
    appsList
  } = useApps('EXTENSION', skip)

  const appMenuElementsByCategory = appCategoriesList.map((category, i) => {
    const apps = appsList
      .filter((app) => app.appCategoryId === category.id)
      .map((app, j) => {
        const schemaId = uuid()
        const accessId = uuid()
        const settingsId = uuid()
        const setupId = uuid()

        const isInstalled = installedAppIds.includes(app.id)
        const installedAppMenuElements = [
          ...((getMenuElements()[app.identifier] || []).map((menuElement) => {
            const id = uuid()
            return ({
              ...menuElement,
              dashboardId: WORKSPACE_DASHBOARD.id,
              id,
              path: `app/${app.id}/${kebabCase(menuElement.name)}`,
              parentId: app.id,
              parentIds: [ app.id, id ],
              viewUrn: `app::${app.id}::${menuElement.componentPath}`
            })
          })),
          {
            name: 'Setup',
            dashboardId: WORKSPACE_DASHBOARD.id,
            id: setupId,
            isRepeated: false,
            isVisible: true,
            isSticky: false,
            parentId: app.id,
            parentIds: [ app.id, schemaId ],
            placement: 'SIDE',
            position: 10_000_000,
            kind: 'GROUP'
          },
          {
            name: 'Data',
            dashboardId: WORKSPACE_DASHBOARD.id,
            id: schemaId,
            path: `app/${app.id}/data`,
            isRepeated: false,
            isVisible: true,
            isSticky: false,
            parentId: app.id,
            parentIds: [ app.id, schemaId ],
            placement: 'SIDE',
            position: 20_000_000,
            target: 'VIEW',
            kind: 'ITEM'
          },
          {
            name: 'Access',
            dashboardId: WORKSPACE_DASHBOARD.id,
            id: accessId,
            path: `app/${app.id}/access`,
            isRepeated: false,
            isVisible: true,
            isSticky: false,
            parentId: app.id,
            parentIds: [ app.id, accessId ],
            placement: 'SIDE',
            position: 30_000_000,
            target: 'VIEW',
            kind: 'ITEM'
          },
          ...(app.identifier === 'planner' ? [ {
            name: 'Settings',
            dashboardId: WORKSPACE_DASHBOARD.id,
            id: settingsId,
            path: `app/${app.id}/settings`,
            isRepeated: false,
            isVisible: true,
            isSticky: false,
            parentId: app.id,
            parentIds: [ app.id, settingsId ],
            placement: 'SIDE',
            position: 40_000_000,
            target: 'VIEW',
            kind: 'ITEM'
          } ] : [])
        ]

        return ([
          {
            name: app.name,
            icon: app.icon || `app-${app.identifier}`,
            dashboardId: WORKSPACE_DASHBOARD.id,
            id: app.id,
            path: `app/${app.id}`,
            isRepeated: false,
            isVisible: true,
            isSticky: false,
            parentId: null,
            parentIds: [],
            placement: 'SIDE',
            position: (i + 1) * 10000 + j * 10 + 1,
            target: isInstalled ? 'SUBMENU' : 'VIEW',
            kind: 'ITEM'
          },
          ...(isInstalled
            ? installedAppMenuElements
            : [])

        ])
      })
      .flat()

    if (!apps.length) {
      return []
    }

    return [
      {
        name: `${category.name} Apps`,
        dashboardId: WORKSPACE_DASHBOARD.id,
        id: category.id,
        path: '',
        isRepeated: false,
        isVisible: true,
        isSticky: false,
        parentId: null,
        parentIds: [],
        placement: 'SIDE',
        position: (i + 1) * 10000,
        target: 'VIEW',
        kind: 'GROUP' as const
      },
      ...apps
    ]
  }).flat() as ComputedMenuElement[]

  return appMenuElementsByCategory
}
export {
  generateDashboard,
  getDefaultIcon,
  getInjectRecursiveProperties,
  MENU_ELEMENT_OPTIMISTIC_PROPERTIES,
  splitMenuElements,
  PERSONAL_DASHBOARD,
  WORKSPACE_DASHBOARD,
  EXTENSIONS_MENU_ELEMENT_ID,
  getRedirectionPath,
  useGenerateProjectMenuElements,
  useGenerateAppMenuElements
}

export type { ComputedMenuElement, MenuElementMap, GroupedMenuElementsMap }
