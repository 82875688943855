/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import dayjs from 'dayjs'
import padStart from 'lodash/padStart'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import type { Dayjs } from 'dayjs'

import Flex from 'components/layout/Flex'
import scrollIntoViewIfNeeded from 'lib/scrollIntoViewIfNeeded'
import Text from 'components/typography/Text'
import typography from 'styles/primitives/typography'
import { styled } from 'styles/stitches'
import type { DATE_FORMATS } from 'components/contentEditors/generic/fields/fieldProps'

type TimeFormat = DATE_FORMATS.DD_MM_YYYY_hh_mm_A
  | DATE_FORMATS.MM_DD_YYYY_hh_mm_A
  | DATE_FORMATS.YYYY_MM_DD_hh_mm_A
  | DATE_FORMATS.DD_MM_YYYY_HH_mm
  | DATE_FORMATS.MM_DD_YYYY_HH_mm
  | DATE_FORMATS.YYYY_MM_DD_HH_mm
type MeridiemType = 'AM' | 'PM'

type TimePickerProps = {
  activeHour?: number,
  activeMinute?: number,
  activeSecond?: number,
  activeMeridiem?: MeridiemType,
  onChangeHour?: (v: number) => void,
  onChangeMinute?: (v: number) => void,
  onChangeSecond?: (v: number) => void,
  onChangeMeridiem?: (v: MeridiemType) => void,

  defaultSelectedDate?: Dayjs | string,
  timeFormat?: TimeFormat,
  height?: number,
  onChange: (time: Dayjs) => void
}

type CellProps = {
  cell: number,
  selectedCell: number,
  onClick: (cell: number) => void
}

type MeridiemCellProps = {
  cell: MeridiemType,
  selectedCell: MeridiemType,
  onClick: (cell: MeridiemType) => void
}

const CELL_FONT_SIZE = 14
const CELL_PADDING = 5
const CELL_WIDTH = 50
const CELL_HEIGHT = Math.floor(
  parseFloat(typography.lineHeights.normal) * CELL_FONT_SIZE + CELL_PADDING * 2
)
const DEFAULT_HEIGHT = 200
const HEADER_CELL_Y_PADDING = 15
const COLUMN_MARGIN_X = 3
const COLUMN_PADDING_Y = 10

const TWENTY_FOUR_HOUR_RANGE = Array(24).fill(0).map((_, index) => index)
const TWELVE_HOUR_RANGE = Array(12).fill(0).map((_, index) => index)
const MINUTE_RANGE = Array(60).fill(0).map((_, index) => index)
const MERIDIEM_RANGE: MeridiemType[] = [ 'AM', 'PM' ]

const StyledHeaderCell = styled(Text, {
  paddingY: HEADER_CELL_Y_PADDING
})

const StyledColumn = styled(Scrollbars, {
  boxSizing: 'content-box',
  marginX: COLUMN_MARGIN_X,
  borderTop: '1px solid light300',
  paddingY: COLUMN_PADDING_Y
})

const StyledCell = styled(Flex, {
  cursor: 'pointer',
  padding: CELL_PADDING,
  width: CELL_WIDTH,

  '&:last-of-type': {
    marginBottom: COLUMN_PADDING_Y
  },

  '&:hover': {
    backgroundColor: 'positive300',
    borderRadius: 4,
    '[data-cell]': {
      color: 'light100'
    }
  },

  variants: {
    isSelected: {
      true: {
        backgroundColor: 'positive300',
        borderRadius: 4,
        '[data-cell]': {
          color: 'light100'
        }
      }
    }
  }
})

function Cell({ cell, selectedCell, onClick }: CellProps) {
  const isSelected = selectedCell === cell
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current && isSelected) {
      scrollIntoViewIfNeeded(ref.current)
    }
  }, [ isSelected ])

  return (
    <StyledCell
      alignItems="center"
      isSelected={isSelected}
      justifyContent="center"
      onClick={() => onClick(cell)}
      ref={ref}
    >
      <Text data-cell color="dark900" fontSize={CELL_FONT_SIZE}>
        {/* Appends 0 to single digit numbers only - 1 -> 01, 11 -> 11 */}
        {padStart(cell.toString(), 2, '0')}
      </Text>
    </StyledCell>
  )
}

function MeridiemCell({ cell, selectedCell, onClick }: MeridiemCellProps) {
  const isSelected = selectedCell === cell
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current && isSelected) {
      scrollIntoViewIfNeeded(ref.current)
    }
  }, [ isSelected ])

  return (
    <StyledCell
      alignItems="center"
      isSelected={isSelected}
      justifyContent="center"
      onClick={() => onClick(cell)}
      ref={ref}
    >
      <Text data-cell color="dark900" fontSize={CELL_FONT_SIZE}>
        {cell}
      </Text>
    </StyledCell>
  )
}

function TimePicker({
  /** Controlled */
  activeHour,
  activeMinute,
  activeSecond,
  activeMeridiem,
  onChangeHour,
  onChangeMinute,
  onChangeMeridiem,

  defaultSelectedDate = '',
  timeFormat,
  height = DEFAULT_HEIGHT,
  onChange
}: TimePickerProps) {
  const hourRef = useRef<Scrollbars | null>(null)
  const minuteRef = useRef<Scrollbars | null>(null)
  const isTwelveHourFormat = timeFormat?.includes(':mm A')

  const defaultSelected = useMemo(() => {
    const now = dayjs()

    const defaultDate = dayjs(defaultSelectedDate, timeFormat)

    return defaultDate.isValid() ? defaultDate : now
  }, [ defaultSelectedDate, timeFormat ])

  const [ selected, setSelected ] = useState(defaultSelected)
  const [ time, meridiem ] = dayjs(selected).format(isTwelveHourFormat ? 'hh:mm A' : 'HH:mm').split(' ')
  const [ hour, minute ] = time.split(':')

  useEffect(() => {
    if (hourRef.current && minuteRef.current) {
      const hour = activeHour ?? defaultSelected.hour()
      const minute = activeMinute ?? defaultSelected.minute()

      const hourOffset = hour * CELL_HEIGHT - (height / 2)
      const minuteOffset = minute * CELL_HEIGHT - (height / 2)
      hourRef.current.scrollTop(hourOffset)
      minuteRef.current.scrollTop(minuteOffset)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resolveHour = (hour: number) => (isTwelveHourFormat
    ? (activeMeridiem === 'PM'
      ? (hour < 12 ? hour + 12 : hour)
      : (hour === 12 ? 0 : hour))
    : hour)

  const onHourClick = (hour: number) => {
    const newSelected = dayjs()
      .hour(resolveHour(hour))
      .minute(activeMinute || selected.minute())
      .second(activeSecond || selected.second())
    onChangeHour?.(hour)
    setSelected(newSelected)
    onChange(newSelected)
  }

  const onMinuteClick = (minute: number) => {
    const newSelected = dayjs().hour(resolveHour(activeHour || selected.hour())).minute(minute)
    onChangeMinute?.(minute)
    setSelected(newSelected)
    onChange(newSelected)
  }

  const onMeridiemClick = (meridiem: MeridiemType) => {
    onChangeMeridiem?.(meridiem)
  }

  const computeHourKey = (hour: number) => {
    if (hour > 12) return hour - 12
    return hour
  }

  const renderHour = () => {
    const selectedHour = activeHour ?? Number.parseInt(hour, 10)

    return (
      <Flex alignItems="center" direction="column" grow={1}>
        <StyledHeaderCell color="dark500" fontSize={12} textTransform="uppercase">
          HH
        </StyledHeaderCell>
        <StyledColumn
          autoHeight
          autoHide
          autoHeightMax={height}
          ref={hourRef}
        >
          {(isTwelveHourFormat ? TWELVE_HOUR_RANGE : TWENTY_FOUR_HOUR_RANGE).map((hour) => (
            <Cell
              key={isTwelveHourFormat ? computeHourKey(hour) : hour}
              // to provision 12 hour format we add +1 to hour
              cell={isTwelveHourFormat ? hour + 1 : hour}
              selectedCell={selectedHour}
              onClick={onHourClick}
            />
          ))}
        </StyledColumn>
      </Flex>
    )
  }

  const renderMinute = () => {
    const selectedMinute = activeMinute ?? Number.parseInt(minute, 10)

    return (
      <Flex alignItems="center" direction="column" grow={1}>
        <StyledHeaderCell color="dark500" fontSize={12} textTransform="uppercase">
          MM
        </StyledHeaderCell>
        <StyledColumn
          autoHeight
          autoHide
          autoHeightMax={height}
          ref={minuteRef}
        >
          {MINUTE_RANGE.map((minute) => (
            <Cell
              key={minute}
              cell={minute}
              selectedCell={selectedMinute}
              onClick={onMinuteClick}
            />
          ))}
        </StyledColumn>
      </Flex>
    )
  }

  const renderMeridiem = () => {
    const selectedMeridiem = activeMeridiem ?? meridiem as MeridiemType
    return (
      <Flex alignItems="center" direction="column" grow={1}>
        <StyledHeaderCell color="dark500" fontSize={12} textTransform="uppercase">
          A
        </StyledHeaderCell>
        <StyledColumn
          autoHeight
          autoHide
          autoHeightMax={height}
        >
          {MERIDIEM_RANGE.map((meridium) => (
            <MeridiemCell
              key={meridium}
              cell={meridium}
              selectedCell={selectedMeridiem}
              onClick={onMeridiemClick}
            />
          ))}
        </StyledColumn>
      </Flex>
    )
  }

  return (
    <Flex>
      {renderHour()}
      {renderMinute()}
      {isTwelveHourFormat && renderMeridiem()}
    </Flex>
  )
}

export type { MeridiemType, TimeFormat }

export default TimePicker
