import { useContext } from 'react'
import kebabCase from 'lodash/kebabCase'
import uuid from 'uuid-random'
import { useRecoilSnapshot, useRecoilValue } from 'recoil'

import DashboardContext from 'components/contexts/DashboardContext'
import useDashboard from 'hooks/useDashboard'
import { Views } from 'components/dashboardEditor/constants'

const useAppendBlockToParent = (urn: string, parentId: string, index: number, block: any) => {
  const { updateBlock, selectBlock, openDashboardEditorView, blockState } = useDashboard()
  const { openDashboardEditor } = useContext(DashboardContext)!

  const parentBlock = useRecoilValue(blockState(parentId))
  const snapshot = useRecoilSnapshot()

  return async () => {
    const id = uuid()
    const newBlock = {
      id,
      identifier: `${kebabCase(block.type).toLowerCase()}-${id.slice(0, 8)}`,
      type: block.type,
      properties: block.properties
    } as any

    const isRoot = !parentId || parentBlock.id.toString() === '-1'
    updateBlock(urn, newBlock, isRoot)

    if (!isRoot) {
      switch (parentBlock.type) {
        case 'CardBlock':
        case 'FormBlock':
          updateBlock(urn, {
            ...parentBlock,
            properties: {
              ...parentBlock.properties,
              children: [
                ...(await Promise.all(
                  (parentBlock.properties.children || [])
                    .map((child: any) => snapshot.getPromise(blockState(child.id)))
                )),
                newBlock
              ]
            }
          } as any)
          break
        case 'ColumnsBlock':
          updateBlock(urn, {
            ...parentBlock,
            properties: {
              ...parentBlock.properties,
              columns: [
                ...(parentBlock.properties.columns || []).slice(0, index),
                {
                  width: '100%',
                  ...(parentBlock.properties.columns || [])[index],
                  children: [
                    ...((parentBlock.properties.columns || [])[index]?.children || []),
                    ...(await Promise.all(
                      ((parentBlock.properties.columns || [])[index]?.children || [])
                        .map((child: any) => snapshot.getPromise(blockState(child.id)))
                    )),
                    newBlock
                  ]
                },
                ...(parentBlock.properties.columns || []).slice(index + 1)
              ]
            }
          } as any)
          break
        case 'TabsBlock':
          updateBlock(urn, {
            ...parentBlock,
            properties: {
              ...parentBlock.properties,
              tabs: [
                ...(parentBlock.properties.tabs || []).slice(0, index),
                {
                  ...(parentBlock.properties.tabs || [])[index],
                  children: [
                    ...(await Promise.all(
                      ((parentBlock.properties.tabs || [])[index]?.children || [])
                        .map((child: any) => snapshot.getPromise(blockState(child.id)))
                    )),
                    newBlock
                  ]
                },
                ...(parentBlock.properties.tabs || []).slice(index + 1)
              ]
            }
          } as any)
          break
      }
    }

    selectBlock(id)
    openDashboardEditorView({
      target: Views.EDIT_BLOCK,
      params: {
        block: newBlock
      }
    })
    openDashboardEditor()
  }
}

export default useAppendBlockToParent
