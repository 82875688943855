import React from 'react'
import Scrollbars from 'react-custom-scrollbars'

import useAppendBlockToParent from 'hooks/useAppendBlockToParent'
import { BLOCK_TYPE_TO_ICON_MAP, BLOCK_TYPE_TO_NAME_MAP } from 'components/dashboardEditor/constants'
import { blocksByCategory } from 'components/dashboardEditor/AddBlockView'
import { Popover, PopoverBody, PopoverDivider, PopoverItem } from 'components/popover'
import { useDashboardViewContext } from 'components/contexts/DashboardViewContext'
import type { BlockType } from 'components/blocks'
import type { PopoverProps } from 'components/popover'

type AddBlockMenuProps = PopoverProps & { id: string }

const AddBlockMenu = React.forwardRef<HTMLDivElement, AddBlockMenuProps>(({
  id,
  ...menuProps
}, ref) => (
  <Popover autoFocus ref={ref} {...menuProps}>
    <Scrollbars autoHeight autoHeightMin="min(60vh, 720px)" autoHide>
      <PopoverBody css={{ width: 240 }}>
        <Basics id={id} />
        <PopoverDivider />
        <Datas id={id} />
        <PopoverDivider />
        <Charts id={id} />
        <PopoverDivider />
        <Layouts id={id} />
        <PopoverDivider />
        <Elements id={id} />
      </PopoverBody>
    </Scrollbars>
  </Popover>
))

const AddBlockItem = ({ id, block }: any) => {
  const [ _, parentId, __, index ] = id.split(':')
  const { activeUrn } = useDashboardViewContext()
  const handleAddBlock = useAppendBlockToParent(activeUrn, parentId, index, block)

  return (
    <PopoverItem
      size="small"
      icon={BLOCK_TYPE_TO_ICON_MAP[block.type as BlockType]}
      onClick={handleAddBlock}
      text={BLOCK_TYPE_TO_NAME_MAP[block.type as BlockType]}
    />
  )
}

const withBlockCategory = (category: keyof typeof blocksByCategory) => ({ id }: { id: string }) => (
  <>
    {blocksByCategory[category].map((block) => (
      <AddBlockItem
        key={block.type}
        block={block}
        id={id}
      />
    ))}
  </>
)

const Basics = withBlockCategory('basics')

const Datas = withBlockCategory('datas')

const Charts = withBlockCategory('charts')

const Layouts = withBlockCategory('layouts')

const Elements = withBlockCategory('elements')

AddBlockMenu.displayName = 'AddBlockMenu'

export default AddBlockMenu
