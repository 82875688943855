import React, { createContext, useContext, useRef } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { useRect } from '@reach/rect'
import type { PropsWithChildren } from 'react'

import Flex from 'components/layout/Flex'
import PageLoader from 'components/loaders/PageLoader'
import reportError from 'lib/reportError'
import spaces from 'styles/primitives/spaces'
import { DEFAULT_ERROR_MESSAGE, DEFAULT_ERROR_TITLE } from 'lib/parseError'
import { generateThemeVariants, styled } from 'styles/stitches'
import type { Space } from 'styles/primitives/spaces'

type MasonryProps = PropsWithChildren<{
  className?: string,
  cols?: number,
  containerPadding?: Space,
  gap?: Space
}>

type MasonryContextType = {
  cols: number,
  containerPadding: Space,
  gridEl: React.RefObject<HTMLDivElement> | null,
  gridRect: DOMRect | null,
  gap: Space
}

const BLOCK_GAP = 32
const RESIZEABLE_HANDLE_CLASS_NAME = 're-resizable-handle'
const NUM_COLS = 60

const spaceVariants = generateThemeVariants('space')

const StyledFlex = styled(Flex, {
  minHeight: '100%',
  position: 'relative',
  alignContent: 'flex-start',

  '&.item_dragging': {
    zIndex: 3
  },
  '&.item_releasing': {
    zIndex: 2
  },
  '&.item_hidden': {
    zIndex: 0
  },

  variants: {
    gap: spaceVariants,
    containerPadding: spaceVariants
  }
})

const spaceOptions = (Object.keys(spaceVariants) as unknown as Space[])

spaceOptions.forEach((gap) => {
  spaceOptions.forEach((containerPadding) => {
    StyledFlex.compoundVariant({
      gap,
      containerPadding
    }, {
      marginBottom: `calc(-${spaces[gap]}/2)`,
      marginLeft: `calc(-${spaces[gap]}/2 + ${spaces[containerPadding]})`,
      marginRight: `calc(-${spaces[gap]}/2 + ${spaces[containerPadding]})`,
      marginTop: `calc(-${spaces[gap]}/2)`
    })
  })
})

const MasonryContext = createContext<MasonryContextType>({
  cols: 0,
  containerPadding: 0,
  gridEl: null,
  gridRect: null,
  gap: 0
})

function useMasonryContext() {
  const value = useContext(MasonryContext)

  return value
}

const ErrorFallback = ({ error }: FallbackProps) => (
  <PageLoader
    empty={{
      title: DEFAULT_ERROR_TITLE,
      subtitle: process.env.REACT_APP_ENV !== 'production' ? error?.message : DEFAULT_ERROR_MESSAGE,
      variant: 'negative'
    }}
  />
)

function Masonry({
  className,
  children,
  cols = NUM_COLS,
  containerPadding = 0,
  gap = BLOCK_GAP,
  ...others
}: MasonryProps) {
  const gridEl = useRef<HTMLDivElement>(null)
  const gridRect = useRect(gridEl)

  return (
    <MasonryContext.Provider
      value={{
        cols,
        containerPadding,
        gap,
        gridRect,
        gridEl
      }}
    >
      <StyledFlex
        className={className}
        containerPadding={containerPadding}
        gap={gap}
        ref={gridEl}
        wrap="wrap"
        {...others}
      >
        <ErrorBoundary FallbackComponent={ErrorFallback} onError={reportError}>
          {children}
        </ErrorBoundary>
      </StyledFlex>
    </MasonryContext.Provider>
  )
}

export default Masonry

export { useMasonryContext }

export { BLOCK_GAP, NUM_COLS, RESIZEABLE_HANDLE_CLASS_NAME }
