import dayjs, { Dayjs } from 'dayjs'
import React, { useMemo, useState, useEffect } from 'react'
import type { ValueType } from 'react-select'

import * as mixins from 'styles/mixins'
import Flex from 'components/layout/Flex'
import Icon from 'components/icons/Icon'
import SimpleSelect, { SimpleSelectOption } from 'components/select/SimpleSelect'
import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'
import TimePicker, { MeridiemType, TimeFormat } from 'components/date/TimePicker'
import typography from 'styles/primitives/typography'
import { css, styled } from 'styles/stitches'
import { DATE_FORMATS } from 'components/contentEditors/generic/fields/fieldProps'

type DatePickerQuickLinksType = typeof quickLinksOptions[number]

type DatePickerProps = {
  activeDay?: number,
  activeMonth?: number,
  activeYear?: number,
  activeHour?: number,
  activeMinute?: number,
  activeSecond?: number,
  activeMeridiem?: MeridiemType,
  onChangeDay?: (v: number) => void,
  onChangeMonth?: (v: number) => void,
  onChangeYear?: (v: number) => void,
  onChangeHour?: (v: number) => void,
  onChangeMinute?: (v: number) => void,
  onChangeSecond?: (v: number) => void,
  onChangeMeridiem?: (v: MeridiemType) => void,

  collapsible?: boolean,
  compact?: boolean,
  defaultCollapsed?: boolean,
  defaultSelectedDate?: any,
  defaultSelectedStartDate?: any,
  defaultSelectedEndDate?: any,
  hideOutOfMonth?: boolean,
  inline?: boolean,
  inputFormat?: DATE_FORMATS,
  minDate?: any,
  maxDate?: any,
  onSelectDate?: (startDate: Dayjs, endDate?: Dayjs) => void,
  quickLinks?: DatePickerQuickLinksType[] | {label: string, handler: () => void}[],
  selectionMode?: 'single' | 'range',
  stepBy?: 'day' | 'month' | 'year',
  withTime?: boolean,
  onClose: () => void
}

const QUICK_LINKS = {
  YESTERDAY: 'Yesterday',
  TODAY: 'Today',
  TOMORROW: 'Tomorrow'
} as const

const quickLinksOptions = Object.values(QUICK_LINKS)

const DAYS = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ]
const MONTHS = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ]
const WEEK_LENGTH = DAYS.length
const WEEKEND_DAYS = [ 'Sunday', 'Saturday' ]

const CELL_FONT_SIZE = 14
const CELL_VERTICAL_PADDING = 15
const CELL_WIDTH = 40
const COMPACT_VARIANT_HORIZONTAL_PADDING = 20
const COMPACT_VARIANT_VERTICAL_PADDING = 25
const DATE_SELECTOR_MONTH_WIDTH = '60%'
const DATE_SELECTOR_WRAPPER_WIDTH = 210
const INLINE_VARIANT_BODY_HORIZONTAL_PADDING = 25
const QUICKLINKS_WRAPPER_TOP_PADDING = 25
const SELECTOR_OPEN_Z_INDEX_INC = 1
const CELL_HEIGHT = (
  (parseFloat(typography.lineHeights.normal) * CELL_FONT_SIZE)
  + (CELL_VERTICAL_PADDING * 2)
)

const getQuickLinkDate = (quickLink: DatePickerQuickLinksType) => {
  const today = dayjs().startOf('day')

  switch (quickLink) {
    case QUICK_LINKS.YESTERDAY:
      return today.subtract(1, 'day')
    case QUICK_LINKS.TODAY:
      return today
    case QUICK_LINKS.TOMORROW:
      return today.add(1, 'day')
    default:
      throw new Error(`Incorrect value passed to quickLinks: ${quickLink}`)
  }
}

const StyledDatePicker = styled(Flex, {
  userSelect: 'none',
  width: '100%',
  variants: {
    selectorOpen: {
      true: {
        position: 'relative',

        '&::before': {
          backgroundColor: 'light100',
          content: "' '",
          opacity: 0.8,
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: `topbar${SELECTOR_OPEN_Z_INDEX_INC}`
        }
      }
    },
    inline: {
      true: {
        backgroundColor: 'light100',
        paddingY: COMPACT_VARIANT_VERTICAL_PADDING + 10,
        paddingX: COMPACT_VARIANT_HORIZONTAL_PADDING + 10
      }
    },
    compact: {
      true: {
        paddingY: COMPACT_VARIANT_VERTICAL_PADDING,
        paddingX: COMPACT_VARIANT_HORIZONTAL_PADDING
      }
    }
  }
})

const StyledHeaderTitle = styled(Text, {
  cursor: 'pointer',
  flex: '1 0 auto',
  textAlign: 'center',

  '&:focus': {
    outline: 'none'
  }
})

const StyledDateSelectorWrapper = styled(Flex, {
  width: DATE_SELECTOR_WRAPPER_WIDTH
})

const StyledSelector = styled(SimpleSelect, {
  '& .selector__menu': {
    zIndex: `topbar${SELECTOR_OPEN_Z_INDEX_INC}${1}`
  }
})

const StyledBody = styled(Flex, {
  paddingTop: 10,
  variants: {
    inline: {
      true: {
        paddingX: INLINE_VARIANT_BODY_HORIZONTAL_PADDING
      }
    },
    collapsed: {
      true: {
        display: 'none'
      }
    }
  }
})

const StyledMonthStepIcon = styled(Icon, {
  ...mixins.transition('simple'),

  color: 'dark100',
  cursor: 'pointer',

  '&:hover': {
    color: 'dark100'
  }
})

const StyledCell = styled('div', {
  width: CELL_WIDTH,
  paddingY: CELL_VERTICAL_PADDING,
  textAlign: 'center',
  color: 'dark900'

})

const StyledDateCell = styled(StyledCell, {
  cursor: 'pointer',
  fontFamily: 'normal',
  fontSize: CELL_FONT_SIZE,
  letterSpacing: 0,
  lineHeight: 'normal',

  '&:hover': {
    backgroundColor: 'positive300',
    borderRadius: 4,
    color: 'light100'
  },
  variants: {
    weekend: {
      true: {
        backgroundColor: 'light300'
      }
    },
    outOfMonth: {
      true: {
        color: 'dark100'
      }
    },
    outOfMonthHidden: {
      true: {
        opacity: 0,
        pointerEvents: 'none'
      }
    },
    selected: {
      true: {
        backgroundColor: 'positive300',
        borderRadius: 4,
        color: 'light100'
      }
    },
    inRange: {
      true: {
        position: 'relative',

        '&::before': {
          backgroundColor: 'positive300',
          content: "''",
          opacity: 0.06,
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        }
      }
    }
  }
})

const StyledDaysRow = styled(Flex, {
  borderBottomStyle: 'solid',
  borderBottomWidth: 1,
  borderColor: 'light300'
})

const StyledQuickLinksWrapper = styled(Flex, {
  paddingTop: QUICKLINKS_WRAPPER_TOP_PADDING
})

const classes = {
  monthPicker: css({
    '&&': {
      width: DATE_SELECTOR_MONTH_WIDTH
    }
  }),
  yearPicker: css({
    '&&': {
      width: `calc(100% - ${DATE_SELECTOR_MONTH_WIDTH})`
    }
  })
}

function DatePicker({
  /* Externally controlled */
  activeDay,
  activeMonth,
  activeYear,
  activeHour,
  activeMinute,
  activeSecond,
  activeMeridiem,
  onChangeDay,
  onChangeMonth,
  onChangeYear,
  onChangeHour,
  onChangeMinute,
  onChangeSecond,
  onChangeMeridiem,
  /* Externally controlled */

  collapsible = false,
  compact = false,
  defaultCollapsed = false,
  defaultSelectedDate = null,
  defaultSelectedEndDate = null,
  defaultSelectedStartDate = null,
  hideOutOfMonth = false,
  inline = false,
  maxDate = dayjs().add(100, 'year'),
  minDate = dayjs().subtract(100, 'year'),
  onSelectDate,
  quickLinks = [],
  selectionMode = 'single',
  stepBy = 'month',
  withTime = false,
  inputFormat = withTime ? DATE_FORMATS.DD_MM_YYYY_hh_mm_A : DATE_FORMATS.DD_MM_YYYY,
  onClose
}: DatePickerProps) {
  let initialSelectedDate = null
  let initialVisibleDate = null
  let initialSelectedStartDate = null
  let initialSelectedEndDate = null

  function getDefaultDate(date: string | Dayjs) {
    if (date) {
      const parsedDate = dayjs(date)
      return withTime ? parsedDate : parsedDate?.startOf('day')
    }

    return null
  }

  function getInitialDate(initialDate: Dayjs | null) {
    if (!initialDate) {
      const now = dayjs()
      return withTime ? now : now?.startOf('day')
    }

    return initialDate
  }

  // We use `.startOf('day')` to remove time components
  if (selectionMode === 'single') {
    initialSelectedDate = getDefaultDate(defaultSelectedDate)
    initialVisibleDate = getInitialDate(initialSelectedDate)
  } else if (selectionMode === 'range') {
    initialSelectedStartDate = getDefaultDate(defaultSelectedStartDate)
    initialSelectedEndDate = getDefaultDate(defaultSelectedEndDate)
    initialVisibleDate = getInitialDate(initialSelectedStartDate)
  }

  // We *must* have a selectedDate in collapsible mode
  if (collapsible) {
    if (selectionMode === 'single') {
      initialSelectedDate = getInitialDate(initialSelectedDate)
    } else if (selectionMode === 'range') {
      initialSelectedStartDate = getInitialDate(initialSelectedStartDate)
    }
  }

  const [ isCollapsed, setIsCollapsed ] = useState(defaultCollapsed)
  const [ isSelectorOpen, setIsSelectorOpen ] = useState(false)
  const [ selectedDate, setSelectedDate ] = useState<Dayjs>(initialSelectedDate as Dayjs)
  const [ selectedEndDate, setSelectedEndDate ] = useState<Dayjs>(initialSelectedEndDate as Dayjs)
  const [
    selectedStartDate, setSelectedStartDate
  ] = useState<Dayjs>(initialSelectedStartDate as Dayjs)
  const [ visibleDate, setVisibleDate ] = useState<Dayjs>(initialVisibleDate as Dayjs)

  useEffect(() => {
    if (activeMonth) {
      setVisibleDate((date) => date?.clone().month(activeMonth - 1))
    }

    if (activeYear) {
      setVisibleDate((date) => date?.clone().year(activeYear))
    }
  }, [ activeMonth, activeYear ])

  const toggleCollapse = () => setIsCollapsed(!isCollapsed)

  const handleSelectMonth = (option: ValueType<SimpleSelectOption>) => {
    const { value } = option as SimpleSelectOption

    if (value) {
      onChangeMonth?.(parseInt(value, 10) + 1)
      setVisibleDate(visibleDate?.clone().month(parseInt(value, 10)))
    }
  }

  const handleSelectYear = (option: ValueType<SimpleSelectOption>) => {
    const { value } = option as SimpleSelectOption

    if (value) {
      onChangeYear?.(parseInt(value, 10))
      setVisibleDate(visibleDate?.clone().year(parseInt(value, 10)))
    }
  }

  const stepForward = () => {
    if (isCollapsed) {
      selectDate(visibleDate.clone().add(1, 'day'))
    } else {
      setVisibleDate(visibleDate.clone().add(1, stepBy))
    }
  }

  const stepBackward = () => {
    if (isCollapsed) {
      selectDate(visibleDate?.clone().subtract(1, 'day'))
    } else {
      setVisibleDate(visibleDate?.clone().subtract(1, stepBy))
    }
  }

  const selectDate = (date: Dayjs) => {
    if (selectionMode === 'single') {
      setSelectedDate(date.clone())
      setVisibleDate(date.clone())

      if (onSelectDate) {
        onChangeDay?.(date.date())
        onSelectDate(date.clone())
      }
    } else if (selectionMode === 'range') {
      let newSelectedStartDate = null
      let newSelectedEndDate = null

      if (selectedEndDate && selectedStartDate?.isSame(selectedEndDate)) {
        if (date.isBefore(selectedStartDate)) {
          newSelectedStartDate = date.clone()
          newSelectedEndDate = selectedEndDate?.clone()
        } else {
          newSelectedStartDate = selectedStartDate?.clone()
          newSelectedEndDate = date.clone()
        }
      } else {
        newSelectedStartDate = date.clone()
        newSelectedEndDate = date.clone()
      }

      setSelectedStartDate(newSelectedStartDate)
      setSelectedEndDate(newSelectedEndDate)
      setVisibleDate(newSelectedStartDate)

      if (onSelectDate) {
        onSelectDate(newSelectedStartDate.clone(), newSelectedEndDate.clone())
      }
    }
  }

  const handleQuickLinkClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    quickLink: DatePickerQuickLinksType | {label: string, handler: () => void}
  ) => {
    e.preventDefault()
    if (typeof quickLink === 'string') {
      selectDate(getQuickLinkDate(quickLink))
    } else {
      quickLink.handler()
      onClose()
    }
  }

  const onTimeChange = (dateTime: Dayjs) => {
    const currentDate = (selectedDate || visibleDate).clone()

    const newSelectedDate = dayjs()
      .year(activeYear || currentDate.year())
      .month((activeMonth || currentDate.month() + 1) - 1)
      .date(activeDay || currentDate.date())
      .hour(dateTime.hour())
      .minute(dateTime.minute())

    setSelectedDate(newSelectedDate)
    setVisibleDate(newSelectedDate)
    onSelectDate?.(newSelectedDate)
  }

  const getFirstLastWeekDates = () => {
    let firstDate = visibleDate.clone().startOf('month').startOf('week')
    let lastDate = visibleDate.clone().endOf('month').endOf('week')

    if (withTime) {
      firstDate = firstDate.clone()
        .set('hour', visibleDate.hour())
        .set('minute', visibleDate.minute())

      lastDate = lastDate.clone()
        .set('hour', visibleDate.hour())
        .set('minute', visibleDate.minute())
    }

    return { firstDate, lastDate }
  }

  const bodyHeight = useMemo(() => {
    const firstDate = visibleDate.clone().startOf('month').startOf('week')
    const lastDate = visibleDate.clone().endOf('month').endOf('week')

    const weeks = (lastDate.diff(firstDate, 'day') + 1) / WEEK_LENGTH

    return weeks * Math.floor(CELL_HEIGHT)
  }, [ visibleDate ])

  const renderCollapsibleTitle = () => {
    const a11yProps = {
      role: 'button',
      tabIndex: -1,
      onClick: toggleCollapse
    }

    let title = visibleDate.format('MMMM YYYY')

    if (isCollapsed) {
      title = (selectedDate || selectedStartDate || visibleDate).format('MMMM D, YYYY')
    }

    return (
      <StyledHeaderTitle
        fontSize={18}
        fontWeight="bold"
        {...a11yProps}
      >
        {title}
      </StyledHeaderTitle>
    )
  }

  const renderSelectors = () => {
    const [ selectedMonth, selectedYear ] = visibleDate.format('MMMM YYYY').split(' ')

    const months = MONTHS.map((option, index) => ({ label: option, value: `${index}` }))
    const monthValue = months.find((m) => (activeMonth !== undefined ? m.value === `${activeMonth - 1}` : m.label === selectedMonth))

    const noOfYears = maxDate.year() - minDate.year()
    const years = Array(noOfYears)
      .fill(minDate.year())
      .map((x, y) => ({ label: `${x + y}`, value: `${x + y}` }))
    const yearValue = years.find((m) => (activeYear !== undefined ? m.label === `${activeYear}` : m.label === selectedYear))

    return (
      <StyledDateSelectorWrapper gap={4} justifyContent="center">
        <StyledSelector
          wrapperClassName={classes.monthPicker}
          classNamePrefix="selector"
          data-month="true"
          onChange={handleSelectMonth}
          onMenuClose={() => setIsSelectorOpen(false)}
          onMenuOpen={() => setIsSelectorOpen(true)}
          options={months}
          value={monthValue}
        />
        <StyledSelector
          wrapperClassName={classes.yearPicker}
          classNamePrefix="selector"
          onChange={handleSelectYear}
          onMenuClose={() => setIsSelectorOpen(false)}
          onMenuOpen={() => setIsSelectorOpen(true)}
          options={years}
          value={yearValue}
        />
      </StyledDateSelectorWrapper>
    )
  }

  const renderHeaderTitle = () => (collapsible ? renderCollapsibleTitle() : renderSelectors())

  const renderDateCell = (date: Dayjs) => {
    const firstOfMonth = visibleDate.clone().startOf('month')
    const endOfMonth = visibleDate.clone().endOf('month')

    const cellDate = date.clone()

    const isWeekend = WEEKEND_DAYS.indexOf(cellDate.format('dddd')) !== -1

    const isOutOfMonth = cellDate.isBefore(firstOfMonth) || cellDate.isAfter(endOfMonth)

    let isSelected = cellDate.date() === activeDay

    if (selectionMode === 'single' && cellDate.isSame(selectedDate) && activeDay === undefined) {
      isSelected = true
    }

    if (selectionMode === 'range' && (cellDate.isSame(selectedStartDate) || cellDate.isSame(selectedEndDate))) {
      isSelected = true
    }

    let isInRange = false

    if (selectionMode === 'range' && (cellDate.isAfter(selectedStartDate) && cellDate.isBefore(selectedEndDate))) {
      isInRange = true
    }

    return (
      <StyledDateCell
        key={cellDate.format('YYYY-MM-DD')}
        role="button"
        tabIndex={-1}
        weekend={isWeekend}
        outOfMonth={isOutOfMonth}
        outOfMonthHidden={hideOutOfMonth && isOutOfMonth}
        selected={isSelected}
        inRange={isInRange}
        onKeyUp={() => selectDate(cellDate)}
        onClick={() => selectDate(cellDate)}
      >
        {cellDate.date()}
      </StyledDateCell>
    )
  }

  const renderDatesRow = (week: Dayjs[]) => {
    const key = `week-${week[0].format('YYYY-MM-DD')}`

    return (
      <Flex key={key} alignItems="center" justifyContent="center">
        {week.map(renderDateCell)}
      </Flex>
    )
  }

  const renderDates = () => {
    const { firstDate, lastDate } = getFirstLastWeekDates()

    const month = []
    let week = []

    for (let date = firstDate.clone(); date < lastDate; date = date.clone().add(1, 'd')) {
      if (week.length === WEEK_LENGTH) {
        month.push(week)
        week = []
      }

      week.push(date)
    }

    if (week.length > 0) {
      month.push(week)
    }

    return month.map(renderDatesRow)
  }

  const renderQuickLinks = () => {
    if (quickLinks.length === 0) {
      return null
    }

    return (
      <StyledQuickLinksWrapper
        alignItems="center"

        direction="row"
        justifyContent="space-around"
      >
        {quickLinks.map((quickLink) => {
          const link = typeof quickLink === 'string' ? quickLink : quickLink.label
          return (
            <TextLink
              as="button"
              type="button"
              key={link}
              mode="subtle"
              fontSize={12}
              onClick={
                (e: React.MouseEvent<HTMLAnchorElement>) => handleQuickLinkClick(e, quickLink)
              }
              variant="underlined"
            >
              {link}
            </TextLink>
          )
        })}
      </StyledQuickLinksWrapper>
    )
  }

  const containsDate = inputFormat.includes('/')

  return (
    <StyledDatePicker
      alignItems="flex-end"
      compact={compact}
      inline={inline}
      selectorOpen={isSelectorOpen}
    >
      <Flex grow={1} direction="column">
        {containsDate && (
        <Flex alignItems="center" justifyContent="space-between">
          <div
            onClick={stepBackward}
            onKeyUp={stepBackward}
            role="button"
            tabIndex={-1}
          >
            <StyledMonthStepIcon name="arrow-left" size={10} />
          </div>
          {renderHeaderTitle()}
          <div
            onClick={stepForward}
            onKeyUp={stepForward}
            role="button"
            tabIndex={-1}
          >
            <StyledMonthStepIcon name="arrow-right" size={10} />
          </div>
        </Flex>
        )}
        <StyledBody
          collapsed={isCollapsed}
          inline={inline}
          gap={18}
        >
          {containsDate && (
          <Flex direction="column" grow={1}>
            <StyledDaysRow
              alignItems="center"
              justifyContent="center"
            >
              {DAYS.map((day) => (
                <StyledCell
                  as={Text}
                  color="dark500"
                  fontSize={12}
                  key={day}
                  textTransform="uppercase"
                >
                  {day[0]}
                </StyledCell>
              ))}
            </StyledDaysRow>
            {renderDates()}
          </Flex>
          )}
          {withTime && (
            <TimePicker
              activeHour={activeHour}
              activeMinute={activeMinute}
              activeSecond={activeSecond}
              activeMeridiem={activeMeridiem}
              onChangeHour={onChangeHour}
              onChangeMinute={onChangeMinute}
              onChangeSecond={onChangeSecond}
              onChangeMeridiem={onChangeMeridiem}
              defaultSelectedDate={selectedDate}
              height={bodyHeight}
              timeFormat={inputFormat as TimeFormat}
              onChange={onTimeChange}
            />
          )}
        </StyledBody>
        {renderQuickLinks()}
      </Flex>
    </StyledDatePicker>
  )
}

export type {
  DatePickerQuickLinksType,
  DatePickerProps
}

export default DatePicker
